import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UnauthGuard implements CanActivate {

  currentURL='';

    constructor(
        private router: Router,
        private auth: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      // console.log('h');
      //this.currentURL = window.location.href;
     // alert(this.currentURL);
       // const _zf_token = this.auth._zf_tokenValue;
       // if (_zf_token) {
        //  alert('1');
         // this.router.navigate(['/user/profile']);
        //} else {
         // return true
        //}
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        // this.router.navigate(['/user/profile']);
        return true;
    }
}
