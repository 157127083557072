<div class="about-us-banner privacy-banner d-flex align-items-center z-pagebanner">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="about-us-banner-content">
                    <div class="about-us-banner-content-inner">
                        <h3 class="title">{{blogdetail?.title}}</h3>

                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">

            </div>
        </div>
    </div>
</div>
<section class="blog-detail-bgcolor">
    <nav class=" mt-0 mb-0 mob-sec-breadcrumb" aria-label="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <ol class="breadcrumb mt-0 pt-0 pb-0">
                        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/news-articles">Aviation News and Articles</a></li>
                        <li class="breadcrumb-item active" aria-current="page">News Detail</li>
                    </ol>
                </div>
            </div>
        </div>
    </nav>
    <div class="back-prieves-page">
        <div class="container">
            <div class="row">
                <div class="col-12 col-12 text-right text-sm-right text-md-left text-lg-left mb-3">
                    <a _ngcontent-hgo-c119="" class="btn btn-ziofly-default default" [routerLink]="['/news-articles']">
                        <span _ngcontent-hgo-c119="">Back</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="blog-detail-img mt-0">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12 detail-col-p" *ngIf="blogdetail?.image">
                    <div class="detail-img-wrap">
                        <!--<img  alt="image" src="http://dev.ziofly.com/admin/storage/app/products/sNbKMEtuzjPmVecKmYnz1QmviN9ljFHKID6FInID.jpg" class="img-fluid">-->
                        <img alt="image" src="{{variables.serverAssetUrl}}{{blogdetail?.image}}" class="img-fluid">

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-12">
                    <div class="page-wrapper privacy-pages-cms privacy-list-mob">
                        <div class="mg-btm">
                            <span [appMath]="blogdetail?.description"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>