import { Component, HostListener, Inject, OnInit, OnDestroy,} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CmsService } from 'src/app/services/cms.service';
import { LoaderService } from 'src/app/common/loader/loader.service';
import { variables } from 'src/environments/variables';
import { AuthService } from 'src/app/services/auth.service';
import { Title, Meta } from '@angular/platform-browser';
import GLightbox from 'glightbox';

declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  lightbox:any;
  cmsData;
  variables = variables;
  productList:any;
  allMediaFiles: any = [];
  isMobile: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cms: CmsService,
    private loader: LoaderService,
    private auth: AuthService, private titleService: Title,
    private metaTagService: Meta
    ) {
      this.cms.getCmsData().subscribe(x=>{
        this.cmsData = x[0]
      })
      this.getProducts();
    }
  public slides = [
    { id: 1, title: 2},
    { id: 3, title: 4},
    { id: 5, title: 6},
    { id: 7, title: 8},
    { id: 9, title: 10},
    { id: 11, title: 12},
    { id: 13, title: 14},
    { id: 15, title: 16},
    { id: 17, title: 18},
    { id: 19, title: 20},
    { id: 21, title: 22},
    { id: 23, title: 24},
    { id: 25, title: 26}
  ]
  public clients = [
    { id: 1, src: 'assets/img/clients/clients_01.png'},
    { id: 3, src: 'assets/img/clients/clients_02.png'},
    { id: 5, src: 'assets/img/clients/clients_03.png'},
    { id: 7, src: 'assets/img/clients/clients_04.png'},
    { id: 9, src: 'assets/img/clients/clients_05.png'},
    { id: 1, src: 'assets/img/clients/clients_01.png'},
    { id: 3, src: 'assets/img/clients/clients_02.png'},
    { id: 5, src: 'assets/img/clients/clients_03.png'},
    { id: 7, src: 'assets/img/clients/clients_04.png'},
    { id: 9, src: 'assets/img/clients/clients_05.png'}
  ]
  public currentSlide : 0;
  categoryslideConfig = {
    "autoplay": false,
    "draggable": false,
    "speed": 1000,
    "slidesToShow": 1,
    "slidesToScroll" : 1,
    // "centerMode": true,
    "infinite": true,
    "focusOnSelect": false,
    "arrows": false,
    "dots": true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          arrows:false,
          dots:true
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          arrows:false,
          dots:true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows:false,
          dots:true
        }
      }
    ]
  };
  slideConfig = {
    "autoplay": false,
    "draggable": true,
    "speed": 1000,
    "slidesToShow": 1,
    "slidesToScroll" : 1,
    "centerMode": true,
    "infinite": true,
    "centerPadding": '0px',
    "variableWidth": true,
    "focusOnSelect": true,
    "asNavFor" :".thumbs",
    "arrows": false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          arrows:false,
          dots:true,
          variableWidth: false
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          arrows:false,
          dots:true,
          variableWidth: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows:false,
          dots:true,
          variableWidth: false
        }
      }
    ]
  };
  slideConfig_2 = {
    "draggable": false,
    "speed": 1000,
    "slidesToShow": 1,
    "slidesToScroll" : 1,
    "centerMode": true,
    "infinite": true,
    "centerPadding": '0px',
    "variableWidth": true,
    "focusOnSelect": true,
    "asNavFor" :".feedback",
    "arrows": false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          arrows:false,
          variableWidth: false
        }
      },
      {
        breakpoint: 700,
        settings: {
          arrows:false,
          variableWidth: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows:false,
          variableWidth: false
        }
      }
    ]
  };
  slideConfig_3 = {
    "draggable": false,
    "speed": 5000,
    "autoplay": true,
    "autoplaySpeed": 100,
    "cssEase": 'linear',
    "slidesToShow": 5,
    "slidesToScroll" : 1,
    "infinite": true,
    "centerPadding": '0px',
    "arrows": false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '40px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '40px',
        }
      }
    ]
  };
  public whyChooseUsVideo;
  onResize(event){
    const screensize = event.target.innerWidth;
    this.isMobile = screensize < 768;
  }
  ngOnInit(): void {
    this.isMobile = window.innerWidth < 768;
    this.whyChooseUsVideo = <HTMLVideoElement>document.getElementById("whyChooseUsVideo")
    setTimeout(() => {
      this.initRatings()
    }, 500);
    document.getElementsByTagName('body')[0].classList.add('is-front-page')
    this.getTags();

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // if (document.body.scrollTop > 110 || document.documentElement.scrollTop > 110) {
    //   document.getElementById('flightImg').classList.add('exit');
    // } else {
    //   document.getElementById('flightImg').classList.remove('exit');
    // }
  }
  setImage(e) {
    document.getElementById(e.srcElement.dataset.id).setAttribute('src', e.srcElement.src)
  }
  initRatings() {
    $(document).find('[data-rating]').each(function() {
      var width = [0, 0, 0, 0, 0];
      var t = Number($(this).attr('data-rating'));
      if (t > 0 && t <= 1) {
          width[0] = t * 100
      }
      if (t > 1 && t <= 2) {
          width[0] = 100;
          width[1] = (t - 1) * 100
      }
      if (t > 2 && t <= 3) {
          width[0] = 100;
          width[1] = 100;
          width[2] = (t - 2) * 100
      }
      if (t > 3 && t <= 4) {
          width[0] = 100;
          width[1] = 100;
          width[2] = 100;
          width[3] = (t - 3) * 100
      }
      if (t > 4 && t <= 5) {
          width[0] = 100;
          width[1] = 100;
          width[2] = 100;
          width[3] = 100;
          width[4] = (t - 4) * 100
      }
      var h = '';
      for (var i = 0; i < width.length; i++) {
          h += `<div class="recent-slider--rating-star">
          <img src="assets/img/star.png" alt="ziofly rating star">
          <span style="width: ` + width[i] + `%;"></span>
          </div>`;
      }
      $(this).html(h)
  })
  }
  addSlide() {
    // this.slides.push({img: "http://placehold.it/350x150/777777"})
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
    this.currentSlide = e.currentSlide
  }

  beforeChange(e) {
    this.currentSlide = e.currentSlide

  }

  playVid() {
      this.whyChooseUsVideo.play();

  }

  pauseVid() {
    this.whyChooseUsVideo.pause();
  }
  videoPlaying(e) {
    if(e.type=='play') {
      $('.laptop-video-play').hide()
      $('.laptop-video-overlay').hide()
      $('#whyChooseUsVideo').attr("controls", "controls")
    } else {
      $('.laptop-video-play').show()
      $('.laptop-video-overlay').show()
      $('#whyChooseUsVideo').removeAttr("controls")
    }
  }


  ngOnDestroy() : void {
    document.getElementsByTagName('body')[0].classList.remove('is-front-page')
  }

  getProducts() {
    var apidata = {
      home_product_type: 'premium',
      offset: 0,
      limit: 10,
    }
    this.cms.getProducts(apidata).subscribe(data => {
      this.productList = data['data'];
      // console.log('product list in home',this.productList)
      var indexcount = 0;
      this.productList.forEach(element => {
        if (element.mediafiles) {
          var imagecount = element.mediafiles.length;
          this.productList[indexcount].image_count = imagecount;
          // element.mediafiles.forEach(media => {
          //   this.allMediaFiles.push(media)
          // });
        }
        indexcount++;
      });
      setTimeout(() => {
        // $(".recent-slider--card-thumb-0").click();
        // $(".recent-slider--card-thumb-holder img:first-child").click();
        //lightbox settings
        for(var im=0;im<10;im++){
          this.lightbox = GLightbox({
            selector: "#glbox-"+im,
          });
        }
      }, 500);
    })
  }

  isVideo(path){
    // const images = ["jpg", "gif", "png"]
    const videos = ["mp4", "3gp", "ogg"]
    const extension = path.split(".")[1]
    if (videos.includes(extension)) {
      return true;
    }
    else{
      return false;
    }
  }

  sellAircraft(){
    const _zf_token = this.auth._zf_tokenValue;
    if(_zf_token){

    }else{
      localStorage.setItem('sellAircraft', 'true');
    }
  }
data:any=[];
  getTags(){
    this.cms.getMetaTags().subscribe(res=>{
      this.data = res;
      console.log('meta tags',this.data)
      if(this.data['status'] == true){
        this.titleService.setTitle(this.data['data'][0].home_seo.title);
        this.metaTagService.updateTag(
          { name: 'keywords', content: this.data['data'][0].home_seo.keywords },
        );

        this.metaTagService.updateTag(
          { name: 'description', content:  this.data['data'][0].home_seo.description },
        );
      }else{
      }
    })
  }
//   settings = {
//     counter: false,
//     plugins: [lgZoom],
// };
// onBeforeSlide = (detail: BeforeSlideDetail): void => {
//     const { index, prevIndex } = detail;
//     console.log(index, prevIndex);
// };
}
// function lightGallery(arg0: HTMLElement, arg1: any) {
//   throw new Error('Function not implemented.');
// }

