import { Component, HostListener, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { variables } from 'src/environments/variables';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { LoaderService } from 'src/app/common/loader/loader.service';
import { Router } from '@angular/router';
import { SwalService } from 'src/app/services/swal.service';
import { validations } from 'src/environments/validations';
import { ToastrService } from 'src/app/services/toastr.service';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'ziofly-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public searchProductHeader = '';
  validations=validations;
  public mobile_search: boolean = false;
  public navbar_open: boolean = false;
  public variables = variables;
  isUserLoggedIn;
  userData;
  scrollconfig;
  // profile_img = variables.blank_img;
  profile_img = 'assets/img/dummy-user.png';
  cmsData;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private auth: AuthService,
    private userService: UserService,
    private loader: LoaderService,
    private router: Router,
    private swal: SwalService,
    private toastr: ToastrService,
    private cms: CmsService
    ) { 
      this.cms.getCmsData().subscribe(x=>{
        this.cmsData = x[0]
      })
      const _zf_token = this.auth._zf_tokenValue;
        if (_zf_token) {
        this.isUserLoggedIn = true ;         
        this.userService.getUserData().subscribe(data=>{
        this.userData = data;
        // console.log(this.userData)
        if(this.userData.avatar) {
          this.profile_img = variables.serverAssetUrl+this.userData.avatar
        } else {
          this.profile_img = 'assets/img/dummy-user.png'
        }
      })        
        }
    }
    
  @ViewChild('btn_search_product_header') btn_search_product_header: ElementRef;

  ngOnInit(): void {
  }

  logout() {
    this.swal.confirm({title:this.validations.messages.logout.warning}).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if(result.isConfirmed){
        this.toastr.success({msg:this.validations.messages.logout.success});
        this.auth.logout();
        localStorage.removeItem('sellAircraft');
        this.router.navigate(['/']);
        this.isUserLoggedIn = false
      }else if(result.isDenied){}
    })
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      document.getElementById('header').classList.add('scrolled');
    } else {
      document.getElementById('header').classList.remove('scrolled');
    }
  }
  navOpen(e) {
    this.navbar_open=true;
    e.stopPropagation()
    document.getElementsByTagName('html')[0].classList.add('fix')
  }
  navClose(e) {
    this.navbar_open=false;
    e.stopPropagation();
    document.getElementsByTagName('html')[0].classList.remove('fix')
  }
  searchProduct(){
    if(this.searchProductHeader){
      this.router.navigate(["/products", { search: this.searchProductHeader}])
    }
  }
  searchProductEnter(){
    // btn_search_product_header
    this.btn_search_product_header.nativeElement.click();
  }

  sellAircraft(){
    const _zf_token = this.auth._zf_tokenValue;
    if(_zf_token){

    }else{
      localStorage.setItem('sellAircraft', 'true');
    }
  }

  paymentMethod(){
    this.userService.setOption('value', '');
    this.router.navigate(["/user/payment-method"]);
  }
}
