import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { variables } from 'src/environments/variables';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { validations } from 'src/environments/validations';
import { Title, Meta } from '@angular/platform-browser';

declare var $;
@Component({
  selector: 'ziofly-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state(
        'inactive',
        style({
          height: '0',
          opacity: '0',
        })
      ),
      state(
        'active',
        style({
          height: '*',
          opacity: '1',
        })
      ),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out')),
    ]),
  ],
})
export class ProductListComponent implements OnInit {
  searchValue = '';
  filterDeley = false;
  filterTimer;
  firstLoadWithFilterPage = false;
  isFilterLoading = false;
  isWaitTime = false;
  isWaitTimeForFilter = false;
  isFirstCall = true;
  isIncludePhotos = false;
  isincludePhotosDisable = false;
  productList: any = [];
  productLoading = false;
  variables = variables;
  public offset = 0;
  public config;
  isPriceFilter = false;
  minValue: number = 0;
  maxValue: number = 1000000;
  options: Options = {
    floor: 0,
    ceil: 1000000,
    translate: (value: number): string => {
      return '' + value;
    },
  };
  dd_options = {
    minimumResultsForSearch: -1,
  };
  ddTime_options = {
    minimumResultsForSearch: -1,
    placeholder: 'Select',
  };
  ddd_options = {
    multiple: true,
    closeOnSelect: false,
    dropdownCssClass: '--select2-filter',
    templateResult: this.format,
    placeholder: 'Type...',
    escapeMarkup: function (markup) {
      return markup;
    },
  };
  ddd_options_filterCategories = {
    multiple: true,
    closeOnSelect: false,
    dropdownCssClass: '--select2-filter filterCategories',
    templateResult: this.format,
    placeholder: 'Type...',
    escapeMarkup: function (markup) {
      return markup;
    },
  };
  ddd_options_filterModels = {
    multiple: true,
    closeOnSelect: false,
    dropdownCssClass: '--select2-filter filterModels',
    templateResult: this.format,
    placeholder: 'Model...',
    escapeMarkup: function (markup) {
      return markup;
    },
  };
  ddd_options_filterMakes = {
    multiple: true,
    closeOnSelect: false,
    dropdownCssClass: '--select2-filter filterMakes',
    templateResult: this.format,
    placeholder: 'Make...',
    escapeMarkup: function (markup) {
      return markup;
    },
  };
  ddd_options_filterSpecialUse = {
    multiple: true,
    closeOnSelect: false,
    dropdownCssClass: '--select2-filter filterSpecialUse',
    templateResult: this.format,
    placeholder: 'Special Use...',
    escapeMarkup: function (markup) {
      return markup;
    },
  };
  ddd_options_filterYears = {
    multiple: true,
    closeOnSelect: false,
    dropdownCssClass: '--select2-filter filterYears',
    templateResult: this.format,
    placeholder: 'Year...',
    escapeMarkup: function (markup) {
      return markup;
    },
  };
  ddd_options_filterConditions = {
    multiple: true,
    closeOnSelect: false,
    dropdownCssClass: '--select2-filter filterConditions',
    templateResult: this.format,
    placeholder: 'Condition...',
    escapeMarkup: function (markup) {
      return markup;
    },
  };
  @ViewChild('mobtoggleFilter', { read: ElementRef })
  mobtoggleFilter: ElementRef;
  limitList = ['12', '24', '36'];
  limitListShowable = [];

  filterCategories: any = [];
  filterConditions: any = [];
  filterMakes: any = [];
  filterModels: any = [];
  filterYears: any = [];
  filterTime: any = [];
  filterSpecialUse: any = [];

  filterValue = {
    filterCategories: [],
    filterConditions: [],
    filterMakes: [],
    filterSpecialUse: [],
    filterModels: [],
    filterYears: [],
    filterTime: '',
  };

  sortValue = {
    make: '',
    model: '',
    since_update: '',
    price: '',
    total_time: '',
    year: '',
    engine_time: '',
  };
  zipcode = '';
  isZipcodeEnable = false;

  navigationSubState: { [menu: string]: string } = {
    Profile: 'inactive',
    Messages: 'inactive',
  };
  special_use_category = false;

  validations = validations;
  enquiryForm: FormGroup;
  isModalFormView = false;
  submitted = false;
  submitting = false;
  selectedProduct: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private cms: CmsService,
    private toastr: ToastrService,
    public UserService: UserService,
    private render: Renderer2,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 12,
      totalItems: 0,
      query: '',
    };

    this.setLimit();
    this.getProducts();
    this.getProductFilter();
    this.getTags();
  }

  toggleNavigationSub(menuName: string, event) {
    var el = event.target;
    const hasClass = el.classList.contains('open');
    if (hasClass) {
      el.classList.remove('open');
    } else {
      el.classList.add('open');
    }
    event.preventDefault();
    this.navigationSubState[menuName] =
      this.navigationSubState[menuName] === 'inactive' ? 'active' : 'inactive';
  }
  // mob-filter-toggle
  mobfilterFlag = false;
  mobfilterAnimation = 'inactive';
  mobFilter() {
    this.mobfilterFlag
      ? (this.mobfilterFlag = false)
      : (this.mobfilterFlag = true),
      this.mobfilterFlag
        ? this.render.addClass(
            this.mobtoggleFilter.nativeElement,
            'mobfilter-toggle'
          )
        : this.render.removeClass(
            this.mobtoggleFilter.nativeElement,
            'mobfilter-toggle'
          ),
      (this.mobfilterAnimation =
        this.mobfilterAnimation === 'inactive' ? 'active' : 'inactive');
  }

  search() {
    this.mobfilterFlag
      ? (this.mobfilterFlag = false)
      : (this.mobfilterFlag = true),
      this.mobfilterFlag
        ? this.render.addClass(
            this.mobtoggleFilter.nativeElement,
            'mobfilter-toggle'
          )
        : this.render.removeClass(
            this.mobtoggleFilter.nativeElement,
            'mobfilter-toggle'
          ),
      (this.mobfilterAnimation =
        this.mobfilterAnimation === 'inactive' ? 'active' : 'inactive');
  }

  changePhone() {
    setTimeout(() => {
      var _phone = this.enquiryForm.value.phone_copy;
      _phone = _phone.replace(/[^A-Z0-9]/gi, '_');
      this.enquiryForm.controls.phone.setValue(_phone);
    }, 200);
  }
  ngOnInit(): void {
    this.navigationSubState['Type'] = 'inactive';
    this.navigationSubState['Conditions'] = 'inactive';
    this.navigationSubState['Model'] = 'inactive';
    this.navigationSubState['Make'] = 'inactive';
    this.navigationSubState['Year'] = 'inactive';
    this.navigationSubState['Time'] = 'inactive';
    this.navigationSubState['Sort'] = 'inactive';
    $('#btn_search_product_header').click(() => {
      this.searchBtnClick();
    });

    this.enquiryForm = this.formBuilder.group({
      name: [
        '',
        [Validators.required, Validators.pattern(validations.def.name.regex)],
      ],
      email: ['', [Validators.required, Validators.email]],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(this.validations.def.phone.regex),
        ],
      ],
      phone_copy: [''],
      message: [
        'I am interested in product. Is it still up for the sale? Please reach out with my email or phone number',
        Validators.required,
      ],
      notify_for_similar_listing: [''],
      notify_offers_giveaways_newsletters: [''],
      product_id: [''],
      seller_id: [''],
    });
    $(document).ready(function () {
      $('.mob-toggle-btn').click(function () {
        $(this).toggleClass('mobToggle_close');
        $('.filter-box').toggleClass('mobFilter_wrap');
      });
    });
  }
  searchBtnClick() {
    var _oldValue = this.searchValue;
    setTimeout(() => {
      var _search = this.route.snapshot.paramMap.get('search');
      if (_search) {
        this.searchValue = _search;
        if (_oldValue != this.searchValue) {
          this.getProducts();
          this.getProductFilter();
        }
      }
    }, 500);
  }
  setLimit() {
    this.limitListShowable = [];
    var isStop = false;
    this.limitList.map((x) => {
      // if(isStop || this.config.totalItems==undefined){
      if (isStop) {
        return;
      }
      var y = x;
      if (x > this.config.totalItems) {
        y = this.config.totalItems;
        isStop = true;
      }
      this.limitListShowable.push({
        id: x,
        text: y + ' out of ' + this.config.totalItems,
      });
    });
  }
  getProducts() {
    if (this.isWaitTime) {
      // console.log('getProducts() Call in wait time!!')
      return;
    }
    this.isWaitTime = true;
    setTimeout(() => {
      this.isWaitTime = false;
    }, 1500);

    if (this.offset == 0) {
      this.config.currentPage = 1;
    }

    var _price = '';
    if (this.isPriceFilter) {
      _price = this.minValue + ' - ' + this.maxValue;
    }
    var _isIncludePhotos: any = this.isIncludePhotos;
    if (_isIncludePhotos) {
      _isIncludePhotos = 'true';
    } else {
      _isIncludePhotos = 'false';
    }

    var _special_use_category = 'false';
    if (this.special_use_category) {
      _special_use_category = 'true';
    }
    var apidata = {
      price: _price,
      offset: this.offset,
      limit: this.config.itemsPerPage,
      category_id: this.filterValue.filterCategories,
      make_id: this.filterValue.filterMakes,
      condition: this.filterValue.filterConditions,
      model_id: this.filterValue.filterModels,
      year: this.filterValue.filterYears,
      time: this.filterValue.filterTime,
      includephotos: _isIncludePhotos,
      sort_by: this.sortValue,
      zipcode: this.zipcode,
      specialusecategories: _special_use_category,
      search: this.searchValue,
    };
    // const fs=require('fs')
    this.productLoading = true;
    this.navigationSubState['Sort'] = 'inactive';
    this.cms.getProducts(apidata).subscribe((data) => {

      this.productList = data['data'];
      // fs.writeFile('sitemap.xml',this.productList,err=>{
      //   if(err){
      //     console.log(err)
      //   }
      // })

      this.config.totalItems = data['totalproducts'];
      if (this.config.totalItems == undefined) {
        this.config.totalItems = 0;
      }
      this.setLimit();
      // console.log(this.productList)
      this.productLoading = false;

    });
    this.scrollToPageWrapper();
  }
  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.offset = (newPage - 1) * this.config.itemsPerPage;
    this.getProducts();
  }
  scrollToPageWrapper() {
    if (this.isFirstCall) {
      setTimeout(() => {
        this.isFirstCall = false;
        return;
      }, 2000);
    }
    let el = document.getElementById('page_wrapper');
    if (el) {
      var headerOffset = 50;
      var elementPosition = el.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }
  onUserChangeEnd(changeContext: ChangeContext): void {
    this.isPriceFilter = true;
    this.offset = 0;
    this.getProducts();
    this.getProductFilter();
  }
  resetAllFilters() {
    this.router.navigate(['/products']);
    this.searchValue = '';
    $('#search_product_header').val('');
    this.special_use_category = false;

    this.isPriceFilter = false;
    this.isIncludePhotos = false;
    this.offset = 0;
    this.filterValue.filterCategories = [];
    (this.filterValue.filterConditions = []),
      (this.filterValue.filterMakes = []),
      (this.filterValue.filterSpecialUse = []),
      (this.filterValue.filterModels = []),
      (this.filterValue.filterYears = []),
      (this.filterValue.filterTime = ''),
      this.getProducts();
    this, this.getProductFilter();
  }
  listLimitChange(e) {
    if (this.isFirstCall) {
      return;
    }
    this.config.itemsPerPage = e;
    this.getProducts();
  }

  getProductFilter() {
    if (this.isWaitTimeForFilter) {
      return;
    }
    this.isWaitTimeForFilter = true;
    setTimeout(() => {
      this.isWaitTimeForFilter = false;
    }, 2000);
    $(
      'body>.select2-container.select2-container--default.select2-container--open'
    ).removeClass('select2-container--open');
    var _price = '';
    if (this.isPriceFilter) {
      _price = this.minValue + ' - ' + this.maxValue;
    }
    var _isIncludePhotos: any = this.isIncludePhotos;
    if (_isIncludePhotos) {
      _isIncludePhotos = 'true';
    } else {
      _isIncludePhotos = 'false';
    }

    var _special_use_category = 'false';
    if (this.special_use_category) {
      _special_use_category = 'true';
    }
    // var special_use='false';

    var apiData = {
      price: _price,
      category_id: this.filterValue.filterCategories,
      make_id: this.filterValue.filterMakes,
      specialUse_id: this.filterValue.filterSpecialUse,
      condition: this.filterValue.filterConditions,
      model_id: this.filterValue.filterModels,
      year: this.filterValue.filterYears,
      includephotos: _isIncludePhotos,
      specialusecategories: _special_use_category,
      search: this.searchValue,
    };

    var exFilterValue = this.filterValue;


    this.cms.getProductFilter(apiData).subscribe((data) => {

      this.isFilterLoading = true;
      // console.log(data['data']);
      if (data['data'].includephotos) {
        if (data['data'].includephotos[0].productcount) {
          this.isincludePhotosDisable = false;
        } else {
          this.isincludePhotosDisable = true;
        }
      }
      // console.log('photo include',data['data'].includephotos)
      this.filterCategories = [];
      data['data'].categories.map((x) => {
        if (x.productcount > 0) {
          this.filterCategories.push({
            id: x.id,
            text: x.name + ' (' + x.productcount + ')',
          });
        }
      });

      this.filterConditions = [];
      data['data'].conditions.map((x) => {
        if (x.productcount > 0) {
          this.filterConditions.push({
            id: x.condition,
            text: x.condition + ' (' + x.productcount + ')',
          });
        }
      });

      this.filterYears = [];
      data['data'].years.map((x) => {
        if (x.productcount > 0) {
          this.filterYears.push({
            id: x.year,
            text: x.year + ' (' + x.productcount + ')',
          });
        }
      });

      this.filterSpecialUse = [];
      data['data'].special_use_categories.map(x=>{
        // console.log("special use filter",x)
        if(x.productcount>0){
          this.filterSpecialUse.push({
            id:x.id,
            text:x.name+'('+x.productcount+')',
          })
        }

      })
      // console.log("special use array",this.filterCategories)

      this.filterMakes = [];
      data['data'].makes.map((x) => {
        // console.log(x)
        if (x.productcount > 0) {
          this.filterMakes.push({
            id: x.id,
            text: x.name + ' (' + x.productcount + ')',
          });
        }
      });
      // console.log("filter of makes",this.filterMakes)

      this.filterModels = [];
      data['data'].models.map((x) => {
        if (x.productcount > 0) {
          this.filterModels.push({
            id: x.id,
            text: x.name + ' (' + x.productcount + ')',
          });
        }
      });

      this.filterTime = [];
      data['data'].totaltime.map((x) => {
        if (x.productcount > 0) {
          this.filterTime.push({
            id: x.value,
            text: x.time + ' (' + x.productcount + ')',
          });
        }
      });


      if (this.firstLoadWithFilterPage) {
        this.firstLoadWithFilterPage = false;
        this.filterValue.filterCategories = [];
        this.filterCategories.forEach((x) => {
          this.filterValue.filterCategories.push(x.id);
        });
      }
      setTimeout(() => {
        // console.log('exFilterValue',exFilterValue);
        this.filterValue = exFilterValue;
        this.isFilterLoading = false;
        setTimeout(() => {
          this.checkFilterCheckboxes();
        }, 300);
      }, 100);
    });
  }
  checkFilterCheckboxes() {
    this.filterValue.filterCategories.forEach((x) => {
      $('.filterCategories input[data-item-id="' + x + '"]').prop(
        'checked',
        true
      );
    });
    this.filterValue.filterSpecialUse.forEach((x) => {
      $('.filterSpecialUse input[data-item-id="' + x + '"]').prop(
        'checked',
        true
      );
    });
    this.filterValue.filterMakes.forEach((x) => {
      $('.filterMakes input[data-item-id="' + x + '"]').prop('checked', true);
    });
    this.filterValue.filterModels.forEach((x) => {
      $('.filterModels input[data-item-id="' + x + '"]').prop('checked', true);
    });
    this.filterValue.filterConditions.forEach((x) => {
      $('.filterConditions input[data-item-id="' + x + '"]').prop(
        'checked',
        true
      );
    });
    this.filterValue.filterYears.forEach((x) => {
      $('.filterYears input[data-item-id="' + x + '"]').prop('checked', true);
    });
  }

  filterChangeType(e) {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      if (e) {
        this.filterValue.filterCategories = e;
        // this.filterValue.filterSpecialUse=e;
        this.config.currentPage = 1;
        this.getProducts();
        this.getProductFilter();
        const selectOptionsContainer = $('.select2-container');
        selectOptionsContainer.removeClass('select2-container--open');
      }
    }, 1500);
  }
  filterChangeCondition(e) {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      if (e) {
        this.filterValue.filterConditions = e;
        this.config.currentPage = 1;
        this.getProducts();
        this.getProductFilter();
      }
    }, 1500);
  }
  filterChangeModal(e) {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      if (e) {
        this.filterValue.filterModels = e;
        this.config.currentPage = 1;
        this.getProducts();
        this.getProductFilter();
      }
    }, 1500);
  }
  filterChangeSpecialUse(e) {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      if (e) {
        this.filterValue.filterSpecialUse = e;
        this.config.currentPage = 1;
        this.getProducts();
        this.getProductFilter();
      }
    }, 1500);
  }
  filterChangeMake(e) {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      if (e) {
        this.filterValue.filterMakes = e;
        this.config.currentPage = 1;
        this.getProducts();
        this.getProductFilter();
      }
    }, 1500);
  }
  filterChangeYear(e) {
    clearTimeout(this.filterTimer);
    this.filterTimer = setTimeout(() => {
      if (e) {
        this.filterValue.filterYears = e;
        this.config.currentPage = 1;
        this.getProducts();
        this.getProductFilter();
      }
    }, 1500);
  }
  filterChangeTime(e) {
    if (e) {
      this.filterValue.filterTime = e;
      this.config.currentPage = 1;
      this.getProducts();
      this.getProductFilter();
    }
  }

  sortChangeMake(e) {
    this.resetSortValue();
    this.sortValue.make = e.target.value;
    this.getProducts();
    //  this.navigationSubState['Sort'] = 'inactive'
  }
  sortChangeModel(e) {
    this.resetSortValue();
    this.sortValue.model = e.target.value;
    this.getProducts();
    // this.navigationSubState['Sort'] = 'inactive'
  }
  sortChangeSince(e) {
    this.resetSortValue();
    this.sortValue.since_update = e.target.value;
    this.getProducts();
    // this.navigationSubState['Sort'] = 'inactive'
  }
  sortChangePrice(e) {
    this.resetSortValue();
    this.sortValue.price = e.target.value;
    this.getProducts();
    // this.navigationSubState['Sort'] = 'inactive'
  }
  sortChangeTotalTime(e) {
    this.resetSortValue();
    this.sortValue.total_time = e.target.value;
    this.getProducts();
    // this.navigationSubState['Sort'] = 'inactive'
  }
  sortChangeYear(e) {
    this.resetSortValue();
    this.sortValue.year = e.target.value;
    this.getProducts();
    // this.navigationSubState['Sort'] = 'inactive'
  }
  sortChangeEngineTime(e) {
    this.resetSortValue();
    this.sortValue.engine_time = e.target.value;
    this.getProducts();
    // this.navigationSubState['Sort'] = 'inactive'
  }
  resetSortValue() {
    this.sortValue.make = '';
    this.sortValue.model = '';
    this.sortValue.since_update = '';
    this.sortValue.price = '';
    this.sortValue.total_time = '';
    this.sortValue.year = '';
    this.sortValue.engine_time = '';
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  zipcodeKeyPress() {
    setTimeout(() => {
      if (this.zipcode.length > 4) {
        this.isZipcodeEnable = true;
      } else {
        this.isZipcodeEnable = true;
      }
    }, 100);
  }
  zipcodeBtnClick() {
    this.getProducts();
  }
  isVideo(path) {
    const images = ['jpg', 'gif', 'png'];
    const videos = ['mp4', '3gp', 'ogg', 'quicktime', 'qt'];
    const extension = path.split('.')[1];
    if (videos.includes(extension)) {
      return true;
    } else {
      return false;
    }
  }
  changeIncludePhoto() {
    this.getProducts();
    this.getProductFilter();
  }

  format(option) {
    if (!option.id) {
      return option.text;
    }
    var ob =
      '<label class="label-filter-input"><input type="checkbox" data-item-id="' +
      option.id +
      '" class="mr-2">' +
      option.text +
      '</label>';
    return ob;
  }
  addToFav(id) {
    var _token = localStorage.getItem('_zf_token');
    if (_token) {
      var apidata = {
        product_id: id,
      };
      let index = this.productList.findIndex((item) => item.id === id);
      this.productList[index].is_favourite = true;
      this.UserService.addtoFavourite(apidata).subscribe(
        (data) => {
          // console.log(data)
          if (data['status']) {
            // this.toastr.success({msg: data['message']});
          } else {
            // this.toastr.error({msg: data['message']});
          }
        },
        (error) => {
          // this.toastr.error({msg:'Something went wrong, please try again'})
        }
      );
    } else {
      this.toastr.error({ msg: 'Please login to make product as favorite' });
    }
  }
  addToUnfav(id) {
    var _token = localStorage.getItem('_zf_token');
    if (_token) {
      var apidata = {
        product_id: id,
      };
      let index = this.productList.findIndex((item) => item.id === id);
      this.productList[index].is_favourite = false;
      this.UserService.unFavourite(apidata).subscribe(
        (data) => {
          // console.log(data)
          if (data['status']) {
            // this.toastr.success({msg: data['message']});
          } else {
            // this.toastr.error({msg: data['message']});
          }
        },
        (error) => {
          // this.toastr.error({msg:'Something went wrong, please try again'})
        }
      );
    } else {
      this.toastr.error({
        msg: 'Please login to mark the product as favorite.',
      });
    }
  }
  openModalForm(product) {
    this.selectedProduct = product;
    this.isModalFormView = true;
    const body = document.getElementsByTagName('html')[0];
    body.classList.add('overflow-hidden');
    //this.enquiryForm.get('phone').setValue('');
    this.enquiryForm.get('phone_copy').setValue('');
    this.enquiryForm
      .get('message')
      .setValue(
        'I am interested in product. Is it still up for the sale? Please reach out with my email or phone number'
      );
  }
  closeModalForm() {
    this.enquiryForm.reset();
    this.submitting = false;
    this.submitted = false;
    this.isModalFormView = false;
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('overflow-hidden');
  }

  get f() {
    return this.enquiryForm.controls;
  }

  enquiryFormSubmit() {
    this.submitted = true;
    if (this.enquiryForm.invalid) {
      return;
    }

    if (this.enquiryForm.value.notify_for_similar_listing) {
      this.enquiryForm.controls.notify_for_similar_listing.setValue(1);
    } else {
      this.enquiryForm.controls.notify_for_similar_listing.setValue(0);
    }

    if (this.enquiryForm.value.notify_offers_giveaways_newsletters) {
      this.enquiryForm.controls.notify_offers_giveaways_newsletters.setValue(1);
    } else {
      this.enquiryForm.controls.notify_offers_giveaways_newsletters.setValue(0);
    }

    this.enquiryForm.controls.product_id.setValue(this.selectedProduct.id);
    this.enquiryForm.controls.seller_id.setValue(this.selectedProduct.sellerid);
    this.submitting = true;
    this.cms.productSendenquiry(this.enquiryForm.value).subscribe(
      (data) => {
        if (data['status']) {
          this.toastr.success({ msg: data['message'] });
          this.enquiryForm.reset();
          this.closeModalForm();
          this.submitting = false;
          this.submitted = false;
        } else {
          if (data['data']) {
            this.toastr.error({ msg: data['data'][0] });
            this.submitting = false;
          } else {
            this.toastr.error({ msg: data['message'] });
            this.submitting = false;
          }
        }
      },
      (error) => {
        this.submitting = false;
        this.toastr.error({ msg: error.message });
      }
    );
  }

  getTags() {
    this.cms.getMetaTags().subscribe((res) => {
      let data = res;
      if (data['status'] == true) {
        this.titleService.setTitle(data['data'][0].aircraftlisting_seo.title);

        this.metaTagService.updateTag({
          name: 'keywords',
          content: data['data'][0].aircraftlisting_seo.keywords,
        });

        this.metaTagService.updateTag({
          name: 'description',
          content: data['data'][0].aircraftlisting_seo.description,
        });
      } else {
      }
    });
  }

}
