import { Injectable } from '@angular/core';
import { validations } from 'src/environments/validations';
import { ToastrService } from './toastr.service';

@Injectable({
  providedIn: 'root'
})
export class FileValidateService {
  validations=validations;
  constructor(private toastr:ToastrService) { }
  getValid(file) { 
    var idxDot = file.name.lastIndexOf(".") + 1;
    var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();    
    if ((validations.def.image.type).indexOf(extFile) > -1) {      
    }  else {
      this.toastr.error({msg:validations.messages.image_upload.wrong_type})  
      return
    } 
    if(file.size>validations.def.image.max) {
      this.toastr.error({msg:validations.messages.image_upload.wrong_size_max})    
      return
    } 
    if(file.size<validations.def.image.min) {
      this.toastr.error({msg:validations.messages.image_upload.wrong_size_min})    
      return
    } 
    return true 
  }
}

