import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ziofly-div-loader',
  templateUrl: './div-loader.component.html',
  styleUrls: ['./div-loader.component.scss']
})
export class DivLoaderComponent implements OnInit {
  @Input() status: boolean = false;
  constructor() { }

  ngOnInit(): void {
    
  }

}
