import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { variables } from 'src/environments/variables';


@Component({
  selector: 'ziofly-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.scss']
})

export class BlogDetailComponent implements OnInit {
  public blogcontent : any;
  user1: any;
  blogdetail: any;
  blog_slug: any;
  variables = variables;
  ipAddress = '';

  constructor(private ActivatedRoute: ActivatedRoute, private Router: Router, 
    private BlogService: BlogService, private http:HttpClient) { }

  ngOnInit(): void {
   this.blog_slug = this.ActivatedRoute.snapshot.paramMap.get("slug");
   this.getBlogDetail(this.blog_slug);
   this.getIPAddress();
  }

  getIPAddress(){
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
      var apidata = {
        ip_address: this.ipAddress,
        blog_id: this.blog_slug
      };
      this.BlogService.blogView(apidata).subscribe(res=>{
        let data = res;
      })
    });
  }

  getBlogDetail(slug){
    let data = {
      slug: slug
    }
    this.BlogService.blogBannerDetail(data).subscribe(res2=>{
      let data = res2;
      this.blogdetail = res2['data'][0];
    })
  }

}
