import { Component, OnInit } from '@angular/core';
import {Options } from '@angular-slider/ngx-slider'
import { BlogService } from 'src/app/services/blog.service';
import { environment } from 'src/environments/environment';
import { variables } from 'src/environments/variables';
import { CmsService } from 'src/app/services/cms.service';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ziofly-blog',
  templateUrl:'./blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  cmsData;  
  public headingdata:any;
  public baseUrlServer: string = environment.apiUrl + '/';
  variables = variables;
  slideConfig_3 = {
    "autoplay": false,
    "draggable": false,
    "speed": 200,
    "slidesToShow": 6, 
    "slidesToScroll" : 1,
    "infinite": true,
    "arrows": true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        }
      }
    ]
  };
  public bannerdetail: any;
  getcontent: any;
  user1: any;
  public config;
  public offset = 0;
  limitList= ['6','12','18'];
  limitListShowable = [];
  featureBlogData: any;
  latestBlogData: any;

  constructor(private blog:BlogService,
    private user:BlogService,
    private dataheading:BlogService,
    private cms: CmsService, private Router: Router,
    private titleService: Title,
    private metaTagService: Meta) 
    { this.cms.getCmsData().subscribe(x=>{
      this.cmsData = x[0];
    }) 
  }
 
  ngOnInit(): void {
    this.config = {
      currentPage: 1,
      itemsPerPage: 6,
      totalItems: 0,
      query: ''
    };
    this.getBanner();
    this.setLimit();
    this.listLimitChange(6);
    this.featureBlogDataAPI();
    this.getTags();
  }

  featureBlogDataAPI(){
    var apidata = {
      offset: '',
      limit: '',
    };
    this.blog.featureBlogBanner(apidata).subscribe(res=>{
      let data = res;
      this.featureBlogData = res['data'];
    })
  }
  
  setLimit(){
    this.limitListShowable = [];
    var isStop = false;
    this.limitList.map(x=>{
      if(isStop){
        return;
      }
      var y = x;
      if(x>this.config.totalItems){
          y = this.config.totalItems
          isStop = true;
      }
      this.limitListShowable.push({
          id: x,
          text: y + " out of "+this.config.totalItems
      })
    })
  }

  getBanner(){
    var apidata = {
      offset: this.offset,
      limit: this.config.itemsPerPage,
    };
    this.blog.latestBlogBanner(apidata).subscribe(res=>{
      let data = res;
      this.latestBlogData = res['data'];
      this.config.totalItems = res['blogcount'];
      if(this.config.totalItems==undefined){
        this.config.totalItems = 0;
      }
      this.setLimit();
    })
  }

  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.offset = (newPage-1)*this.config.itemsPerPage;
    this.getBanner();
    /*let el = document.getElementById('page_wrapper');
    var headerOffset = 50;
    var elementPosition = el.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });*/
  }

  listLimitChange(e){
    this.config.itemsPerPage = e;
    this.getBanner();
  }

  blogDetail(slug){
    this.Router.navigate(['/blog-detail', slug]);
  }

  getTags(){
    this.cms.getMetaTags().subscribe(res=>{
      let data = res;
      if(data['status'] == true){
        this.titleService.setTitle(data['data'][0].blog.title);
       
        this.metaTagService.updateTag(
          { name: 'keywords', content: data['data'][0].blog.keywords },
        );

        this.metaTagService.updateTag(
          { name: 'description', content:  data['data'][0].blog.description },
        );
      }else{
      }
    })
  }

}
