import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { variables } from 'src/environments/variables';

@Injectable({
  providedIn: 'root'
})

export class BlogService {

  variables = variables;
  public baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  featureBlogBanner(data){
    return this.http.post(`${this.baseUrl}/featureblog`, data)
  }

  latestBlogBanner(data){
    return this.http.post(`${this.baseUrl}/latestblog`, data)
  }

  headingcontent(){
    return this.http.get(`${this.baseUrl}/getcontent`);
  }

  blogBannerDetail(data){
    return this.http.post(`${this.baseUrl}/blogdetail`, data)
  }

  hotNewsDetail(data){
    return this.http.post(`${this.baseUrl}/news_section_first`, data)
  }

  aviationNewsDetail(data){
    return this.http.post(`${this.baseUrl}/news_section_second`, data)
  }

  newsDetail(data){
    return this.http.post(`${this.baseUrl}/getnews`, data)
  }

  eventDetail(data){
    return this.http.post(`${this.baseUrl}/aviationevent`, data)
  }

  blogView(data){
    return this.http.post(`${this.baseUrl}/blogview`, data)
  }

}