<div class="main-wrapper pt-5">
  <div class="container main-container pb-5">
    <div class="row back-btn">
      <!-- <div class="col-sm-6 col-6">
                <a class="back-1"><img src="assets/img/angle-left.svg" alt=""> Back</a>
            </div> -->
    </div>
    <div class="steper-wrap">
      <h1>Please provide your card details</h1>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-6 mb-4">
          <div class="card_listing-col">
            <h3 *ngIf="selectedListngType?.subscriptiontype.toLowerCase() === 'free'">60 Days {{selectedListngType?.subscriptiontype | titlecase}} Listing</h3>
            <h3 *ngIf="selectedListngType?.subscriptiontype.toLowerCase() !== 'free'">{{selectedListngType?.subscriptiontype | titlecase}} Listing</h3>
            <p>Note: {{selectedListngType?.subscriptionmessage}}.</p>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center justify-content-between card-add-heading-wrap mb-3">
            <h2 class="title-light mb-0">Saved card(s)</h2>
            <button type="submit" class="btn" (click)="addCardDetails()"><span
                class="material-icons">
                add
              </span> {{ savedCardDetails?.cardno ? "Update" : "Add" }} Card
            </button>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6 mb-4" *ngIf="savedCardDetails?.cardno">


          <div class="card-detail-info">
            <img src="assets/img/card-img.png" alt="Card" class="img-fluid">
            <div class="card-txt-info">
              <div class="card-no-wrap">
                <p class="card-no">**** **** **** {{savedCardDetails?.cardno}}</p>
                <!-- <span><small>{{savedCardDetails?.cardno}}</small></span> -->
              </div>
              <div class="card-valid-detail">
                <span>VALID THRU</span>
                <span><img src="assets/img/arrow-icon-2.svg" alt=""></span>
                <span>{{savedCardDetails?.exp_month}}/{{savedCardDetails?.exp_year}}</span>
              </div>
              <!-- <div class="cardholder-name">
                                <p>john doe</p>
                            </div> -->
            </div>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-6 mb-3 buttonClass">
          <div class="d-flex flex-wrap align-items-center justify-content-between">

            <button class="btn btn-green w-100 px-3 mb-2" (click)="addPromoCode()">
              Add Promo Code
            </button>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-6 mb-4" *ngIf="hideCardForm || hidePromoCode">
          <div class="card-info">
            <div *ngIf="!hidePromoCode && hideCardForm">
              <div class="card-type mb-3 d-flex align-items-center flex-wrap justify-content-between">
                <img src="assets/img/mastercard.png" alt="" class="img-fluid mx-2">
                <img src="assets/img/discover.png" alt="" class="img-fluid mx-2">
                <img src="assets/img/visa.png" alt="" class="img-fluid mx-2">
              </div>
              <p>{{selectedListngType?.subscriptionmessage}}</p>
              <span id="error-message" style="color: red;"></span>
            </div>
            <form class="form-min-height-170" *ngIf="!hidePromoCode && hideCardForm" [formGroup]="saveCardForm">
              <div class="row">
                <div class="col-12 col-md-12 col-lg-6">
                  <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.cardnumber.errors }">
                    <div class="zf-input-label">Card Number</div>
                    <div class="zf-input-control">
                      <input type="text" class="form-control" formControlName="cardnumber"
                        (keypress)="numberOnly($event)" (change)="cardNumType()" maxlength="23">
                    </div>
                  </div>
                  <div *ngIf="submitted && f.cardnumber.errors" class="invalid-feedback">
                    <div *ngIf="f.cardnumber.errors.required">Card Number is required</div>
                    <div *ngIf="f.cardnumber.errors.minlength || f.cardnumber.errors.maxlength">
                      Card Number should contain minimum 14 digit and maximum 19 digit
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-12 col-lg-6 pr-2">
                  <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.month.errors }">
                    <div class="zf-input-label">Month</div>
                    <div class="zf-input-control">
                      <!-- <select class="form-control" matNativeControl formControlName="month">
                        <option *ngFor="let item of monthList" [value]="item?.id">
                          {{item?.text}}
                        </option>
                      </select> -->
                      <ng-select2 matNativeControl formControlName="month">
                        <option *ngFor="let item of monthList" [value]="item?.id">
                          {{item?.text}}
                        </option>
                        <!-- <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option> -->
                      </ng-select2>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.month.errors" class="invalid-feedback">
                    <div *ngIf="f.month.errors.required">Month is required</div>
                  </div>
                </div>

                <div class="col-6 col-md-12 col-lg-6 pl-2">
                  <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.year.errors }">
                    <div class="zf-input-label">Year</div>
                    <div class="zf-input-control">
                      <!-- <select class="form-control" matNativeControl formControlName="year">
                        <option *ngFor="let item of yearList" [value]="item?.id">
                          {{item?.text}}
                        </option>
                      </select> -->
                      <ng-select2 matNativeControl formControlName="year">
                        <option *ngFor="let item of yearList" [value]="item?.id">
                          {{item?.text}}
                        </option>
                        <!-- <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option> -->
                      </ng-select2>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.year.errors" class="invalid-feedback">
                    <div *ngIf="f.year.errors.required">Year is required</div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-lg-6">
                  <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.cvv.errors }">
                    <div class="zf-input-label">Enter CVV</div>
                    <div class="zf-input-control">
                      <input type="text" pattern="[0-9]*" class="form-control" formControlName="cvv" maxlength="4"
                        (keypress)="numberOnly($event)">
                    </div>
                  </div>
                  <div *ngIf="submitted && f.cvv.errors" class="invalid-feedback">
                    <div *ngIf="f.cvv.errors.required">CVV is required</div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="d-flex align-items-center justify-content-between">
                    <button type="submit" [disabled]="submitting" class="btn btn-green w-100 px-3 mr-3"
                      (click)="onSubmitClick()">
                      <span *ngIf="!submitting">Save Card</span>
                      <span *ngIf="submitting">
                        <span class="zf-btn-loader"></span>
                      </span>
                    </button>
                    <button (click)="resetPaymentform()" class="btn btn-dark w-100 px-3">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <form [formGroup]="saveCardForm2" *ngIf="!hideCardForm && hidePromoCode" class="form-min-height-170">
              <div class="mb-0" id="add_promocode_2">
                <div class="add-card-card add-promo-code">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-6">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted2 && f2.promocode.errors }">
                        <div class="zf-input-label">Promo Code</div>
                        <div class="zf-input-control">
                          <input type="text" class="form-control" formControlName="promocode">
                        </div>
                      </div>
                      <div *ngIf="submitted2 && f2.promocode.errors" class="invalid-feedback">
                        <div *ngIf="f2.promocode.errors.required && submitted2">Promo Code is required</div>
                      </div>
                    </div>

                  </div>
                  <div class="mb-0 responsive-btn-wrap text-center">
                    <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active min-width-125"
                      (click)="applyPromoCode()">
                      <span *ngIf="!submitting">Apply Promo Code</span>
                      <span *ngIf="submitting">
                        <span class="zf-btn-loader"></span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="buttons-listing back-button-listing" *ngIf="!savedCardDetails?.cardno && !hidePromoCode"
        [hidden]="apply_promoCodeStatus == true">
        <ng-container>
          <!-- <a color="primary" class="btn btn-ziofly-default active mr-2">
                  <span>I will do it later</span>
                </a> -->
        </ng-container>
      </div>

      <div class="buttons-listing back-button-listing mb-3" [class.disable]="submitting"
        *ngIf="(savedCardDetails?.cardno || apply_promoCodeStatus == true) && hideafterEmailVarify">
        <!-- <ng-container *ngIf="selectedListngType?.productstatus==1 || selectedListngType?.productstatus==0">
                <button color="primary" class="btn btn-ziofly-default active mr-2">
                  <span>Save and Exit </span>
                </button>
              </ng-container> -->
        <!-- <ng-container *ngIf="!(selectedListngType?.productstatus==1 || selectedListngType?.productstatus==0)"> -->
        <ng-container *ngIf="selectedListngType?.show_publish_button">
          <!-- <button color="primary" class="btn btn-ziofly-default active mr-2">
                  <span> Draft your listing </span>
                </button> -->
          <div class="v-msgs-show d-block">
            <!--
            <div class="v-msgs-show d-block" [class.isbefore]="Vlist.length>0">
            <ul class="vList" *ngIf="Vlist.length>0">
              <li *ngFor="let item of Vlist; let i = index">
                {{item}}
              </li>
            </ul> -->
            <button color="primary" id="publish_Listing" class="btn btn-dark w-100 mb-2" (click)="onPublishCard()" type="submit">
              <span class="mr-2" *ngIf="isNextLoading">
                <span class="zf-btn-loader"></span>
              </span>
              <span class="chrg-txt"> Charge card & publish listing </span>
              <span class="publish-txt">Publish listing </span>
            </button>
          </div>
        </ng-container>
      </div>
      <div>
        <ng-container>
          <button color="primary" class="btn btn-dark w-100" (click)="backToAircraftList()" type="button">
            <span class="mr-2" *ngIf="isNextLoading">
              <span class="zf-btn-loader"></span>
            </span>
            <span> Back To List </span>
          </button>
        </ng-container>
      </div>
      <!-- <div *ngIf="!hideafterEmailVarify">
              <button color="primary" class="btn btn-ziofly-default active mr-2">
                <span>Go to Aircraft listing  </span>
              </button>
            </div> -->
    </div>
  </div>
</div>