<div class="breadcrumb-wrapper cstm-breadcrumb-wrapper section pb-0">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">My Listings</li>
      </ol>
    </nav>
  </div>
</div>
<div class="page-wrapper" id="page_wrapper">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg col-12 d-flex align-items-center mob-add-heading mb-2 mb-md-0 mb-lg-0">
            <h3 class="font-weight-bold text-body text-left text-md-center text-lg-left flex-grow-1">My
              Listings</h3>
            <a routerLink="/user/sell-aircraft" class="mc-data-link mob-add-btn btn btn-green">Sell Your Aircraft</a>
          </div>
          <div class="col-12 col-md-auto pr-md-0 align-self-center">
            <div class="search-my-product">
              <input type="search" placeholder="Search" [(ngModel)]="search_txt" (keyup.enter)="searchMyProduct()">
              <a (click)="searchMyProduct()" class="search-my-product-btn">
                <i class="icon-search"></i>
              </a>
            </div>
          </div>
          <div class="col-12 col-md-auto d-flex align-items-center filter-head-main mt-1 mt-md-0 mt-lg-0">
            <label class="show-label-mob" *ngIf="productList && productList.length>1">Showing results</label>
            <label class="show-label-mob" *ngIf="productList && productList.length==1">Showing result</label>
            <div class="filter-items-head-wrap">
              <div
                class="filter-items-head d-inline-flex mr-0 mr-sm-0 mr-md-3 mr-lg-3 flex-grow-1 mt-0 mt-md-3 mt-lg-3 mb-0 mb-md-3 mb-lg-3 mb-sm-0 mt-sm-0">
                <label class="show-label-desk" *ngIf="productList && productList.length>1">Showing results</label>
                <label class="show-label-desk" *ngIf="productList && productList.length==1">Showing result</label>
                <span class="d-block w-140px">
                  <ng-select2 [data]="limitListShowable" [options]="dd_options" [value]='12'
                    (valueChanged)="listLimitChange($event)"></ng-select2>
                </span>
              </div>
              <div class="text-center text-md-left d-flex align-items-center mx-auto">
                <a routerLink="/user/sell-aircraft" class="btn btn-ziofly-default active desk-add-btn"><span><img
                      src="assets/img/plus-icon-circle.png" class="mr-2"> Add Aircraft</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 detail-col-p">
        <div class="mc-list mt-3">
          <ng-container *ngIf="productLoading">
            <div class="text-center py-5">
              <span class="zf-btn-loader zf-btn-loader-yellow m-auto"></span>
            </div>
          </ng-container>
          <ng-container *ngIf="!productLoading">
            <div class="text-center my-5" *ngIf="productList.length<1">
              No record found
            </div>
            <div class="mc-card" *ngFor="let item of productList | paginate: config; let i = index">
              <div class="mc-card-body">
                <div class="mc-card-img">
                  <!-- <img class="mc-card-img-f" src="assets/img/mc-aircarft.png"> -->

                  <ng-container *ngIf="!item.mediafiles.length>0">
                    <img class="mc-card-img-f" src="assets/img/no-image-aircraft.jpg">
                  </ng-container>

                  <ng-container *ngIf="item.mediafiles.length>0">
                    <img *ngIf="!isVideo(item.mediafiles[0])" class="mc-card-img-f"
                      src="{{variables.serverAssetThumbUrl}}{{item.mediafiles[0]}}">
                    <i class="icon-play" *ngIf="isVideo(item.mediafiles[0])"></i>
                    <video class="mc-card-img-f" *ngIf="isVideo(item.mediafiles[0])">
                      <source src="{{variables.serverAssetUrl}}{{item.mediafiles[0]}}" type="video/mp4">
                    </video>
                  </ng-container>

                  <a routerLink="/user/sell-aircraft/{{item.listing_id}}"><img
                      src="assets/img/mc-icon-edit-white.png"></a>

                </div>

                <div class="mc-card-data">
                  <div class="mc-card-data-left">
                    <div class="mc-listing-id desk-list-id">Listing ID: <a class="cursor-pointer"
                        routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">{{item.listing_id}}</a></div>
                    <div class="aircraft-heading-mob">
                      <!-- routerLink="/product-detail/{{item.listing_id}}" -->
                      <h4 class="cursor-pointer" routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">{{item.category.name}}
                        {{item.make?.name}} {{item.model?.name}}</h4>
                      <div class="mob-view-btn-2">
                        <a class="mc-data-link mob-btn-view mr-2" routerLink="/user/sell-aircraft/{{item.listing_id}}">
                          <span><img src="assets/img/edit-icon.svg" alt="Edit" class="edit-icon-1"></span></a>
                        <a class="mc-data-link mob-btn-view" (click)="deleteProduct(item.id)"><span><img
                              src="assets/img/delete-icon.svg" alt="Delete"></span></a>
                      </div>
                    </div>


                    <div class="mc-by mc-by-mob">By: {{item.sellername}}.
                      <div class="price-label-mob">
                        <span class="mobile-list-label mobile-price-label">Price:</span>
                        <div class="mc-price" *ngIf="item.price">
                          {{item.currency?.symbol}}{{item.price |
                          number}}</div>
                        <div class="mc-price" *ngIf="!item.price">NA</div>
                      </div>
                      <label class="zf-toggle-switch desktop-toggle-status" *ngIf="item.status!=2">
                        <input type="checkbox" name="check_product_status_{{item.id}}" [checked]="item.status==1"
                          (change)="changeProductStatus(item.id, item.status)">
                        <div class="zf-toggle-switch-box">
                          <div class="zf-toggle-switch-thumb"></div>
                        </div>
                      </label>
                      <label class="zf-toggle-switch desktop-publish-btn" *ngIf="item.status==2 && item.canPublishProduct==true">
                        <button class="btn btn-ziofly-default active" (click)="publishModel(item.id, item.listing_id)">
                          <span class="">
                            Publish
                          </span>
                        </button>
                      </label>


                      <div class="mc-listing-id mob-list-id">Listing ID: <a class="cursor-pointer"
                          routerLink="/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}/{{item.listing_id}}">{{item.listing_id}}</a>
                      </div>

                    </div>
                  </div>
                  <div class="mc-card-data-right mc-card-data-r-mob price-mob-list">
                    <!-- currency:'':'' -->
                    <div class="price-label-mob">
                      <span class="mobile-list-label mobile-price-label">Price</span>
                      <div class="mc-price" *ngIf="item.price">
                        {{item.currency?.symbol}}{{item.price |
                        number}}</div>
                      <div class="mc-price" *ngIf="!item.price">NA</div>
                    </div>


                    <div class="mc-status pt-lg-2">
                      <span class="mobile-list-label">Status</span>
                      <span *ngIf="item.status==1"
                        class="status status-active bg-app-secondary text-white p-2 font-weight-bold rounded px-3 ml-2">Active</span>
                      <span *ngIf="item.status==2"
                        class="status status-active bg-app-primary text-white p-2 font-weight-bold rounded px-3 ml-2">Draft</span>
                      <span *ngIf="item.status==0"
                        class="status status-active bg-danger text-white p-2 font-weight-bold rounded px-3 ml-2">Inactive</span>
                    </div>
                    <div class="col-auto mc-card-data-bottom-col mc-card-data-bottom-col-2 mc-card-mob-veiw-pay mt-0">

                      <div class="mobile-view-publish-status">
                        <a class="view-btn-2 btn btn-ziofly-default active"
                          routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">View Details</a>

                        <label class="zf-toggle-switch" *ngIf="item.status!=2">




                          <div class="o-switch btn-group" data-toggle="buttons" role="group" *ngIf="item.status!=2">
                            <label class="btn active-status" [ngClass]="item.status == 1 ? 'active' : ''">
                              <input type="radio" name="check_product_status_{{item.id}}" id="option1"
                                autocomplete="off"
                                (click)="changeProductStatus(item.id, item.status)">Active
                            </label>
                            <label class="btn inactive-status" [ngClass]="item.status == 0 ? 'active' : ''">
                              <input type="radio" name="check_product_status_{{item.id}}" id="option2"
                                autocomplete="off"
                                (click)="changeProductStatus(item.id, item.status)">Inactive
                            </label>
                          </div>
                        </label>

                        <label class="zf-toggle-switch" *ngIf="item.status==2 && item.canPublishProduct==true">
                          <button class="btn btn-ziofly-default btn-dark active"
                            (click)="publishModel(item.id, item.listing_id)">
                            <span class="">
                              Publish
                            </span>
                          </button>
                        </label>
                      </div>
                      <a *ngIf="item.subscription_type=='free' && item.status==1"
                        class="mc-data-link btn btn-green w-100" (click)="openModalForm(item)">
                        <!-- <span><img src="assets/img/mc-white-icon-pay.png"></span>  -->
                        Upgrade
                      </a>
                    </div>
                  </div>
                  <div class="mc-card-data-bottom">
                    <div class="row row-m-0">
                      <div class="col-auto mr-lg-auto mc-card-data-bottom-col mc-card-data-bottom-col-1">
                        <div class="mc-icon-data list-mc-data-mob">
                          <div class="mc-id-icon">
                            <img src="assets/img/mc-icon-id.svg">
                          </div>
                          <div class="mc-id-data">
                            <span>ID: </span>
                            <a class="cursor-pointer "
                              routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">{{item.listing_id}}</a>

                          </div>
                        </div>
                        <div class="mc-icon-data">
                          <div class="mc-id-icon">
                            <img src="assets/img/mc-icon-reg.svg">
                          </div>
                          <div class="mc-id-data">
                            <span> Reg#: </span>
                            <ng-container *ngIf="!item.registration_no">NA</ng-container>
                            <ng-container *ngIf="item.registration_no">
                              {{item.registration_no}}</ng-container>
                          </div>
                        </div>

                        <div class="mc-icon-data">
                          <div class="mc-id-icon">
                            <img src="assets/img/mc-icon-year.svg">
                          </div>
                          <div class="mc-id-data">
                            <span>Year: </span>
                            <ng-container *ngIf="!item.year">NA</ng-container>
                            <ng-container *ngIf="item.year">{{item.year}}</ng-container>
                          </div>
                        </div>

                        <div class="mc-icon-data">
                          <div class="mc-id-icon">
                            <img src="assets/img/mc-icon-exp.svg">
                          </div>
                          <div class="mc-id-data">
                            <span>Expires: </span>
                            <!-- In {{item.productexpires}} Days -->
                            <ng-container *ngIf="item.productexpires">
                              {{item.productexpires}}</ng-container>
                            <ng-container *ngIf="!item.productexpires">NA</ng-container>
                          </div>
                        </div>

                      </div>
                      <div class="col-auto mc-card-data-bottom-col mc-card-data-bottom-col-2 mc-card-desk-view-pay">
                        <a *ngIf="item.subscription_type=='free' && item.status==1" class="mc-data-link"
                          (click)="openModalForm(item)">
                          <span><img src="assets/img/mc-icon-pay.svg"></span>
                          Upgrade
                        </a>
                        <a class="mc-data-link" routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}"><span><img
                              src="assets/img/mc-icon-view.svg"></span>View</a>
                        <a class="mc-data-link desk-btn-view"
                          routerLink="/user/sell-aircraft/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}"><span><img
                              src="assets/img/mc-icon-edit.svg"></span>Edit</a>
                        <a class="mc-data-link desk-btn-view" (click)="deleteProduct(item.id)"><span><img
                              src="assets/img/mc-icon-delete.svg"></span>Delete</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" *ngIf="productList.length>0">
              <pagination-controls (pageChange)="pageChange($event)" previousLabel="" nextLabel=""
                class="zf-pagination">
              </pagination-controls>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->

<div class="modal-form-modal" [class.open]="isModalFormView">
  <div class="modal-form-box subscription-modal">
    <h3 class="h3-add-aircarft mb-0">Upgrade your subscription</h3>
    <div class="row">
      <div class="col-lg-6 my-4">
        <div class="listing-type-box free" [class.selected]="selectedListngType=='free'">
          <span class="icon-listing-type-selected"><img src="assets/img/checked-symbol-green.png"></span>
          <div class="ltb-left">
            <img src="assets/img/listing-free-icon.png">
            <h6>Free Listing</h6>
            <h4>$0.00</h4>
            <a class="ltb-btn" (click)="selectListngType('free')">Select</a>
          </div>
          <div class="ltb-right">
            <ul class="ltb-list">
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>1 listing per account
              </li>
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>60 day Listing</li>
              <!-- <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Unlimited Listings</li> -->
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Upload upto 10 Photos
              </li>
              <li><span class="icon"><img src="assets/img/cross-iconn.png"></span>No Video</li>
              <li><span class="icon"><img src="assets/img/cross-iconn.png"></span>Premium placement in
                search results</li>
              <li><span class="icon"><img src="assets/img/cross-iconn.png"></span>1 mentioned post per
                month on Ziofly social media platforms</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-6 my-4">
        <div class="listing-type-box premium" [class.selected]="selectedListngType=='premium'">
          <span class="icon-listing-type-selected"><img src="assets/img/checked-symbol-yellow.png"></span>
          <div class="ltb-left">
            <img src="assets/img/listing-premium-icon.png">
            <h6>Premium Listing</h6>
            <h4>$29.99</h4>
            <a class="ltb-btn" (click)="selectListngType('premium')">Select</a>
          </div>
          <div class="ltb-right">
            <ul class="ltb-list">
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>1 listing per account
              </li>
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Unlimited Photos</li>
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Videos</li>
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Ad Analytics</li>
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Featured on Home page
              </li>
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Premium placement in
                search results</li>
              <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>1 mentioned post per
                month on Ziofly social media platforms</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-ziofly-default default mr-2" (click)="closeModalForm()"><span>Cancle</span></button>
      <button class="btn btn-ziofly-default active" (click)="updateSubscription()"
        [disabled]="submitting || selectedListngType=='free'"><span>
          <span class="mr-2" *ngIf="submitting">
            <span class="zf-btn-loader"></span>
          </span>
          Update</span></button>
    </div>
  </div>
</div>
