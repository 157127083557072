import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ziofly-mobile-content',
  templateUrl: './mobile-content.component.html',
  styleUrls: ['./mobile-content.component.scss']
})
export class MobileContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
