import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/helper/strong-password';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { validations } from 'src/environments/validations';
import { Title, Meta } from '@angular/platform-browser';

declare var $;
@Component({
  selector: 'ziofly-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUSComponent implements OnInit {
  zForm: FormGroup;
  submitting = false;
  submitted = false;
  validations = validations;
  cmsData;
  constructor(
    private formBuilder: FormBuilder,
    private cms: CmsService,
    private toastr: ToastrService, private titleService: Title,
    private metaTagService: Meta
  ) {
    this.cms.getCmsData().subscribe(x => {
      this.cmsData = x[0]
    })
  }

  ngOnInit(): void {
    this.zForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.validations.def.email.regex), Validators.email]],
      name: ['', [Validators.required, CustomValidators.trimValidator, Validators.pattern(validations.def.name.regex), Validators.minLength(validations.def.name.full.min), Validators.maxLength(validations.def.name.full.max)]],
      //website : ['', [Validators.pattern(validations.def.website.regex)]],
      phone: ['', [Validators.required, Validators.pattern(validations.def.phone.regex)]],
      message: ['', [Validators.required, CustomValidators.trimValidator, Validators.minLength(validations.def.message.min), Validators.maxLength(validations.def.message.max)]]
    });
    this.getTags();

    // text-area-focuses
    // $('textarea').focus(function () {
    //   $(".zf-textarea-group").addClass("focused")
    // })
    //   .blur(function () {
    //     $(".zf-textarea-group").removeClass("focused")
    //   })


      
    // text-area-focuses
    $( document ).ready(function() {
      $('.is-floating-label textarea').on('focus blur', function (e) {
        $(this).parents('.is-floating-label').toggleClass('is-focused', (e.type === 'focus' || this.value.length > 0));
      }).trigger('blur');
  });
  }

  get f() { return this.zForm.controls; }

  submit() {
    this.submitted = true;
    if (this.zForm.invalid) {
      return
    }
    this.zForm.value.name = this.zForm.value.name.replace(/\s\s+/g, ' ')
    this.submitting = true;
    this.cms.sendEnquiry(this.zForm.value).subscribe(data => {
      if (data['status']) {
        this.zForm.reset();
        this.submitted = false;
        this.toastr.success({ msg: this.validations.messages.contact_us.success })
        this.submitting = false;
      } else {
        this.toastr.error({ msg: data['message'] })
        this.submitting = false;
      }
    }, error => {
      this.submitting = false;
      this.toastr.error({ msg: error.message })
    })
  }

  getTags() {
    this.cms.getMetaTags().subscribe(res => {
      let data = res;
      if (data['status'] == true) {
        this.titleService.setTitle(data['data'][0].contactus_seo.title);

        this.metaTagService.updateTag(
          { name: 'keywords', content: data['data'][0].contactus_seo.keywords },
        );

        this.metaTagService.updateTag(
          { name: 'description', content: data['data'][0].contactus_seo.description },
        );
      } else {
      }
    })
  }
}
