import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { variables } from 'src/environments/variables';
import { Router }  from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'ziofly-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public variables = variables;
  cmsData;

  submitting = false;
  submitted = false;
  newsletterForm : FormGroup;

  constructor(
    private cms: CmsService,
    public router: Router,
    private userService: UserService,
    private formbuilder: FormBuilder,
    private toastr: ToastrService,
  ) { 
    this.cms.getCmsData().subscribe(x=>{
      this.cmsData = x[0]
    })
  }
  ngOnInit(): void {
    this.newsletterForm = this.formbuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
  }
  get f() { return this.newsletterForm.controls; }
  newsletterFormSubmit(){
    this.submitted = true;
    if(this.newsletterForm.invalid){
      return;
    }
    this.submitting = true;

    this.userService.newsletter(this.newsletterForm.value).subscribe(data=>{
      if(data["status"]){ //when cards is stored successfully
        this.toastr.success({msg:data['message']})  
        this.submitting = false;
        this.newsletterForm.reset();
        this.submitted = false;
      }else{
        this.submitting = false;
        this.toastr.error({msg:data['message']})  
        // show toaster message when if something went wrong
      }
    },error=>{
      this.toastr.error({msg:'Something went wrong, please try again'})
      this.submitting = false;
    });
  }

}
