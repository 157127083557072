import { Directive, ElementRef, Input } from '@angular/core';
import { validations } from 'src/environments/validations';

@Directive({
  selector: '[zioflyFileAccept]'
})
export class FileAcceptDirective {
  validations=validations;
  constructor(private el: ElementRef) {   
    if(this.el.nativeElement.getAttribute('data-type')=='image') {
      this.el.nativeElement.setAttribute('accept', this.acceptImages(validations.def.image.type));
    }
  }
  acceptImages(arr) {
    var t = '';
    arr.map(function(el,i) {
       if(i<arr.length-1) {
          t = t+'image/'+el+', '; 
       } else {
          t = t+'image/'+el; 
       }
    });
    return t;
 }
}
