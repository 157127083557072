<div class="about-us-banner privacy-banner d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6">
               <div class="about-us-banner-content top-mobile-heading">
                    <div class="about-us-banner-content-inner">
                        <h3 class="title">Terms & Conditions</h3>
                       
                    </div>
               </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="about-us-banner-person">
                    <div class="about-us-banner-person-img-holder">
                        <img src="assets/img/terms-page-b.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-wrapper privacy-pages-cms">
    <div class="container">
        <div class="text-center py-5" *ngIf="pageLoading">
            <span class="zf-btn-loader zf-btn-loader-yellow m-auto"></span>
        </div>
        <ng-container *ngFor="let item of pagedata">
            <h4 [innerHTML]="item.heading"></h4>
            <div class="cms-dec" [innerHTML]="item.description"></div>
        </ng-container>
    </div>
</div>