<div class="page-wrapper news-page-wrapper">

	<div class="news-banner" *ngIf="cmsData?.aviation_news?.aviation_news_banner_image"
		style="background-image: url(assets/img/news-banner-new.jpg);">
		<div class="container">
			<div class="row justify-content-center">
				<!-- <div class="col-12 col-lg-6">
					<div class="news-banner-content">
						<div class="news-banner-content-inner">
							<h3 class="title" [innerHTML]="cmsData?.aviation_news?.aviation_news_heading"></h3>
						</div>
					</div>
				</div> -->
				<div class="col-11 col-md-8 col-lg-8 align-self-center">
					<div class="banner-img-wrap">
						<div class="about-us-banner-person-img-holder">
							<img src="assets/img/news-articles-banner-img.png" class="img-fluid">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="news-banner" *ngIf="!cmsData?.aviation_news?.aviation_news_banner_image">
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="news-banner-content">
						<div class="news-banner-content-inner">
							<h3 class="title" [innerHTML]="cmsData?.aviation_news?.aviation_news_heading"></h3>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6"></div>
			</div>
		</div>
	</div>

</div>
<section class="section">
	<nav class=" mt-0 mb-0 mob-sec-breadcrumb" aria-label="breadcrumb">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<ol class="breadcrumb mt-0 py-0">
						<li class="breadcrumb-item"><a routerLink="/">Home</a></li>
						<li class="breadcrumb-item active" aria-current="page">Aviation News and Articles</li>
					</ol>
				</div>
			</div>
		</div>
	</nav>
	<div class="container">
		<div class="row gap">
			<div class="col-12 col-lg-6 align-center--section--title-after-md mob-sec-heading-hide">
				<h2 class="section--title ">
					<b>Hot News</b>
					<span class="animate-flight-svg">
						<i class="animate-flight-svg-border"></i>
						<i class="animate-flight-svg-icon icon-flight-right"></i>
					</span>
				</h2>
			</div>

			<div class="col-12 col-lg-6"></div>
		</div>
		<div class="row  news-gallry">
			<div class="col-12 col-lg-6 align-center--section--title-after-md" *ngIf="hotNews1?.image"
				(click)="details(hotNews1?.slug)">
				<div class="news-gallary-info">
					<div class="news-bigimg">
						<img src="{{variables.serverAssetUrl}}{{hotNews1?.image}}" class="img-fluid large-imgsize ">
					</div>
					<div class="fadedbox">
						<div class="title text">
							<h6 class="mb-1 ng-tns-c106-0 news-fild-hvr">{{hotNews1?.news_date | date:'mediumDate'}}
							</h6>
							<h5 class="news-fild-text" [innerHTML]="hotNews1?.title"></h5>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-6 align-center--section--title-after-md" *ngIf="hotNews2?.image"
				(click)="details(hotNews2?.slug)">
				<div class="news-gallary-info">
					<div class="news-bigimg">
						<img src="{{variables.serverAssetUrl}}{{hotNews2?.image}}" class="img-fluid small-imgsize">
					</div>
					<div class="fadedbox1">
						<div class="title text">
							<h6 class="mb-1 ng-tns-c106-0 news-fild-hvr">{{hotNews2?.news_date | date:'mediumDate'}}
							</h6>
							<h5 class="news-fild-text" [innerHTML]="hotNews2?.title"></h5>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-lg-6 align-center--section--title-after-md" *ngIf="hotNews3?.image"
						(click)="details(hotNews3?.slug)">
						<div class="news-gallary-info">
							<div class="news-smallimg">
								<img src="{{variables.serverAssetUrl}}{{hotNews3?.image}}"
									class="img-fluid small-bottom-img">
							</div>
							<div class="fadedbox2">
								<div class="title text">
									<h6 class="mb-1 ng-tns-c106-0 news-fild-hvr">{{hotNews3?.news_date |
										date:'mediumDate'}}</h6>
									<h5 class="news-fild-text1" [innerHTML]="hotNews3?.title"></h5>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-6 align-center--section--title-after-md" *ngIf="hotNews4?.image"
						(click)="details(hotNews4?.slug)">
						<div class="news-gallary-info">
							<div class="news-smallimg">
								<img src="{{variables.serverAssetUrl}}{{hotNews4?.image}}"
									class="img-fluid small-bottom-img">
							</div>
							<div class="fadedbox2">
								<div class="title text">
									<h6 class="mb-1 ng-tns-c106-0 news-fild-hvr">{{hotNews4?.news_date |
										date:'mediumDate'}}</h6>
									<h5 class="news-fild-text1" [innerHTML]="hotNews4?.title"></h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="section vision-misson-section blog-popular p-0">
	<div class="page-wrapper privacy-pages-cms">
		<div class="container">
			<div class="row space">
				<div class="col-12 col-lg-6 align-center--section--title-after-md">
					<h2 class="section--title ">
						<b>Latest News</b>
						<span class="animate-flight-svg">
							<i class="animate-flight-svg-border"></i>
							<i class="animate-flight-svg-icon icon-flight-right"></i>
						</span>
					</h2>
				</div>
			</div>
			<div class="row bloglist-bottom-rowspace">
				<div class="col-lg-6" *ngFor="let item of LatestNews | paginate: config; let i = index">
					<a class="mc-data-link" [routerLink]="['/news-detail/', item.slug]">
						<div class="card-seller box-center-align ">
							<div class="d-flex box-center-align blog-col-1">
								<div class="box-left-side">
									<img src="{{variables.serverAssetUrl}}{{item.image}}" class="blog-img-small">
								</div>
								<div class="box-right-side">
									<div class="col px-0 ng-tns-c106-0">
										<h6 class="mb-1 ng-tns-c106-0 date-line">{{item?.news_date | date:'mediumDate'}}
										</h6>
										<h5 class="ng-tns-c106-3 ng-star-inserted">{{item?.title}}</h5>
										<!--<p [innerHTML]="item?.description"></p>-->
										<p [innerHTML]="item?.description" class="desc_length"></p>
										<div class="d-flex  more-blogbtn" style="justify-content: space-between;">
											<div>
												<a class="btn btn-ziofly-default active">
													<span _ngcontent-egg-c59="">Read More</span>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>

			</div>
		</div>
	</div>
</section>
<div _ngcontent-iup-c102="">
	<div class="" *ngIf="LatestNews?.length>0">
		<pagination-controls (pageChange)="pageChange($event)" previousLabel="" nextLabel="" class="zf-pagination">
		</pagination-controls>
	</div>
</div>