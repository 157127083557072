import { AotSummaryResolver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd,Router } from '@angular/router';
import * as AOS from 'aos';

import { filter } from 'rxjs/operators';
import { LoaderService } from 'src/app/common/loader/loader.service';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { variables } from 'src/environments/variables';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ziofly';

  constructor(
    private cms: CmsService,
    private loader: LoaderService,
    private toastr: ToastrService,
    private auth: AuthService,
    private userService: UserService,
  ) {
    // const navEndEvents = this.router.events.pipe(
    //   filter((event) => event instanceof NavigationEnd)
    // );
    // navEndEvents.subscribe((event: NavigationEnd) => {
    //   gtag('config', 'GTM-5TKBCKD', {
    //     page_path: event.urlAfterRedirects,
    //   });
    // });
  }
  ngOnInit() {

    this.loader.showLoader();
    this.setVars();
    const _zf_token = this.auth._zf_tokenValue;
    if (_zf_token) {
      this.loader.showLoader();
      this.userService.getUserDetails().subscribe(
        (data) => {
          if (data['status']) {
            this.userService.setUserData(data['data']);
          } else {
            this.auth.logout();
            window.location.reload();
            this.toastr.error({ msg: data['message'] });
            // this.loader.hideLoader()
          }
          this.loader.hideLoader();
        },
        (error) => {
          this.toastr.error({ msg: error.message });
          this.loader.hideLoader();
        }
      );
    }
    AOS.init();
  }
  async setVars() {
    await this.cms.getCmsDataFromServer().subscribe(
      (data) => {
        if (data['status']) {
          this.cms.setCmsData(data['data']);
          setTimeout(() => {
            this.loader.hideLoader();
          }, 500);
        } else {
          this.toastr.error({ msg: data['message'] });
          this.loader.hideLoader();
        }
      },
      (error) => {
        this.toastr.error({ msg: error.message });
        this.loader.hideLoader();
      }
    );
  }
}
function gtag(arg0: string, arg1: string, arg2: { page_path: string }) {
  throw new Error('Function not implemented.');
}
