import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/common/loader/loader.service';
import { MustMatch } from 'src/app/helper/must-match';
import { CustomValidators } from 'src/app/helper/strong-password';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { validations } from 'src/environments/validations';
import { variables } from 'src/environments/variables';
import { AuthService } from 'src/app/services/auth.service';

declare var $;
declare function initInputs();
@Component({
  selector: 'ziofly-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  variables = variables;
  validations = validations;
  submitted_1=false;
  submitted_2=false;
  zForm_1: FormGroup;
  zForm_2: FormGroup;
  submitting=false;
  signup_step = 1;
  isAircraftOwned=false;
  accepted_terms=false;
  countries=[];
  states=[];
  cities=[];
  categories=[];
  country_loaded=false;
  state_loaded=false;
  city_loaded=false;
  category_loaded=false;
  country_loading=false;
  state_loading=false;
  city_loading=false;
  category_loading=false;
  cmsData;
  constructor(
    private formbuilder: FormBuilder,
    private toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private cms: CmsService,
    private loader: LoaderService,
    private auth: AuthService
  ) {
    this.cms.getCmsData().subscribe(x=>{
      this.cmsData = x[0]
    })
  }

  ngOnInit(): void {
    this.initForms()
    this.getCountries()
  }
// ng-select
// dropdown-search
onFocus(){
  $(".select2-search__field").focus();
}
// dropdown-search
// ng-select
  ngOnDestroy(){ // called to remove the ng select 2 class which is creating bug on going back in browser
    const selectOptionsContainer = $('.select2-container');
    selectOptionsContainer.removeClass('select2-container--open')
  }

changePhone(){
  /*setTimeout(() => {
    let _phone = this.zForm_1.value.phone;
    _phone = _phone.replace(/[^A-Z0-9]/ig, "_");
    this.zForm_1.controls.phone.setValue(_phone);
  }, 200);*/
}
  initForms() {
    this.zForm_1 = this.formbuilder.group({
      first_name : ['', [Validators.required, CustomValidators.trimValidator, Validators.pattern(validations.def.name.regex), Validators.minLength(validations.def.name.first_name.min), Validators.maxLength(validations.def.name.first_name.max)]],
      last_name : ['', [Validators.required, CustomValidators.trimValidator, Validators.pattern(validations.def.name.regex), Validators.minLength(validations.def.name.last_name.min), Validators.maxLength(validations.def.name.last_name.max)]],
      seller_name : ['', [Validators.required, CustomValidators.trimValidator,
        Validators.pattern(validations.def.seller_name.regex),
        Validators.minLength(validations.def.seller_name.min),
        Validators.maxLength(validations.def.seller_name.max)]],
      email: ['', [Validators.required, Validators.pattern(this.validations.def.email.regex), Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(this.validations.def.phone.regex)]],
      phone_copy: [''],
     // fax: ['', [Validators.pattern(this.validations.def.fax.regex)]],
      fax: [''],
      address_first: ['', [Validators.required, Validators.minLength(validations.def.address.line_1.min), Validators.maxLength(validations.def.address.line_1.max)]],
      address_second: ['', [ Validators.maxLength(validations.def.address.line_2.max)]],
      city: [''],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zipcode: ['', [Validators.required, Validators.pattern(validations.def.address.zipcode.regex)]]
    })
    this.zForm_2 = this.formbuilder.group({
      is_pilot : [false],
      is_aircraft_owner : [false],
      notify_for_anynew : [false],
      aircraft_owned : [''],
      password: ['', [Validators.required,

        Validators.minLength(this.validations.def.password.min),Validators.maxLength(this.validations.def.password.max)]],
      password_confirmation: ['', [Validators.required]],
    },{
      validator: MustMatch('password', 'password_confirmation')
    });
  }
  get f_1() { return this.zForm_1.controls; }
  get f_2() { return this.zForm_2.controls; }
  step_1_submit() {

    this.toastr.removeAll()
    this.submitted_1= true
    if(this.zForm_1.invalid) {
      return
    }
    this.submitting=true;
    var fData = {
      email: this.zForm_1.value.email,
      phone: this.zForm_1.value.phone,
      fax: this.zForm_1.value.fax
    }
    this.userService.checkUnique(fData).subscribe(data=>{
      if(data['status']) {
        this.signup_step=2;
        this.submitting=false;
        this.getCategories()
      } else {
        this.toastr.error({msg:data['message']})
        this.submitting=false;
      }
    },error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})
    })
  }

  step_2_submit() {
    this.toastr.removeAll()
    this.submitted_2= true
    if(!this.accepted_terms) {
       return
    }
    if(this.zForm_2.invalid) {
      return
    }
    // frm data
    this.zForm_1.value.first_name = this.zForm_1.value.first_name.replace(/\s\s+/g, ' ')
    this.zForm_1.value.last_name = this.zForm_1.value.last_name.replace(/\s\s+/g, ' ')
    this.zForm_1.value.seller_name = this.zForm_1.value.seller_name.replace(/\s\s+/g, ' ')
    var step_1_Data = this.zForm_1.value
    var step_2_Data = this.zForm_2.value
    if(step_2_Data.is_pilot) {step_2_Data.is_pilot = 1} else {step_2_Data.is_pilot = 0}
    if(step_2_Data.is_aircraft_owner) {step_2_Data.is_aircraft_owner = 1} else {step_2_Data.is_aircraft_owner = 0}
    if(step_2_Data.notify_for_anynew) {step_2_Data.notify_for_anynew = 1} else {step_2_Data.notify_for_anynew = 0}
    var fData = {...step_1_Data, ...step_2_Data}
    // signup
    this.submitting=true;
    this.auth.login(fData, 'signup').subscribe(data=>{
      if(data['status']) {
        this.userService.setUserData(data['data'][0]);
        this.router.navigate(['/user/profile']);
        this.toastr.success({msg:this.validations.messages.sign_up.success})
        this.submitting=false;
      } else {
        this.toastr.error({msg:data['message']})
        this.submitting=false;
      }
    },error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})
    })
  }
  getCategories() {
    this.category_loaded=false;
    this.category_loading=true;
    this.cms.getCategories().subscribe(data=>{
      this.categories = []
      data['data'].map(x=>{
        this.categories.push({
          id: x.id,
          text: x.name
        })
      })
      this.category_loaded=true
      this.category_loading=false;
    })
  }
  terms(e) {
    this.accepted_terms = !this.accepted_terms
  }
  aircraftOwn(e) {
    if(e.target.checked) {
      this.isAircraftOwned = true;
      this.zForm_2.get('aircraft_owned').setValidators(Validators.required)
      this.zForm_2.get('aircraft_owned').updateValueAndValidity();
    } else {
      this.isAircraftOwned = false;
      this.zForm_2.get('aircraft_owned').clearValidators();
      this.zForm_2.get('aircraft_owned').setValue('');
      this.zForm_2.get('aircraft_owned').updateValueAndValidity();
    }
  }
  getCountries() {
    this.country_loaded=false;
    this.country_loading=true;
    this.cms.getCountries().subscribe(data=>{
      this.countries = []
      this.states = []
      this.cities = []
      data['data'].map(x=>{
        this.countries.push({
          id: x.id,
          text: x.name
        })
      })
      this.country_loaded=true
      this.country_loading=false;
    })
  }
  getStates(id) {
    this.zForm_1.get('state').setValue(null)
    this.zForm_1.get('state').updateValueAndValidity()
    $(document).find('.state-dd').removeClass('focused')
    this.zForm_1.get('city').setValue(null)
    this.zForm_1.get('city').updateValueAndValidity()
    $(document).find('.city-dd').removeClass('focused')
    this.state_loading=true;
    this.state_loaded=false;
    this.city_loaded=false;
    this.cms.getStates({ country_id : id}).subscribe(data=>{
      this.states = []
      this.cities = []
      data['data'].map(x=>{
        this.states.push({
          id: x.id,
          text: x.name
        })
      })
      this.state_loaded=true
      this.state_loading=false;
    })
  }
  onCountryChange(e:any) {
    if(e) {
      this.getStates(e)
    }
  }
  onStateChange(e:any) {
    if(e) {
      this.getCities(e)
    }
  }
  convertLowerCase(e){
    setTimeout(() => {
      var _num = this.zForm_1.value.email;
      if (_num.length > 0) {
        this.zForm_1.controls.email.setValue(_num.toLowerCase());
      }
    }, 30);
  }
  getCities(id) {
    this.zForm_1.get('city').setValue(null)
    this.zForm_1.get('city').updateValueAndValidity()
    $(document).find('.city-dd').removeClass('focused')
    this.city_loading=true;
    this.city_loaded=false
    this.cms.getCities({ state_id : id}).subscribe(data=>{
    this.cities = []
      data['data'].map(x=>{
        this.cities.push({
          id: x.id,
          text: x.name
        })
      })
      this.city_loaded=true
      this.city_loading=false;
    })

  }
  dd_options = {

  };
  aircrafts = [
    {
      id: '',
      text: 'Select Aircraft'
    },
    {
      id: 'Aircraft1',
      text: 'Aircraft 1'
    },
    {
      id: 'Aircraft2',
      text: 'Aircraft 2'
    },
    {
      id: 'Aircraft3',
      text: 'Aircraft 3'
    }
  ]
}
