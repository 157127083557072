import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { variables } from 'src/environments/variables';
import { UserService } from 'src/app/services/user.service';
import { Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute, Router } from '@angular/router';
import { validations } from 'src/environments/validations';
import { MatDialog } from '@angular/material/dialog';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';

declare function initInputs();
declare var $;
@Component({
  selector: 'ziofly-sell-aircraft',
  templateUrl: './sell-aircraft.component.html',
  styleUrls: ['./sell-aircraft.component.scss']
})
export class SellAircraftComponent implements OnInit {
  isSaveExit = false;
  validations = validations;
  selectedListngType = '';
  currentStep = 0;
  category_loading = false;
  countries: any;
  countriesAll: any;
  categories: any;
  makesList: any;
  modelsList: any;
  currencyList: any;
  unitsList: any;
  usefulLoadUnitsList: any;
  fractionalOwnershipList: any;
  specialUseCategoryList: any;
  models_loading = false;
  variables = variables;
  activeCategorie;
  activeCategorieData;
  activesaleStatusRadio;
  activeConditionRadio;
  activeflightRule;
  yearsList;
  subscription_type: any;
  check_enginetime1_unit = false;
  check_enginetime2_unit = false;
  check_proptime1_unit = false;
  check_proptime2_unit = false;
  default_unit = "Lbs";

  uploadedImages = [];
  uploadedVideos = [];

  value: number = 100;
  options: Options = {
    floor: 0,
    ceil: 4000,
    step: 50,
  };
  optionsRate: Options = {
    floor: 0,
    ceil: 10
  };

  privacyPrefere = [

    {
      name: 'Display Aircraft Location',
      id: 'display_full_address',
      check: false
    },

    {
      name: 'Allow Buyers To Call Directly',
      id: 'display_contact_button'
    },

  ];
  sellAircraftForm: FormGroup;
  submitted_01 = false;
  isNextLoading = false;
  isEmailLoading = false;
  product_id: any;

  sellAircraftForm_03: FormGroup;
  submitted_03 = false;
  sellAircraftForm_04: FormGroup;
  submitted_04 = false;
  sellAircraftForm_05: FormGroup;
  submitted_05 = false;
  sellAircraftForm_06: FormGroup;
  submitted_06 = false;
  sellAircraftForm_07: FormGroup;
  submitted_07 = false;
  sellAircraftForm_08: FormGroup;
  submitted_08 = false;
  sellAircraftForm_09: FormGroup;
  submitted_09 = false;
  sellAircraftForm_10: FormGroup;
  submitted_10 = false;
  specialusecategories_dummy_arry = [];
  sellAircraftForm_11: FormGroup;
  submitted_11 = false;
  emailtowebmasterForm: FormGroup
  submitted_emailtowebmaster = false;

  uploading = false;
  subscription_message = '';
  firstTimeOnstep8 = true;
  editProductId = '';
  isEditPage = false;
  productData: any;
  paymentStep = false;
  savedCardDetails: any;
  isModalFormView = false;
  selectStatus: boolean;

  // FOR MAP ONLY
  map: any;  // variable for storing map

  @ViewChild('info', { static: false }) info: ElementRef
  @ViewChild('tla', { static: false }) tla: ElementRef
  @ViewChild('imageInput') imageInput;

  style = new Style({
    fill: new Fill({
      color: '#F0F0F0',
    }),
    stroke: new Stroke({
      color: 'white',
      width: 1,
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: '#F0F0F0',
      }),
      stroke: new Stroke({
        color: '#F0F0F0',
        width: 0,
      }),
    }),
  });

  vectorLayer = new VectorLayer({
    source: new VectorSource({
      url: 'assets/countries.geojson',
      format: new GeoJSON(),
    }),
    style: (feature) => {
      this.style.getText().setText(feature.get('name'));
      return this.style;
    },
  });

  highlight;

  highlightStyle = new Style({
    stroke: new Stroke({
      color: '#1D8D84',
      width: 1,
    }),
    fill: new Fill({
      color: '#1D8D84',
    }),
    text: new Text({
      font: '12px Calibri,sans-serif',
      fill: new Fill({
        color: '#1D8D84',
      }),
      stroke: new Stroke({
        color: '#1D8D84',
        width: 0,
      }),
    }),
  });
  featureOverlay: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private cms: CmsService,
    private toastr: ToastrService,
    public UserService: UserService,
    public dialog: MatDialog
  ) {
    this.getCategories();
    this.editProductId = this.route.snapshot.paramMap.get("id");
    // console.log('edit product id',this.editProductId)
    if (this.editProductId) {
      this.selectStatus = false;
      this.isEditPage = true;
      var apidata = {
        listing_id: this.editProductId,
        is_product_edit: 'true'
      }
      this.cms.getProducts(apidata).subscribe(data => {
        // console.log("product id",data)
        if (data['status']) {
          this.productData = data['data'][0];
          this.isEditPage = true;
          this.subscription_message = this.productData.subscriptionmessage;
          this.product_id = this.productData.id;
          this.setValueInForms();
        }
        else {
          this.toastr.error({ msg: data['message'] });
          this.router.navigate(['/user/my-aircrafts']);
        }
      }, error => {
        this.toastr.error({ msg: 'Something went wrong, please try again' })
        this.router.navigate(['/user/my-aircrafts']);
      })
    } else {
      this.selectStatus = true;
    }
  }

  dd_options = {
    minimumResultsForSearch: -1,
  };

  d_options = {
    // minimumResultsForSearch: -1,
  };

  setValueInForms() {
    this.activeCategorie = this.productData.category.id;
    this.activeCategorieData = this.productData.category;
    this.selectedListngType = this.productData.subscription_type;


    this.sellAircraftForm.controls.make_id.setValue(this.productData.make_id);
    this.sellAircraftForm.controls.model_id.setValue(this.productData.model_id);
    this.getMakes();
    this.getModelsForEdit();
    // step3
    this.sellAircraftForm_03.controls.aircraft_condition.setValue(this.productData.aircraft_condition);
    this.sellAircraftForm_03.controls.sale_status.setValue(this.productData.sale_status);
    if (this.productData.sale_status == 'For Sale') {
      this.activesaleStatusRadio = 1;
    } else if (this.productData.sale_status == 'For Lease') {
      this.activesaleStatusRadio = 2;
    } else if (this.productData.sale_status == 'Swap or Trade') {
      this.activesaleStatusRadio = 3;
    }
    this.sellAircraftForm_03.controls.flight_rules.setValue(this.productData.flight_rules);
    this.sellAircraftForm_03.controls.no_of_seats.setValue(this.productData.no_of_seats);
    if (this.productData.fraction_ownership) {
      this.sellAircraftForm_03.controls.fraction_ownership.setValue(this.productData.fraction_ownership);
    }

    // step4
    if (this.productData.year) {
      this.sellAircraftForm_04.controls.year.setValue(this.productData.year)
    }
    this.sellAircraftForm_04.controls.price.setValue(this.productData.price)
    if (this.productData.currency_id) {
      this.sellAircraftForm_04.controls.currency_id.setValue(this.productData.currency_id)
    }

    this.sellAircraftForm_04.controls.registration_no.setValue(this.productData.registration_no)
    this.sellAircraftForm_04.controls.serial_no.setValue(this.productData.serial_no)
    this.sellAircraftForm_04.controls.show_resgistration_number.setValue(this.productData.show_resgistration_number)
    this.sellAircraftForm_04.controls.show_serialnumber.setValue(this.productData.show_serialnumber)
    this.sellAircraftForm_04.controls.aircraft_description.setValue(this.productData.aircraft_description)
    // step5
    this.sellAircraftForm_05.controls.total_time_on_aircraft.setValue(this.productData.total_time_on_aircraft)
    this.sellAircraftForm_05.controls.engine_time1.setValue(this.productData.engine_time1)
    if (this.productData.engine_time1_unit) {
      this.check_enginetime1_unit = true;
      this.sellAircraftForm_05.controls.engine_time1_unit.setValue(this.productData.engine_time1_unit)
    }
    this.sellAircraftForm_05.controls.engine_time2.setValue(this.productData.engine_time2)
    if (this.productData.engine_time2_unit) {
      this.check_enginetime2_unit = true;
      this.sellAircraftForm_05.controls.engine_time2_unit.setValue(this.productData.engine_time2_unit)
    }

    this.sellAircraftForm_05.controls.prop_time1.setValue(this.productData.prop_time1)
    if (this.productData.prop_time1_unit) {
      this.check_proptime1_unit = true;
      this.sellAircraftForm_05.controls.prop_time1_unit.setValue(this.productData.prop_time1_unit)
    }

    this.sellAircraftForm_05.controls.prop_time2.setValue(this.productData.prop_time2)
    if (this.productData.prop_time2_unit) {
      this.check_proptime2_unit = true;
      this.sellAircraftForm_05.controls.prop_time2_unit.setValue(this.productData.prop_time2_unit)
    }

    this.sellAircraftForm_05.controls.useful_load.setValue(this.productData.useful_load)
    if (this.productData.useful_load_unit) {
      this.sellAircraftForm_05.controls.useful_load_unit.setValue("abd")
    }

    // step6
    this.sellAircraftForm_06.controls.interior_ratting.setValue(this.productData.interior_ratting)
    this.sellAircraftForm_06.controls.exterior_ratting.setValue(this.productData.exterior_ratting)
    if (this.productData.year_painted) {
      this.sellAircraftForm_06.controls.year_painted.setValue(this.productData.year_painted)
    }
    if (this.productData.year_interior) {
      this.sellAircraftForm_06.controls.year_interior.setValue(this.productData.year_interior)
    }
    this.sellAircraftForm_06.controls.interior_exterior_description.setValue(this.productData.interior_exterior_description)
    this.sellAircraftForm_06.controls.interior_exterior_description.setValue(this.productData.exterior_description)
    // step7
    // this.uploadedImages = this.productData.mediafiles;
    // step8
    if (this.productData.country_id) {
      this.sellAircraftForm_08.controls.country_id.setValue(this.productData.country_id)
      // this.onCountryChange(this.productData.country_id);
    }
    this.sellAircraftForm_08.controls.zipcode.setValue(this.productData.zipcode)
    // step9
    this.sellAircraftForm_09.controls.air_frame.setValue(this.productData.air_frame)
    this.sellAircraftForm_09.controls.engine_mods_pops.setValue(this.productData.engine_mods_pops)
    this.sellAircraftForm_09.controls.avionics_equipment.setValue(this.productData.avionics_equipment)
    // step10
    // step11
    this.sellAircraftForm_11.controls.display_seller_name.setValue(this.productData.display_seller_name)
    this.sellAircraftForm_11.controls.display_contact_name.setValue(this.productData.display_contact_name)
    this.sellAircraftForm_11.controls.display_full_address.setValue(this.productData.display_full_address)
    this.sellAircraftForm_11.controls.display_phone_number.setValue(this.productData.display_phone_number)

    setTimeout(() => {
      initInputs();
    }, 100);
  }
  checkIsSCheck(id) {
    this.specialusecategories_dummy_arry.includes(id);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      width: '250px',
    });
  }

  ngAfterViewInit() {

    this.featureOverlay = new VectorLayer({
      source: new VectorSource(),
      map: this.map,
      style: (feature) => {
        this.highlightStyle.getText().setText(feature.get('name'));
        return this.highlightStyle;
      }
    });
    window.dispatchEvent(new Event('resize'));
  }

  ngOnDestroy() { // called to remove the ng select 2 class which is creating bug on going back in browser
    const selectOptionsContainer = $('.select2-container');
    selectOptionsContainer.removeClass('select2-container--open')
  }
  // dropdown-search
  onFocus() {
    $(".select2-search__field").focus();
  }

  // dropdown-search
  ngOnInit(): void {


    this.map = new Map({
      layers: [this.vectorLayer],
      target: 'hotel_map',
      view: new View({
        center: [0, 0],
        zoom: 0,
      }),
    });
    $(document).ready(function () {
      if ($(window).width() < 767) {
        $("#hotel_map").attr("tabindex", "1");
      }
      else {
        $("#hotel_map").removeAttr("tabindex");
      }
    });
    $(window).resize(function () {
      if ($(window).width() < 767) {
        $("#hotel_map").attr("tabindex", "1");
      }
      else {
        $("#hotel_map").removeAttr("tabindex");
      }
    });
    // mobile map

    this.getSavedCardDetails() // if already card is stored check and show saved cards
    this.getFractionalOwnership();
    this.getCurrency();
    this.getUnits();
    this.getUsefulLoadUnits();
    this.getCountries();
    this.getSpecialUseCategory();



    var currentYear = new Date().getFullYear();
    this.yearsList = [];
    while (currentYear > 1900) {
      this.yearsList.push({
        id: currentYear,
        text: currentYear
      })
      currentYear--;
    }
    this.sellAircraftForm = this.formBuilder.group({
      category_id: [''],
      make_id: ['', Validators.required],
      model_id: ['', Validators.required],
      subscription_type: [this.selectedListngType],
    })
    this.sellAircraftForm_03 = this.formBuilder.group({
      product_id: [''],
      aircraft_condition: ['', Validators.required],
      sale_status: ['', Validators.required],
      flight_rules: ['', Validators.required],
      no_of_seats: ['', Validators.required],
      fraction_ownership: [''],
    })

    this.sellAircraftForm_04 = this.formBuilder.group({
      product_id: [''],
      year: ['', Validators.required],
      price: ['', Validators.required],
      currency_id: ['', Validators.required],
      registration_no: [''],
      serial_no: [''],
      show_resgistration_number: [true],
      show_serialnumber: [''],
      aircraft_description: ['', Validators.required],
    })
    this.sellAircraftForm_05 = this.formBuilder.group({
      product_id: [''],
      total_time_on_aircraft: ['', Validators.required],
      engine_time1: ['', Validators.required],
      engine_time1_unit: ['', Validators.required],
      engine_time2: [''],
      engine_time2_unit: [''],
      prop_time1: [''],
      prop_time1_unit: ['', Validators.required],
      prop_time2: [''],
      prop_time2_unit: [''],
      useful_load: ['', Validators.required],
      useful_load_unit: ['', Validators.required],
    })
    this.sellAircraftForm_06 = this.formBuilder.group({
      product_id: [''],
      interior_ratting: [''],
      exterior_ratting: [''],
      year_painted: [''],
      year_interior: [''],
      interior_exterior_description: [''],
      exterior_description: [''],
    })
    this.sellAircraftForm_07 = this.formBuilder.group({
      product_id: [''],
      image: [''],
      video: [''],
    })
    this.sellAircraftForm_08 = this.formBuilder.group({
      product_id: [''],
      country_id: ['', Validators.required],
      zipcode: ['', Validators.required],
    })
    this.sellAircraftForm_09 = this.formBuilder.group({
      product_id: [''],
      air_frame: [''],
      engine_mods_pops: [''],
      avionics_equipment: [''],
    })
    this.sellAircraftForm_10 = this.formBuilder.group({
      product_id: [''],
      specialusecategories: [''],
      specialusecategories_copy: this.formBuilder.array([]),
    })

    this.sellAircraftForm_11 = this.formBuilder.group({
      product_id: [''],
      display_seller_name: [''],
      display_contact_name: [''],
      display_full_address: [''],
      display_web_address: [''],
      display_phone_number: [''],
      display_contact_button: [''],
    })

    this.emailtowebmasterForm = this.formBuilder.group({
      makename: ['', Validators.required],
      modelname: ['', Validators.required],
      description: [''],
    })

    // this.getProductPrivacy(this.editProductId)
  }

  // emailtowebmaster

  get specialusecategories_copy(){
    return this.sellAircraftForm_10.get('specialusecategories_copy') as FormArray;
  }

  onCheckSpecialuse(item){
    // console.log(item)
    this.sellAircraftForm_10.value.specialusecategories_copy.push(item);
  }
  onCheckSpecialusecategories(id) {
    const index = this.specialusecategories_dummy_arry.indexOf(id);
    if (index > -1) {
      this.specialusecategories_dummy_arry.splice(index, 1);
    }
    else {
      this.specialusecategories_dummy_arry.push(id);
    }
    // console.log(this.specialusecategories_dummy_arry);
  }

  get f() { return this.sellAircraftForm.controls; }
  get f3() { return this.sellAircraftForm_03.controls; }
  get f4() { return this.sellAircraftForm_04.controls; }
  get f5() { return this.sellAircraftForm_05.controls; }
  get f6() { return this.sellAircraftForm_06.controls; }
  get f7() { return this.sellAircraftForm_07.controls; }
  get f8() { return this.sellAircraftForm_08.controls; }
  get f9() { return this.sellAircraftForm_09.controls; }
  get f10() { return this.sellAircraftForm_10.controls; }
  get f11() { return this.sellAircraftForm_11.controls; }
  get femail() { return this.emailtowebmasterForm.controls; }

  stepPlus(isExit?) {
    if (isExit) {
      this.isSaveExit = true;
    }
    if (this.currentStep == 2) {
      this.submitStep2();
      return
    }
    if (this.currentStep == 3) {
      this.submitStep3();
      return
    }
    if (this.currentStep == 4) {
      this.submitStep4();
      return
    }
    if (this.currentStep == 5) {
      this.submitStep5();
      return
    }
    if (this.currentStep == 6) {
      this.submitStep6();
      // interior and exterior
      return
    }
    if (this.currentStep == 7) {
      this.submitStep7();
      return
    }
    if (this.currentStep == 8) {
      this.submitStep8();
      return
    }
    if (this.currentStep == 9) {
      this.submitStep9();
      return
    }
    if (this.currentStep == 10) {
      this.submitStep10();
      return
    }
    if (this.currentStep == 11) {
      // console.log('privacy step',this.currentStep)
      this.submitStep11();
      return
    }
    this.goToStep(this.currentStep + 1)
    // console.log(this.currentStep);
  }


  stepMinus() {
    if (this.paymentStep) {
      this.paymentStep = false;
      return;
    }
    this.goToStep(this.currentStep - 1);
  }

  goToStep(step) {
    if (this.editProductId) {

    } else {
      if (this.subscription_type) {
        this.getUpdatedSubscription();
      }
    }
    this.paymentStep = false;
    if (this.isSaveExit) {
      this.router.navigate(['/user/my-aircrafts']);
    }
    if (step == 4) {
      var _price = this.sellAircraftForm_04.value.price;
      if (_price) {
        _price = _price.toString().replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      this.sellAircraftForm_04.controls.price.setValue(_price);

    }
    if (step == 8) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
        setTimeout(() => {
          if (this.productData) {
            if (this.productData.country_id) {
              this.onCountryChange(this.productData.country_id);
            }
          }
        }, 300);
      }, 200);
    }
    if (step == 10) {
      if (this.isEditPage) {
        // console.log('this is product data',this.productData)
        if (this.productData.special_use_categories) {
          // console.log(this.specialUseCategoryList);
          setTimeout(() => {
            this.productData.special_use_categories.forEach(x => {
              $('.specialusecategoryCheck label.data-id-' + x.specialusecategoryid).click();
            });
          }, 300);
        }
      }
    }
    if (step == 11) {
      if (!this.isEditPage) {
        if (this.firstTimeOnstep8) {

          this.sellAircraftForm_11.controls.display_seller_name.setValue(1)
          this.sellAircraftForm_11.controls.display_contact_name.setValue(1)
          this.sellAircraftForm_11.controls.display_phone_number.setValue(1)
        }
      }
      this.firstTimeOnstep8 = false;
    }
    this.currentStep = step;
    setTimeout(() => {
      initInputs();
    }, 300);

  }
  openModalForm() {
    this.isModalFormView = true;
    const body = document.getElementsByTagName('html')[0];
    body.classList.add('overflow-hidden');
  }
  closeModalForm() {
    this.isModalFormView = false;
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('overflow-hidden');
  }
  emailtowebmasterSubmit() {
    this.submitted_emailtowebmaster = true;
    if (this.emailtowebmasterForm.invalid) {
      return;
    }
    this.isEmailLoading = true;
    this.UserService.emailtowebmaster(this.emailtowebmasterForm.value).subscribe(data => {
      this.isEmailLoading = false;
      if (data['status']) {
        // console.log(data['data']);
        this.toastr.success({ msg: data['message'] });
        this.emailtowebmasterForm.reset();
        this.submitted_emailtowebmaster = false;
        this.closeModalForm();
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isEmailLoading = false;
    })
  }

  submitStep2() {
    this.submitted_01 = true;

    if (this.sellAircraftForm.invalid) {
      return;
    }
    this.isNextLoading = true;
    this.sellAircraftForm.controls.category_id.setValue(this.activeCategorie);
    this.sellAircraftForm.controls.subscription_type.setValue(this.selectedListngType);
    var _apidata = this.sellAircraftForm.value;
    if (this.product_id) {
      _apidata.product_id = this.product_id;
    }
    this.UserService.productCategoryMakeModel(_apidata).subscribe(data => {
      this.isNextLoading = false;
      if (data['status']) {
        // console.log(data['data']);
        this.product_id = data['data'][0].id;
        this.subscription_message = data['data'][0].subscriptionmessage;
        this.goToStep(3);
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    })
  }

  submitStep3() {
    this.submitted_03 = true;
    if (this.sellAircraftForm_03.invalid) {
      return;
    }
    this.isNextLoading = true;
    this.sellAircraftForm_03.controls.product_id.setValue(this.product_id);
    this.UserService.productCondition(this.sellAircraftForm_03.value).subscribe(data => {
      this.isNextLoading = false;
      if (data['status']) {
        // console.log(data['data']);
        this.goToStep(4);
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    })
  }

  submitStep4() {

    this.submitted_04 = true;
    if (this.sellAircraftForm_04.invalid) {
      return;
    }
    this.isNextLoading = true;


    var _price = this.sellAircraftForm_04.value.price;
    var _price1 = _price;
    if (_price) {
      _price = _price.toString().replace(/[, ]+/g, "").trim();
    }
    this.sellAircraftForm_04.controls.price.setValue(_price);


    this.sellAircraftForm_04.controls.product_id.setValue(this.product_id);
    if (this.sellAircraftForm_04.value.show_resgistration_number) {
      this.sellAircraftForm_04.controls.show_resgistration_number.setValue(1);
    }
    else {
      this.sellAircraftForm_04.controls.show_resgistration_number.setValue(0);
    }

    if (this.sellAircraftForm_04.value.show_serialnumber) {
      this.sellAircraftForm_04.controls.show_serialnumber.setValue(1);
    }
    else {
      this.sellAircraftForm_04.controls.show_serialnumber.setValue(0);
    }
    this.UserService.productSearialization(this.sellAircraftForm_04.value).subscribe(data => {
      this.isNextLoading = false;
      this.sellAircraftForm_04.controls.price.setValue(_price1);
      if (data['status']) {
        // console.log(data['data']);
        this.goToStep(5);
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
      $('#price_input_farmated').keyup();
    })
  }
  submitStep5() {
    this.submitted_05 = true;
    if (this.sellAircraftForm_05.invalid) {
      return;
    }
    this.isNextLoading = true;
    this.sellAircraftForm_05.controls.product_id.setValue(this.product_id);
    this.UserService.productEnginePropTime(this.sellAircraftForm_05.value).subscribe(data => {
      this.isNextLoading = false;
      if (data['status']) {
        // console.log(data['data']);
        this.goToStep(6);
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    })
  }
  submitStep6() {
 // avi complete nahi h
     this.submitted_09 = true;
     if (this.sellAircraftForm_09.invalid) {
       return;
     }
     this.isNextLoading = true;
     //
     this.sellAircraftForm_09.controls.product_id.setValue(this.product_id);
     this.UserService.productMoreDetails(this.sellAircraftForm_09.value).subscribe(data => {
       this.isNextLoading = false;
       if (data['status']) {
         // console.log(data['data']);
         this.goToStep(7);
       }
       else {
         if (data['data']) {
           this.toastr.error({ msg: data['data'] });
         }
         else {
           this.toastr.error({ msg: data['message'] });
         }
       }
     }, error => {
       this.toastr.error({ msg: 'Something went wrong, please try again' })
       this.isNextLoading = false;
     })
  }
  submitStep7() {
    this.submitted_06 = true;
    if (this.sellAircraftForm_06.invalid) {
      return;
    }
    this.isNextLoading = true;
    //
    this.sellAircraftForm_06.controls.product_id.setValue(this.product_id);
    this.UserService.productinteriorexterior(this.sellAircraftForm_06.value).subscribe(data => {
      this.isNextLoading = false;
      if (data['status']) {
        // console.log(data['data']);
        this.goToStep(8);
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    })

  }
  submitStep8() {
 // avi complete nahi h
    this.submitted_07 = true;
    if (this.sellAircraftForm_07.invalid) {
      return;
    }
    this.isNextLoading = true;
    //

    this.sellAircraftForm_07.controls.product_id.setValue(this.product_id);
    this.sellAircraftForm_07.controls.video.setValue(this.uploadedVideos);
    this.sellAircraftForm_07.controls.image.setValue(this.uploadedImages);
    this.UserService.productMedia(this.sellAircraftForm_07.value).subscribe(data => {
      this.isNextLoading = false;
      if (data['status']) {
        // console.log(data['data']);
        this.goToStep(9);
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    })


  }

  submitStep9() {
      // // avi complete nahi h
    this.submitted_08 = true;
    if (this.sellAircraftForm_08.invalid) {
      return;
    }
    this.isNextLoading = true;
    //
    this.sellAircraftForm_08.controls.product_id.setValue(this.product_id);
    this.UserService.productOtherDetails(this.sellAircraftForm_08.value).subscribe(data => {
      this.isNextLoading = false;
      if (data['status']) {
        // console.log(data['data']);
        this.goToStep(10);
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    })

  }
  submitStep10() {
    // avi complete nahi h
    this.submitted_10 = true;
    if (this.sellAircraftForm_10.invalid) {

      return;
    }
    this.isNextLoading = true;
    this.sellAircraftForm_10.value.product_id=this.product_id;
    this.sellAircraftForm_10.value.specialusecategories=[];

    this.UserService.productSpecialUse(this.sellAircraftForm_10.value).subscribe(data => {

      this.UserService.emitProductId(this.product_id);
      this.isNextLoading = false;
      if (data['status']) {
        // console.log(data['data']);
        this.goToStep(11);
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    })
  }
  submitStep11() {


    // avi complete nahi h
    this.submitted_11 = true;
    if (this.sellAircraftForm_11.invalid) {
      return;
    }
    this.isNextLoading = true;

    if (this.sellAircraftForm_11.value.display_seller_name) {
      this.sellAircraftForm_11.controls.display_seller_name.setValue(1);
    }
    else {
      this.sellAircraftForm_11.controls.display_seller_name.setValue(0);
    }

    if (this.sellAircraftForm_11.value.display_contact_name) {
      this.sellAircraftForm_11.controls.display_contact_name.setValue(1);
    }
    else {
      this.sellAircraftForm_11.controls.display_contact_name.setValue(0);
    }

    if (this.sellAircraftForm_11.value.display_full_address) {
      this.sellAircraftForm_11.controls.display_full_address.setValue(1);
    }
    else {
      this.sellAircraftForm_11.controls.display_full_address.setValue(0);
    }

    if (this.sellAircraftForm_11.value.display_web_address) {
      this.sellAircraftForm_11.controls.display_web_address.setValue(1);
    }
    else {
      this.sellAircraftForm_11.controls.display_web_address.setValue(0);
    }

    if (this.sellAircraftForm_11.value.display_phone_number) {
      this.sellAircraftForm_11.controls.display_phone_number.setValue(1);
    }
    else {
      this.sellAircraftForm_11.controls.display_phone_number.setValue(0);
    }

    if (this.sellAircraftForm_11.value.display_contact_button) {

      this.sellAircraftForm_11.controls.display_contact_button.setValue(1);
    }
    else {

      this.sellAircraftForm_11.controls.display_contact_button.setValue(0);
    }


    this.sellAircraftForm_11.controls.product_id.setValue(this.product_id);
    // console.log('privacy product',this.sellAircraftForm_11.value)
    this.UserService.productPrivacy(this.sellAircraftForm_11.value).subscribe(data => {
      this.isNextLoading = false;
      // console.log(data['status'])
      // console.log(data['data'])
      this.goToStep(12);
      if (data['status']) {

        if (this.isSaveExit == true) {
          // this.goToStep(12);
        } else {
          this.paymentStep = true;

        }
      }
      else {
        if (data['data']) {
          this.toastr.error({ msg: data['data'] });
        }
        else {
          this.toastr.error({ msg: data['message'] });
        }
      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    })
  }

  onSuccessFullCardAdd($event) {
    this.paymentStep = false
    this.goToStep(12);
  }
  saveCardDataEvent(card) {
    this.getSavedCardDetails();
  }

  getCategories() {
    this.category_loading = true;
    this.cms.getCategories().subscribe(data => {
      this.categories = data['data'];
      // this.activeCategorie = this.categories[0].id;

      if (this.categories.length && !this.isEditPage) {
        this.activeCategorie = this.categories[0].id;
        this.activeCategorieData = this.categories[0];
        this.getMakes();
      }
      // console.log(this.categories);
      this.category_loading = false;
    })
  }

  getMakes() {
    var apiData = {
      category_id: this.activeCategorie
    }
    this.UserService.getMakes(apiData).subscribe(data => {
      this.makesList = [];
      data['data'].map(x => {
        this.makesList.push({
          id: x.id,
          text: x.name
        })
      })
      // console.log(this.makesList);
    })
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    removeCommas(x){
      return parseInt(x.replace(/,/g, ''))
    }

  onTotalTimeOnAircraftChange(e){
    if(e){
      let valueWithOutCommas = this.removeCommas(e.target.value);
      let valueWithCommas = this.numberWithCommas(valueWithOutCommas);
      this.sellAircraftForm_05.controls.total_time_on_aircraft.setValue(valueWithCommas);
    }
  }

  onEngineTime1Change(e){
    if(e){
      let valueWithOutCommas = this.removeCommas(e.target.value);
      let valueWithCommas = this.numberWithCommas(valueWithOutCommas);
      this.sellAircraftForm_05.controls.engine_time1.setValue(valueWithCommas);
    }
  }

  onEngineTime2Change(e){
    if(e){
      let valueWithOutCommas = this.removeCommas(e.target.value);
      let valueWithCommas = this.numberWithCommas(valueWithOutCommas);
      this.sellAircraftForm_05.controls.engine_time2.setValue(valueWithCommas);
    }
  }
  onPropTime1Change(e){
    if(e){
      let valueWithOutCommas = this.removeCommas(e.target.value);
      let valueWithCommas = this.numberWithCommas(valueWithOutCommas);
      this.sellAircraftForm_05.controls.prop_time1.setValue(valueWithCommas);
    }
  }
  onPropTime2Change(e){
    if(e){
      let valueWithOutCommas = this.removeCommas(e.target.value);
      let valueWithCommas = this.numberWithCommas(valueWithOutCommas);
      this.sellAircraftForm_05.controls.prop_time2.setValue(valueWithCommas);
    }
  }
  onUsefulLoadChange(e){
    if(e){
      let valueWithOutCommas = this.removeCommas(e.target.value);
      let valueWithCommas = this.numberWithCommas(valueWithOutCommas);
      this.sellAircraftForm_05.controls.useful_load.setValue(valueWithCommas);
    }
  }

  onMakeChange(e) {
    if (e) {
      var apiData = {
        category_id: this.activeCategorie,
        make_id: e
      }
      this.models_loading = true;
      this.UserService.getModels(apiData).subscribe(data => {
        this.sellAircraftForm.controls.model_id.setValue('');
        this.modelsList = [];
        data['data'].map(x => {
          this.modelsList.push({
            id: x.id,
            text: x.name
          })
        })
        // console.log(this.modelsList);
        this.models_loading = false;
      })
    }

  }

  getFractionalOwnership() {
    this.UserService.getFractionalOwnership().subscribe(data => {
      this.fractionalOwnershipList = [];
      data['data'].map(x => {
        this.fractionalOwnershipList.push({
          id: x,
          text: x
        })
      })
      // console.log(this.fractionalOwnershipList);
    })
  }
  getCurrency() {
    this.UserService.getCurrency().subscribe(data => {
      this.currencyList = [];
      data['data'].map(x => {
        this.currencyList.push({
          id: x.id,
          text: x.code
        })
      })
    })
  }

  getUnits() {
    this.UserService.getUnits().subscribe(data => {
      this.unitsList = [];
      data['data'].map(x => {
        this.unitsList.push({
          id: x,
          text: x
        });
      });

      if(!this.check_enginetime1_unit){
        this.sellAircraftForm_05.controls['engine_time1_unit'].setValue(data['data'][0])
      }
      if(!this.check_enginetime2_unit){
        this.sellAircraftForm_05.controls['engine_time2_unit'].setValue(data['data'][0])
      }
      if(!this.check_proptime1_unit){
        this.sellAircraftForm_05.controls['prop_time1_unit'].setValue(data['data'][1])
      }
      if(!this.check_proptime2_unit){
        this.sellAircraftForm_05.controls['prop_time2_unit'].setValue(data['data'][1])
      }
      //console.log(this.sellAircraftForm_05);
    })
  }
  getUsefulLoadUnits() {

    this.UserService.getUsefulLoadUnits().subscribe(data => {
      this.usefulLoadUnitsList = [];
      data['data'].map(x => {
        this.usefulLoadUnitsList.push({
          id: x,
          text: x
        })
      })
      this.sellAircraftForm_05.controls['useful_load_unit'].setValue(data['data'][0]);
    });
  }
  aircraftRadioChange(item) {

    this.activeCategorie = item.id;
    this.activeCategorieData = item;
    this.getMakes();
    this.sellAircraftForm.controls.make_id.setValue('');
    this.sellAircraftForm.controls.model_id.setValue('');
  }
  flightRuleChange(id) {
    this.activeflightRule = id;
  }
  conditionRadioChange(id) {
    this.activeConditionRadio = id;
  }

  saleStatusRadioChange(id) {
    this.activesaleStatusRadio = id;
    if (id == 1) {
      this.sellAircraftForm_03.controls.fraction_ownership.setValue('');
    } else if (id == 2) {
      this.sellAircraftForm_03.controls.fraction_ownership.setValue('');
    } else if (id == 3) {
      this.sellAircraftForm_03.controls.fraction_ownership.setValue('');
    }
  }

  getCountries() {
    this.cms.getCountries().subscribe(data => {
      this.countries = []
      this.countriesAll = data['data'];
      // console.log(this.countriesAll);
      data['data'].map(x => {
        this.countries.push({
          id: x.id,
          text: x.name
        })
      })
    })
  }


  getSpecialUseCategory() {

    this.UserService.getSpecialUseCategory().subscribe(data => {
      this.specialUseCategoryList = data['data'];
      // console.log('this is special use catagory list',this.specialUseCategoryList)

      for(let outerCounter = 0; outerCounter < this.specialUseCategoryList.length; outerCounter++){
        this.specialUseCategoryList[outerCounter]["status"] = false;
        let status = false;
        for(let innerCounter = 0; innerCounter < this.productData.special_use_categories.length; innerCounter++){

          if(this.specialUseCategoryList[outerCounter].id == this.productData.special_use_categories[innerCounter].specialusecategoryid){
            this.specialUseCategoryList[outerCounter]["status"] = true;
            status = true;
          }

        }

        if(status){
          this.specialusecategories_copy.push(this.formBuilder.group({
            name: [this.specialUseCategoryList[outerCounter].name],
            id: [this.specialUseCategoryList[outerCounter].id],
            toggle: [true]
          }));
        }
        else{
          this.specialusecategories_copy.push(this.formBuilder.group({
            name: [this.specialUseCategoryList[outerCounter].name],
            id: [this.specialUseCategoryList[outerCounter].id],
            toggle: [false]
          }));
        }

      }

      // console.log('this is sell aircraft10',this.sellAircraftForm_10);
    })
  }



  getUpdatedSubscription() {
    const uploadData3 = new FormData();
    uploadData3.append('product_id', this.product_id);
    this.UserService.getSubscription(uploadData3).subscribe(data => {
      if (data['status']) {
        if (data['data']) {
          //this.subscription_type = data["data"][0].subscriptiontype;
          this.selectedListngType = data["data"][0].subscriptiontype;
        }
      }
    });
  }

  uploadProfileImage(e) {

    // const maxsize = 3e+6;
    const maxsize = 2.46e+7;
    // const maxsize = imageInput.length();
    console.log(e.target.files.size)
    const filesizeerror = [];
    let productstatus;
    const uploadData3 = new FormData();
    uploadData3.append('product_id', this.product_id);
    this.UserService.getSubscription(uploadData3).subscribe(data => {
      console.log(data)
      if (data['status']) {
        if (data['data']) {
          this.subscription_type = data["data"][0].subscriptiontype;
          this.selectedListngType = this.subscription_type;
          //this.subscription_message = data["data"][0].subscriptionmessage;
          productstatus = data["data"][0].productstatus;
          var queue = [];
          for (var i = 0; i < e.target.files.length; i++) {
            var filesize = e.target.files[i].size;
            console.log(filesize)
            if(filesize < 94371841){
              queue.push(e.target.files[i]);
            }else{
              var filename = e.target.files[i].name;
              var errormessage = filename+" should be less than 90 MB,";
              filesizeerror.push(errormessage);
            }
          }
          if(filesizeerror.length > 0){
            this.toastr.error({msg:filesizeerror});
          }
          //if(this.selectedListngType=='free'){
          if (this.subscription_type == 'free') {
            var _a = queue.length;
            var _b = this.uploadedImages.length;
            var _c = 0;
            if (this.productData) {
              if (this.productData.mediafiles.length > 0) {
                const isServerImage = this.productData.mediafiles[0].includes('aircraftdummy')
                if (isServerImage) {
                  this.productData.mediafiles.splice(0, 1)
                }
                _c = this.productData.mediafiles.length;
              }
            }

            if (_a + _b + _c > 10) {
              this.toastr.error({ msg: 'In order to upload more than 10 product images, please upgrade to the premium subscription.' });
              return;
            }
          }

          var files = e.target.files
          var file = files[0]
          this.uploading = true;
          var formData = new FormData()
          for (var i = 0; i < queue.length; i++) {
            formData.append("image[]", queue[i]);
          }
          formData.append('directory', 'products');
          formData.append('subscription_type', this.subscription_type);
          formData.append('productstatus', productstatus);

          this.UserService.uploadFile(formData).subscribe(data => {
            // console.log(data)
            this.imageInput.nativeElement.value = '';
            if (data['status']) {
              this.uploading = false
              if (data['data']) {
                this.uploadedImages = this.uploadedImages.concat(data['data']);
              }
            } else {
              if (data['data']) {
                if (data['issuetype'] == "freevideo") {
                  if (this.productData && this.productData.status == 2) {
                    const dialogRef = this.dialog.open(ImageDialogComponent, {
                      panelClass: 'cstm_dialog_panel',
                      data: data['data'][0] + '-' + this.product_id
                    })
                  } else {
                    const dialogRef = this.dialog.open(ImageDialogComponent, {
                      panelClass: 'cstm_dialog_panel',
                      data: data['data'][0] + '-' + this.product_id
                    })
                  }
                } else {
                  if(data['data'][0]){
                    this.toastr.error({ msg: data['data'][0] })
                  }
                }
              } else {
                this.toastr.error({ msg: data['message'] })
              }
              this.uploading = false
            }
          },
            error => {
              this.toastr.error({ msg: error.message })
              this.uploading = false
            })
        }
      }
    }, error => {

    })
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  CommaFormatted(event) {
    // skip for arrow keys
    // if(event.which >= 37 && event.which <= 40) return;

    // format number
    if (event.target.value) {
      event.target.value = event.target.value.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  isVideo(path) {
    const images = ["jpg", "gif", "png"]
    const videos = ["mp4", "3gp", "ogg"]
    const extension = path.split(".")[1]
    if (videos.includes(extension)) {
      return true;
    }
    else {
      return false;
    }
  }

  selectListngType(type) {
    this.selectedListngType = type;
    this.stepPlus();
  }

  getModelsForEdit() {
    var apiData = {
      category_id: this.activeCategorie,
      make_id: this.productData.make_id
    }
    this.models_loading = true;
    this.UserService.getModels(apiData).subscribe(data => {
      this.modelsList = [];
      data['data'].map(x => {
        this.modelsList.push({
          id: x.id,
          text: x.name
        })
      })
      setTimeout(() => {
        initInputs();
      }, 200);
      // console.log(this.modelsList);
      this.models_loading = false;
    })
  }

  removeAircarfImg(id) {
    let _apiData = {
      product_id: this.product_id,
      medianame: id
    }
    this.UserService.productMediaDelete(_apiData).subscribe()

    const index = this.uploadedImages.indexOf(id);
    if (index > -1) {
      this.uploadedImages.splice(index, 1);
    }

    const index1 = this.productData.mediafiles.indexOf(id);
    if (index1 > -1) {
      this.productData.mediafiles.splice(index1, 1);
    }

  }

  getSavedCardDetails() {
    this.UserService.getcarddetails().subscribe(data => {
      if (data['status']) {
        this.savedCardDetails = data["data"];
      }
    });
  }

  onCountryChange(e) {
    if (!this.countriesAll) {
      return;
    }
    if (e) {
      // window.dispatchEvent(new Event('resize'));
      let index = this.countriesAll.findIndex(item => item.id == e)
      var _code = this.countriesAll[index].iso3;
      var feature = this.vectorLayer.getSource().getFeatureById(_code);
      if (this.highlight) {
        this.featureOverlay.getSource().removeFeature(this.highlight);
      }
      if (feature) {
        this.featureOverlay.getSource().addFeature(feature);
        let extent = this.featureOverlay.getSource().forEachFeature(feature => {
          return feature.getGeometry().getExtent()
        });
        // let zoomBounds =  transformExtent(this.zoomBounds,'EPSG:4326','EPSG:3857');
        this.map.getView().fit(extent, (this.map.getSize()));
        this.map.getView().setZoom(2);
        setTimeout(() => {
          this.map.updateSize();
        }, 200);
        // this.map.getView().fitExtent(extent,this.map.getSize())
      }
      this.highlight = feature;
    }
  }

  changeTabStatus(step){
    this.currentStep=step;
  }

}
