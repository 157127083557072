<ziofly-banner image="assets/img/payment-banner.jpg" title="Payment method" class="payment-method-wrap-1 payment-banner-2">
</ziofly-banner>
<!-- responsive-banner -->
<div class="payment-banner-mob section">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="about-us-banner-content top-mobile-heading">
                    <div class="about-us-banner-content-inner">
                        <h3 class="title">Payment method</h3>

                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="about-us-banner-person">
                    <div class="about-us-banner-person-img-holder text-center">
                        <img src="assets/img/payment-banner-img-mob.svg" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- responsive-banner -->
<div class="page-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <h3 class="font-weight-bold text-black mb-0">Add your card details.</h3>
            </div>
            <div class="col-lg-4 toggle-btn-col text-right">
                <!-- <a class="btn btn-ziofly-default active card-add-btn mr-3" id="card-btn"  href="javascript:(void)"
                    >
                    <!--<i class="icon-close"></i>
                  Add Card--
                    Add Card
                </a>
                <a class="btn btn-ziofly-default active card-add-btn" id="promo-btn"  href="javascript:(void)"
                    >

                    Add Promo Code
                </a> -->
                <ul class="nav nav-pills mb-0 justify-content-end" id="pills-tab" role="tablist">
                    <li class="nav-item" [hidden]="subscription_type != 'free'">
                      <a class="btn btn-green" id="pills-home-tab" data-toggle="pill" 
                      href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                         Add Card
                      </a>
                    </li>
                    <li class="nav-item" [hidden]="subscription_type != 'free'">
                      <a class="btn btn-dark" id="pills-profile-tab" data-toggle="pill" 
                      href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
                          Add Promo Code
                      </a>
                    </li>
                </ul>
            </div>
        </div>


        <div class="row mt-4 mob-card-row">
            <div class="col-lg-auto col-12">
                <ul class="cc-card-list mx-auto">
                    <li><img src="assets/img/cc-master.png"></li>
                    <li><img src="assets/img/cc-discover.png"></li>
                    <li><img src="assets/img/cc-visa.png"></li>
                </ul>
            </div>
            <div class="col-md-12 col-lg">

                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <form class="add-card-card-form" [formGroup]="saveCardForm">

                        <div class="" id="add_card">
                            <div class="mb-4" *ngIf="savedCardDetails">
                                <h5 class="font-weight-bold text-black">Saved card detail</h5>
                                <p>{{savedCardDetails.brand}} **** **** **** {{savedCardDetails.cardno}} <span
                                        class="px-1"></span>with Exp {{savedCardDetails.exp_month}}/{{savedCardDetails.exp_year}}
                                </p>
                                <p class="">If you add new card,already added card will be remove and this card will be save as
                                    default card
                                </p>
                            </div>
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.cardnumber.errors }">
                                            <div class="zf-input-label">Card Number</div>
                                            <div class="zf-input-control">
                                                <input type="text" class="form-control" 
                                                formControlName="cardnumber"
                                                    (keypress)="numberOnly($event)" (change)="cardNumType()" maxlength="23">
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.cardnumber.errors" class="invalid-feedback">
                                            <div *ngIf="f.cardnumber.errors.required">Card Number is required</div>
                                            <div *ngIf="f.cardnumber.errors.minlength || f.cardnumber.errors.maxlength">
                                                Card Number should contain minimum 14 digit and maximum 19 digit</div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <p class="mb-2">Expiry Date</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row cc-card-month-year">
                                            <div class="col-6 pr-0">
                                                <div class="zf-input-group cc-card-month"
                                                    [ngClass]="{ 'is-invalid': submitted && f.month.errors }">
                                                    <div class="zf-input-label">Month</div>
                                                    <div class="zf-input-control">
                                                        <ng-select2 formControlName="month" [data]="monthList"
                                                            [options]="dd_options"></ng-select2>
                                                    </div>
                                                </div>
                                                <div *ngIf="submitted && f.month.errors" class="invalid-feedback">
                                                    <div *ngIf="f.month.errors.required">Month is required</div>
                                                </div>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <div class="zf-input-group cc-card-year"
                                                    [ngClass]="{ 'is-invalid': submitted && f.year.errors }">
                                                    <div class="zf-input-label">Year</div>
                                                    <div class="zf-input-control">
                                                        <ng-select2 formControlName="year" [data]="yearList" [options]="dd_options">
                                                        </ng-select2>
                                                    </div>
                                                </div>
                                                <div *ngIf="submitted && f.year.errors" class="invalid-feedback">
                                                    <div *ngIf="f.year.errors.required">Year is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.cvv.errors }">
                                            <div class="zf-input-label">Enter CVV</div>
                                            <div class="zf-input-control">
                                                <input type="text" pattern="[0-9]*" class="form-control" formControlName="cvv"
                                                    maxlength="4" (keypress)="numberOnly($event)">
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.cvv.errors" class="invalid-feedback">
                                            <div *ngIf="f.cvv.errors.required">CVV is required</div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 text-center text-md-left text-lg-left">
                                        <button type="submit" [disabled]="submitting"
                                            class="btn btn-ziofly-default active min-width-125" (click)="onSubmitClick()">
                                            <span *ngIf="!submitting">Save</span>
                                            <span *ngIf="submitting">
                                                <span class="zf-btn-loader"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                        </div>
                        </form>
                    </div>

                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <form class="add-card-card-form" [formGroup]="saveCardForm2">
                        <div class="" id="add_promocode">
                            <div class="add-card-card add-promo-code">
                                <h5 class="font-weight-bold text-black">Apply Promo Code</h5>
                                <div class="row">
                                  <div class="col-12 mb-2">
                                    <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted2 && f2.promocode.errors }">
                                      <div class="zf-input-label">Promo Code</div>
                                      <div class="zf-input-control">
                                        <input type="text" class="form-control" formControlName="promocode">
                                      </div>
                                    </div>
                                    <div *ngIf="submitted2 && f2.promocode.errors" class="invalid-feedback">
                                        <div *ngIf="f2.promocode.errors.required && submitted2">Promo Code is required</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="mb-3 responsive-btn-wrap">
                                  <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active min-width-125"
                                    (click)="applyPromoCode()">
                                    <span *ngIf="!submitting">Apply Promo Code</span>
                                    <span *ngIf="submitting">
                                      <span class="zf-btn-loader"></span>
                                    </span>
                                  </button>
                                </div>
                              </div>
                        </div>
                        </form>
                    </div>

                  </div>
                
            </div>
        </div>

    </div>
</div>