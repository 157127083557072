<div class="page-wrapper about-us-page-wrapper">
    <div class="about-us-banner">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                   <div class="about-us-banner-content">
                        <div class="about-us-banner-content-inner">
                            <h3 class="title" [innerHTML]="cmsData?.aboutus.about_us_heading"></h3>
                            <div class="desc" [innerHTML]="cmsData?.aboutus.about_us_paragraph"></div>
                            <div class="banner-btn-wrap">
                            <a routerLink="/products" class="btn btn-ziofly-default active"><span>Browse Listings</span></a>
                            </div>
                        </div>
                   </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="about-us-banner-person">
                        <div class="about-us-banner-person-img-holder">
                            <img *ngIf="cmsData?.aboutus?.about_us_banner_image" src="{{variables.serverAssetUrl}}{{cmsData?.aboutus?.about_us_banner_image}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="section about-us-why-choose-us">
        <div class="container">
            <div class="row mb-1">
                <div class="col-12">
                    <div class="align-center--section--title">
                        <h2 class="section--title">
                            <b [innerHTML]="cmsData?.aboutus.about_us_second_heading">
                            </b>
                            <span class="animate-flight-svg">
                                <i class="animate-flight-svg-border"></i>
                                <i class="animate-flight-svg-icon icon-flight-right"></i>
                            </span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 about-us-why-choose-us-desc" [innerHTML]="cmsData?.aboutus?.about_us_second_paragraph">
                </div>
            </div>
            <div class="row about-us-why-choose-us-card">
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="why-choose-us-card">
                        <span *ngIf="cmsData?.home?.home_page_icon_one" class="feature-icon"><img src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_icon_one}}" alt="image" ></span>
                        <div class="feature-title" [innerHTML]="cmsData?.home?.home_page_icon_one_heading"></div>
                        <div class="feature-desc" [innerHTML]="cmsData?.home?.home_page_icon_one_paragraph"></div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">                            
                    <div class="why-choose-us-card">
                        <span *ngIf="cmsData?.home?.home_page_icon_two" class="feature-icon"><img src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_icon_two}}" alt="image" ></span>                                
                        <div class="feature-title" [innerHTML]="cmsData?.home?.home_page_icon_two_heading"></div>
                        <div class="feature-desc" [innerHTML]="cmsData?.home?.home_page_icon_two_paragraph"></div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="why-choose-us-card">
                        <span *ngIf="cmsData?.home?.home_page_icon_three" class="feature-icon"><img src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_icon_three}}" alt="image" ></span>                                
                        <div class="feature-title" [innerHTML]="cmsData?.home?.home_page_icon_three_heading"></div>
                        <div class="feature-desc" [innerHTML]="cmsData?.home?.home_page_icon_three_paragraph"></div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="why-choose-us-card">
                        <span *ngIf="cmsData?.home?.home_page_icon_four" class="feature-icon"><img src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_icon_four}}" alt="image" ></span>                            
                        <div class="feature-title" [innerHTML]="cmsData?.home?.home_page_icon_four_heading"></div>
                        <div class="feature-desc" [innerHTML]="cmsData?.home?.home_page_icon_four_paragraph"></div>
                    </div>
                    </div>
            </div> 
        </div>
    </section>
    <section class="section vision-misson-section">
        <div class="container">
            <div class="row gap">
                <div class="col-12 col-lg-6 align-center--section--title-after-md">
                    <h2 class="section--title ">
                        <b [innerHTML]="cmsData?.aboutus.about_us_third_section_heading"></b>
                        <span class="animate-flight-svg">
                            <i class="animate-flight-svg-border"></i>
                            <i class="animate-flight-svg-icon icon-flight-right"></i>
                        </span>
                    </h2>
                    <p class="desc" [innerHTML]="cmsData?.aboutus.about_us_third_section_paragraph">
                    </p>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="vision-mission-img-holder">
                        <img *ngIf="cmsData?.aboutus?.about_us_third_section_image" src="{{variables.serverAssetUrl}}{{cmsData?.aboutus?.about_us_third_section_image}}" class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-6 align-center--section--title-after-md">
                    <h2 class="section--title ">
                        <b [innerHTML]="cmsData?.aboutus.about_us_fourth_section_heading"></b>
                        <span class="animate-flight-svg">
                            <i class="animate-flight-svg-border"></i>
                            <i class="animate-flight-svg-icon icon-flight-right"></i>
                        </span>
                    </h2>
                    <p class="desc" [innerHTML]="cmsData?.aboutus.about_us_fourth_section_paragraph">
                    </p>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="vision-mission-img-holder">
                        <img *ngIf="cmsData?.aboutus?.about_us_fourth_section_image" src="{{variables.serverAssetUrl}}{{cmsData?.aboutus?.about_us_fourth_section_image}}" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="section our-team" *ngIf="false">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 align-center--section--title-after-md">
                    <h2 class="section--title ">
                        <b>Our Team</b>
                        <span class="animate-flight-svg">
                            <i class="animate-flight-svg-border"></i>
                            <i class="animate-flight-svg-icon icon-flight-right"></i>
                        </span>
                    </h2>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="team-slider-btns">
                        <a href="javascript:void(0)" class="btn btn-ziofly-default btn-ziofly-circle" (click)="slickModal.slickPrev()" ><span><i class="icon-arrow-right flip-x"></i></span></a>
                        <a href="javascript:void(0)" class="btn btn-ziofly-default btn-ziofly-circle" (click)="slickModal.slickNext()" ><span><i class="icon-arrow-right"></i></span></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <ngx-slick-carousel class="team-slider carousel" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfig_3" 
                        >
                        <div ngxSlickItem *ngFor="let slide of teams" class="slide ">
                           <div class="team-slide">
                              <div class="team-slide-card">
                                 <img src="{{slide.image}}">
                                 <div class="team-member-details">
                                     <div class="text">
                                         <div class="name">{{slide.name}}</div>
                                         <div class="designation">{{slide.designation}}</div>
                                     </div>
                                     <div class="social">
                                        <a target="_blank" href="{{slide.social.facebook}}"><i class="active icon-facebook"></i></a>
                                        <a target="_blank" href="{{slide.social.twitter}}"><i class="active icon-twitter"></i></a>
                                        <a target="_blank" href="{{slide.social.linkedin}}"><i class="active icon-linkedin"></i></a>
                                     </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </ngx-slick-carousel>

                </div>
            </div>
        </div>
    </section>
</div>