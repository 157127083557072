import { Component, OnInit, OnDestroy } from '@angular/core';
import { variables } from 'src/environments/variables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'src/app/services/toastr.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { LoaderService } from 'src/app/common/loader/loader.service';
import { EncrDecrService } from 'src/app/services/encr-decr.service';
import { validations } from 'src/environments/validations';
import { CmsService } from 'src/app/services/cms.service';
declare function initInputs();
@Component({
  selector: 'ziofly-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  variables = variables;
  validations = validations;
  user_want_to_remember = false;
  hasDataInStorage=false;
  zForm: FormGroup;
  submitted = false;
  submitting;
  cmsData;
  constructor(
    private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private auth: AuthService,
    private router: Router,
    private userService: UserService,
    private loader: LoaderService,
    private EncrDecr: EncrDecrService,
    private cms: CmsService
    ) {
      this.cms.getCmsData().subscribe(x=>{
        this.cmsData = x[0]
      })
    }

  ngOnInit(): void {
    initInputs();
    this.zForm = this.formBuilder.group({      
      email: ['', [Validators.required, Validators.pattern(this.validations.def.email.regex), Validators.email]],
      password: ['', [Validators.required]],
    });
    this.setValueFromStorage();
    const _zf_token = this.auth._zf_tokenValue;
    if(_zf_token){
      localStorage.removeItem('sellAircraft');
    }
  }

  get f() { return this.zForm.controls; }
  
  login() {
    this.toastr.removeAll();
    if(!this.user_want_to_remember) {
      localStorage.removeItem('_zf_data')
    }
    this.submitted = true;
    if (this.zForm.invalid) {
      return;
    }
    this.submitting=true;
    this.auth.login(this.zForm.value, '').subscribe(data=>{      
       if(data['status']) { 
        if(this.user_want_to_remember) {
          var encryptedForm = this.EncrDecr.set(JSON.stringify(this.zForm.value));
          localStorage.setItem('_zf_data', encryptedForm)
        }       
         this.toastr.success({msg:this.validations.messages.log_in.success});         
         this.userService.getUserDetails().subscribe(data=>{
          if(data['status']) {  
            this.userService.setUserData(data['data']);
            if(localStorage.getItem('sellAircraft')){
              this.router.navigate(['/user/sell-aircraft']);
            }else{
              this.router.navigate(['/user/profile']);
            }
            this.submitting=false;
          } else {
            this.toastr.error({msg:data['message']})
            this.submitting=false;
          }        
        }, 
        error=>{
          this.toastr.error({msg:error.message})
          this.submitting=false;      
        })
        
       } else {
        this.submitting=false;
        this.toastr.error({msg: data['message']})  
       }
    }, 
    error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})      
      this.loader.hideLoader()
    })
      
  }
  toggleRemember(e) {
    this.user_want_to_remember = e.target.checked
  }
  ngOnDestroy(): void {
    // this.toastr.removeAll()
  }
  deleteSavedCred() {
    this.user_want_to_remember = false
    localStorage.removeItem('_zf_data');
    this.setValueFromStorage() 
  }
  setValueFromStorage() {
    if(localStorage.getItem('_zf_data')) {
      this.hasDataInStorage=true;
      this.user_want_to_remember = true
      var decrypted = this.EncrDecr.get(localStorage.getItem('_zf_data'));
      var form = JSON.parse(decrypted);
      this.zForm.patchValue({
        email: form.email,
        password: form.password
      });
      setTimeout(() => {
        initInputs();
      }, 500);
    } else {
      this.hasDataInStorage=false;
      this.zForm.patchValue({
        email: '',
        password: ''
      });
      this.zForm.get('email').updateValueAndValidity()
      this.zForm.get('password').updateValueAndValidity()
      setTimeout(() => {
        initInputs();
      }, 500);
    }
  }
  convertLowerCase(e){
    setTimeout(() => {
      var _num = this.zForm.value.email;
      if (_num.length > 0) {
          this.zForm.controls.email.setValue(_num.toLowerCase());
      }
    }, 30);
  }
}
