<ziofly-banner image="assets/img/profile-banner.png" title="My profile" class="section"></ziofly-banner>
<div class="page-wrapper pb-4">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="profile-page">
                    <div class="profile-img-col">
                        <div class="profile-img">
                            <img [src]="profile_img" alt="image">
                        </div>
                        <div class="profile-user-info">
                            <h2 class="name">{{userData.first_name | titlecase}} {{userData.last_name | titlecase}}</h2>
                        <div class="profile-info"><i class="icon-user"></i><span> Seller name: </span>
                                {{userData.seller_name ? userData.seller_name : 'Unavailable'}}</div>
                        <div class="profile-info"><i class="icon-email"></i><span> Email: </span>
                                {{userData.email}}</div>
                        <div class="profile-info"><i class="icon-call"></i><span> Phone <span class="phn-txt-mob">number</span>: </span>
                                {{userData.phone | mask: '(000) 000-000000'}}</div>
                        </div>
                        
                    </div>
                    <div class="profile-detail-col">
                        <div class="row mb-3">
                            <div class="col-6 col-sm-6 col-lg-4 col-xl-4 mb-3 mb-md-0 mb-lg-0">
                                <div class="profile-count-card card-1">
                                    <div class="count-card-inner">
                                        <i class="icon-date"></i>
                                        <p>Total aircraft</p>
                                        <span *ngIf="userData.no_of_aircraft>0">{{userData.no_of_aircraft |
                                            number}}</span>
                                        <span *ngIf="userData.no_of_aircraft==0"><i class="fa fa-lock"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-lg-4 col-xl-4 mb-3 mb-md-0 mb-lg-0">
                                <div class="profile-count-card card-2">
                                    <div class="count-card-inner">
                                        <i class="icon-date"></i>
                                        <p>Total views</p>
                                        <span *ngIf="userData.aircraftviews>0">{{userData.aircraftviews |
                                            number}}</span>
                                        <span *ngIf="userData.aircraftviews==0"><i class="fa fa-lock"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-4 col-xl-4 mb-3 mb-md-0 mb-lg-0">
                                <div class="profile-count-card card-3">
                                    <div class="count-card-inner">
                                        <i class="icon-date"></i>
                                        <p>Total favorites </p>
                                        <span *ngIf="userData.favourite>0">{{userData.favourite | number}}</span>
                                        <span *ngIf="userData.favourite==0"><i class="fa fa-lock"></i></span>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
                                <div class="profile-count-card card-3">
                                    <i class="icon-date"></i>
                                    <p>Total viewed</p>
                                    <span>{{userData.aircraftviewed  | number}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
                                <div class="profile-count-card card-4">
                                    <i class="icon-date"></i>
                                    <p>Total favourite</p>
                                    <span>{{userData.favourite  | number}}</span>
                                </div>
                            </div> -->
                            <div class="col-12" *ngIf="!userData.checkanypremium">
                                <!--<p class="text-black">Above analytics feature only available in a premium listing.</p>-->
                                <p class="text-black">Please upgrade to a premium account in order to view your profile analytics.</p>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12 col-lg-4">
                                <div class="profile-detail-line">
                                    <span class="profile-detail-icon">
                                        <i class="icon-fax"></i>
                                    </span>
                                    <span class="profile-detail-label">
                                        Fax:
                                    </span>
                                    <span class="profile-detail-value">
                                        {{userData.fax ? userData.fax : 'Unavailable'}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="profile-detail-line">
                                    <span class="profile-detail-icon">
                                        <i class="icon-building"></i>
                                    </span>
                                    <span class="profile-detail-label">
                                        City:
                                    </span>
                                    <span class="profile-detail-value">
                                        {{userData.cityname ? userData.cityname : 'Unavailable'}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="profile-detail-line">
                                    <span class="profile-detail-icon">
                                        <i class="icon-globe"></i>
                                    </span>
                                    <span class="profile-detail-label">
                                        Country:
                                    </span>
                                    <span class="profile-detail-value">
                                        {{userData.countryname ? userData.countryname : 'Unavailable'}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-12">
                                <div class="profile-detail-line">
                                    <span class="profile-detail-icon">
                                        <i class="icon-location"></i>
                                    </span>
                                    <span class="profile-detail-label">
                                        Address:
                                    </span>
                                    <span class="profile-detail-value">
                                        {{userData.address_first ? userData.address_first : 'Unavailable'}}
                                        {{userData.address_second}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="profile-detail-line">
                                    <span class="profile-detail-icon">
                                        <i class="icon-flag"></i>
                                    </span>
                                    <span class="profile-detail-label">
                                        State:
                                    </span>
                                    <span class="profile-detail-value">
                                        {{userData.statename ? userData.statename : 'Unavailable'}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="profile-detail-line">
                                    <span class="profile-detail-icon">
                                        <i class="icon-postbox"></i>
                                    </span>
                                    <span class="profile-detail-label">
                                        Postal Code:
                                    </span>
                                    <span class="profile-detail-value">
                                        {{userData.zipcode ? userData.zipcode : 'Unavailable'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center text-lg-left">
                                <a routerLink="/user/profile/edit" class="btn btn-ziofly-default active"><span>Edit
                                        Profile</span></a>
                                &nbsp;
                                <a routerLink="/user/change-password"
                                    class="btn btn-ziofly-default default"><span>Change Password</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>