import { Injectable } from '@angular/core';
declare var toastr;
@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor() { 
    toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": false,
      "positionClass": "toast-top-center",
      "preventDuplicates": true,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "3000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
  }

  success(data:any) {
    var msg = data.msg ? data.msg : '';
    toastr.success(msg)
  }
  error(data:any) {
    var msg = data.msg ? data.msg : '';
    // if(data.msg.length>1){
    //  msg = data.msg.join(', ');
    // }
    toastr.error(msg)
  }
  info(data:any) {
    var msg = data.msg ? data.msg : '';
    toastr.info(msg)
  }
  warning(data:any) {
    var msg = data.msg ? data.msg : '';
    toastr.warning(msg)
  }
  removeAll() {
    toastr.remove()
  }
}
