import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { variables } from 'src/environments/variables';
import { SwalService } from 'src/app/services/swal.service';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { Router, RouterLink } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ziofly-my-aircrafts',
  templateUrl: './my-aircrafts.component.html',
  styleUrls: ['./my-aircrafts.component.scss']
})
export class MyAircraftsComponent implements OnInit {
  productList:any;
  productLoading = false; 
  variables = variables;
  public config;
  public offset = 0;
  public search_txt = '';
  dd_options = {
    minimumResultsForSearch: -1,
  };
  limitList= ['12','24','36',];
  limitListShowable = [];

  selectedProduct:any;
  isModalFormView = false;
  selectedListngType = '';
  submitting = false;
  @Output() cardAddedSuccessEvent = new EventEmitter<any>();
  sub_msg: any;
  
  constructor(
    private cms: CmsService,
    private toastr: ToastrService,
    private swal: SwalService,
    public UserService : UserService,
    public dialog: MatDialog,
    private Router: Router,
    private titleService: Title,
    private metaTagService: Meta
  ) {

   }

  ngOnInit(): void {
    this.config = {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      query: ''
    };
    this.getProducts();
    this.setLimit();
    this.UserService.setOption('value', '');
  }

  setLimit(){
    this.limitListShowable = [];
    var isStop = false;
    this.limitList.map(x=>{
      // if(isStop || this.config.totalItems==undefined){
      if(isStop){
        return;
      }
      var y = x;
      if(x>this.config.totalItems){
          y = this.config.totalItems
          isStop = true;
      }
      this.limitListShowable.push({
          id: x,
          text: y + " out of "+this.config.totalItems
      })
    })
  }

  getProducts(){
    this.productLoading = true
    var apidata = {
      offset: this.offset,
      limit: this.config.itemsPerPage,
      search: this.search_txt,
    };
    this.UserService.getProductByUserId(apidata).subscribe(data=>{
      this.productList = data['data'];
      this.config.totalItems = data['totalproducts'];
      if(this.config.totalItems==undefined){
        this.config.totalItems = 0;
      }
      // console.log(this.productList)
      this.setLimit();
      this.productLoading = false;
    })
  }

  pageChange(newPage: number) {
    // debugger;
    this.config.currentPage = newPage;
    this.offset = (newPage-1)*this.config.itemsPerPage;
    this.getProducts();
    let el = document.getElementById('page_wrapper');
    var headerOffset = 50;
    var elementPosition = el.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
  listLimitChange(e){
    this.config.itemsPerPage = e;
    this.getProducts();
  }
  isVideo(path){
    // const images = ["jpg", "gif", "png"]
    const videos = ["mp4", "3gp", "ogg"]
    const extension = path.split(".")[1]
    if (videos.includes(extension)) {
      return true;
    }
    else{
      return false;
    }
  }
  deleteProduct(id){
    this.swal.confirm({title:'Are you sure you want to delete this product?'}).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        var apidata = {
          product_id: id
        }
        this.UserService.deleteProduct(apidata).subscribe(data=>{
          if(data['status']){
            this.toastr.success({msg: data['message']});
            this.getProducts();
            // this.productList = this.productList.filter(item=>item.id !=id );
            console.log('product listing',this.productList)
          }else{
            this.toastr.error({msg: data['message']});
          }
        },error=>{
          this.toastr.error({msg:'Something went wrong, please try again'})
        })
        // this.toastr.success({msg:this.validations.messages.logout.success})
      } else if (result.isDenied) {
        
      }
    })
  }
  searchMyProduct(){
    this.config.currentPage= 1;
    this.getProducts();

  }
  changeProductStatus(id, status){
    var _status = '1';
    if(status==1){
      _status = '0';
    }
    var apidata = {
      product_id : id,
      status : _status
    }
    this.UserService.updateProductStatus(apidata).subscribe(data=>{
      if(data['status']){
        this.toastr.success({msg: data['message']});
        this.getProducts();
        this.productList.find(item => item.id == id).status = _status;
      }
      else{        
        this.toastr.error({msg: data['message']});
        this.getProducts();
      }
    },error=>{
      this.toastr.error({msg:'Something went wrong, please try again'})
    })
  }

  openModalForm(product){
    this.selectedProduct = product;
    this.selectedListngType = product.subscription_type;
    this.isModalFormView = true;
    const body = document.getElementsByTagName('html')[0];
    body.classList.add('overflow-hidden');
  }
  closeModalForm(){
    this.isModalFormView = false;
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('overflow-hidden');
  }
  selectListngType(type){
    this.selectedListngType = type;
  }
  updateSubscription(){
    this.submitting = true;
    var apidata = {
      product_id : this.selectedProduct.id,
      subscription_type : this.selectedListngType
    }
    this.UserService.updateSubscription(apidata).subscribe(data=>{
      if(data['status']) {
        this.toastr.success({msg: data['message']}) ;
        this.closeModalForm();
        this.submitting = false;
        let index = this.productList.findIndex(item => item.id === this.selectedProduct.id)
        this.productList[index].subscription_type = this.selectedListngType;
        this.productList[index].productexpires = data['data'].productexpires;
      } else {
        if(data['data']){
          this.toastr.error({msg:data['message']});
          this.submitting = false 
        }
        else{
          this.toastr.error({msg:data['message']}) 
          this.submitting = false  
        }  
      }
    },error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})      
    })
  }

  publishModel(pid, id){
    this.swal.confirm({title:'Are you sure you want to publish listing?'}).then((result) => {
      if (result.isConfirmed) {
        const payload = {"status":'publish', "product_id" : pid}
        this.UserService.setProductId('product_id', pid);
        this.addCard(payload, pid);
      }else if (result.isDenied) {
      }  
    });  
  }

  addCard(payload, product_id){
    this.UserService.addCards(payload).subscribe(data=>{
      if(data["status"]){ 
        this.toastr.success({msg:data['message']})  
        this.cardAddedSuccessEvent.emit(true); // alerting the sell-aircraft.component about the payment success
        this.ngOnInit();
      }else{
        if(data["email_verification"] == false){
          const dialogRef = this.dialog.open( ImageDialogComponent,{
            panelClass: 'cstm_dialog_panel',
            data: 'false'+'-'+product_id
          })
        }else{
          this.toastr.error({msg:data['message']});  
          if(data["cardstatus"] == false){
            this.sub_msg = data['subscriptionmessage'];
            this.UserService.setOption('value', this.sub_msg);
            this.Router.navigate(['/user/payment-method']);
          }
        }
      }
    },error=>{
      this.toastr.error({msg:'Something went wrong, please try again'})
    });
  }

  aircraftlisting_seo

}
