import { Component, OnInit } from '@angular/core';
import {Options } from '@angular-slider/ngx-slider'
import { BlogService } from 'src/app/services/blog.service';
import { environment } from 'src/environments/environment';
import { variables } from 'src/environments/variables';
import { CmsService } from 'src/app/services/cms.service';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ziofly-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  public newsEvent: any;
  public baseUrlServer: string = environment.apiUrl + '/';
  public config;
  public offset = 0;
  limitList= ['9','18','27'];
  limitListShowable = [];
  variables = variables;
  isModalFormView = false;
  cmsData; 
  eventDetail: any; 

  constructor(private blog:BlogService, private user:BlogService, private dataheading:BlogService,
    private cms: CmsService, private Router: Router,  private titleService: Title,
    private metaTagService: Meta) { 
      this.cms.getCmsData().subscribe(x=>{
        this.cmsData = x[0];
      }) 
  }

  ngOnInit(): void {
    this.config = {
      currentPage: 1,
      itemsPerPage: 9,
      totalItems: 0,
      query: ''
    };
    this.getAviationNews();
    this.setLimit();
    this.listLimitChange(9);
    this.getTags();
  }


  setLimit(){
    this.limitListShowable = [];
    var isStop = false;
    this.limitList.map(x=>{
      if(isStop){
        return;
      }
      var y = x;
      if(x>this.config.totalItems){
          y = this.config.totalItems
          isStop = true;
      }
      this.limitListShowable.push({
          id: x,
          text: y + " out of "+this.config.totalItems
      })
    })
  }

  getAviationNews(){
    var apidata = {
      offset: this.offset,
      limit: this.config.itemsPerPage,
    };
    this.blog.eventDetail(apidata).subscribe(res=>{
      let data = res;
      this.newsEvent = res['data'];
      this.config.totalItems = res['eventcount'];
      if(this.config.totalItems==undefined){
        this.config.totalItems = 0;
      }
      this.setLimit();
    })
  }

  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.offset = (newPage-1)*this.config.itemsPerPage;
    this.getAviationNews();
    /*let el = document.getElementById('page_wrapper');
    var headerOffset = 10;
    var elementPosition = el.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });*/
  }

  listLimitChange(e){
    this.config.itemsPerPage = e;
    this.getAviationNews();
  }

  getTags(){
    this.cms.getMetaTags().subscribe(res=>{
      let data = res;
      if(data['status'] == true){
        this.titleService.setTitle(data['data'][0].aviationevent.title);
      

        this.metaTagService.updateTag(
          { name: 'keywords', content: data['data'][0].aviationevent.keywords },
        );

        this.metaTagService.updateTag(
          { name: 'description', content:  data['data'][0].aviationevent.description },
        );
      }else{
      }
    })
  }

  openModalForm(data){
    this.eventDetail = data;
    this.isModalFormView = true;
    const body = document.getElementsByTagName('html')[0];
    body.classList.add('overflow-hidden');
  }

  closeModalForm(){
    this.isModalFormView = false;
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('overflow-hidden');
  }
}
