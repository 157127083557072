import { Component, OnInit, OnDestroy } from '@angular/core';
import { variables } from 'src/environments/variables';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { validations } from 'src/environments/validations';
import { CmsService } from 'src/app/services/cms.service';
declare function initInputs();
@Component({
  selector: 'ziofly-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit, OnDestroy {
  variables = variables;
  validations = validations;
  zForm: FormGroup;
  submitted = false;
  submitting;
  cmsData;
  constructor(
    private formBuilder: FormBuilder, 
    private toastr: ToastrService,
    private userService: UserService,
    private cms: CmsService
    ) {
      this.cms.getCmsData().subscribe(x=>{
        this.cmsData = x[0]
      })
     }

  ngOnInit(): void {
    initInputs();
    this.zForm = this.formBuilder.group({      
      email: ['', [Validators.required, Validators.pattern(this.validations.def.email.regex), Validators.email]],
   });
  }
  
  get f() { return this.zForm.controls; }
  forgot() {
    this.toastr.removeAll();
    this.submitted = true;
    if (this.zForm.invalid) {
      return;
    }
    this.submitting=true;
    this.userService.forgotPassword(this.zForm.value).subscribe(data=>{ 
       if(data['status']) {
         this.toastr.success({msg:this.validations.messages.forgot.success}); 
         this.submitting=false; 
       } else {
        this.submitting=false;
        this.toastr.error({msg: data['message']})  
       }
    }, 
    error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})      
    })
      
  }
  ngOnDestroy(): void {
  }
}
