
<div class="container-fluid">
  <div class="row">
    <div class="col-12 p-0">
      <div class="page-banner product_list-page-banner section aircraftmob-banner-sec">
        <img class="plan-on-banner" src="assets/img/fly-plan-on-banner.gif">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h3 class="page-banner-title">Aircrafts</h3>
            </div>
            <div class="col-12">
              <div class="aircraft-banner-img">
                <img src="assets/img/aircraft-listing-banner-img.svg" alt="" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
        <img class="page-banner-img" src="assets/img/product-page-banner-11.png" alt="image">
      </div>
    </div>
  </div>
</div>

<nav class="mt-0 mb-0 mob-sec-breadcrumb" aria-label="breadcrumb">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <ol class="breadcrumb mt-0 py-0">
          <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Aircrafts</li>
        </ol>
      </div>
    </div>
  </div>
</nav>
<div class="page-wrapper pt-0 pt-md-4 pt-lg-4" id="page_wrapper">
  <div class="container">
    <nav class="mt-0 mb-2 desk-breadcrumb-hide" aria-label="breadcrumb">
      <ol class="breadcrumb mt-0">
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Aircrafts</li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-lg-3 mob-filter-p">
        <div class="filter-box">
          <div class="filter-head">
            <span class="filter-title mob-heading-hide">Filter</span>
            <h3 class="font-weight-bold text-body mb-0 mt-1 mob-heading-show">Aircrafts for sale</h3>
            <div class="filter-mobile-wrap-left">
              <button class="mob-toggle-btn" (click)="mobFilter()"><span class="material-icons filter-open">
                  filter_alt
                </span>
                <span class="material-icons filter-close">
                  highlight_off
                </span>
              </button>
              <a class="filter-head-btn" (click)="resetAllFilters()">Reset</a>
            </div>
          </div>



          <div class="filter-body mob-filter-body" #mobtoggleFilter [@toggleHeight]="mobfilterAnimation">
            <ul class="filter-list">
              <li>
                <div class="row">
                  <div class="col">Includes photos</div>
                  <div class="col-auto">
                    <label class="zf-toggle-switch mb-0" [class.disabled]="isincludePhotosDisable">
                      <input type="checkbox" [(ngModel)]="isIncludePhotos" (change)="changeIncludePhoto()">
                      <div class="zf-toggle-switch-box mr-0">
                        <div class="zf-toggle-switch-thumb"></div>
                      </div>
                    </label>
                  </div>
                </div>
              </li>
              <ng-container *ngIf="!isFilterLoading">
                <li>
                  Type <a class="right-filter-toggle-btn" [class.open]="navigationSubState['Type'] === 'active'"
                    (click)="toggleNavigationSub('Type', $event)"><i class="icon-close"></i></a>
                  <a *ngIf="filterValue.filterCategories.length" class="right-filter-clear-btn"
                    (click)="filterValue.filterCategories=[]; getProducts(); this.getProductFilter();">{{filterValue.filterCategories.length}}
                    <i class="icon-close"></i></a>
                  <div class="filter-toggle-div filterCategories" (click)="checkFilterCheckboxes()"
                    [@toggleHeight]="navigationSubState.Type">
                    <ng-select2 [data]="filterCategories" [options]="ddd_options_filterCategories"
                      (valueChanged)="filterChangeType($event)" [(ngModel)]="filterValue.filterCategories"></ng-select2>
                  </div>
                </li>
                <li>
                  Make <a class="right-filter-toggle-btn" [class.open]="navigationSubState['Make'] === 'active'"
                    (click)="toggleNavigationSub('Make', $event)"><i class="icon-close"></i></a>
                  <a *ngIf="filterValue.filterMakes.length" class="right-filter-clear-btn"
                    (click)="filterValue.filterMakes=[]; getProducts(); this.getProductFilter();">{{filterValue.filterMakes.length}}
                    <i class="icon-close"></i></a>
                  <div class="filter-toggle-div filterMakes" (click)="checkFilterCheckboxes()"
                    [@toggleHeight]="navigationSubState.Make">
                    <ng-select2 [data]="filterMakes" [options]="ddd_options_filterMakes"
                      (valueChanged)="filterChangeMake($event)" [(ngModel)]="filterValue.filterMakes"></ng-select2>
                  </div>
                </li>
                <li>
                  Model <a class="right-filter-toggle-btn" [class.open]="navigationSubState['Model'] === 'active'"
                    (click)="toggleNavigationSub('Model', $event)"><i class="icon-close"></i></a>
                  <a *ngIf="filterValue.filterModels.length" class="right-filter-clear-btn"
                    (click)="filterValue.filterModels=[]; getProducts(); this.getProductFilter();">{{filterValue.filterModels.length}}
                    <i class="icon-close"></i></a>
                  <div class="filter-toggle-div filterModels" (click)="checkFilterCheckboxes()"
                    [@toggleHeight]="navigationSubState.Model">
                    <ng-select2 [data]="filterModels" [options]="ddd_options_filterModels"
                      (valueChanged)="filterChangeModal($event)" [(ngModel)]="filterValue.filterModels"></ng-select2>
                  </div>
                </li>
                <li>
                  Year <a class="right-filter-toggle-btn" [class.open]="navigationSubState['Year'] === 'active'"
                    (click)="toggleNavigationSub('Year', $event)"><i class="icon-close"></i></a>
                  <a *ngIf="filterValue.filterYears.length" class="right-filter-clear-btn"
                    (click)="filterValue.filterYears=[]; getProducts(); this.getProductFilter();">{{filterValue.filterYears.length}}
                    <i class="icon-close"></i></a>
                  <div class="filter-toggle-div filterYears" (click)="checkFilterCheckboxes()"
                    [@toggleHeight]="navigationSubState.Year">
                    <ng-select2 [data]="filterYears" [options]="ddd_options_filterYears"
                      (valueChanged)="filterChangeYear($event)" [(ngModel)]="filterValue.filterYears"></ng-select2>
                  </div>
                </li>
                <li>
                  Price
                  <div class="">
                    <ngx-slider (userChangeEnd)="onUserChangeEnd($event)" [options]="options" [(value)]="minValue"
                      [(highValue)]="maxValue"></ngx-slider>
                  </div>
                </li>
                <li>
                  Conditions <a class="right-filter-toggle-btn"
                    [class.open]="navigationSubState['Conditions'] === 'active'"
                    (click)="toggleNavigationSub('Conditions', $event)"><i class="icon-close"></i></a>
                  <a *ngIf="filterValue.filterConditions.length" class="right-filter-clear-btn"
                    (click)="filterValue.filterConditions=[]; getProducts(); this.getProductFilter();">{{filterValue.filterConditions.length}}
                    <i class="icon-close"></i></a>
                  <div class="filter-toggle-div filterConditions" (click)="checkFilterCheckboxes()"
                    [@toggleHeight]="navigationSubState.Conditions">
                    <ng-select2 [data]="filterConditions" [options]="ddd_options_filterConditions"
                      (valueChanged)="filterChangeCondition($event)" [(ngModel)]="filterValue.filterConditions">
                    </ng-select2>
                  </div>
                </li>
                <li>
                  Total Time <a class="right-filter-toggle-btn" [class.open]="navigationSubState['Time'] === 'active'"
                    (click)="toggleNavigationSub('Time', $event)"><i class="icon-close"></i></a>
                  <a *ngIf="filterValue.filterTime" class="right-filter-clear-btn"
                    (click)="filterValue.filterTime=''; getProducts(); this.getProductFilter();">1
                    <i class="icon-close"></i></a>
                  <div class="filter-toggle-div filterTime" (click)="checkFilterCheckboxes()"
                    [@toggleHeight]="navigationSubState.Time">
                    <ng-select2 [data]="filterTime" [options]="ddTime_options" (valueChanged)="filterChangeTime($event)"
                      [(ngModel)]="filterValue.filterTime"></ng-select2>
                  </div>
                </li>
                <li>
                  Special Use <a class="right-filter-toggle-btn"
                    [class.open]="navigationSubState['Special_Use'] === 'active'"
                    (click)="toggleNavigationSub('Special_Use', $event)"><i class="icon-close"></i></a>
                  <a *ngIf="filterValue.filterSpecialUse.length" class="right-filter-clear-btn"
                    (click)="filterValue.filterSpecialUse=[]; getProducts(); this.getProductFilter();">{{filterValue.filterSpecialUse.length}}
                    <i class="icon-close"></i></a>
                  <div class="filter-toggle-div filterSpecialUse" (click)="checkFilterCheckboxes()"
                    [@toggleHeight]="navigationSubState.Special_Use">
                    <ng-select2 [data]="filterSpecialUse" [options]="ddd_options_filterSpecialUse"
                      (valueChanged)="filterChangeSpecialUse($event)" [(ngModel)]="filterValue.filterSpecialUse">
                    </ng-select2>
                  </div>
                </li>
                <li>
                  <button class="mob-toggle-btn btn btn-ziofly-default btn-yellow active" (click)="search();"
                    style="margin: 0 auto;">
                    Search
                  </button>
                </li>
              </ng-container>
            </ul>
          </div>

        </div>
      </div>
      <div class="col-lg-9 mb-3 mb-lg-0 detail-col-p">
        <div class="row align-items-center mob-row-m-0">
          <div class="col">
            <h3 class="font-weight-bold text-body mb-0 mt-1 mob-heading-hide">Aircrafts for sale</h3>
          </div>
          <div class="col-12 col-lg-auto">
            <div class="filter-items-head aircraft-list-mob-head">
              <div class="mobile-filter-heading">
                <label class="mt-2" *ngIf="productList.length > 1">Showing results</label>
                <label class="mt-2" *ngIf="productList.length == 1">Showing result</label>
                <span class="d-block w-140px mr-3 mt-2">
                  <ng-select2 [data]="limitListShowable" [options]="dd_options" [value]='12'
                    (valueChanged)="listLimitChange($event)"></ng-select2>
                </span>
              </div>

              <!-- <label class="">Sort By</label> -->
              <span class="d-inline-block mt-2 sort-mobile-wrap">
                <div class="sort-box">
                  <div class="sort-head" (click)="toggleNavigationSub('Sort', $event)">Sort by
                    <ng-container *ngIf="sortValue.make">Make</ng-container>
                    <ng-container *ngIf="sortValue.model">Model</ng-container>
                    <ng-container *ngIf="sortValue.since_update">Last update</ng-container>
                    <ng-container *ngIf="sortValue.price">Price</ng-container>
                    <ng-container *ngIf="sortValue.total_time">Total time</ng-container>
                    <ng-container *ngIf="sortValue.year">Year</ng-container>
                    <ng-container *ngIf="sortValue.engine_time">Engine time</ng-container>
                  </div>
                  <div class="sort-body" [@toggleHeight]="navigationSubState.Sort">
                    <ul class="sort-list">
                      <li>
                        <div class="row sort-row">
                          <div class="col sort-title-col">Make</div>
                          <div class="col-auto sort-filter-col">
                            <div class="switch-field">
                              <input type="radio" [(ngModel)]="sortValue.make" (change)="sortChangeMake($event)"
                                id="sort-radio-11" name="make" value="asc" />
                              <label for="sort-radio-11" class="sort-mob-label">A-Z</label>
                              <input type="radio" [(ngModel)]="sortValue.make" (change)="sortChangeMake($event)"
                                id="sort-radio-12" name="make" value="desc" />
                              <label for="sort-radio-12" class="sort-mob-label">Z-A</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row sort-row">
                          <div class="col sort-title-col">Model</div>
                          <div class="col-auto sort-filter-col">
                            <div class="switch-field">
                              <input type="radio" [(ngModel)]="sortValue.model" (change)="sortChangeModel($event)"
                                id="sort-radio-21" name="model" value="asc" />
                              <label for="sort-radio-21" class="sort-mob-label">A-Z</label>
                              <input type="radio" [(ngModel)]="sortValue.model" (change)="sortChangeModel($event)"
                                id="sort-radio-22" name="model" value="desc" />
                              <label for="sort-radio-22" class="sort-mob-label">Z-A</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row sort-row">
                          <div class="col sort-title-col">Last update</div>
                          <div class="col-auto sort-filter-col">
                            <div class="switch-field">
                              <input type="radio" [(ngModel)]="sortValue.since_update"
                                (change)="sortChangeSince($event)" id="sort-radio-31" name="since_update"
                                value="desc" />
                              <label for="sort-radio-31" class="sort-mob-label">Newest</label>
                              <input type="radio" [(ngModel)]="sortValue.since_update"
                                (change)="sortChangeSince($event)" id="sort-radio-32" name="since_update" value="asc" />
                              <label for="sort-radio-32" class="sort-mob-label">Oldest</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row sort-row">
                          <div class="col sort-title-col">Price</div>
                          <div class="col-auto sort-filter-col">
                            <div class="switch-field">
                              <input type="radio" [(ngModel)]="sortValue.price" (change)="sortChangePrice($event)"
                                id="sort-radio-111" name="price" value="asc" />
                              <label for="sort-radio-111" class="sort-mob-label">Lowest</label>
                              <input type="radio" [(ngModel)]="sortValue.price" (change)="sortChangePrice($event)"
                                id="sort-radio-112" name="price" value="desc" />
                              <label for="sort-radio-112" class="sort-mob-label">Highest</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row sort-row">
                          <div class="col sort-title-col">Total time</div>
                          <div class="col-auto sort-filter-col">
                            <div class="switch-field">
                              <input type="radio" [(ngModel)]="sortValue.total_time"
                                (change)="sortChangeTotalTime($event)" id="sort-radio-41" name="total_time"
                                value="asc" />
                              <label for="sort-radio-41" class="sort-mob-label">Lowest</label>
                              <input type="radio" [(ngModel)]="sortValue.total_time"
                                (change)="sortChangeTotalTime($event)" id="sort-radio-42" name="total_time"
                                value="desc" />
                              <label for="sort-radio-42" class="sort-mob-label">Highest</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row sort-row">
                          <div class="col sort-title-col">Year</div>
                          <div class="col-auto sort-filter-col">
                            <div class="switch-field">
                              <input type="radio" [(ngModel)]="sortValue.year" (change)="sortChangeYear($event)"
                                id="sort-radio-51" name="year" value="asc" />
                              <label for="sort-radio-51" class="sort-mob-label">Lowest</label>
                              <input type="radio" [(ngModel)]="sortValue.year" (change)="sortChangeYear($event)"
                                id="sort-radio-52" name="year" value="desc" />
                              <label for="sort-radio-52" class="sort-mob-label">Highest</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row sort-row">
                          <div class="col sort-title-col">Engine time</div>
                          <div class="col-auto sort-filter-col">
                            <div class="switch-field">
                              <input type="radio" [(ngModel)]="sortValue.engine_time"
                                (change)="sortChangeEngineTime($event)" id="sort-radio-61" name="engine_time"
                                value="asc" />
                              <label for="sort-radio-61" class="sort-mob-label">Lowest</label>
                              <input type="radio" [(ngModel)]="sortValue.engine_time"
                                (change)="sortChangeEngineTime($event)" id="sort-radio-62" name="engine_time"
                                value="desc" />
                              <label for="sort-radio-62" class="sort-mob-label">Highest</label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-2">
                        <div class="row sort-row">
                          <div class="col-auto pr-0 sort-title-col">Zip code</div>
                          <div class="col sort-filter-col">
                            <input type="text" [(ngModel)]="zipcode"
                              class="form-control zipcode-sort-control shadow-none" placeholder="Enter zipcode"
                              (keypress)="numberOnly($event); zipcodeKeyPress()" maxlength="6">
                            <button [disabled]="!isZipcodeEnable" class="zipcode-sort-btn"
                              (click)="zipcodeBtnClick()"><i class="icon-arrow-right"></i></button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="ap-list mt-3">
          <ng-container *ngIf="productLoading">
            <div class="text-center py-5">
              <span class="zf-btn-loader zf-btn-loader-yellow m-auto"></span>
            </div>
          </ng-container>
          <ng-container *ngIf="!productLoading">
            <div class="row gutter-10">
              <div class="col-12 text-center my-5" *ngIf="productList.length<1">
                No record found
              </div>
              <div class="col-xl-4 col-md-6 md-6 mb-4"
                *ngFor="let item of productList | paginate: config; let i = index">
                <div class="ap-card">
                  <div class="ap-card-body">
                    <div class="ap-card-img cursor-pointer">
                      <a class="ap-card-img-action" (click)="addToFav(item.id)" *ngIf="!item.is_favourite">
                        <img src="assets/img/action-heart.png">
                      </a>
                      <a class="ap-card-img-action" (click)="addToUnfav(item.id)" *ngIf="item.is_favourite">
                        <img src="assets/img/action-heart-fill.png">
                      </a>
                      <a (click)="shareData(item.listing_id)"
                        routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">
                        <ng-container *ngIf="!item.mediafiles.length>0">
                          <img class="ap-card-img-f" src="assets/img/no-image-aircraft.jpg">
                        </ng-container>
                        <ng-container *ngIf="item.mediafiles.length>0">
                          <img *ngIf="!isVideo(item.mediafiles[0])" class="ap-card-img-f"
                            src="{{variables.serverAssetThumbUrl}}{{item.mediafiles[0]}}">
                          <i class="icon-play" *ngIf="isVideo(item.mediafiles[0])"></i>
                          <video class="ap-card-img-f" *ngIf="isVideo(item.mediafiles[0])">
                            <source src="{{variables.serverAssetUrl}}{{item.mediafiles[0]}}" type="video/mp4">
                          </video>
                        </ng-container>
                      </a>
                      <img *ngIf="item.subscription_type=='premium'" class="img-fluid premium-isting"
                        src="assets/img/premium_isting.svg">
                    </div>
                    <div class="ap-card-data">


                      <h4 class="cursor-pointer"
                        routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">
                        {{item.year}} {{item.category.name}} {{item.make?.name}}
                        {{item.model?.name}}</h4>
                      <p class="ap-desc">{{item.aircraft_description}}</p>
                      <div class="product-list-row-mob">
                        <div class="row ap-data-row text-black">

                          <div class="col-12">REG#:
                            <ng-container *ngIf="!item.show_resgistration_number">No description
                            </ng-container>
                            <ng-container *ngIf="item.show_resgistration_number">
                              {{item.registration_no}}</ng-container>
                          </div>
                          <div class="col-12">SN#:<span class="mr-2"></span>
                            <ng-container *ngIf="!item.show_serialnumber">No description</ng-container>
                            <ng-container *ngIf="item.show_serialnumber">{{item.serial_no}}
                            </ng-container>
                          </div>
                        </div>
                        <div class="row ap-data-row">
                          <div class="col pr-0">
                            <h4 *ngIf="item.price" class="mobile-heading-price">
                              {{item.currency?.symbol}}{{item.price |
                              number}}</h4>
                            <h4 *ngIf="!item.price">Call for price</h4>
                          </div>

                        </div>
                      </div>

                      <div class="row ap-data-row border-bottom-0">
                        <div class="col pr-0">
                          <ng-container *ngIf="item.display_seller_name">
                            <h4 class="mb-1" *ngIf="item.sellername">{{item.sellername |
                              titlecase}}</h4>
                            <h4 *ngIf="!item.sellername">No description</h4>
                          </ng-container>
                          <ng-container *ngIf="!item.display_seller_name">
                            <h4>No description</h4>
                          </ng-container>

                          <ng-container *ngIf="!item.country && item?.display_full_address">No description
                          </ng-container>
                          <ng-container *ngIf="item.country && item?.display_full_address">{{item.country?.name}}
                          </ng-container>

                        </div>

                      </div>
                      <div class="row">
                        <div class="col-6 pr-1" *ngIf="(item?.display_contact_button)">
                          <a class="btn btn-ap btn-block" href="tel:{{item.phone}}"><span><img
                                src="assets/img/telephone-icon.svg"> Call</span></a>
                        </div>
                        <div style="margin-left: auto; margin-right: auto;" class="pl-1"
                          [ngClass]="!(item?.display_contact_button) ? 'col-12' : 'col-6' ">
                          <a class="btn btn-ap btn-block" (click)="openModalForm(item)"><span><img
                                src="assets/img/msg-icon.svg">Message</span></a>
                        </div>
                      </div>
                      <div class="text-center ap-updated-at">
                        Last Update:
                        <ng-container *ngIf="item.updated_at"> {{item.updated_at |
                          date:"MM/dd/yyyy"}}</ng-container>
                        <ng-container *ngIf="!item.updated_at">No description</ng-container>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12" *ngIf="productList.length>0">
                <pagination-controls (pageChange)="pageChange($event)" previousLabel="" nextLabel=""
                  class="zf-pagination"></pagination-controls>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- Modal -->

<div class="modal-form-modal" [class.open]="isModalFormView">
  <div class="modal-form-box sm p-0">
    <div class="email-seller rounded p-md-4">
      <h4 class="d-flex align-items-center"><img src="assets/img/form-msg.png" class="mr-2"> <span
          class=" font-weight-bold">Email to seller</span></h4>
      <form [formGroup]="enquiryForm" class="mt-3">
        <div class="form-group">
          <input type="text" class="form-control with-icon-left name" placeholder="Name" formControlName="name">
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback d-block">
            <div *ngIf="f.name.errors.required">Name is required</div>
            <div *ngIf="f.name.errors.pattern">Name should contain only alphabetical characters</div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" class="form-control with-icon-left email" placeholder="Email" formControlName="email">
          <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email is Invalid</div>
          </div>
        </div>
        <div class="form-group">
          <input type="text" class="form-control  d-none" placeholder="Phone" maxlength="12" formControlName="phone">
          <input type="text" class="form-control with-icon-left phone" placeholder="Phone" formControlName="phone_copy"
            mask="(000) 000-0000||(000) 000-000000" (change)="changePhone()" (keypress)="changePhone()">
          <div *ngIf="submitted && f.phone.errors" class="invalid-feedback d-block">
            <div *ngIf="f.phone.errors.required">{{validations.messages.phone.required}}</div>
            <div *ngIf="f.phone.errors.pattern">{{validations.messages.phone.invalid}}</div>
          </div>
        </div>
        <div class="form-group">
          <textarea class="form-control" formControlName="message"
            placeholder="I am interested in product. Is it still up for the sale? Please reach out with my email or phone number"
            rows="4"></textarea>
          <div *ngIf="submitted && f.message.errors" class="invalid-feedback d-block">
            <div *ngIf="f.message.errors.required">Message is required</div>
          </div>
        </div>

        <div class="mt-2">
          <button class="btn btn-ziofly-default active mr-2" (click)="closeModalForm()"><span>Cancel</span></button>
          <button class="btn btn-ziofly-default active" (click)="enquiryFormSubmit()" [disabled]="submitting"><span>
              <span class="mr-2" *ngIf="submitting">
                <span class="zf-btn-loader"></span>
              </span>
              Send Email</span></button>
        </div>
      </form>
    </div>
  </div>
</div>
