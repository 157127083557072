import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { validations } from 'src/environments/validations';
import { variables } from 'src/environments/variables';

@Component({
  selector: 'ziofly-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  variables=variables;
  validations=validations;
  verifying=true;
  token;
  id;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router
  ) { 
    this.token = this.route.snapshot.paramMap.get("token");
    this.id = this.route.snapshot.paramMap.get("id")
  }

  ngOnInit(): void {
    this.router.navigate(['/user/my-aircrafts']);
    if(this.token) {
      this.userService.verifyEmail({usertoken: this.token, listing_id: this.id}).subscribe(data=>{
        if(data['status']) {
          this.verifying=false;
          this.toastr.success({msg:this.validations.messages.sign_up.verified});
          this.router.navigate(['/user/my-aircrafts']);
          window.location.reload();
        } else {
          this.verifying=false;
          this.toastr.error({msg:data['message']})  
          this.router.navigate(['/'])
        }
      }, 
      error=>{
        this.verifying=false;
        this.toastr.error({msg:error.message})      
        this.router.navigate(['/'])
      })
    }
  }

}
