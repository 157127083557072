import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { variables } from 'src/environments/variables';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'ziofly-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  cmsData;
  variables=variables;
  constructor(
    private cms: CmsService,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    this.cms.getCmsData().subscribe(x=>{
      this.cmsData = x[0]
    })
  }

  ngOnInit(): void {
    this.getTags();
  }

  public teams = [
    { 
      id: 1, 
      image: 'assets/img/team/team_1.png',
      name : 'John Doe',
      designation: 'CEO & Founder',
      social : {
        facebook : 'https://facebook.com/facebook-test',
        twitter : 'https://twitter.com/twitter-test',
        linkedin : 'https://linkedin.com/linkedin-test',
      }
    },
    { 
      id: 2, 
      image: 'assets/img/team/team_2.png',
      name : 'Mike Robert',
      designation: 'Sales Expert',
      social : {
        facebook : 'https://facebook.com/facebook-test',
        twitter : 'https://twitter.com/twitter-test',
        linkedin : 'https://linkedin.com/linkedin-test',
      }
    },
    { 
      id: 3, 
      image: 'assets/img/team/team_3.png',
      name : 'William Disuja',
      designation: 'Technical Engineer',
      social : {
        facebook : 'https://facebook.com/facebook-test',
        twitter : 'https://twitter.com/twitter-test',
        linkedin : 'https://linkedin.com/linkedin-test',
      }
    },
    { 
      id: 4, 
      image: 'assets/img/team/team_4.png',
      name : 'Rian David',
      designation: 'Digital Marketing Expert',
      social : {
        facebook : 'https://facebook.com/facebook-test',
        twitter : 'https://twitter.com/twitter-test',
        linkedin : 'https://linkedin.com/linkedin-test',
      }
    },
    { 
      id: 1, 
      image: 'assets/img/team/team_1.png',
      name : 'John Doe',
      designation: 'CEO & Founder',
      social : {
        facebook : 'https://facebook.com/facebook-test',
        twitter : 'https://twitter.com/twitter-test',
        linkedin : 'https://linkedin.com/linkedin-test',
      }
    },
    { 
      id: 2, 
      image: 'assets/img/team/team_2.png',
      name : 'Mike Robert',
      designation: 'Sales Expert',
      social : {
        facebook : 'https://facebook.com/facebook-test',
        twitter : 'https://twitter.com/twitter-test',
        linkedin : 'https://linkedin.com/linkedin-test',
      }
    },
    { 
      id: 3, 
      image: 'assets/img/team/team_3.png',
      name : 'William Disuja',
      designation: 'Technical Engineer',
      social : {
        facebook : 'https://facebook.com/facebook-test',
        twitter : 'https://twitter.com/twitter-test',
        linkedin : 'https://linkedin.com/linkedin-test',
      }
    },
    { 
      id: 4, 
      image: 'assets/img/team/team_4.png',
      name : 'Rian David',
      designation: 'Digital Marketing Expert',
      social : {
        facebook : 'https://facebook.com/facebook-test',
        twitter : 'https://twitter.com/twitter-test',
        linkedin : 'https://linkedin.com/linkedin-test',
      }
    },
  ]
  slideConfig_3 = {
    "autoplay": true,
    "draggable": false,
    "speed": 200,
    "slidesToShow": 4, 
    "slidesToScroll" : 1,
    "infinite": true,
    "arrows": true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  getTags(){
    this.cms.getMetaTags().subscribe(res=>{
      let data = res;
      if(data['status'] == true){
        this.titleService.setTitle(data['data'][0].aboutus_seo.title);
        this.metaTagService.updateTag(
          { name: 'keywords', content: data['data'][0].aboutus_seo.keywords },
        );

        this.metaTagService.updateTag(
          { name: 'description', content:  data['data'][0].aboutus_seo.description },
        );
      }else{
      }
    })
  }
}
