import { Injectable } from '@angular/core';
import { variables } from 'src/environments/variables';
import { HttpClient } from '@angular/common/http';
import { endpoints } from 'src/environments/endpoint';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  variables = variables;
  private dataSource = new BehaviorSubject({});
  public productId = new BehaviorSubject<any>('');
  emitProductId(data: any) {
    this.productId.next(data);
  }

  getProductID(): Observable<any> {
    return this.productId.asObservable();
  }
  data = this.dataSource.asObservable();
  private datas = [];
  private _zf_tokenSubject: BehaviorSubject<any>;
  public _zf_token: Observable<any>;
  private datass: any;
  private product_id: any;

  constructor(private http: HttpClient) {
    // debugger;
    this._zf_tokenSubject = new BehaviorSubject<any>(
      localStorage.getItem('_zf_token')
    );
    this._zf_token = this._zf_tokenSubject.asObservable();
  }

  setUserData(val: object) {
    this.dataSource.next(val);
  }
  getUserData() {
    return this.data;
  }
  getUserDetails() {
    return this.http.get(endpoints.auth.userdetails);
  }
  forgotPassword(data) {
    return this.http.post(endpoints.auth.forgot, data);
  }

  public get _zf_tokenValue(): any {
    return this._zf_tokenSubject.value;
  }

  signUp(data) {
    //return this.http.post(endpoints.auth.signup, data)
    return this.http.post<any>(endpoints.auth.signup, data).pipe(
      map((user) => {
        if (user.status) {
          if (user.data) {
            var temp_zf_token = user.token;
            temp_zf_token = temp_zf_token.replace(/['"]+/g, '');
            // console.log(temp_zf_token);
            localStorage.setItem('_zf_token', temp_zf_token);
            this._zf_tokenSubject.next(temp_zf_token);
          }
        }
        return user;
      })
    );
  }


  checkUnique(data) {
    return this.http.post(endpoints.auth.checkunique, data);
  }

  verifyEmail(data) {
    return this.http.post(endpoints.auth.verifyemail, data);
  }

  sendemailverification(data) {
    return this.http.post(endpoints.auth.sendemailverification, data);
  }
  resetPassword(data) {
    return this.http.post(endpoints.auth.resetpassword, data);
  }
  changePassword(data) {
    return this.http.post(endpoints.auth.changepassword, data);
  }
  uploadFile(data) {
    return this.http.post(endpoints.uploadfile, data);
  }
  updateProfile(data) {
    return this.http.post(endpoints.updateprofile, data);
  }
  mobileselectPlan(data) {
    return this.http.post(endpoints.baseUrl + '/iossubscription', data);
  }
  getMakes(data) {
    return this.http.post(endpoints.baseUrl + '/makes', data);
  }
  getModels(data) {
    return this.http.post(endpoints.baseUrl + '/models', data);
  }
  getFractionalOwnership() {
    return this.http.get(endpoints.baseUrl + '/getfractionalownership');
  }
  getCurrency() {
    return this.http.get(endpoints.baseUrl + '/getcurrency');
  }
  getUnits() {
    return this.http.get(endpoints.baseUrl + '/getunits');
  }
  getUsefulLoadUnits() {
    return this.http.get(endpoints.baseUrl + '/getusefulloadunits');
  }
  getSpecialUseCategory() {
    return this.http.get(endpoints.baseUrl + '/specialusecategory');
  }

  checkCvc(data){
    return this.http.post(endpoints.baseUrl+'/checkcvc',data)
  }

  // step2
  productCategoryMakeModel(data) {
    return this.http.post(
      endpoints.baseUrl + '/product_category_make_model',
      data
    );
  }
  // step3
  productCondition(data) {
    return this.http.post(endpoints.baseUrl + '/productcondition', data);
  }
  // step4
  productSearialization(data) {
    return this.http.post(endpoints.baseUrl + '/productsearialization', data);
  }
  // step5
  productEnginePropTime(data) {
    return this.http.post(endpoints.baseUrl + '/productengineproptime', data);
  }
  // step6
  productinteriorexterior(data) {
    return this.http.post(endpoints.baseUrl + '/productinteriorexterior', data);
  }
  // step07
  productMedia(data) {
    return this.http.post(endpoints.baseUrl + '/productmedia', data);
  }
  // step8
  productOtherDetails(data) {
    return this.http.post(endpoints.baseUrl + '/productotherdetails', data);
  }
  // step9
  productMoreDetails(data) {
    return this.http.post(endpoints.baseUrl + '/productmoredetails', data);
  }
  // step10
  productSpecialUse(data) {
    // debugger;
    return this.http.post(endpoints.baseUrl + '/productspecialuse', data);
  }
  // step11
  productPrivacy(data) {
    // debugger;
    return this.http.post(endpoints.baseUrl+'/productprivacy', data);
  }

  getProductByUserId(data) {
    return this.http.post(
      endpoints.baseUrl + '/optimized/getproductbyuserid',
      data
    );
  }

  // card new api's
  postPaymentCard(data){
    return this.http.post(endpoints.baseUrl+'/store-card-new',data)
  }
  getPaymentCards(){
    return this.http.get(endpoints.baseUrl+'/get-all-cards')
  }
  updateCardNew(card){
    return this.http.post(endpoints.baseUrl+'/update-card-new',card)
  }
  deletePaymentCard(cardId){
    return this.http.post(endpoints.baseUrl+'/remove-card',cardId)
  }

  // api/optimized/productprivacy
  // getProductPrivacy(data){
  //   return this.http.post(endpoints.baseUrl+'/optimized/productprivacy',data)
  // }

  productMediaDelete(data) {
    return this.http.post(endpoints.baseUrl + '/productmediadelete', data);
  }
  deleteProduct(data) {
    return this.http.post(endpoints.baseUrl + '/deleteproduct', data);
  }

  addCards(data) {
    // debugger;
    // console.log('Add cards called!');
    return this.http.post(endpoints.baseUrl+'/addcard',data);
  }

  getcarddetails() {
    // debugger;
    // console.log('Add cards called!');
    return this.http.get(endpoints.baseUrl + '/getcarddetails');
  }

  canPublish(data) {
    return this.http.post(endpoints.baseUrl + '/optimized/canpublish', data);
  }

  emailtowebmaster(data) {
    return this.http.post(endpoints.baseUrl + '/emailtowebmaster', data);
  }
  addtoFavourite(data) {
    return this.http.post(endpoints.baseUrl + '/addtofavourite', data);
  }
  unFavourite(data) {
    return this.http.post(endpoints.baseUrl + '/unfavourite', data);
  }
  getfavourite(data) {
    return this.http.post(endpoints.baseUrl + '/optimized/getfavourite', data);
  }
  storecard(data) {
    // console.log(data)
    // debugger;
    return this.http.post(endpoints.baseUrl + '/storecard', data);
  }

  newsletter(data) {
    return this.http.post(endpoints.baseUrl + '/newsletter', data);
  }
  updateProductStatus(data) {
    // debugger;
    return this.http.post(
      endpoints.baseUrl + '/optimized/updateproductstatus',
      data
    );
  }

  updateSubscription(data) {
    // debugger;
    return this.http.post(
      endpoints.baseUrl + '/optimized/updatesubscription',
      data
    );
  }

  changeSubscription(data) {
    // debugger;
    return this.http.post(
      endpoints.baseUrl + '/optimized/changesubscription',
      data
    );
  }

  getSubscription(data) {
    // debugger;
    // console.log('Get Subscription called!');
    // console.log(endpoints.baseUrl + '/optimized/getsubscriptiontype');
    return this.http.post(
      endpoints.baseUrl + '/optimized/getsubscriptiontype',
      data
    );
  }

  setOption(option, value) {
    this.datass = value;
  }

  getOption() {
    return this.datass;
  }

  setProductId(option, value) {
    this.product_id = value;
  }

  getProductId() {
    return this.product_id;
  }

  applyPromoCode(data) {
    return this.http.post(endpoints.baseUrl + '/applypromocode', data);
  }
}
