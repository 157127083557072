<div class="signup-container">
    <div class="container px-0">
        <div class="auth-card auth-card-signup">
            <div class="auth-card-logo" *ngIf="signup_step==1">
                <a routerLink="/"><img *ngIf="cmsData?.site.logo"
                        src="{{variables.serverAssetUrl}}{{cmsData?.site.logo}}" alt="image"></a>
            </div>
            <div class="auth-card-nav" *ngIf="signup_step!=1">
                <button (click)="signup_step=1" class="btn btn-sm btn-ziofly-default default"><span><i
                            class="icon-arrow-right rotate-180 mr-2"></i> Back</span></button>
            </div>
            <div class="auth-card-title left">
                Create your account
            </div>
            <div class="signup-step step-1" [class.shown]="signup_step === 1">
                <form [formGroup]="zForm_1" autocomplete="new-password">
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_1 && f_1.first_name.errors }">
                                <div class="zf-input-label">Enter your first name</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="first_name"
                                        maxlength="{{validations.def.name.first_name.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f_1.first_name.errors.required">{{validations.messages.first_name.required}}
                                </div>
                                <div *ngIf="f_1.first_name.errors.pattern">{{validations.messages.first_name.invalid}}
                                </div>
                                <div
                                    *ngIf="!f_1.first_name.errors.required && !f_1.first_name.errors.pattern && f_1.first_name.errors.trimError">
                                    {{validations.messages.first_name.invalid}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_1 && f_1.last_name.errors }">
                                <div class="zf-input-label">Enter your last name</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="last_name"
                                        maxlength="{{validations.def.name.last_name.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f_1.last_name.errors.required">{{validations.messages.last_name.required}}
                                </div>
                                <div *ngIf="f_1.last_name.errors.pattern">{{validations.messages.last_name.invalid}}
                                </div>
                                <div
                                    *ngIf="!f_1.last_name.errors.required && !f_1.last_name.errors.pattern && f_1.last_name.errors.trimError">
                                    {{validations.messages.last_name.invalid}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_1 && f_1.seller_name.errors }">
                                <div class="zf-input-label">Enter your seller name</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="seller_name"
                                        maxlength="{{validations.def.seller_name.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.seller_name.errors" class="invalid-feedback">
                                <div *ngIf="f_1.seller_name.errors.required">
                                    {{validations.messages.seller_name.required}}
                                </div>
                                <div *ngIf="f_1.seller_name.errors.pattern">{{validations.messages.seller_name.invalid}}
                                </div>
                                <div
                                    *ngIf="!f_1.seller_name.errors.required && !f_1.seller_name.errors.pattern && f_1.seller_name.errors.trimError">
                                    {{validations.messages.seller_name.invalid}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_1 && f_1.email.errors }">
                                <div class="zf-input-label">Enter your email address</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="email"
                                        maxlength="{{validations.def.email.max}}" (keypress)="convertLowerCase($event)">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.email.errors" class="invalid-feedback">
                                <div *ngIf="f_1.email.errors.required">{{validations.messages.email.required}}</div>
                                <div *ngIf="f_1.email.errors.pattern">{{validations.messages.email.invalid}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_1 && f_1.phone.errors }">
                                <div class="zf-input-label">Enter your phone number</div>
                                <div class="zf-input-control">
                                    <!-- <input type="search"   class="form-control d-none"  formControlName="phone" maxlength="{{validations.def.phone.max}}"> -->
                                    <input type="tel" (change)="changePhone()" (keypress)="changePhone()"
                                        class="form-control" pattern="[0-9]+" formControlName="phone"
                                        mask="(000) 000-0000|| (000) 000-00000|| (000) 000-000000">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.phone.errors" class="invalid-feedback">
                                <div *ngIf="f_1.phone.errors.required">{{validations.messages.phone.required}} </div>
                                <div *ngIf="f_1.phone.errors.pattern">{{validations.messages.phone.invalid}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_1 && f_1.fax.errors }">
                                <div class="zf-input-label">Enter your fax number</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="fax"
                                        maxlength="{{validations.def.fax.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.fax.errors" class="invalid-feedback">
                                <div *ngIf="f_1.fax.errors.required">{{validations.messages.fax.required}}</div>
                                <!--<div *ngIf="f_1.fax.errors.pattern">{{validations.messages.fax.invalid}}</div>-->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_1 && f_1.address_first.errors }">
                                <div class="zf-input-label">Enter your address line 1</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="address_first"
                                        maxlength="{{validations.def.address.line_1.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.address_first.errors" class="invalid-feedback">
                                <div *ngIf="f_1.address_first.errors.required">
                                    {{validations.messages.address.line_1.required}}</div>
                                <div *ngIf="f_1.address_first.errors.minlength">
                                    {{validations.messages.address.line_1.invalid}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_1 && f_1.address_second.errors }">
                                <div class="zf-input-label">Enter your address line 2</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="address_second"
                                        maxlength="{{validations.def.address.line_2.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.address_second.errors" class="invalid-feedback">
                                <!-- <div *ngIf="f_1.address_second.errors.required">{{validations.messages.address.line_2.required}}</div>
                                    <div *ngIf="f_1.address_second.errors.minlength">{{validations.messages.address.line_2.invalid}}</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group country-dd" id="country-dd"
                                [ngClass]="{ 'is-invalid': submitted_1 && f_1.country.errors }">
                                <span *ngIf="country_loading" class="input-loader"><span
                                        class="zf-btn-loader"></span></span>
                                <div class="zf-input-label">
                                    <span *ngIf="!country_loading">Select your country</span>
                                    <span *ngIf="country_loading">Please wait...</span>
                                </div>
                                <div class="zf-input-control">
                                    <ng-select2 id="countryDropdown" [disabled]="!country_loaded"
                                        formControlName="country" [data]="countries" [options]="dd_options"
                                        (click)="onFocus()" (valueChanged)="onCountryChange($event)"></ng-select2>
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.country.errors" class="invalid-feedback">
                                <div *ngIf="f_1.country.errors.required">
                                    {{validations.messages.address.country.required}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group state-dd"
                                [ngClass]="{ 'is-invalid': submitted_1 && f_1.state.errors }">
                                <span *ngIf="state_loading" class="input-loader"><span
                                        class="zf-btn-loader"></span></span>
                                <div class="zf-input-label">
                                    <span *ngIf="!state_loading">Select your state</span>
                                    <span *ngIf="state_loading">Please wait...</span>
                                </div>
                                <div class="zf-input-control">
                                    <ng-select2 formControlName="state" [disabled]="!state_loaded" [data]="states"
                                        [options]="dd_options" (click)="onFocus()"
                                        (valueChanged)="onStateChange($event)">
                                    </ng-select2>
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.state.errors" class="invalid-feedback">
                                <div *ngIf="f_1.state.errors.required">{{validations.messages.address.state.required}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group city-dd"
                                [ngClass]="{ 'is-invalid': submitted_1 && f_1.city.errors }">
                                <span *ngIf="city_loading" class="input-loader"><span
                                        class="zf-btn-loader"></span></span>
                                <div class="zf-input-label">
                                    <span *ngIf="!city_loading">Select your City</span>
                                    <span *ngIf="city_loading">Please wait...</span>
                                </div>
                                <div class="zf-input-control">
                                    <ng-select2 formControlName="city" [disabled]="!city_loaded" [data]="cities"
                                        (click)="onFocus()" [options]="dd_options"></ng-select2>
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.city.errors" class="invalid-feedback">
                                <div *ngIf="f_1.city.errors.required">{{validations.messages.address.city.required}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_1 && f_1.zipcode.errors }">
                                <div class="zf-input-label">Enter your zip / postal code</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="zipcode"
                                        maxlength="{{validations.def.address.zipcode.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_1 && f_1.zipcode.errors" class="invalid-feedback">
                                <div *ngIf="f_1.zipcode.errors.required">
                                    {{validations.messages.address.zipcode.required}}
                                </div>
                                <div *ngIf="f_1.zipcode.errors.pattern">{{validations.messages.address.zipcode.invalid}}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="signup-step step-2" [class.shown]="signup_step === 2">
                <form [formGroup]="zForm_2">
                    <p class="mb-2 color-black">Are you currently a</p>
                    <div class="row">
                        <div class="col-12">
                            <div class="toggle-row mb-2">
                                <div class="toggle-row-text">Pilot?</div>
                                <div class="toggle-row-toggle">
                                    <label class="zf-toggle-switch">
                                        <input type="checkbox" formControlName="is_pilot">
                                        <div class="zf-toggle-switch-box">
                                            <div class="zf-toggle-switch-thumb"></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="toggle-row mb-2">
                                <div class="toggle-row-text">Aircraft Owner?</div>
                                <div class="toggle-row-toggle">
                                    <label class="zf-toggle-switch">
                                        <input type="checkbox" formControlName="is_aircraft_owner"
                                            (change)="aircraftOwn($event)">
                                        <div class="zf-toggle-switch-box">
                                            <div class="zf-toggle-switch-thumb"></div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1" *ngIf="isAircraftOwned">
                        <div class="col-12 col-lg-12">
                            <div class="zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_2 && f_2.aircraft_owned.errors }">
                                <span *ngIf="category_loading" class="input-loader"><span
                                        class="zf-btn-loader"></span></span>
                                <div class="zf-input-label">
                                    <!--<span *ngIf="!category_loading">If applicable, what kind of aircraft do you own?</span>-->
                                    <span *ngIf="!category_loading">What kind of aircraft do you own?</span>

                                    <span *ngIf="category_loading">Please wait...</span>
                                </div>
                                <div class="zf-input-control">
                                    <ng-select2 [disabled]="!category_loaded" [data]="categories"
                                        formControlName="aircraft_owned" [options]="dd_options" (click)="onFocus()">
                                    </ng-select2>
                                </div>
                            </div>
                            <div *ngIf="submitted_2 && f_2.aircraft_owned.errors" class="invalid-feedback">
                                <div *ngIf="f_2.aircraft_owned.errors.required">
                                    {{validations.messages.aircraft.required}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_2 && f_2.password.errors }">
                                <div class="zf-input-label">Enter Password</div>
                                <div class="zf-input-control">
                                    <input type="password" zioflyAppPassword class="form-control"
                                        formControlName="password" maxlength="{{validations.def.password.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_2 && f_2.password.errors" class="invalid-feedback">
                                <div *ngIf="f_2.password.errors.required">{{validations.messages.password.required}}
                                </div>

                                <div *ngIf="f_2.password.errors.minlength || f_2.password.errors.maxlength">
                                    {{validations.messages.password.invalid}}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_2 && f_2.password_confirmation.errors }">
                                <div class="zf-input-label">Confirm Password</div>
                                <div class="zf-input-control">
                                    <input type="password" zioflyAppPassword class="form-control"
                                        formControlName="password_confirmation"
                                        maxlength="{{validations.def.password.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted_2 && f_2.password_confirmation.errors" class="invalid-feedback">
                                <div *ngIf="f_2.password_confirmation.errors.required">
                                    {{validations.messages.password_confirmation.required}}</div>
                                <div *ngIf="f_2.password_confirmation.errors.mustMatch">
                                    {{validations.messages.password_confirmation.same}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6">
                            <div class="zf-checkbox-text zf-input-group"
                                [ngClass]="{ 'is-invalid': submitted_2 && accepted_terms==false }">
                                <label class="zf-checkbox">
                                    <input type="checkbox" [attr.checked]="accepted_terms==true ? '' : null"
                                        (change)="terms($event)">
                                    <div class="zf-checkbox-box"></div>
                                </label>
                                <span>I accept the ZioFly <a target="_blank" routerLink="/terms-and-conditions">terms of
                                        use
                                        & service</a>.</span>
                            </div>
                            <div *ngIf="submitted_2 && accepted_terms==false" class="invalid-feedback">
                                <div>{{validations.messages.terms.required}}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="zf-checkbox-text zf-input-group">
                                <label class="zf-checkbox">
                                    <input type="checkbox" formControlName="notify_for_anynew">
                                    <div class="zf-checkbox-box"></div>
                                </label>
                                <span class="outline">Notify me about new features, upcoming events, and industry
                                    news.</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-2">
                            <small class="font-size-90per">Note: Your email address will never be disclosed to bulk
                                emailers. See the Privacy Statement for more information.</small>
                        </div>
                    </div>
                </form>
            </div>
            <div class="signup-footer">
                <div class="row">
                    <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                        <button class="btn btn-ziofly-default active" [disabled]="submitting" *ngIf="signup_step==1"
                            (click)="step_1_submit()">
                            <span *ngIf="!submitting">Next</span>
                            <span *ngIf="submitting">
                                <span class="zf-btn-loader"></span>
                            </span>
                        </button>
                        <button class="btn btn-ziofly-default active min-width-125" [disabled]="submitting"
                            *ngIf="signup_step==2" (click)="step_2_submit()">
                            <span *ngIf="!submitting">Sign Up</span>
                            <span *ngIf="submitting">
                                <span class="zf-btn-loader"></span>
                            </span>
                        </button>
                    </div>
                    <div
                        class="col-12 col-lg-6 d-flex align-items-center justify-content-center justify-content-lg-end">
                        <p class="mb-0 right-on-sm color-black">Already have an account?
                            <a routerLink="/login">Sign In</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
