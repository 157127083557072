<ng-container *ngIf="router.url !== '/user/sell-aircraft' ">
<footer>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="footer-logo">
                    <a routerLink="/">
                        <img *ngIf="cmsData?.site.logo" src="{{variables.serverAssetUrl}}{{cmsData?.site.logo}}" alt="image" >
                    </a>
                </div>
                <div class="footer-desc" [innerHTML]="cmsData?.site.footer_description">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <form [formGroup]="newsletterForm">
                    <div class="footer-subscribe">
                        <i class="footer-subscribe-icon icon-email"></i>
                        <input class="footer-subscribe-input" type="search" formControlName="email" placeholder="Enter your email to subscribe our news letter">
                        <a class="footer-subscribe-btn" [class.disabled]="submitting" (click)="newsletterFormSubmit()">
                            <i *ngIf="!submitting" class="icon-arrow-right"></i>
                            <span *ngIf="submitting">
                                <span class="zf-btn-loader"></span>
                            </span>
                        </a>
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Invalid email</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="footer-links" *ngIf="false">            
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="footer-widget">
                        <div class="footer-widget-title">Market</div>
                        <div class="footer-widget-links">
                            <ul>
                                <li><a routerLink="/">Aerospace</a></li>
                                <li><a routerLink="/">Air Transport</a></li>
                                <li><a routerLink="/">MRO</a></li>
                                <li><a routerLink="/">Defense and Space</a></li>
                                <li><a routerLink="/">Business Aviation</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="footer-widget">
                        <div class="footer-widget-title">PRODUCTS</div>
                        <div class="footer-widget-links">
                            <ul>
                                <li><a routerLink="/">AWIN Intelligence Bundles</a></li>
                                <li><a routerLink="/">Market Briefings</a></li>
                                <li><a routerLink="/">Publications</a></li>
                                <li><a routerLink="/">Data Services</a></li>
                                <li><a routerLink="/">Directories</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="footer-widget">
                        <div class="footer-widget-title">CONTACT US</div>
                        <div class="footer-widget-links">
                            <ul>
                                <li><a routerLink="/">Subscriber Services</a></li>
                                <li><a routerLink="/">Advertising, Marketing</a></li>
                                <li><a routerLink="/">Content Sales</a></li>
                                <li><a routerLink="/">Events</a></li>
                                <li><a routerLink="/">PR & Communications</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <div class="footer-widget">
                        <div class="footer-widget-title">OTHER RESOURCES</div>
                        <div class="footer-widget-links">
                            <ul>
                                <li><a routerLink="/">Aviation Week Marketplace</a></li>
                                <li><a routerLink="/">Knowledge Center</a></li>
                                <li><a routerLink="/">Newsletters</a></li>
                                <li><a routerLink="/">Show News</a></li>
                                <li><a routerLink="/faq">FAQ</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-social-links">
            <ul>
                <li *ngIf="cmsData?.site?.facebook_link"><a target="_blank" href="{{cmsData?.site?.facebook_link}}"><i class="active icon-facebook"></i></a></li>
                <li *ngIf="cmsData?.site?.linkedin_link"><a target="_blank" href="{{cmsData?.site?.linkedin_link}}"><i class="active icon-linkedin"></i></a></li>
                <li *ngIf="cmsData?.site?.instagram_link"><a target="_blank" href="{{cmsData?.site?.instagram_link}}"><i class="active icon-instagram"></i></a></li>
                <li *ngIf="cmsData?.site?.twitter_link"><a target="_blank" href="{{cmsData?.site?.twitter_link}}"><i class="active icon-twitter"></i></a></li>
                <li *ngIf="cmsData?.site?.youtube_link"><a target="_blank" href="{{cmsData?.site?.youtube_link}}"><i class="active icon-youtube"></i></a></li>
            </ul>
        </div>
    </div>
</footer>
<div class="footer-helper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="footer-bottom">
                    <img src="assets/img/footer-flight.png" alt="footer flight">
                </div>
            </div>
        </div>
    </div>
</div>
</ng-container>