import { variables } from "./variables";
const base = variables.apiUrl;
export const endpoints = {
   baseUrl: base,
   cms: base+'/getcontent',
   mobileplan: base+'/iossubscription',
   countries: base+'/countries',
   states: base+'/states',
   cities: base+'/cities',
   contactus: base+'/consultexpert',
   categories: base+'/categories',
   auth : {
    login: base+'/login',
    userdetails: base+'/optimized/userprofile',
    forgot: base+'/optimized/forgotpassword',
    signup: base+'/signup',
    verifyemail: base+'/optimized/verifyemail',
    sendemailverification: base+'/optimized/sendemailverification',
    resetpassword: base+'/optimized/resetpassword',
    changepassword: base+'/optimized/changepassword',
    checkunique: base+'/optimized/checkemailphonefax'
   },
   updateprofile: base+'/updateprofile',
   uploadfile: base+'/uploadfile',


};
