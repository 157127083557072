<div class="sell-aircraft-page section" [class.iseditproduct]="isEditPage">
  <div class="container">
    <div class="sell-aircraft-warpper">
      <ul class="sell-aircraft-steps pt-4" [class.subscription-list]="selectStatus==true">
        <li [class.active]="currentStep>=1 ">

          <div class="sas-step">
            <span class="sas-number" *ngIf="currentStep<1 && !isEditPage">1</span>
            <span class="sas-number" *ngIf="(currentStep>=1 && currentStep<=4) && !isEditPage">1</span>
            <span class="sas-number" *ngIf="currentStep>4 && !isEditPage"><i class="fa-solid fa-check"></i></span>
            <span class="sas-number cursor-pointer" (click)="goToStep(1)" *ngIf="isEditPage"><img
                src="assets/img/edit-pencil.png"></span>
            <span class="sas-text" *ngIf="!isEditPage">Aircraft Info</span>
            <span class="sas-text cursor-pointer" (click)="goToStep(1)" *ngIf="isEditPage">Aircraft Info</span>
          </div>
        </li>

        <li [class.active]="currentStep>=5">
          <div class="sas-step">

            <span class="sas-number" *ngIf="currentStep<5 && !isEditPage">2</span>
            <span class="sas-number" *ngIf="(currentStep>=5 && currentStep<=6) && !isEditPage">2</span>
            <span class="sas-number" *ngIf="currentStep>6 && !isEditPage"><i class="fa-solid fa-check"></i></span>
            <span class="sas-number cursor-pointer" (click)="goToStep(5)" *ngIf="isEditPage"><img
                src="assets/img/edit-pencil.png"></span>
            <span class="sas-text" *ngIf="!isEditPage">Engine & Prop Time</span>
            <span class="sas-text cursor-pointer" (click)="goToStep(5)" *ngIf="isEditPage">Engine & Prop Time</span>
          </div>
        </li>
        <li [class.active]="currentStep>=7">
          <div class="sas-step">
            <span class="sas-number" *ngIf="currentStep<7 && !isEditPage">3</span>

            <span class="sas-number" *ngIf="currentStep==7 && !isEditPage">3</span>
            <span class="sas-number" *ngIf="currentStep>7 && !isEditPage"><i class="fa-solid fa-check"></i></span>
            <span class="sas-number cursor-pointer" (click)="goToStep(6)" *ngIf="isEditPage"><img
                src="assets/img/edit-pencil.png"></span>
            <span class="sas-text" *ngIf="!isEditPage">Interior & Exterior</span>
            <span class="sas-text cursor-pointer" (click)="goToStep(6)" *ngIf="isEditPage">Interior & Exterior</span>
          </div>
        </li>
        <li [class.active]="currentStep>=8">
          <div class="sas-step">
            <span class="sas-number" *ngIf="currentStep<8 && !isEditPage">4</span>

            <span class="sas-number" *ngIf="currentStep==8 && !isEditPage">4</span>
            <span class="sas-number" *ngIf="currentStep>8 && !isEditPage"><i class="fa-solid fa-check"></i></span>
            <span class="sas-number cursor-pointer" (click)="goToStep(7)" *ngIf="isEditPage"><img
                src="assets/img/edit-pencil.png"></span>
            <span class="sas-text" *ngIf="!isEditPage">Photos & Videos</span>
            <span class="sas-text cursor-pointer" (click)="goToStep(7)" *ngIf="isEditPage">Photos & Videos</span>
          </div>
        </li>
        <li [class.active]="currentStep>=9">
          <div class="sas-step">

            <span class="sas-number" *ngIf="currentStep<9 && !isEditPage">5</span>
            <span class="sas-number" *ngIf="(currentStep>=9 && currentStep<=12) && !isEditPage">5</span>
            <span class="sas-number" *ngIf="currentStep>12 && !isEditPage"><i class="fa-solid fa-check"></i></span>
            <span class="sas-number cursor-pointer" (click)="goToStep(8)" *ngIf="isEditPage"><img
                src="assets/img/edit-pencil.png"></span>
            <span class="sas-text" *ngIf="!isEditPage">Other Details</span>
            <span class="sas-text cursor-pointer" (click)="goToStep(8)" *ngIf="isEditPage">Other Details</span>
          </div>
        </li>
      </ul>
      <div class="sell-aircraft-card">
        <ng-container *ngIf="currentStep!=0">
          <img class="sell-aircraft-card-img sell-aircraft-card-circle" src="assets/img/sell-aircraft-card-circle.png">
          <img class="sell-aircraft-card-img sell-aircraft-card-path" src="assets/img/sell-aircraft-card-path.png">
        </ng-container>
        <div class="row">
          <div class="col-lg-5 flex-img-center" [class.d-none]='currentStep==0'>
            <img *ngIf="currentStep<5" class="sell-aircraft-step-img" src="assets/img/sell-aircraft-step-7.png">
            <img *ngIf="currentStep==5" class="sell-aircraft-step-img" src="assets/img/sell-aircraft-step-2.png">
            <img *ngIf="currentStep==6" class="sell-aircraft-step-img" src="assets/img/sell-aircraft-step-3.png">
            <img *ngIf="currentStep==7" class="sell-aircraft-step-img" src="assets/img/sell-aircraft-step-4_1.png">
            <img *ngIf="currentStep==8" class="sell-aircraft-step-img" src="assets/img/sell-aircraft-step-5.png">
            <img *ngIf="currentStep==9" class="sell-aircraft-step-img" src="assets/img/sell-aircraft-step-6.png">
            <img *ngIf="currentStep==10" class="sell-aircraft-step-img" src="assets/img/sell-aircraft-step-7.png">
            <img *ngIf="currentStep==11 && !paymentStep" class="sell-aircraft-step-img"
              src="assets/img/sell-aircraft-step-8.png">
            <img *ngIf="currentStep==11  && paymentStep" class="sell-aircraft-step-img"
              src="assets/img/sell-aircraft-step-payment.png">
            <img *ngIf="currentStep==12" class="sell-aircraft-step-img" src="assets/img/sell-aircraft-step-9.png">
          </div>
          <div class="col-lg-7 col-lg-7 d-flex flex-column" [class.col-full-size]='currentStep==0'>
            <div class="steps-in-card-okay mb-auto">
              <ng-container *ngIf="currentStep==0">
                <h3 class="h3-add-aircarft" *ngIf="!selectedListngType">Please select the type of listing to get started
                  {{selectedListngType}}</h3>
                <h3 class="h3-add-aircarft" *ngIf="selectedListngType">Your selected listing type</h3>

                <div class="row mob-row" [class.subscription-row]="selectStatus==false">
                  <div class="col-lg-6 my-4 free-col mob-col" [class.selected_default]="selectStatus==false
                                && selectedListngType=='free'">
                    <div class="listing-type-box free" [class.selected]="selectedListngType=='free'">
                      <span class="icon-listing-type-selected"><img src="assets/img/checked-symbol-green.png"></span>
                      <div class="ltb-left">
                        <img src="assets/img/listing-free-icon.png">
                        <h6>Free Listing</h6>
                        <h4>$0.00</h4>
                        <a *ngIf="!isEditPage" class="ltb-btn" (click)="selectListngType('free')">Select</a>
                      </div>
                      <div class="ltb-right">
                        <ul class="ltb-list">
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>1 listing per account
                          </li>
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>60 day Listing</li>
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Upload upto 10 Photos
                          </li>
                          <li><span class="icon"><img src="assets/img/cross-iconn.png"></span>No Video</li>
                          <li><span class="icon"><img src="assets/img/cross-iconn.png"></span>Premium placement in
                            search results</li>
                          <li><span class="icon"><img src="assets/img/cross-iconn.png"></span>1 mentioned post per month
                            on Ziofly social media platforms</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 my-4 premium-col mob-col" [class.selected_default]="selectStatus==false
                                && selectedListngType=='premium'">
                    <div class="listing-type-box premium" [class.selected]="selectedListngType=='premium'">
                      <span class="icon-listing-type-selected"><img src="assets/img/checked-symbol-yellow.png"></span>
                      <div class="ltb-left">
                        <img src="assets/img/listing-premium-icon.png">
                        <h6>Premium Listing</h6>
                        <h4>$29.99</h4>
                        <a *ngIf="!isEditPage" class="ltb-btn" (click)="selectListngType('premium')">Select</a>
                      </div>
                      <div class="ltb-right">
                        <ul class="ltb-list">
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>1 listing per account
                          </li>
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Unlimited Photos</li>
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Videos</li>
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Ad Analytics</li>
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Featured on Home page
                          </li>
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>Premium placement in
                            search results</li>
                          <li><span class="icon"><img src="assets/img/checked-symbol.png"></span>1 mentioned post per
                            month on Ziofly social media platforms</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="selectStatus==false">
                  <p class="note mt-3" *ngIf="selectedListngType == 'free'">
                    Note: You can edit aircraft details by click save and continue.
                    To upgrade subscription, you can change it from upgrade option on listing.
                  </p>

                  <p class="note mt-3" *ngIf="selectedListngType == 'premium'">
                    Note: You can't update premium subscription.
                    Click on save and continue to edit aircraft information.
                  </p>
                </div>
              </ng-container>

              <div class="container">
                <ul class="list-inline row" *ngIf="currentStep>=1 && currentStep<=4">
                  <li class="tabs-margin" *ngIf="currentStep!=1"><a>Catagory</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==1"><a class="active-tab">Catagory</a></li>
                  <li class="tabs-margin" *ngIf="currentStep!=2"><a>Make & Model</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==2"><a class="active-tab">Make & Model</a></li>
                  <li class="tabs-margin" *ngIf="currentStep!=3"><a>Condition</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==3"><a class="active-tab">Condition</a></li>
                  <li class="tabs-margin" *ngIf="currentStep!=4"><a>Pricing</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==4"><a class="active-tab">Pricing</a></li>
                </ul>
                <ul class="list-inline row" *ngIf="currentStep>=5 && currentStep<=6">
                  <li class="tabs-margin" *ngIf="currentStep!=5"><a>Engine & Props Hours</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==5"><a class="active-tab">Engine & Props Hours</a></li>
                  <li class="tabs-margin" *ngIf="currentStep!=6"><a>Details</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==6"><a class="active-tab">Details</a></li>
                </ul>
                <ul class="list-inline row" *ngIf="currentStep==7">
                  <li class="tabs-margin" *ngIf="currentStep==7"><a class="active-tab">Ratings</a></li>
                </ul>
                <ul class="list-inline row" *ngIf="currentStep==8">
                  <li class="tabs-margin" *ngIf="currentStep==8"><a class="active-tab">Media Upload</a></li>
                </ul>
                <ul class="list-inline row" *ngIf="currentStep>=9 && currentStep<=12">
                  <li class="tabs-margin" *ngIf="currentStep!=9"><a>Location</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==9"><a class="active-tab">Location</a></li>
                  <li class="tabs-margin" *ngIf="currentStep!=10"><a>Special Use</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==10"><a class="active-tab">Special Use</a></li>
                  <li class="tabs-margin" *ngIf="currentStep!=11"><a>Privacy</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==11"><a class="active-tab">Privacy</a></li>
                  <li class="tabs-margin" *ngIf="currentStep!=12"><a>Payment</a></li>
                  <li class="tabs-margin" *ngIf="currentStep==12"><a class="active-tab">Payment</a></li>
                </ul>
              </div>
              <ng-container *ngIf="currentStep==1">
                <h3 *ngIf="!isEditPage" class="h3-add-aircarft">What aircraft are you looking to sell?</h3>

                <div class="text-center py-5" *ngIf="category_loading">
                  <span class="zf-btn-loader zf-btn-loader-yellow m-auto"></span>
                </div>
                <ul class="aircraft-radio-list" *ngIf="!category_loading">
                  <li *ngFor="let item of categories">
                    <label class="aircraft-radio-box" for="aircraftRadio_{{item.id}}"
                      [class.active]="activeCategorie == item.id">
                      <img src="{{variables.serverAssetUrl}}{{item.image}}">
                      <div class="aircraft-radio">
                        <input type="radio" name="aircraftRadio" (change)="aircraftRadioChange(item)" [value]="item.id"
                          id="aircraftRadio_{{item.id}}" [checked]="activeCategorie == item.id">
                        <span></span>
                      </div>
                      <div class="aircraft-radio-label">{{item.name}}</div>
                    </label>
                  </li>
                </ul>


              </ng-container>
              <ng-container *ngIf="currentStep==2">
                <h3 *ngIf="!isEditPage" class="h3-add-aircarft"> Tell us about your make and model.</h3>
                <form [formGroup]="sellAircraftForm">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_01 && f.make_id.errors }">
                        <div class="zf-input-label">Make</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="make_id" [data]="makesList" [options]="d_options"
                            (click)="onFocus()" (valueChanged)="onMakeChange($event)"></ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_01 && f.make_id.errors" class="invalid-feedback">
                        <div *ngIf="f.make_id.errors.required">Make is required</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_01 && f.model_id.errors }">
                        <span *ngIf="models_loading" class="input-loader"><span class="zf-btn-loader"></span></span>
                        <div class="zf-input-label">
                          <span *ngIf="!models_loading">Model</span>
                          <span *ngIf="models_loading">Please wait...</span>
                        </div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="model_id" [data]="modelsList" (click)="onFocus()"
                            [options]="d_options"></ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_01 && f.model_id.errors" class="invalid-feedback">
                        <div *ngIf="f.model_id.errors.required">Model is required</div>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="grey-card mt-3 mb-lg-5">
                  <div class="text-center">Don't see the make or model you need?</div>
                  <div class="text-center my-3"><img src="assets/img/email-envelop.png"></div>
                  <div class="text-center mb-3">
                    <a href="javascript:void(0)" (click)="openModalForm()"
                      class="btn btn-ziofly-default webmaster-btn shadow font-weight-normal"><span>Email ZioFly
                        Webmaster</span></a>
                  </div>
                  <p class="text-center mb-0 small">NOTE: The following fields, registration, serial, and year are
                    HIGHLY RECOMMENDED for most</p>
                </div>
              </ng-container>
              <ng-container *ngIf="currentStep==3">
                <h3 *ngIf="!isEditPage" class="h3-add-aircarft">Provide a little more info on the condition and type of
                  transaction</h3>
                <form [formGroup]="sellAircraftForm_03">
                  <label class="mb-0 mt-2">Condition</label>

                  <ul class="aircraft-radio-list in-row-4"
                    [ngClass]="{ 'is-invalid': submitted_03 && f3.aircraft_condition.errors}">
                    <li>
                      <label class="aircraft-radio-box" for="conditionRadio_1"
                        [class.active]="activeConditionRadio == 1">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="aircraft_condition" (change)="conditionRadioChange(1)"
                            value="New" id="conditionRadio_1">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">New</div>
                      </label>
                    </li>
                    <li>
                      <label class="aircraft-radio-box" for="conditionRadio_2"
                        [class.active]="activeConditionRadio == 2">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="aircraft_condition" (change)="conditionRadioChange(2)"
                            value="Used" id="conditionRadio_2">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">Used</div>
                      </label>
                    </li>
                    <li>
                      <label class="aircraft-radio-box" for="conditionRadio_3"
                        [class.active]="activeConditionRadio == 3">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="aircraft_condition" (change)="conditionRadioChange(3)"
                            value="Project" id="conditionRadio_3">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">Project</div>
                      </label>
                    </li>
                    <li>
                      <label class="aircraft-radio-box" for="conditionRadio_4"
                        [class.active]="activeConditionRadio == 4">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="aircraft_condition" (change)="conditionRadioChange(4)"
                            value="Not Specified" id="conditionRadio_4">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">Not specified</div>
                      </label>
                    </li>
                  </ul>
                  <div *ngIf="submitted_03 && f3.aircraft_condition.errors" class="invalid-feedback">
                    <div *ngIf="f3.aircraft_condition.errors.required">Condition is required</div>
                  </div>

                  <label class="mb-0 mt-2">Sale Status</label>
                  <ul class="aircraft-radio-list in-row-4"
                    [ngClass]="{ 'is-invalid': submitted_03 && f3.sale_status.errors}">
                    <li>
                      <label class="aircraft-radio-box" for="saleStatusRadio_1"
                        [class.active]="activesaleStatusRadio == 1">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="sale_status" (change)="saleStatusRadioChange(1)"
                            value="For Sale" id="saleStatusRadio_1">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">For sale</div>
                      </label>
                    </li>
                    <li>
                      <label class="aircraft-radio-box" for="saleStatusRadio_2"
                        [class.active]="activesaleStatusRadio == 2">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="sale_status" (change)="saleStatusRadioChange(2)"
                            value="For Lease" id="saleStatusRadio_2">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">For lease</div>
                      </label>
                    </li>
                    <li>
                      <label class="aircraft-radio-box" for="saleStatusRadio_3"
                        [class.active]="activesaleStatusRadio == 3">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="sale_status" (change)="saleStatusRadioChange(3)"
                            value="Swap or Trade" id="saleStatusRadio_3">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">Swap or Trade</div>
                      </label>
                    </li>
                    <li>
                      <label class="aircraft-radio-box" for="saleStatusRadio_4"
                        [class.active]="activesaleStatusRadio == 4">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="sale_status" (change)="saleStatusRadioChange(4)"
                            value="Fractional Ownership" id="saleStatusRadio_4">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">Fractional ownership</div>
                      </label>
                    </li>
                  </ul>
                  <div *ngIf="submitted_03 && f3.sale_status.errors" class="invalid-feedback">
                    <div *ngIf="f3.sale_status.errors.required">Sale Status is required</div>
                  </div>

                  <label class="mb-0 mt-2">Flight rules</label>
                  <ul class="aircraft-radio-list in-row-4"
                    [ngClass]="{ 'is-invalid': submitted_03 && f3.flight_rules.errors}">

                    <li>
                      <label class="aircraft-radio-box" for="flightRule_2" [class.active]="activeflightRule == 2">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="flight_rules" (change)="flightRuleChange(2)" value="VFR"
                            id="flightRule_2">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">VFR</div>
                      </label>
                    </li>
                    <li>
                      <label class="aircraft-radio-box" for="flightRule_3" [class.active]="activeflightRule == 3">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="flight_rules" (change)="flightRuleChange(3)"
                            value="IFR & VFR" id="flightRule_3">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">IFR & VFR</div>
                      </label>
                    </li>
                    <li>
                      <label class="aircraft-radio-box" for="flightRule_4" [class.active]="activeflightRule == 4">
                        <div class="aircraft-radio">
                          <input type="radio" formControlName="flight_rules" (change)="flightRuleChange(4)" value="N/A"
                            id="flightRule_4">
                          <span></span>
                        </div>
                        <div class="aircraft-radio-label">N/A</div>
                      </label>
                    </li>
                  </ul>
                  <div *ngIf="submitted_03 && f3.flight_rules.errors" class="invalid-feedback">
                    <div *ngIf="f3.flight_rules.errors.required">Flight Rules is required</div>
                  </div>

                  <div class="row mt-3">
                    <div [ngClass]="activesaleStatusRadio == 4 ? 'col-md-6' : 'col-md-12'">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_03 && f3.no_of_seats.errors}">
                        <div class="zf-input-label"># of seats</div>
                        <div class="zf-input-control">
                          <input type="text" class="form-control" formControlName="no_of_seats"
                            (keypress)="numberOnly($event)" maxlength="4">
                        </div>
                      </div>
                      <div *ngIf="submitted_03 && f3.no_of_seats.errors" class="invalid-feedback">
                        <div *ngIf="f3.no_of_seats.errors.required">No. of seats is required</div>
                      </div>
                    </div>

                    <div class="col-md-6" *ngIf="activesaleStatusRadio == 4">
                      <div class="zf-input-group">
                        <div class="zf-input-label">Fractional ownership</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="fraction_ownership" [data]="fractionalOwnershipList"
                            [options]="dd_options" [disabled]="activesaleStatusRadio == 1
                                                        || activesaleStatusRadio == 2 || activesaleStatusRadio == 3">
                          </ng-select2>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>


              </ng-container>
              <ng-container *ngIf="currentStep==4">
                <h3 *ngIf="!isEditPage" class="h3-add-aircarft">Now provide more details on your aircraft...</h3>
                <form [formGroup]="sellAircraftForm_04">
                  <div class="row mt-3">

                    <div class="col-lg-6">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_04 && f4.year.errors }">
                        <div class="zf-input-label">Select Year</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="year" [data]="yearsList" [options]="dd_options"></ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_04 && f4.year.errors" class="invalid-feedback">
                        <div *ngIf="f4.year.errors.required">Year is required</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-7">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_04 && f4.price.errors }">
                        <div class="zf-input-label">Sale Price </div>
                        <div class="zf-input-control">
                          <input type="text" id="price_input_farmated" class="form-control" formControlName="price"
                            (keypress)="numberOnly($event)" (keyup)="CommaFormatted($event)">
                        </div>
                      </div>
                      <div *ngIf="submitted_04 && f4.price.errors" class="invalid-feedback">
                        <div *ngIf="f4.price.errors.required">Sale Price is required</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-5 pl-0">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_04 && f4.currency_id.errors }">
                        <div class="zf-input-label">Currency</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="currency_id" [data]="currencyList" [options]="dd_options">
                          </ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_04 && f4.currency_id.errors" class="invalid-feedback">
                        <div *ngIf="f4.currency_id.errors.required">Currency is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="zf-input-group"
                        [ngClass]="{ 'is-invalid': submitted_04 && f4.registration_no.errors }">
                        <div class="zf-input-label">Enter registration number</div>
                        <div class="zf-input-control">
                          <input type="text" class="form-control" formControlName="registration_no">
                        </div>
                      </div>

                    </div>
                    <div class="col-md-6">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_04 && f4.serial_no.errors }">
                        <div class="zf-input-label">Enter serial number</div>
                        <div class="zf-input-control">
                          <input type="text" class="form-control" formControlName="serial_no">
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="zf-toggle-switch w-100">
                        <span class="mr-auto">Show registration number:</span>
                        <input type="checkbox" formControlName="show_resgistration_number">
                        <div class="zf-toggle-switch-box">
                          <div class="zf-toggle-switch-thumb"></div>
                        </div>
                      </label>
                    </div>
                    <div class="col-md-6">
                      <label class="zf-toggle-switch w-100">
                        <span class="mr-auto">Show serial number:</span>
                        <input type="checkbox" formControlName="show_serialnumber" checked="true">
                        <div class="zf-toggle-switch-box">
                          <div class="zf-toggle-switch-thumb"></div>
                        </div>
                      </label>
                    </div>
                    <div class="col-md-12">
                      <div class="zf-input-group mt-2"
                        [ngClass]="{ 'is-invalid': submitted_04 && f4.aircraft_description.errors}">
                        <div class="zf-input-control">
                          <textarea class="form-control" formControlName="aircraft_description" rows="5"
                            placeholder="Aircraft description" maxlength="400"></textarea>
                        </div>
                      </div>
                      <div *ngIf="submitted_04 && f4.aircraft_description.errors" class="invalid-feedback">
                        <div *ngIf="f4.aircraft_description.errors.required">Aircraft Description is required</div>
                      </div>
                    </div>
                  </div>
                </form>
              </ng-container>
              <ng-container *ngIf="currentStep==5">
                <h3 class="h3-add-aircarft">Let's get some input from you on hours on engine, prop and airframe</h3>
                <form [formGroup]="sellAircraftForm_05">
                  <div class="zf-input-group"
                    [ngClass]="{ 'is-invalid': submitted_05 && f5.total_time_on_aircraft.errors}">
                    <div class="zf-input-label">Total time on aircraft</div>

                    <div class="zf-input-control">
                      <input type="text" class="form-control" formControlName="total_time_on_aircraft"
                        (change)="onTotalTimeOnAircraftChange($event)">
                    </div>
                  </div>
                  <div *ngIf="submitted_05 && f5.total_time_on_aircraft.errors" class="invalid-feedback">
                    <div *ngIf="f5.total_time_on_aircraft.errors.required">Total Time on Aircraft is required</div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-sm-8">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_05 && f5.engine_time1.errors}">
                        <div class="zf-input-label">Engine <span *ngIf="activeCategorieData.engine_count>1">1</span>
                          Time</div>
                        <div class="zf-input-control">

                          <input type="text" class="form-control" formControlName="engine_time1"
                            (change)="onEngineTime1Change($event)" min="0" />
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.engine_time1.errors" class="invalid-feedback">
                        <div *ngIf="f5.engine_time1.errors.required">Engine <span
                            *ngIf="activeCategorieData.engine_count>1">1</span> Time is required</div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="zf-input-group"
                        [ngClass]="{ 'is-invalid': submitted_05 && f5.engine_time1_unit.errors}">
                        <div class="zf-input-label">Unit</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="engine_time1_unit" [data]="unitsList" [options]="dd_options">
                          </ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.engine_time1_unit.errors" class="invalid-feedback">
                        <div *ngIf="f5.engine_time1_unit.errors.required">Unit is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2" *ngIf="activeCategorieData.engine_count>1">
                    <div class="col-sm-8">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_05 && f5.engine_time2.errors}">
                        <div class="zf-input-label">Engine 2 Time</div>
                        <div class="zf-input-control">

                          <input type="text " class="form-control" (change)="onEngineTime2Change($event)"
                            formControlName="engine_time2" min="0" />
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.engine_time2.errors" class="invalid-feedback">
                        <div *ngIf="f5.engine_time2.errors.required">Engine 2 Time is required</div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="zf-input-group"
                        [ngClass]="{ 'is-invalid': submitted_05 && f5.engine_time2_unit.errors}">
                        <div class="zf-input-label">Unit</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="engine_time2_unit" [data]="unitsList" [options]="dd_options">
                          </ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.engine_time2_unit.errors" class="invalid-feedback">
                        <div *ngIf="f5.engine_time2_unit.errors.required">Unit is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <div class="col-sm-8">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_05 && f5.prop_time1.errors}">
                        <div class="zf-input-label">Prop <span *ngIf="activeCategorieData.prop_count>1">1</span> Time
                        </div>
                        <div class="zf-input-control">

                          <input type="text" (change)="onPropTime1Change($event)" class="form-control"
                            formControlName="prop_time1" min="0" />
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.prop_time1.errors" class="invalid-feedback">
                        <div *ngIf="f5.prop_time1.errors.required">Prop <span
                            *ngIf="activeCategorieData.prop_count>1">1</span> Time is required</div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="zf-input-group"
                        [ngClass]="{ 'is-invalid': submitted_05 && f5.prop_time1_unit.errors}">
                        <div class="zf-input-label">Unit</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="prop_time1_unit" [data]="unitsList" [options]="dd_options">
                          </ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.prop_time1_unit.errors" class="invalid-feedback">
                        <div *ngIf="f5.prop_time1_unit.errors.required">Unit is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2" *ngIf="activeCategorieData.prop_count>1">
                    <div class="col-sm-8">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_05 && f5.prop_time2.errors}">
                        <div class="zf-input-label">Prop 2 Time</div>
                        <div class="zf-input-control">

                          <input type="text" class="form-control" formControlName="prop_time2"
                            (change)="onPropTime2Change($event)" min="0" />
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.prop_time2.errors" class="invalid-feedback">
                        <div *ngIf="f5.prop_time2.errors.required">Prop 2 Time is required</div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="zf-input-group"
                        [ngClass]="{ 'is-invalid': submitted_05 && f5.prop_time2_unit.errors}">
                        <div class="zf-input-label">Unit</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="prop_time2_unit" [data]="unitsList" [options]="dd_options">
                          </ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.prop_time2_unit.errors" class="invalid-feedback">
                        <div *ngIf="f5.prop_time2_unit.errors.required">Unit is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-sm-8">
                      <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_05 && f5.useful_load.errors}">
                        <div class="zf-input-label">Useful load</div>
                        <div class="zf-input-control">

                          <input type="text" class="form-control" formControlName="useful_load"
                            (change)="onUsefulLoadChange($event)" min="0" />
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.useful_load.errors" class="invalid-feedback">
                        <div *ngIf="f5.useful_load.errors.required">Useful Load is required</div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="zf-input-group"
                        [ngClass]="{ 'is-invalid': submitted_05 && f5.useful_load_unit.errors}">
                        <div class="zf-input-label">Unit</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="useful_load_unit" [data]="usefulLoadUnitsList"
                            [options]="dd_options"></ng-select2>
                        </div>
                      </div>
                      <div *ngIf="submitted_05 && f5.useful_load_unit.errors" class="invalid-feedback">
                        <div *ngIf="f5.useful_load_unit.errors.required">Unit is required</div>
                      </div>
                    </div>
                  </div>
                </form>
              </ng-container>
              <ng-container *ngIf="currentStep==7">
                <!-- 6 -->
                <h3 class="h3-add-aircarft">Let's rate the interior and exterior of the aircraft</h3>
                <form [formGroup]="sellAircraftForm_06">
                  <div class="mt-3">
                    <label class="mb-0">Rate the Interior</label>
                    <div class="row">
                      <div class="col-auto">
                        <span class="text-danger mt-2 d-block">Poor</span>
                      </div>
                      <div class="col px-0">
                        <div class="hide-bubble hide-value">
                          <ngx-slider [options]="optionsRate" formControlName="interior_ratting"></ngx-slider>
                          <ul class="slider-rating">
                            <li></li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>10</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-auto">
                        <span class="text-success mt-2 d-block">Excellent</span>
                      </div>
                    </div>
                  </div>


                  <div class="mt-3">
                    <label class="mb-0">Rate the Exterior</label>
                    <div class="row">
                      <div class="col-auto">
                        <span class="text-danger mt-2 d-block">Poor</span>
                      </div>
                      <div class="col px-0">
                        <div class="hide-bubble hide-value">
                          <ngx-slider [options]="optionsRate" formControlName="exterior_ratting"></ngx-slider>
                          <ul class="slider-rating">
                            <li></li>
                            <li>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li>4</li>
                            <li>5</li>
                            <li>6</li>
                            <li>7</li>
                            <li>8</li>
                            <li>9</li>
                            <li>10</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-auto">
                        <span class="text-success mt-2 d-block">Excellent</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 my-3">
                      <div class="zf-input-group">
                        <div class="zf-input-label">Year painted</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="year_painted" [data]="yearsList" [options]="dd_options">
                          </ng-select2>
                        </div>
                      </div>
                      <div class="label-not-req">Not required</div>
                    </div>
                    <div class="col-lg-6 my-3">
                      <div class="zf-input-group">
                        <div class="zf-input-label">Year interior</div>
                        <div class="zf-input-control">
                          <ng-select2 formControlName="year_interior" [data]="yearsList" [options]="dd_options">
                          </ng-select2>
                        </div>
                      </div>
                      <div class="label-not-req">Not required</div>
                    </div>
                    <div class="col-lg-6">
                      <div class="zf-input-group">

                        <div class="zf-input-control">
                          <textarea class="form-control" rows="5" placeholder="Interior"
                            formControlName="interior_exterior_description" maxlength="400"></textarea>
                        </div>
                      </div>
                      <div class="label-not-req">Not required</div>
                    </div>
                    <div class="col-lg-6">
                      <div class="zf-input-group">
                        <div class="zf-input-control">
                          <textarea class="form-control" rows="5" placeholder="Exterior"
                            formControlName="exterior_description" maxlength="400"></textarea>
                        </div>
                      </div>
                      <div class="label-not-req">Not required</div>
                    </div>
                  </div>
                </form>
              </ng-container>
              <ng-container *ngIf="currentStep==8">
                <h3 class="h3-add-aircarft">Now let's upload some videos & pictures of the aircraft</h3>

                <div class="mob-upload-view">
                  <div class="aircarf-img-upload-box my-3" (click)="imageInput.click()" [class.loading]="uploading">
                    <div class="row align-items-center">
                      <div class="col-sm-6">
                        <div class="text-center">
                          <img class="my-0 my-md-3 my-lg-3" src="assets/img/img-upload.png">
                          <p>.jpg .png .mp4</p>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="text-center">
                          <div class="mb-2 upload-txt">Draft & drop to upload or click the <br />
                            upload button</div>
                          <button class="btn btn-ziofly-default green px-5"><span>Upload</span></button>
                          <!-- , .mp4 -->
                          <input name="filess" type="file" multiple #imageInput accept=".png, .jpg, .mp4"
                            (change)="uploadProfileImage($event)">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="aircarf-img-listing-box">
                    <ng-container>
                      <p class="text-center py-0 py-md-4 py-lg-5 mb-0"
                        *ngIf="uploadedImages.length<1 && productData?.mediafiles.length<1">
                        No image or video is uploaded
                      </p>
                    </ng-container>

                    <ul class="aircarf-img-listing">
                      <li *ngFor="let item of uploadedImages">
                        <div class="aircarf-img-iconss" *ngIf="!isVideo(item)">
                          <a class="remove-aircarf-img" (click)="removeAircarfImg(item)">
                            <img src="assets/img/cross-icon.png">
                          </a>
                          <img class="aircarf-icon" src="{{variables.serverAssetThumbUrl}}{{item}}">
                        </div>
                        <div class="aircarf-img-iconss video" *ngIf="isVideo(item)">
                          <a class="remove-aircarf-img" (click)="removeAircarfImg(item)">
                            <img src="assets/img/cross-icon.png">
                          </a>
                          <i class="icon-play"></i>
                          <video class="aircarf-icon">
                            <source src="{{variables.serverAssetUrl}}{{item}}" type="video/mp4">
                          </video>
                        </div>
                      </li>
                      <ng-container *ngIf="productData?.mediafiles.length>0">
                        <li *ngFor="let item of productData.mediafiles">
                          <div class="aircarf-img-iconss" *ngIf="!isVideo(item)">
                            <a class="remove-aircarf-img" (click)="removeAircarfImg(item)"><img
                                src="assets/img/cross-icon.png"></a>
                            <img class="aircarf-icon" src="{{variables.serverAssetThumbUrl}}{{item}}">
                          </div>
                          <div class="aircarf-img-iconss video" *ngIf="isVideo(item)">
                            <a class="remove-aircarf-img" (click)="removeAircarfImg(item)"><img
                                src="assets/img/cross-icon.png"></a>
                            <i class="icon-play"></i>
                            <video class="aircarf-icon">
                              <source src="{{variables.serverAssetUrl}}{{item}}" type="video/mp4">
                            </video>
                          </div>
                        </li>
                      </ng-container>

                    </ul>
                  </div>
                </div>
              </ng-container>
              <div style="display: none;" [class.d-block]="currentStep==9">
                <h3 *ngIf="!isEditPage" class="h3-add-aircarft">What's the location of the aircraft?</h3>
                <form [formGroup]="sellAircraftForm_08">
                  <div class="my-3">
                    <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_08 && f8.country_id.errors}">
                      <div class="zf-input-label">Select Country</div>
                      <div class="zf-input-control">
                        <ng-select2 formControlName="country_id" [data]="countries" [options]="d_options"
                          (click)="onFocus()" (valueChanged)="onCountryChange($event)"></ng-select2>
                      </div>
                    </div>
                    <div *ngIf="submitted_08 && f8.country_id.errors" class="invalid-feedback">
                      <div *ngIf="f8.country_id.errors.required">Country is required</div>
                    </div>
                  </div>
                  <div class="my-3">
                    <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted_08 && f8.zipcode.errors}">
                      <div class="zf-input-label">Zip / Postal Code</div>
                      <div class="zf-input-control cstm-zf-input-control">
                        <input type="number" pattern="[0-9]*" class="form-control" formControlName="zipcode"
                          onKeyPress="if(this.value.length==9) return false;"
                          maxlength="{{validations.def.address.zipcode.max}}">
                      </div>
                    </div>
                    <div *ngIf="submitted_08 && f8.zipcode.errors" class="invalid-feedback">
                      <div *ngIf="f8.zipcode.errors.required">Zip Code is required</div>
                    </div>
                  </div>
                  <p>A zip code is highly recommended as it's used by potential buyers to find aircraft near their
                    location.</p>
                  <div class="text-center my-3">
                    <div id="hotel_map"></div>
                  </div>
                </form>
              </div>
              <ng-container *ngIf="currentStep==6">
                <!-- 9 -->
                <h3 *ngIf="!isEditPage" class="h3-add-aircarft">Provide more details on Airframe, Engine, Prop and
                  Avionics</h3>
                <form [formGroup]="sellAircraftForm_09">
                  <div class="zf-input-group my-3" [ngClass]="{ 'is-invalid': submitted_09 && f9.air_frame.errors}">
                    <div class="zf-input-control">
                      <textarea class="form-control" rows="5" formControlName="air_frame" placeholder="Airframe"
                        maxlength="400"></textarea>
                    </div>
                  </div>
                  <div *ngIf="submitted_09 && f9.air_frame.errors" class="invalid-feedback">
                    <div *ngIf="f9.air_frame.errors.required">Airframe is required</div>
                  </div>
                  <div class="zf-input-group my-3"
                    [ngClass]="{ 'is-invalid': submitted_09 && f9.engine_mods_pops.errors}">
                    <div class="zf-input-control">
                      <textarea class="form-control" rows="5" formControlName="engine_mods_pops"
                        placeholder="Engines / Mods / Prop" maxlength="400"></textarea>
                    </div>
                  </div>
                  <div *ngIf="submitted_09 && f9.engine_mods_pops.errors" class="invalid-feedback">
                    <div *ngIf="f9.engine_mods_pops.errors.required">Engines/Mods/Prop is required</div>
                  </div>
                  <div class="zf-input-group my-3"
                    [ngClass]="{ 'is-invalid': submitted_09 && f9.avionics_equipment.errors}">
                    <div class="zf-input-control">
                      <textarea class="form-control" rows="5" formControlName="avionics_equipment"
                        placeholder="Avionics / Equipment" maxlength="400"></textarea>
                    </div>
                  </div>
                  <div *ngIf="submitted_09 && f9.avionics_equipment.errors" class="invalid-feedback">
                    <div *ngIf="f9.avionics_equipment.errors.required">Avionics/Equipment is required</div>
                  </div>
                </form>
              </ng-container>
              <ng-container *ngIf="currentStep==10">
                <h3 *ngIf="!isEditPage" class="h3-add-aircarft">Please make the appropriate selection for special use
                </h3>
                <form [formGroup]="sellAircraftForm_10">
                  <ul class="check-item-list specialusecategoryCheck">
                    <li *ngFor="let item of specialUseCategoryList">
                      <div class="row align-items-center">
                        <div class="col">
                          {{item.name | titlecase }}
                        </div>
                        <div class="col-auto">
                          <label class="zf-toggle-switch w-100 mb-0 data-id-{{item.id}}">
                            <!-- specialusecategories_copy -->
                            <input type="checkbox" class="data-id-{{item.id}}" formArrayName="specialusecategories_copy"
                              [value]="item.id" (change)="onCheckSpecialusecategories(item.id)"
                              (change)="onCheckSpecialuse(item)">
                            <div class="zf-toggle-switch-box">
                              <div class="zf-toggle-switch-thumb"></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </form>
              </ng-container>
              <ng-container *ngIf="currentStep==11">
                <h3 *ngIf="!isEditPage" class="h3-add-aircarft">Now some final touches for privacy preferences</h3>
                <form [formGroup]="sellAircraftForm_11">
                  <ul class="check-item-list">
                    <li *ngFor="let item of privacyPrefere">
                      <div class="row align-items-center">
                        <div class="col">
                          {{item.name}}
                        </div>
                        <div class="col-auto">
                          <label class="zf-toggle-switch w-100 mb-0">
                            <input type="checkbox" formControlName="{{item.id}}" value="1">
                            <div class="zf-toggle-switch-box">
                              <div class="zf-toggle-switch-thumb"></div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </form>
                <!-- </ng-container> -->
              </ng-container>
              <div *ngIf="currentStep==12">
                <ziofly-payment-new [savedCardDetails]="savedCardDetails" [product_id]="product_id"
                  [product_status]="productData?.status" [subscription_message]="subscription_message"
                  (cardAddedSuccessEvent)="onSuccessFullCardAdd($event)"
                  (getSaveCardDataEvent)="saveCardDataEvent($event)"></ziofly-payment-new>
              </div>
            </div>
            <div class="row mt-3 step-buttons-back-next">
              <div class="col">
                <button *ngIf="currentStep>0 && currentStep != 12" (click)="stepMinus()"
                  class="btn btn-ziofly-default default">
                  <span>Back</span>
                </button>
              </div>
              <div class="col-auto">
                <ng-container *ngIf="currentStep==0">
                  <button *ngIf="currentStep<12" [disabled]="!selectedListngType" (click)="stepPlus()"
                    class="btn btn-ziofly-default active">
                    <span *ngIf="!isNextLoading">
                      <ng-container *ngIf="!isEditPage">Next</ng-container>
                      <ng-container *ngIf="isEditPage">Save & Continue</ng-container>
                    </span>
                    <span *ngIf="isNextLoading">
                      <span class="zf-btn-loader"></span>
                    </span>
                  </button>
                </ng-container>
                <ng-container *ngIf="currentStep!=0">
                  <ng-container *ngIf="!paymentStep">
                    <ng-container *ngIf="isEditPage">
                      <button *ngIf="currentStep<12 && currentStep>1" (click)="stepPlus(true)"
                        [disabled]="isNextLoading" class="btn btn-ziofly-default active mr-1">
                        <span>
                          <ng-container>Save and Exit</ng-container>
                        </span>
                        <span *ngIf="isNextLoading && isSaveExit">
                          <span class="zf-btn-loader"></span>
                        </span>
                      </button>
                    </ng-container>
                    <button *ngIf="currentStep<12" (click)="stepPlus()" [disabled]="isNextLoading"
                      class="btn btn-ziofly-default active">
                      <span>
                        <ng-container *ngIf="!isEditPage">Next</ng-container>
                        <ng-container *ngIf="isEditPage">Save and Continue</ng-container>
                      </span>
                      <span *ngIf="isNextLoading && !isSaveExit">
                        <span class="zf-btn-loader"></span>
                      </span>
                    </button>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->

<div class="modal-form-modal" [class.open]="isModalFormView">
  <div class="modal-form-box">
    <h4 class="text-black mb-4">Request for add make & model</h4>
    <form class="" [formGroup]="emailtowebmasterForm">
      <div class="row">
        <div class="col-sm-6">
          <div class="zf-input-group"
            [ngClass]="{ 'is-invalid': submitted_emailtowebmaster && femail.makename.errors }">
            <div class="zf-input-label">Make</div>
            <div class="zf-input-control">
              <input type="text" class="form-control" formControlName="makename">
            </div>
          </div>
          <div *ngIf="submitted_emailtowebmaster && femail.makename.errors" class="invalid-feedback">
            <div *ngIf="femail.makename.errors.required">Make name is required</div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="zf-input-group"
            [ngClass]="{ 'is-invalid': submitted_emailtowebmaster && femail.modelname.errors }">
            <div class="zf-input-label">Model</div>
            <div class="zf-input-control">
              <input type="text" class="form-control" formControlName="modelname">
            </div>
          </div>
          <div *ngIf="submitted_emailtowebmaster && femail.modelname.errors" class="invalid-feedback">
            <div *ngIf="femail.modelname.errors.required">Model name is required</div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="zf-input-group"
            [ngClass]="{ 'is-invalid': submitted_emailtowebmaster && femail.description.errors }">
            <!-- <div class="zf-input-label">Description</div> -->
            <div class="zf-input-control">
              <textarea placeholder="Description" class="form-control" formControlName="description" rows="4"
                maxlength="999"></textarea>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="text-center">
            <button class="btn btn-ziofly-default default mr-2" (click)="closeModalForm()"><span>Cancle</span></button>
            <button class="btn btn-ziofly-default active" (click)="emailtowebmasterSubmit()"
              [disabled]="isEmailLoading">
              <span>Send</span>
              <span *ngIf="isEmailLoading">
                <span class="zf-btn-loader"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
