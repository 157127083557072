var email = {
   max: 255,
   regex: '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$'
};
var website = {
   max: 255,
   regex: '^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$'
};
var message = {
   min: 8,
   max: 255
};
 var password = {
    min: 6,
    max: 25,
    /*hasNumber_regex : /\d/,
    hasCapitalCase_regex : /[A-Z]/,
    hasSmallCase_regex : /[a-z]/,
    hasSpecialCharacters_regex : /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,*/
 }
 var name = {
    regex : '^[a-zA-Z ]{2,25}$',
    first_name : {
       min: 2,
       max: 25,
    },
    last_name : {
       min: 2,
       max: 25
    },
    full : {
       min: 2,
       max: 25
    },
 }
 var seller_name = {
    //regex : '^[a-zA-Z ]{2,25}$',
    regex : '^[a-zA-Z0-9 _]*$',
    min: 2,
    max: 25
 }
 var address = {
    line_1 : {
      min: 2,
      max: 40
    },
    line_2 : {
      min: 2,
      max: 40
    },
    zipcode : {
      regex: '^[0-9]{5,9}$',
      min: 5,
      max: 9
    }
 }
var phone = {
   min: 14,
   max: 16,
   regex: '^[0-9]{10,12}$'
}
var fax = {
   min: 10,
   max: 12,
   regex: '^[0-9]{10,12}$'
}
 var image = {
    type : ['jpg','jpeg','png'],
    min : 1024,
    //max : 2097152, 
    max : 90000000,   
 }
 var date = {
    date : 'dd-MM-yyyy, HH:mm'
 }
 export const validations = {
   def : {
       email : email,  
       password : password,  
       name : name, 
       seller_name : seller_name, 
       phone : phone,  
       fax : fax,  
       address : address,  
       image : image,
       date : date,
       website: website,
       message: message,
   },
   messages : {        
        email : {
          required : 'Email address is required',
          invalid : 'Enter a valid email address'
        },
        password : {
           required : 'Password is required',
           invalid : 'Password must contain at least '+password.min+' characters and max '+password.max+' character value'           

           //invalid : 'Password must contain at least '+password.min+' characters and max '+password.max+' character value, including special character'           
        },   
        current_password : {
           required : 'Current Password is required',
           invalid : 'Current Password must contain at least '+password.min+' characters'           
        },   
        new_password : {
           required : 'New Password is required',
           issame: 'Current Password and New Password can\'t be same Please choose a different password',
         //   invalid : 'New Password must contain at least '+password.min+' characters and max '+password.max+' character value, including UPPER / lowercase, numbers, special character'           
           //invalid : 'New Password must contain at least '+password.min+' characters and max '+password.max+' character value, including special character'
           invalid : 'New Password must contain at least '+password.min+' characters and max '+password.max+' character value'                     
        },   
        password_confirmation : {
           required : 'Confirm password is required',
           same : 'Password and Confirm password should be same'
        },
        first_name : {
           required : 'First name is required',
           invalid : 'The first name should contain only alphabetical characters and must contain at least '+name.first_name.min+' characters and max '+name.first_name.max+' character value',
        },
        last_name : {
           required : 'Last name is required',
           invalid : 'The last name should contain only alphabetical characters and must contain at least '+name.last_name.min+' characters and max '+name.last_name.max+' character value',
         },
         full_name : {
            required : 'Name is required',
            invalid : 'Name should be contain only alphabetical characters and must contain at least '+name.full.min+' characters and max '+name.full.max+' character value',
          },
         seller_name : {
            required : 'Seller name is required',
            invalid : 'Seller name must contain at least '+seller_name.min+' characters and max '+seller_name.max+' character value',
         },
        sign_up : {
         //success : 'Thank you for singing up. A verification link has been sent to your email address.',
         success : 'Thank you for singing up.',
         verified: 'Account verified successfully.'
        },
        log_in : {
           success : 'Login successful.',
           not_verified : 'Your Account is not verified yet please go to your inbox and click on verification link.'       
        },
        terms : {
            required: 'Please accept terms of use & service'
        },
        profile_update : {
           success : 'Profile updated!'
        },
        forgot : {
           success : 'Password reset link has been sent to your registered email.',
           email_not_exist : 'Not found any account with this email'
        },
        reset_password : {
          same : 'New password could not be same as Old password',
          success : 'Your password has been changed successfully.'
        },
        change_password : {
          current_password_required : 'Old password is required',
          new_pass_required : 'New password is required',
          not_same_as_old : 'Old password & new password should not be same',
          success : 'Your password has been changed successfully.',
        },
        phone : {
          required : 'Phone number is required',
          already_added : 'This Phone number is already added on your account.',
          already_exist : 'This Phone number is already exist.',
          invalid : 'Phone number should contain min '+(phone.min == 14 ? 10 : phone.min) +' digits and max '+(phone.max == 16 ? 12 : phone.max)+' digits',       
        },
        fax : {
          required : 'Fax number is required',
          already_added : 'This Fax number is already added on your account.',
          already_exist : 'This Fax number is already exist.',
          invalid : 'Fax number should be contain only numbers and must at least '+fax.min+' digits  and max '+fax.max+' digits value',    
        },
        address : {
         line_1 : {
           required : 'Address line 1 is required',
           invalid : 'Address line 1 must contain at least '+address.line_1.min+' characters and max '+address.line_1.max+' character value',
         },
         line_2 : {
           required : 'Address line 2 is required',
           invalid : 'Address line 2 must contain at least '+address.line_2.min+' characters and max '+address.line_2.max+' character value',
         },
         city : {
            required : 'City is required',
         },
         state : {
            required : 'State is required',
         },
         country : {
            required : 'Country is required',
         },
         zipcode : {            
           required : 'Zipcode is required',
           invalid : 'Zipcode should only contain numbers and must and at least '+address.zipcode.min+' digits and max '+address.zipcode.max+' digits value',
         }
        },
        image_upload : {
           wrong_type : 'Only '+image.type.join('/')+' files are allowed!',
           wrong_size_min : 'Please select image greater then '+niceBytes(image.min), 
           wrong_size_max : 'Please select image less then '+niceBytes(image.max), 
        },  
        aircraft : {
            required : 'Please select aircraft'
        },
        unknown_server_error  : 'Some error occured please try again',        
        logout : {
           warning: 'Are you sure you want to logout?',
           success: 'Logged out successfully.'
        },              
        website : {
         required : 'Website is required',
         invalid : 'Enter a valid website'
       },            
       message : {
        required : 'Message is required',
        invalid : 'Message must contain at least '+message.min+' characters and max '+message.max+' character value'
      },
      contact_us : {
         success: 'Thank you for contacting us. A representative will be in touch shortly.'
      }
   }
 }
 
 
 function niceBytes(x){
   const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
   let l = 0, n = parseInt(x, 10) || 0;
   while(n >= 1024 && ++l){
       n = n/1024;
   }
   return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
 }
 