import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { variables } from 'src/environments/variables';

@Component({
  selector: 'ziofly-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {

  public blogcontent : any;
  user1: any;
  blogdetail: any;
  blog_slug: any;
  variables = variables;

  constructor(private ActivatedRoute: ActivatedRoute, private Router: Router,
    private BlogService: BlogService) { }

    ngOnInit(): void {
      this.blog_slug = this.ActivatedRoute.snapshot.paramMap.get("slug");
      this.getBlogDetail(this.blog_slug);
     }

     getBlogDetail(slug){
       let data = {
         slug: slug
       }
       this.BlogService.newsDetail(data).subscribe(res2=>{
         let data = res2;
         this.blogdetail = res2['data'][0];
        //  console.log(this.blogdetail)
       })
     }


}
