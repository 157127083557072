import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EncrCvvService } from 'src/app/services/cvv-encrypt';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';

import { ImageDialogComponent } from '../user-pages/image-dialog/image-dialog.component';

declare function initInputs();
declare var $;
declare var stripe: any;
declare var elements: any;

@Component({
  selector: 'ziofly-payment-new',
  templateUrl: './payment-new.component.html',
  styleUrls: ['./payment-new.component.scss'],
})
export class PaymentNewComponent implements OnInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;
  cardsDetail: any = [];
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  @Output() cardAddedSuccessEvent = new EventEmitter<any>();
  // @Input() product_id;
  product_id: any;
  @Input() savedCardDetails: any;
  @Input() product_status;
  @Input() subscription_message;
  @Output() getSaveCardDataEvent = new EventEmitter<any>();
  canPublishListing: boolean = true;
  isNextLoading: boolean = false;
  Vlist: any = [];
  // subscription_message: any;
  subscription_type: any;
  submitting = false;
  submittedValue = false;
  submitted = false;
  submitted2 = false;
  saveCardForm: FormGroup;
  saveCardForm2: FormGroup;

  dd_options = {
    minimumResultsForSearch: -1,
  };
  monthList: any;
  yearList: any;
  show_card: any;
  apply_promoCodeStatus: boolean;

  navigationSubState: { [menu: string]: string } = {
    payment: 'active',
  };
  navigationCode: { [menu: string]: string } = {
    promocode: 'active',
  };
  cvcCheck: FormGroup;
  constructor(
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private formbuilder: FormBuilder,
    public dialog: MatDialog,
    private encrdecr: EncrCvvService
  ) {
    var currentYear = new Date().getFullYear();
    var maxYear = currentYear + 51;
    this.yearList = [];
    while (currentYear < maxYear) {
      this.yearList.push({
        id: currentYear,
        text: currentYear,
      });
      currentYear++;
    }
    this.monthList = [];
    var num = 1;
    while (num < 13) {
      this.monthList.push({
        id: num,
        text: num,
      });
      num++;
    }

    this.saveCardForm = this.formbuilder.group({
      cardnumber: [
        '',
        [
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(23),
        ],
      ],
      month: ['', [Validators.required]],
      year: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
    });

    this.cvcCheck = this.formbuilder.group({
      cvc: ['', [Validators.required]],
      // tokenId: ['', [Validators.required]],
    });
    this.saveCardForm2 = this.formbuilder.group({
      promocode: ['', [Validators.required]],
    });
  }

  onSubmitClick() {
    this.submitted = true;
    if (this.saveCardForm.invalid) {
      return;
    }
    // this.submitting = true;
    // console.log(this.encrdecr.set('123456$#@$^@1ERF',this.savedCardDetails.value.cvv));
    // console.log(encryptedCvv)
    // '123456$#@$^@1ERF',
    // var decrypted = this.EncrDecr.get('123456$#@$^@1ERF', encrypted);
    (<any>window).Stripe.card.createToken(
      {
        number: this.saveCardForm.value.cardnumber,
        exp_month: this.saveCardForm.value.month,
        exp_year: this.saveCardForm.value.year,
        cvc: this.saveCardForm.value.cvv,
      },
      (status: number, response: any) => {
        // console.log('response token',response.error);
        if(response.error){
          // console.log(response)
          this.toastr.error({msg:response.error.message})
        }

        this.submitted = false;
        if (status === 200) {
          var apidata = {
            stripetoken: response.id,
            cvc: this.saveCardForm.value.cvv,
          };
          // console.log('api data token and cvv', apidata);
          this.userService.postPaymentCard(apidata).subscribe((data) => {
            // console.log('post card', data);
            if (data['status']) {
              this.toastr.success({ msg: data['message'] });
              // this.getSavedCardDetails();
              this.getSavedCards();
              this.isModalFormViewAddCard = false;
            } else {
              this.submitting = false;
              // this.toastr.error({ msg: data['message'] });
              this.toastr.error({msg:'No Card Found'});
            }

            (error) => {
              this.toastr.error({
                msg: 'Something went wrong, please try again',
              });
              this.submitting = false;
            };
          });
        }
      }
    );
  }

  applyPromoCode() {
    this.submitted2 = true;
    if (this.saveCardForm2.invalid) {
      return;
    }
    this.submitting = true;
    const uploadDatas = new FormData();
    uploadDatas.append('product_id', this.product_id);
    uploadDatas.append('promocode', this.saveCardForm2.value.promocode);
    this.userService.applyPromoCode(uploadDatas).subscribe(
      (data) => {
        this.f2['promocode'].reset();
        if (data['status']) {
          this.submitted2 = false;
          this.submitting = false;
          this.apply_promoCodeStatus = data['status'];
          this.toastr.success({ msg: data['message'] });
        } else {
          this.submitted2 = false;
          this.submitting = false;
          this.toastr.error({ msg: data['message'] });
        }
      },
      (error) => {
        this.submitted2 = false;
        this.submitting = false;
        this.toastr.error({ msg: 'Something went wrong, please try again' });
        this.isNextLoading = false;
      }
    );
  }
  // cardsDetails:any=[];

  getSavedCards() {
    this.userService.getPaymentCards().subscribe((data) => {
      // console.log('get cards', data);
      this.cardsDetail = data['data'];
      // cardsDetail
      // cardIdToCharge
      this.cardsDetail.forEach(card => {
        this.cardIdToCharge=card.id;

      });
    });
  }

  ngOnInit() {
    // console.log("this is subscription msg",this.subscription_message);

    this.userService.getProductID().subscribe((res) => {
      // console.log("this is product id",this.product_id)
      this.product_id = res;
    });
    this.getSavedCards();
    this.getUserDetails();
    this.loadStripe();
    if (this.savedCardDetails && this.savedCardDetails.cardno) {
      this.navigationSubState['payment'] = 'inactive';
    }
    if (this.savedCardDetails && this.savedCardDetails.cardno) {
      this.navigationCode['promocode'] = 'inactive';
    }
    $(document).ready(function () {
      // console.log('ready!');
      $('#add_card_2').addClass('show');

      $('#card-btn-2').click(function () {
        $('#add_promocode_2').removeClass('show');
      });
      $('#promo-btn-2').click(function () {
        $('#add_card_2').removeClass('show');
      });
    });
    // this.cardIdTocharge()
  }

  cardNumType() {
    if (this.saveCardForm.value.cardnumber) {
      var _num = this.saveCardForm.value.cardnumber;
      _num = _num.replace(/ /g, '');
      if (_num.length > 0) {
        var a = _num.toString().replace(/\d{4}(?=.)/g, '$& ');
        this.saveCardForm.controls.cardnumber.setValue(a);
      }
    }
  }

  get f() {
    return this.saveCardForm.controls;
  }

  get f2() {
    return this.saveCardForm2.controls;
  }

  ngAfterViewInit() {
    this.canPublish(); // final server validation check

    const style = {
      // styling the card
      hidePostalCode: true,
      base: {
        lineHeight: '24px',
        fontFamily: 'monospace',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        '::placeholder': {
          color: 'purple',
        },
      },
    };
  }

  ngOnDestroy() {
    var myobj = document.getElementById('stripe-custom-form-script');
    myobj.remove();
  }

  numberOnly(event): boolean {
    this.cardNumType();
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  loadStripe() {
    this.getSubscriptionMessage();
    if (!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement('script');
      s.id = 'stripe-custom-form-script';
      s.type = 'text/javascript';
      s.src = 'https://js.stripe.com/v2/';
      s.onload = () => {
        // window['Stripe'].setPublishableKey(
        //   'pk_test_51GuEqfI5XxL1byFo66kCuqXGnq6GTg9tPwEy5S29BAdmRrIG1Z0PSnTaM1DbGKgtPbQhzJj6m2EW8infZPeTvuFJ00lIz4CrnH'
        // );
        window['Stripe'].setPublishableKey('pk_live_51IbRPbDJFz7KSnz9cJwNUJiaCKjj8QTt9TOZdJ2xNAqLqWZKEGtnPGFQM2B2ktquTnKmtDVpjw61gh6uyIInnTUp00YxxMfr4i');
      };
      window.document.body.appendChild(s);
    }
  }

  getSubscriptionMessage() {
    const uploadData3 = new FormData();
    uploadData3.append('product_id', this.product_id);
    this.userService.getSubscription(uploadData3).subscribe((data) => {
      if (data['status']) {
        if (data['data']) {
          this.subscription_message = data['data'][0].subscriptionmessage;
          this.subscription_type = data['data'][0].subscriptiontype;
        }
      }
    });
  }

  onChange({ error }) {
    // to catch the changes when entering card details
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }
  card_id: any;
  async onSubmit(form: NgForm, type) {
    // on submit of card
    const { token, error } = await stripe.createToken(this.card);
    if (error) {
      // incase when stripe has some error while checkig cards details
      // console.log('Something is wrong:', error)
      this.toastr.error({ msg: error.message });
    } else {
      // after success of stripe card details verification
      const payload = {
        status: type,
        product_id: this.product_id,
        token: token.id,
        card_id: this.card_id,
      };
      this.addCard(payload);
    }
  }


  addCard(payload) {
    this.isNextLoading = true;
    this.userService.addCards(payload).subscribe(
      (data) => {
        if (data['status']) {
          //when cards is stored successfully
          this.toastr.success({ msg: data['message'] });
          this.isNextLoading = false;
          this.cardAddedSuccessEvent.emit(true); // alerting the sell-aircraft.component about the payment success
        } else {
          this.isNextLoading = false;
          if (data['email_verification'] == false) {
            const dialogRef = this.dialog.open(ImageDialogComponent, {
              panelClass: 'cstm_dialog_panel',
              data: 'false' + '-' + this.product_id,
            });
          } else {
            this.toastr.error({ msg: data['message'] });
          }
        }
      },
      (error) => {
        this.toastr.error({ msg: 'Something went wrong, please try again' });
        this.isNextLoading = false;
      }
    );
  }

  canPublish() {
    const payload = { product_id: this.product_id };
    this.userService.canPublish(payload).subscribe((data) => {
      // console.log('data status', data['status']);
      // console.log('data data', data['data']);

      if (!data['status']) {
        this.Vlist = data['data'];
        this.canPublishListing = false;
        // note => need to show pop message over here about the required details
      } else {
        this.Vlist = data['data'];
        this.canPublishListing = true;
      }
    });
    // console.log('vlist', this.Vlist);
  }

  onPublishCard() {
    // note => Just for test purpose kindly look into code , need to manager as per UI

    const payload = { status: 'publish', product_id: this.product_id };
    // console.log('this is payload', payload);
    this.addCard(payload);
  }

  onRemoveCard() {
    this.savedCardDetails = undefined;
  }

  gotoNextDirect() {
    this.router.navigate(['/user/my-aircrafts']);
    this.cardAddedSuccessEvent.emit(true);
  }

  getSavedCardDetails() {
    this.userService.getcarddetails().subscribe((data) => {
      if (data['status']) {
        this.savedCardDetails = data['data'];
        this.getSaveCardDataEvent.emit(true);
        // console.log(this.savedCardDetails);
        this.submitting = false;
        this.saveCardForm.reset();
        $('.add-card-card .zf-input-group.focused').removeClass('focused');
        this.submitted = false;
        this.navigationSubState['payment'] = 'inactive';
      }
    });
  }

  toggleNavigationSub(menuName: string, event) {
    event.preventDefault();
    this.navigationSubState[menuName] =
      this.navigationSubState[menuName] === 'inactive' ? 'active' : 'inactive';
    this.navigationCode[menuName] =
      this.navigationCode[menuName] === 'inactive' ? 'active' : 'inactive';
  }

  selectedList: any;

  isModalFormView = false;
  isModalFormViewAddCard = false;

  userData: any = {};
  getUserDetails() {
    this.userService.getUserData().subscribe((data) => {
      this.userData = data;
    });
  }

  openModalFormAddCard() {
    this.isModalFormViewAddCard = true;
    const body = document.getElementsByTagName('html')[0];
    body.classList.add('overflow-hidden');
  }
  // // cvv-modal

  openModalForm() {
    this.isModalFormView = true;
    const body = document.getElementsByTagName('html')[0];
    body.classList.add('overflow-hidden');
  }
  // //close modal of add card
  closeModalAddCard() {
    this.isModalFormViewAddCard = false;
    const body = document.getElementsByName('html')[0];
    body.classList.remove('overflow-hidden');
  }
  closeModalForm() {
    this.isModalFormView = false;
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('overflow-hidden');
  }
  deletePaymentCard(cardId) {
    // console.log('card id', cardId);
    let apidata = {
      card_id: cardId,
    };
    this.userService.deletePaymentCard(apidata).subscribe((data) => {
      // console.log('deleted card', data);
      if (data['status'] == true) {
        this.toastr.success('Card Removed Successfully');
      }

      this.getSavedCards();
    });
  }
  cardIdToCharge = '';
  changePaymentCard(item) {
    this.cvcCheck.reset();
    this.cardIdToCharge='';
    this.cardIdToCharge = item.id;
  }

  submitCVC() {
    let apiData = {
      cvc: this.cvcCheck.value.cvc,
      card_id: this.cardIdToCharge,
    };
    this.userService.checkCvc(apiData).subscribe((res) => {
      // console.log('submited cvc', res);
      if (res['status'] == true) {
        this.toastr.success({msg:res['message']});
        this.onPublishCard();
      }
      else {
        // res['message']
        this.toastr.error({msg:res['message']})
      }
    });
  }
}
