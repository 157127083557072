import { Injectable } from '@angular/core';
declare var Swal;
@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor() { }

  zioflySwal = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-ziofly-default active min-width-120',
      cancelButton: 'btn btn-ziofly-default default min-width-120'
    },
    buttonsStyling: false
  })
  success(data:any) {
    var title = data.title ? data.title : 'Done';
    var msg = data.msg ? data.msg : '';
    return this.zioflySwal.fire({
      confirmButtonText: '<span>Okay</span>',
      icon: 'success',
      title: title,
      text: msg
    })
  }

  confirm(data:any) {    
    var title = data.title ? data.title : 'Are you sure?';
    var msg = data.msg ? data.msg : '';
    return this.zioflySwal.fire({
      title: title,
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '<span>Yes</span',
      cancelButtonText: '<span>Cancel</span',
    })
  }
}
