<ziofly-banner image="assets/img/profile-banner.png" title="Edit my profile"></ziofly-banner>
<div class="page-wrapper">
	<div class="container">
		<div class="row mb-4">
			<div class="col-12"> <a routerLink="/user/profile" class="btn btn-sm btn-ziofly-default btn-ziofly-default-border"><span><i class="icon-arrow-right rotate-180 mr-2"></i> Back</span></a>
			</div>
		</div>
		<ziofly-div-loader [status]="profileLoading"></ziofly-div-loader>
		<div class="row" *ngIf="!profileLoading">
			<div class="col-12">
				<div class="profile-page">
					<div class="profile-img-col">
						<div class="profile-img-align-center">
							<div class="profile-img text-center">
								<img *ngIf="!uploading" [src]="profile_img" alt="image">
								<img *ngIf="uploading" src="{{variables.blank_img}}" class="is-img-lazy" alt="image">
							</div>
							<label class="file-uploader-btn">
								<input type="file" zioflyFileAccept data-type="image" (change)="uploadProfileImage($event)"> 
								<span class="btn btn-ziofly-default default block" [class.disabled]="uploading">
                                    <span *ngIf="!uploading">Change Photo</span>
								<span *ngIf="uploading">
                                        <span class="zf-btn-loader"></span>
								</span>
								</span>
							</label>
						</div>
					</div>
					<div class="profile-detail-col">
						<form [formGroup]="zForm" (ngSubmit)="updateProfile()">
							<div class="row">
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
										<div class="zf-input-label">Enter your first name</div>
										<div class="zf-input-control">
											<input type="search" class="form-control" formControlName="first_name" maxlength="{{validations.def.name.first_name.max}}">
										</div>
									</div>
									<div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
										<div *ngIf="f.first_name.errors.required">{{validations.messages.first_name.required}}</div>
										<div *ngIf="f.first_name.errors.pattern">{{validations.messages.first_name.invalid}}</div>
										<div *ngIf="!f.first_name.errors.required && !f.first_name.errors.pattern && f.first_name.errors.trimError">{{validations.messages.first_name.invalid}}</div>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
										<div class="zf-input-label">Enter your last name</div>
										<div class="zf-input-control">
											<input type="search" class="form-control" formControlName="last_name" maxlength="{{validations.def.name.last_name.max}}">
										</div>
									</div>
									<div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
										<div *ngIf="f.last_name.errors.required">{{validations.messages.last_name.required}}</div>
										<div *ngIf="f.last_name.errors.pattern">{{validations.messages.last_name.invalid}}</div>
										<div *ngIf="!f.last_name.errors.required && !f.last_name.errors.pattern && f.last_name.errors.trimError">{{validations.messages.last_name.invalid}}</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.seller_name.errors }">
										<div class="zf-input-label">Enter your seller name</div>
										<div class="zf-input-control">
											<input type="search" class="form-control" formControlName="seller_name" maxlength="{{validations.def.seller_name.max}}">
										</div>
									</div>
									<div *ngIf="submitted && f.seller_name.errors" class="invalid-feedback">
										<div *ngIf="f.seller_name.errors.required">{{validations.messages.seller_name.required}}</div>
										<div *ngIf="f.seller_name.errors.pattern">{{validations.messages.seller_name.invalid}}</div>
										<div *ngIf="!f.seller_name.errors.required && !f.seller_name.errors.pattern && f.seller_name.errors.trimError">{{validations.messages.seller_name.invalid}}</div>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
										<div class="zf-input-label">Enter your email address</div>
										<div class="zf-input-control">
											<input type="search" class="form-control" readonly formControlName="email" maxlength="{{validations.def.email.max}}">
										</div>
									</div>
									<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
										<div *ngIf="f.email.errors.required">{{validations.messages.email.required}}</div>
										<div *ngIf="f.email.errors.pattern">{{validations.messages.email.invalid}}</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
										<div class="zf-input-label">Enter your phone number</div>
										<div class="zf-input-control">
											<!--<input type="search" class="form-control d-none" formControlName="phone" pattern="[0-9]+"
                                            maxlength="{{validations.def.phone.max}}">
											<input type="search" (change)="changePhone()" (keypress)="changePhone()" 
											class="form-control" 
                                            formControlName="phone_copy"  pattern="[0-9]+" maxlength="16" 
                                            mask="(000) 000-0000|| (000) 000-00000|| (000) 000-000000">-->
											<input type="tel" (change)="changePhone()" (keypress)="changePhone()" 
											class="form-control"  pattern="[0-9]+"
											formControlName="phone" mask="(000) 000-0000|| (000) 000-00000|| (000) 000-000000">
                                        </div>
									</div>
									<div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
										<div *ngIf="f.phone.errors.required">{{validations.messages.phone.required}}</div>
										<div *ngIf="f.phone.errors.pattern">{{validations.messages.phone.invalid}}</div>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.fax.errors }">
										<div class="zf-input-label">Enter your fax number</div>
										<div class="zf-input-control">
											<input type="search" class="form-control" formControlName="fax" 
                                            maxlength="{{validations.def.fax.max}}">
										</div>
									</div>
									<div *ngIf="submitted && f.fax.errors" class="invalid-feedback">
										<div *ngIf="f.fax.errors.required">{{validations.messages.fax.required}}</div>
										<!--<div *ngIf="f.fax.errors.pattern">{{validations.messages.fax.invalid}}</div>-->
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.address_first.errors }">
										<div class="zf-input-label">Enter your address line 1</div>
										<div class="zf-input-control">
											<input type="search" class="form-control" formControlName="address_first" maxlength="{{validations.def.address.line_1.max}}">
										</div>
									</div>
									<div *ngIf="submitted && f.address_first.errors" class="invalid-feedback">
										<div *ngIf="f.address_first.errors.required">{{validations.messages.address.line_1.required}}</div>
										<div *ngIf="f.address_first.errors.minlength">{{validations.messages.address.line_1.invalid}}</div>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.address_second.errors }">
										<div class="zf-input-label">Enter your address line 2</div>
										<div class="zf-input-control">
											<input type="search" class="form-control" formControlName="address_second" maxlength="{{validations.def.address.line_2.max}}">
										</div>
									</div>
									<div *ngIf="submitted && f.address_second.errors" class="invalid-feedback">
										<!-- <div *ngIf="f.address_second.errors.required">{{validations.messages.address.line_2.required}}</div>
                <div *ngIf="f.address_second.errors.minlength">{{validations.messages.address.line_2.invalid}}</div> --></div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group country-dd" [ngClass]="{ 'is-invalid': submitted && f.country.errors }"> <span *ngIf="country_loading" class="input-loader"><span class="zf-btn-loader"></span></span>
										<div class="zf-input-label"> <span *ngIf="!country_loading">Select your country</span>
											<span *ngIf="country_loading">Please wait...</span>
										</div>
										<div class="zf-input-control">
											<ng-select2 formControlName="country" [data]="countries" [options]="dd_options" (click)="onFocus()" (valueChanged)="onCountryChange($event)"></ng-select2>
										</div>
									</div>
									<div *ngIf="submitted && f.country.errors" class="invalid-feedback">
										<div *ngIf="f.country.errors.required">{{validations.messages.address.country.required}}</div>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group state-dd" [ngClass]="{ 'is-invalid': submitted && f.state.errors }"> <span *ngIf="state_loading" class="input-loader"><span class="zf-btn-loader"></span></span>
										<div class="zf-input-label"> <span *ngIf="!state_loading">Select your state</span>
											<span *ngIf="state_loading">Please wait...</span>
										</div>
										<div class="zf-input-control">
											<ng-select2 formControlName="state" [data]="states" [options]="dd_options" (click)="onFocus()" (valueChanged)="onStateChange($event)"></ng-select2>
										</div>
									</div>
									<div *ngIf="submitted && f.state.errors" class="invalid-feedback">
										<div *ngIf="f.state.errors.required">{{validations.messages.address.state.required}}</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group city-dd" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"> <span *ngIf="city_loading" class="input-loader"><span class="zf-btn-loader"></span></span>
										<div class="zf-input-label"> <span *ngIf="!city_loading">Select your City</span>
											<span *ngIf="city_loading">Please wait...</span>
										</div>
										<div class="zf-input-control">
											<ng-select2 formControlName="city" [data]="cities" (click)="onFocus()" [options]="dd_options"></ng-select2>
										</div>
									</div>
									<div *ngIf="submitted && f.city.errors" class="invalid-feedback">
										<div *ngIf="f.city.errors.required">{{validations.messages.address.city.required}}</div>
									</div>
								</div>
								<div class="col-12 col-lg-6 mb-2">
									<div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.zipcode.errors }">
										<div class="zf-input-label">Enter your zip / postal code</div>
										<div class="zf-input-control">
											<input type="search" class="form-control" formControlName="zipcode" maxlength="{{validations.def.address.zipcode.max}}">
										</div>
									</div>
									<div *ngIf="submitted && f.zipcode.errors" class="invalid-feedback">
										<div *ngIf="f.zipcode.errors.required">{{validations.messages.address.zipcode.required}}</div>
										<div *ngIf="f.zipcode.errors.pattern">{{validations.messages.address.zipcode.invalid}}</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-12 text-center">
									<button type="submit" [disabled]="submitting || uploading 
                                    || country_loading || state_loading || city_loading" class="btn btn-ziofly-default active min-width-125"> 
                                    <span *ngIf="!submitting">Save Profile</span>
										<span *ngIf="submitting">
                                            <span class="zf-btn-loader"></span>
										</span>
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>