import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/common/loader/loader.service';
import { CustomValidators } from 'src/app/helper/strong-password';
import { AuthService } from 'src/app/services/auth.service';
import { CmsService } from 'src/app/services/cms.service';
import { FileValidateService } from 'src/app/services/file-validate.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { validations } from 'src/environments/validations';
import { variables } from 'src/environments/variables';
declare function initInputs();
declare var $;
@Component({
  selector: 'ziofly-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  userData;
  profileLoading=false;
  initCountry=0;
  initState=0;
  initCity=0;
  zForm: FormGroup;
  validations=validations;
  submitting;
  submitted;
  profile_img = 'assets/img/dummy-user.png';
  uploading;
  countries=[];
  states=[];
  cities=[];
  country_loaded=false;
  state_loaded=false;
  city_loaded=false;
  country_loading=false;
  state_loading=false;
  city_loading=false;

  constructor(private userService: UserService,
    private formbuilder: FormBuilder,
    private cms: CmsService,
    private loader: LoaderService,
    private toastr: ToastrService,
    private router: Router,
    private auth: AuthService,
    private FileValidate: FileValidateService) { }


  variables = variables;

  changePhone(){
    /*setTimeout(() => {
      var _phone = this.zForm.value.phone_copy;
      _phone = _phone.replace(/[^A-Z0-9]/ig, "_");
      this.zForm.controls.phone.setValue(_phone);
    }, 200);*/
  }

  ngOnInit(): void {

    this.userService.getUserData().subscribe(data=>{
      this.userData = data;
      if(this.userData.avatar) {
        this.profile_img = variables.serverAssetUrl+this.userData.avatar
      } else {
        // this.profile_img = variables.no_img_path
      }
      this.zForm = this.formbuilder.group({
        first_name : [this.userData.first_name, [Validators.required, CustomValidators.trimValidator, Validators.pattern(validations.def.name.regex), Validators.minLength(validations.def.name.first_name.min), Validators.maxLength(validations.def.name.first_name.max)]],
        last_name : [this.userData.last_name, [Validators.required, CustomValidators.trimValidator, Validators.pattern(validations.def.name.regex), Validators.minLength(validations.def.name.last_name.min), Validators.maxLength(validations.def.name.last_name.max)]],
        seller_name : [this.userData.seller_name, [Validators.required, CustomValidators.trimValidator, Validators.pattern(validations.def.seller_name.regex), Validators.minLength(validations.def.seller_name.min), Validators.maxLength(validations.def.seller_name.max)]],
        email: [this.userData.email],
        phone: [this.userData.phone, [Validators.required,
          Validators.pattern(this.validations.def.phone.regex)]],
        phone_copy: [''],
        //fax: [this.userData.fax, [Validators.pattern(this.validations.def.fax.regex)]],
        fax: [this.userData.fax],
        address_first: [this.userData.address_first, [Validators.required, Validators.minLength(validations.def.address.line_1.min), Validators.maxLength(validations.def.address.line_1.max)]],
        address_second: [this.userData.address_second, [ Validators.maxLength(validations.def.address.line_2.max)]],
        city: [this.userData.city_id],
        state: [this.userData.state_id, [Validators.required]],
        country: [this.userData.country_id, [Validators.required]],
        zipcode: [this.userData.zipcode, [Validators.required, Validators.pattern(validations.def.address.zipcode.regex)]],
        imagename: [this.userData.avatar],
        oldimagename: [null],
      })
      setTimeout(() => {
        initInputs();
      }, 100);
      this.getCountries()
      setTimeout(() => {

        this.getStates(this.userData.country_id)
      }, 200);
      setTimeout(() => {
        this.getCities(this.userData.state_id)
      }, 400);
      setTimeout(() => {
        this.zForm.patchValue({
          country: this.userData.country_id,
          state: this.userData.state_id,
          city: this.userData.city_id,
        })
        this.profileLoading=false;
        setTimeout(() => {
          initInputs();
        }, 200);
      }, 2000);
    })
  }
// dropdown-search
onFocus(){
  $(".select2-search__field").focus();
}
// dropdown-search
  ngOnDestroy(){ // called to remove the ng select 2 class which is creating bug on going back in browser
    const selectOptionsContainer = $('.select2-container');
    selectOptionsContainer.removeClass('select2-container--open')
  }

  get f() { return this.zForm.controls; }

  updateProfile() {
    this.toastr.removeAll()
    this.submitted = true;
    // console.log(this.zForm.invalid);
    if(this.zForm.invalid) {
      return;
    }
    this.zForm.value.first_name = this.zForm.value.first_name.replace(/\s\s+/g, ' ')
    this.zForm.value.last_name = this.zForm.value.last_name.replace(/\s\s+/g, ' ')
    this.zForm.value.seller_name = this.zForm.value.seller_name.replace(/\s\s+/g, ' ')
    this.submitting = true
    this.userService.updateProfile(this.zForm.value).subscribe(data=>{
      if(data['status']) {
        this.toastr.success({msg:this.validations.messages.profile_update.success})
        //
        this.userService.getUserDetails().subscribe(data=>{
          if(data['status']) {
            this.userService.setUserData(data['data'])
            this.submitting = false
            this.router.navigate(['/user/profile'])
          } else {
            this.auth.logout()
            window.location.reload()
            this.toastr.error({msg:data['message']})
          }
          this.submitting = false
        },
        error=>{
          this.toastr.error({msg:error.message})
          this.submitting = false
        })
      } else {
        if(data['data']){
          this.toastr.error({msg:data['data'][0]});
          this.submitting = false
        }
        else{
          this.toastr.error({msg:data['message']})
          this.submitting = false
        }
      }
    },error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})
    })
  }
  uploadProfileImage(e) {
    var files = e.target.files
    var file = files[0]
    if(this.FileValidate.getValid(file)) {
      this.uploading = true;
      var formData = new FormData()
      formData.append('image[]', file)
      formData.append('directory', 'user')
      this.userService.uploadFile(formData).subscribe(data=>{
        if(data['status']) {
          this.profile_img = variables.blank_img
          this.profile_img = variables.serverAssetUrl+data['data'][0]
          this.zForm.get('imagename').setValue(data['data'][0])
          this.zForm.get('imagename').updateValueAndValidity()
          this.zForm.get('oldimagename').setValue(this.userData.avatar)
          this.zForm.get('imagename').updateValueAndValidity()
          this.uploading = false
        } else {
          this.toastr.error({msg:data['message']})
          this.uploading  = false
        }
      },
      error=>{
        this.toastr.error({msg:error.message})
        this.uploading = false
      })
    }
  }
  dd_options = {
  };

  getCountries() {
    this.country_loaded=false;
    this.country_loading=true;
    this.cms.getCountries().subscribe(data=>{
      this.countries = []
      if(this.initState>0) {
        this.states=[]
      }
      if(this.initCity>0) {
        this.cities=[]
      }
      data['data'].map(x=>{
        this.countries.push({
          id: x.id,
          text: x.name
        })
      })
      initInputs();
      this.country_loaded=true
      this.country_loading=false;
      if(this.initCountry==0) {
        this.initCountry++
        this.zForm.patchValue({
          country: this.userData.country_id
        })
      }
    })
  }
  getStates(id) {
    this.zForm.get('state').setValue(null)
    this.zForm.get('state').updateValueAndValidity()
    $(document).find('.state-dd').removeClass('focused')
    if(this.initCity>0) {
      this.zForm.get('city').setValue(null)
      this.zForm.get('city').updateValueAndValidity()
      $(document).find('.city-dd').removeClass('focused')
    }
    this.state_loading=true;
    this.state_loaded=false;
    this.cms.getStates({ country_id : id}).subscribe(data=>{
      this.states = []
      if(this.initCity>0) {
        this.cities=[]
      }
      data['data'].map(x=>{
        this.states.push({
          id: x.id,
          text: x.name
        })
      })
      initInputs();
      this.state_loaded=true
      this.state_loading=false;
      if(this.initState==0) {
        this.initState++
        this.zForm.patchValue({
          state: this.userData.state_id
        })
      }
    })
  }
  onCountryChange(e:any) {
    if(e) {
      this.getStates(e)
    }
  }
  onStateChange(e:any) {
    if(e) {
      this.getCities(e)
    }
  }
  getCities(id) {
    this.zForm.get('city').setValue(null)
    this.zForm.get('city').updateValueAndValidity()
    $(document).find('.city-dd').removeClass('focused')
    this.city_loading=true;
    this.city_loaded=false
    this.cms.getCities({ state_id : id}).subscribe(data=>{
    this.cities = []
      data['data'].map(x=>{
        this.cities.push({
          id: x.id,
          text: x.name
        })
      })
      initInputs();
      this.city_loaded=true
      this.city_loading=false;
      if(this.initCity==0) {
        this.initCity++
        this.zForm.patchValue({
          city: this.userData.city_id
        })
      }
    })

  }
}
