import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './front-pages/about-us/about-us.component';
import { BlogComponent } from './front-pages/blog/blog.component';
import { BlogDetailComponent } from './front-pages/blog-detail/blog-detail.component';
import { NewsArticlesComponent } from './front-pages/news-articles/news-articles.component';
import { EventComponent } from './front-pages/event/event.component';
import { AuthLayoutComponent } from './front-pages/auth/auth-layout/auth-layout.component';
import { ForgotComponent } from './front-pages/auth/forgot/forgot.component';
import { LoginComponent } from './front-pages/auth/login/login.component';
import { ResetPasswordComponent } from './front-pages/auth/reset-password/reset-password.component';
import { SignupComponent } from './front-pages/auth/signup/signup.component';
import { VerifyEmailComponent } from './front-pages/auth/verify-email/verify-email.component';
import { ContactUSComponent } from './front-pages/contact-us/contact-us.component';
import { FaqComponent } from './front-pages/faq/faq.component';
import { HomeComponent } from './front-pages/home/home.component';
import { ContentComponent } from './front-pages/layout/content/content.component';
import { PrivacyPolicyComponent } from './front-pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './front-pages/terms-and-conditions/terms-and-conditions.component';
import { ChangePasswordComponent } from './front-pages/user-pages/change-password/change-password.component';
import { FavoriteProductComponent } from './front-pages/user-pages/favorite-product/favorite-product.component';
import { MyAircraftsComponent } from './front-pages/user-pages/my-aircrafts/my-aircrafts.component';
import { NotificationsComponent } from './front-pages/user-pages/notifications/notifications.component';
import { PaymentComponent } from './front-pages/user-pages/notifications/payment/payment.component';
import { PaymentMethodComponent } from './front-pages/user-pages/payment-method/payment-method.component';
import { ProductDetailComponent } from './front-pages/user-pages/product-detail/product-detail.component';
import { ProductListComponent } from './front-pages/user-pages/product-list/product-list.component';
import { ProfileEditComponent } from './front-pages/user-pages/profile-edit/profile-edit.component';
import { ProfileComponent } from './front-pages/user-pages/profile/profile.component';
import { SellAircraftComponent } from './front-pages/user-pages/sell-aircraft/sell-aircraft.component';
import { AuthGuard } from './helper/auth.guard';
import { UnauthGuard } from './helper/unauth.guard';
import { NewsDetailComponent } from './front-pages/news-detail/news-detail.component';
import { MobileSelectPlanComponent } from './front-pages/mobile-app/mobile-select-plan/mobile-select-plan.component';
import { MobilePaymentPayComponent } from './front-pages/mobile-app/mobile-payment-pay/mobile-payment-pay.component';
import { MobileContentComponent } from './front-pages/layout/mobile-content/mobile-content.component';
import { PaymentNewComponent } from './front-pages/payment-new/payment-new.component';
const routes: Routes = [
  {
    path: '',
    component: ContentComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'contact-us', component: ContactUSComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'blog', component: BlogComponent },
      { path: 'blog-detail/:slug', component: BlogDetailComponent },
      { path: 'news-articles', component: NewsArticlesComponent },
      { path: 'news-detail/:slug', component: NewsDetailComponent },
      { path: 'event', component: EventComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
      { path: 'products', component: ProductListComponent },
      {
        path: 'product-detail/:name/:name/:name/:year/:id',
        component: ProductDetailComponent,
      },
      { path: 'payment-method', component: PaymentMethodComponent },
      // { path: 'faq', component: FaqComponent },
    ],
  },

  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [UnauthGuard],
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'verify/:token/:id', component: VerifyEmailComponent },
      { path: 'forgot-password', component: ForgotComponent },
      { path: 'reset-password/:token', component: ResetPasswordComponent },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'user/change-password', component: ChangePasswordComponent },
    ],
  },
  {
    path: 'user',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'notifications', component: NotificationsComponent },
      // { path: 'payment-method', component: PaymentMethodComponent },
      { path: 'payment-method', component: PaymentComponent },
      { path: 'sell-aircraft', component: SellAircraftComponent },
      { path: 'sell-aircraft/:name/:name/:name/:year/:id', component: SellAircraftComponent },
      { path: 'my-aircrafts', component: MyAircraftsComponent },
      { path: 'favorite-products', component: FavoriteProductComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'profile/edit', component: ProfileEditComponent },
    ],
  },
  { path: 'mobile-plan', component: MobileSelectPlanComponent },
  { path: 'mobile-plan-payment', component: MobilePaymentPayComponent },
  { path: 'payment-new', component: PaymentNewComponent },
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
