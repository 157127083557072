import { ValidationErrors, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
  static trimValidator: ValidatorFn = (control: FormControl) => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }
    if (control.value.trim()==='' || control.value.trim().length<2) {
        return {
        'trimError': true
        };
    }
    return null;
    };
}