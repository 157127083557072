import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ziofly-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() image: string;
  @Input() alt: string = '';
  @Input() title: string;
  constructor() { }

  ngOnInit(): void {
  }

}
