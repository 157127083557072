import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/app/helper/must-match';
import { CustomValidators } from 'src/app/helper/strong-password';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { validations } from 'src/environments/validations';
import { variables } from 'src/environments/variables';

@Component({
  selector: 'ziofly-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  variables=variables;
  validations=validations;
  zForm: FormGroup;
  submitted = false;
  submitting;
  isPassSame;
  token;
  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthService
  ) { 
  }
  get f() { return this.zForm.controls; }
  ngOnInit(): void {
    this.zForm = this.formBuilder.group({   
      current_password: ['', [Validators.required]], 
     
    new_password: ['', [Validators.required,         
      // CustomValidators.patternValidator(this.validations.def.password.hasNumber_regex, {
      //   hasNumber: true
      // }),        
      // CustomValidators.patternValidator(this.validations.def.password.hasCapitalCase_regex, {
      //   hasCapitalCase: true
      // }),        
      // CustomValidators.patternValidator(this.validations.def.password.hasSmallCase_regex, {
      //   hasSmallCase: true
      // }),        
      /*CustomValidators.patternValidator(this.validations.def.password.hasSpecialCharacters_regex, {
          hasSpecialCharacters: true
      }),*/
      Validators.minLength(this.validations.def.password.min),Validators.maxLength(this.validations.def.password.max)]], 
    password_confirmation: ['', [Validators.required]],
  },{
      validator: MustMatch('new_password', 'password_confirmation')
    });
  }
  checkPassSame() {
    this.isPassSame = false;   
    if(this.f.current_password.value==this.f.new_password.value)   { 
      this.isPassSame = true;
      return    
    } 
  }
  changePassword() {
    this.toastr.removeAll()
    this.submitted=true;
    if(this.zForm.invalid || this.isPassSame) {
      return
    }    
    this.submitting=true;
    this.userService.changePassword(this.zForm.value).subscribe(data=>{
        if(data['status']) {
          this.submitting=false;
          this.toastr.success({msg:this.validations.messages.change_password.success})
          this.auth.logout()
          this.router.navigate(['/login']);
        } else {
          this.submitting=false;
          this.toastr.error({msg:data['message']})  
        }
      }, 
      error=>{
        this.submitting=false;
        this.toastr.error({msg:error.message})
      })
  }
}
