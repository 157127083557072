<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="auth-card">                
                <div class="auth-card-logo">
                    <a routerLink="/"><img *ngIf="cmsData?.site.logo" src="{{variables.serverAssetUrl}}{{cmsData?.site.logo}}" alt="image" ></a>
                </div>
                <div class="auth-card-title">
                    Set New Password
                </div>
                <form [formGroup]="zForm" (ngSubmit)="reset()">
                    <div class="auth-form">
                        <div class="row">
                            <div class="col-12">
                                <div class="zf-input-group"  [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }">
                                    <div class="zf-input-label">Enter Password</div>
                                    <div class="zf-input-control">
                                        <input type="password"   zioflyAppPassword class="form-control"  formControlName="newpassword" maxlength="{{validations.def.password.max}}" >
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.newpassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.newpassword.errors.required">{{validations.messages.password.required}}</div>
                                    <!--<div *ngIf="f.newpassword.errors.minlength || f.newpassword.errors.maxlength 
                                    || f.newpassword.errors.hasNumber || f.newpassword.errors.hasCapitalCase 
                                    || f.newpassword.errors.hasSmallCase || f.newpassword.errors.hasSpecialCharacters">
                                    {{validations.messages.password.invalid}}</div>-->
                                    <div *ngIf="f.newpassword.errors.minlength || f.newpassword.errors.maxlength">
                                    {{validations.messages.password.invalid}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="zf-input-group"  [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }">
                                    <div class="zf-input-label">Confirm Password</div>
                                    <div class="zf-input-control">
                                        <input type="password"   zioflyAppPassword class="form-control"  formControlName="password_confirmation" maxlength="{{validations.def.password.max}}" >
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                                    <div *ngIf="f.password_confirmation.errors.required">{{validations.messages.password_confirmation.required}}</div>
                                    <div *ngIf="f.password_confirmation.errors.mustMatch">{{validations.messages.password_confirmation.same}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">                                
                                <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active block">
                                    <span *ngIf="!submitting">Set New Password</span>
                                    <span *ngIf="submitting">
                                        <span class="zf-btn-loader"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>