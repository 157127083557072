import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'ziofly-mobile-select-plan',
  templateUrl: './mobile-select-plan.component.html',
  styleUrls: ['./mobile-select-plan.component.scss']
})
export class MobileSelectPlanComponent implements OnInit {
  mobile: boolean = false;
  EncrDecr;
  tokenValue: any;
  selectType: string = '';
  constructor(
    public userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams
      .subscribe(params => {
        this.tokenValue = params['token'];
        // console.log(this.tokenValue);
        });
    }

  ngOnInit(): void {
    // if (window.screen.width === 360) { // 768px portrait
    //   this.mobile = true;
    // } else {
    //   this.mobile = false;
    // }
    // this.selectType='';

  }

  mobileselectPlan(type) {
    this.selectType = type;
    let apiData = {
      "subscription_type" : this.selectType,
       "user_token" : this.tokenValue,
    }
    this.userService.mobileselectPlan(apiData).subscribe(data=>{
      if(data['status']) {
        let currenturl = this.router.url;
        let updateurl = currenturl+'&is-subscribed-user=yes';
        window.location.href = updateurl;
      } else {
      }
    },error=>{

    })
  }
}
