<ng-container *ngIf="productLoading">
  <div class="page-wrapper product-detail-page-wrapper pt-4">
    <div class="text-center py-5">
      <span class="zf-btn-loader zf-btn-loader-yellow m-auto"></span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!productLoading">
  <div class="page-wrapper product-detail-page-wrapper section pb-4" id="page_wrapper"
    (window:resize)="onResize($event)">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12 col-12">
          <nav class=" mt-0" aria-label="breadcrumb">
            <ol class="breadcrumb mt-0 pt-0">
              <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
              <li class="breadcrumb-item" *ngIf="productData?.is_self_product == true">
                <a [routerLink]="['/user/my-aircrafts']">
                  My Listings
                </a>
              </li>
              <li class="breadcrumb-item" *ngIf="productData?.is_self_product == false">
                <a [routerLink]="['/products', {category:productData.category.slug}]">
                  {{productData.category.name}}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">{{productData.model.name}}</li>
            </ol>
          </nav>

        </div>
        <div class="col-10 col-lg-10">
          <h4 class="font-weight-bold color-black product-title">{{productData.year}} {{productData.category.name}}
            {{productData.make?.name}} {{productData.model?.name}}</h4>
        </div>
        <div class="col-2">
          <ul class="p-action-list">
            <li>
              <a (click)="addToFav(productData.id)" *ngIf="!productData.is_favourite"><img
                  src="assets/img/icon-circle-heart.png"> <span class="mob-txt">Save</span> </a>
              <a (click)="addToUnfav(productData.id)" *ngIf="productData.is_favourite"><img
                  src="assets/img/icon-circle-heart-fill.png"><span class="mob-txt">Save</span> </a>
            </li>

          </ul>
        </div>
      </div>
    </div>

    <div class="product-detail-slider-wrapper mt-3" *ngIf="!isMobile">
      <div class="pd-slider-one-wrapper">
        <a *ngIf="productData.mediafiles.length>0" class="btn-zoom-in-btn desk-btn" (click)="zoomImage()"><img
            src="assets/img/zoom-in-btn.png"></a>
        <a class="btn-zoom-out-btn" (click)="zoomOutImage()"><img src="assets/img/zoom-out-btn.png"></a>
        <ngx-slick-carousel class="carousel pd-slider-one" #slickModal="slick-carousel" [config]="slideConfig"
          (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
          (beforeChange)="beforeChange($event)">
          <div ngxSlickItem class="pd-slider-one-slide" *ngFor="let item of productData.mediafiles">
            <div *ngIf="productData.mediafiles.length>0" (click)="zoomImage()" class="mob-view-1">
              <img *ngIf="!isVideo(item)" class="ap-card-img-f" src="{{variables.serverAssetUrl}}{{item}}">
              <video class="ap-card-img-f" *ngIf="isVideo(item)" controls>
                <source src="{{variables.serverAssetUrl}}{{item}}">
              </video>
            </div>
            <div class="desk-view-1">
              <img *ngIf="!isVideo(item)" class="ap-card-img-f" src="{{variables.serverAssetUrl}}{{item}}">
              <video class="ap-card-img-f" *ngIf="isVideo(item)" controls>
                <source src="{{variables.serverAssetUrl}}{{item}}">
              </video>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
      <div class="pd-slider-thumb-wrapper mt-lg-3">
        <ngx-slick-carousel class="carousel pd-slider-thumb" #slickModal="slick-carousel" [config]="slideConfigthumb"
          (init)="slickInit($event)" (breakpoint)="breakpoint($event)">
          <div ngxSlickItem class="pd-slider-thumb-slide" *ngFor="let item of productData.mediafiles">
            <img *ngIf="!isVideo(item)" class="ap-card-img-f" src="{{variables.serverAssetThumbUrl}}{{item}}">
            <img class="video-play-icon" src="assets/img/video-play-icon.png" *ngIf="isVideo(item)">
            <video class="ap-card-img-f" *ngIf="isVideo(item)">
              <source src="{{variables.serverAssetUrl}}{{item}}" type="video/mp4">
            </video>
          </div>
        </ngx-slick-carousel>

      </div>
    </div>

    <div *ngIf="isMobile" class="mobile_slider_desgin">
      <div class="mobile-large-image" *ngFor="let media of productData.mediafiles | slice:0:1">
        <a href="{{variables.serverAssetUrl}}{{media}}" class="glightbox img_glightbox">
          <img *ngIf="!isVideo(media)" src="{{variables.serverAssetUrl}}{{media}}" alt="image" class="img-fluid">
          <img class="video-play-icon" src="assets/img/video-play-icon.png" *ngIf="isVideo(media)">
          <video class="ap-card-img-f" *ngIf="isVideo(media)">
            <source src="{{variables.serverAssetUrl}}{{media}}" type="video/mp4">
          </video>
        </a>
      </div>
      <div class="detail-mobile-thumb">
        <ng-container *ngFor="let item of productData.mediafiles | slice:1:productData.imagecount; let z = index">
          <a href="{{variables.serverAssetUrl}}{{item}}" [ngClass]="{'glightbox': z < 3}" class="img_glightbox">
            <img *ngIf="z < 3 && !isVideo(item)" src="{{variables.serverAssetUrl}}{{item}}">
            <img class="video-play-icon" src="assets/img/video-play-icon.png" *ngIf="z < 3 && isVideo(item)">
            <video class="ap-card-img-f" *ngIf="z < 3 && isVideo(item)">
              <source src="{{variables.serverAssetUrl}}{{item}}" type="video/mp4">
            </video>
            <span *ngIf="z == 2" class="remaining_image_count">+{{productData.imagecount - 3}}</span>
          </a>
        </ng-container>
      </div>
    </div>


    <div class="container">
      <div class="mt-lg-5"></div>
      <div class="row">
        <div class="col-lg-8">
          <h4 class="font-weight-bold color-black">Description <span class="pd-user-count"
              *ngIf="productData.is_self_product && productData.subscription_type=='premium'"><i class="fa fa-eye"
                ngbTooltip="Tooltip on top"></i> {{productData.aircraftviews}} <i class="fa fa-heart-o ml-3"></i>
              {{productData.favourite}}</span> </h4>
          <p class="">{{productData.aircraft_description}}</p>
        </div>
        <div class="col-lg-4">
          <div class="card-seller">
            <ng-container *ngIf="productData.display_seller_name">
              <h5 *ngIf="productData.sellername">{{productData.sellername | titlecase}}</h5>
              <h5 *ngIf="!productData.sellername">No Description</h5>
            </ng-container>
            <ng-container *ngIf="!productData.display_seller_name">
              <h5>No Description</h5>
            </ng-container>
            <ul class="seller-detail-list">
              <ng-container *ngIf="productData.display_contact_button">
                <li><span class="icon"><img src="assets/img/call-iicon.png"></span><a
                    href="tel:{{productData.phone}}">{{productData.phone | mask: '(000)
                    000-0000'}}</a></li>
              </ng-container>
              <li><span class="icon"><img src="assets/img/msg-iicon.png"></span><a
                  href="mailto:{{productData.email}}">{{productData.email}}</a></li>
              <ng-container *ngIf="productData.display_full_address">
                <li><span class="icon"><img src="assets/img/map-iicon.png"></span>{{productData.address_first}}
                  {{productData.address_second}}</li>
              </ng-container>
            </ul>
            <div>
              <button class="btn btn-ziofly-default active mr-2 my-2">
                                <span>View Inventory</span>
                            </button>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="product-detail-more py-lg-5 py-0 py-md-4">
    <div class="container" *ngIf="!productLoading">
      <div class="row">
        <div class="col-lg-8">

          <div class="mdc-collapse-wrapper mt-3">
            <div class="mdc-collapse">
              <div class="mdc-collapse-head">
                <h5><a data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                    aria-controls="collapseOne"><img src="assets/img/plus-primary.png"></a> Details</h5>
              </div>
              <div class="mdc-collapse-body collapse show" id="collapseOne" aria-labelledby="headingOne"
                data-parent="#accordion">
                <div class="mcd-details">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Make</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.make.name}}</div>
                      </div>
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Year</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.year}}</div>
                      </div>
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Price</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">
                          <ng-container *ngIf="productData.price">
                            {{productData.currency?.symbol}}{{productData.price | number}}
                          </ng-container>
                          <ng-container *ngIf="!productData.price">Call for price
                          </ng-container>
                        </div>
                      </div>

                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Registration</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">
                          <ng-container *ngIf="!productData.registration_no">No Description</ng-container>
                          <ng-container *ngIf="productData.registration_no">
                            {{productData.registration_no}}</ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Model</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.model.name}}</div>
                      </div>
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Condition</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.aircraft_condition}}</div>
                      </div>
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Type</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.category.name}}</div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mdc-collapse">
              <div class="mdc-collapse-head">
                <h5><a (click)="toggleNavigationSub('detail_2', $event)"><img src="assets/img/plus-primary.png"></a>
                  General Specs</h5>
              </div>
              <div class="mdc-collapse-body" [@toggleHeight]="navigationSubState.detail_2">
                <div class="mcd-details">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Total Time</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.total_time_on_aircraft}}</div>
                      </div>
                      <div class="mcd-details-item">
                        <div class="mcd-i-a"># of Seats</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.no_of_seats}}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Flight Rules</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.flight_rules}}</div>
                      </div>
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Sale Status</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.sale_status}}</div>
                      </div>
                    </div>

                    <div class="col-md-6" *ngIf="productData.fraction_ownership">
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Ownership</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.fraction_ownership}}</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="mdc-collapse">
              <div class="mdc-collapse-head">
                <h5><a (click)="toggleNavigationSub('detail_3', $event)"><img src="assets/img/plus-primary.png"></a>
                  Avionics/Equipment</h5>
              </div>
              <div class="mdc-collapse-body" [@toggleHeight]="navigationSubState.detail_3">
                <p>
                  <ng-contiaber *ngIf="productData.avionics_equipment">
                    {{productData.avionics_equipment}}</ng-contiaber>
                  <ng-contiaber *ngIf="!productData.avionics_equipment">No Description</ng-contiaber>
                </p>
              </div>
            </div>
            <div class="mdc-collapse">
              <div class="mdc-collapse-head">
                <h5><a (click)="toggleNavigationSub('detail_4', $event)"><img src="assets/img/plus-primary.png"></a>
                  Airframe</h5>
              </div>
              <div class="mdc-collapse-body" [@toggleHeight]="navigationSubState.detail_4">
                <p>
                  <ng-contiaber *ngIf="productData.air_frame">{{productData.air_frame}}</ng-contiaber>
                  <ng-contiaber *ngIf="!productData.air_frame">No Description</ng-contiaber>
                </p>
              </div>
            </div>
            <div class="mdc-collapse">
              <div class="mdc-collapse-head">
                <h5><a (click)="toggleNavigationSub('detail_5', $event)"><img src="assets/img/plus-primary.png"></a>
                  Interior</h5>
              </div>
              <div class="mdc-collapse-body" [@toggleHeight]="navigationSubState.detail_5">
                <p>
                  <ng-contiaber *ngIf="productData.interior_exterior_description">
                    {{productData.interior_exterior_description}}</ng-contiaber>
                  <ng-contiaber *ngIf="!productData.interior_exterior_description">No Description</ng-contiaber>
                </p>
              </div>
            </div>
            <div class="mdc-collapse">
              <div class="mdc-collapse-head">
                <h5><a (click)="toggleNavigationSub('detail_6', $event)"><img src="assets/img/plus-primary.png"></a>
                  Exterior</h5>
              </div>
              <div class="mdc-collapse-body" [@toggleHeight]="navigationSubState.detail_6">
                <p>
                  <ng-contiaber *ngIf="productData.exterior_description">
                    {{productData.exterior_description}}</ng-contiaber>
                  <ng-contiaber *ngIf="!productData.exterior_description">No Description</ng-contiaber>
                </p>
              </div>
            </div>
            <div class="mdc-collapse">
              <div class="mdc-collapse-head">
                <h5><a (click)="toggleNavigationSub('detail_7', $event)"><img src="assets/img/plus-primary.png"></a>
                  Engines / Mods / Prop</h5>
              </div>
              <div class="mdc-collapse-body" [@toggleHeight]="navigationSubState.detail_7">
                <p>{{productData.engine_mods_pops}}</p>
                <div class="mcd-details">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Total time</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.total_time_on_aircraft}}</div>
                      </div>
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Engine 1 Time</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.engine_time1}}
                          {{productData.engine_time1_unit}}</div>
                      </div>
                      <div class="mcd-details-item" *ngIf="productData.engine_time2">
                        <div class="mcd-i-a">Engine 2 Time</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.engine_time2}}
                          {{productData.engine_time2_unit}}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Useful Load</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.useful_load}}
                          {{productData.useful_load_unit}}</div>
                      </div>
                      <div class="mcd-details-item">
                        <div class="mcd-i-a">Prop 1 Time</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.prop_time1}}
                          {{productData.prop_time1_unit}}</div>
                      </div>
                      <div class="mcd-details-item" *ngIf="productData.prop_time2">
                        <div class="mcd-i-a">Prop 2 Time</div>
                        <div class="mcd-i-b"></div>
                        <div class="mcd-i-c">{{productData.prop_time2}}
                          {{productData.prop_time2_unit}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 px-0 px-md-3 px-lg-3">

          <div class="email-seller" *ngIf="productData?.is_self_product == true">
            <h3 class="d-flex align-items-center">
              <img src="assets/img/form-msg.png" class="mr-2">
              <span class=" font-weight-bold">Contact to support</span>
            </h3>
            <form [formGroup]="contactForm" class="mt-3">

              <div class="form-group">
                <input type="text" class="form-control with-icon-left name" placeholder="Name" formControlName="name">
                <div *ngIf="submitted && f2.name.errors" class="invalid-feedback d-block">
                  <div *ngIf="f2.name.errors.required">Name is required</div>
                  <div *ngIf="f2.name.errors.pattern">Name should contain only alphabetical characters
                  </div>
                </div>
              </div>

              <div class="form-group">
                <input type="text" class="form-control with-icon-left email" placeholder="Email"
                  formControlName="email">
                <div *ngIf="submitted && f2.email.errors" class="invalid-feedback d-block">
                  <div *ngIf="f2.email.errors.required">Email is required</div>
                  <div *ngIf="f2.email.errors.email">Email is Invalid</div>
                </div>
              </div>

              <div class="form-group">
                <input type="text" class="form-control  d-none" placeholder="Phone" maxlength="12"
                  formControlName="phone">
                <input type="text" class="form-control with-icon-left phone" placeholder="Phone"
                  formControlName="phone_copy" mask="(000) 000-0000||(000) 000-000000" (change)="changePhone()"
                  (keypress)="changePhone()">
                <div *ngIf="submitted && f2.phone.errors" class="invalid-feedback d-block">
                  <div *ngIf="f2.phone.errors.required">{{validations.messages.phone.required}}</div>
                  <div *ngIf="f2.phone.errors.pattern">{{validations.messages.phone.invalid}}</div>
                </div>
              </div>

              <div class="form-group">
                <textarea class="form-control" formControlName="message" placeholder="Message" rows="4"></textarea>
                <div *ngIf="submitted && f2.message.errors" class="invalid-feedback d-block">
                  <div *ngIf="f2.message.errors.required">Message is required</div>
                </div>
              </div>

              <div class="mt-2">
                <button class="btn btn-ziofly-default active" (click)="enquiryFormSubmits()"
                  [disabled]="submitting"><span>
                    <span class="mr-2" *ngIf="submitting">
                      <span class="zf-btn-loader"></span>
                    </span>
                    Send Email</span></button>
              </div>
            </form>
          </div>

          <div class="email-seller" *ngIf="productData?.is_self_product == false">
            <h3 class="d-flex align-items-center">
              <img src="assets/img/form-msg.png" class="mr-2">
              <span class=" font-weight-bold">Email to seller</span>
            </h3>
            <form [formGroup]="enquiryForm" class="mt-3">
              <div class="form-group">
                <input type="text" class="form-control with-icon-left name" placeholder="Name" formControlName="name">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback d-block">
                  <div *ngIf="f.name.errors.required">Name is required</div>
                  <div *ngIf="f.name.errors.pattern">Name should contain only alphabetical characters
                  </div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control with-icon-left email" placeholder="Email"
                  formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email is Invalid</div>
                </div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control  d-none" placeholder="Phone" maxlength="12"
                  formControlName="phone">
                <input type="text" class="form-control with-icon-left phone" placeholder="Phone"
                  formControlName="phone_copy" mask="(000) 000-0000||(000) 000-000000" (change)="changePhone()"
                  (keypress)="changePhone()">
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback d-block">
                  <div *ngIf="f.phone.errors.required">{{validations.messages.phone.required}}</div>
                  <div *ngIf="f.phone.errors.pattern">{{validations.messages.phone.invalid}}</div>
                </div>
              </div>
              <div class="form-group">
                <textarea class="form-control" formControlName="message"
                  placeholder="I am interested in product. Is it still up for the sale? Please reach out with my email or phone number"
                  rows="4"></textarea>
                <div *ngIf="submitted && f.message.errors" class="invalid-feedback d-block">
                  <div *ngIf="f.message.errors.required">Message is required</div>
                </div>
              </div>
              <div class="mt-2">
                <button class="btn btn-ziofly-default active" (click)="enquiryFormSubmit()"
                  [disabled]="submitting"><span>
                    <span class="mr-2" *ngIf="submitting">
                      <span class="zf-btn-loader"></span>
                    </span>
                    Send Email</span></button>
              </div>
            </form>
          </div>


        </div>
      </div>

    </div>
  </div>

  <div class="page-wrapper" *ngIf="productList.length>0">
    <div class="container mob-p-0">
      <div class="row align-items-center mb-4 mx-0">
        <div class="col-6">
          <h4 class="font-weight-bold mb-0 pl-0 pl-md-0 pl-lg-0">Similar listing</h4>
        </div>
        <div class="col-6">
          <div class="slide__arrows ">

          </div>
        </div>
      </div>

      <ng-container *ngIf="similerListingLoading">
        <div class="text-center py-5">
          <span class="zf-btn-loader zf-btn-loader-yellow m-auto"></span>
        </div>
      </ng-container>
      <ng-container *ngIf="!similerListingLoading">
        <div class="row gutter-10">
          <div class="col-12 text-center my-5" *ngIf="productList.length<1">
            No record found
          </div>
          <div class="col-12">
            <ngx-slick-carousel class="similar-listing-slider carousel" #slickModal="slick-carousel"
              [config]="slideConfig_3">
              <div ngxSlickItem class="similar-listing-slide" *ngFor="let item of productList">
                <div class="ap-card">
                  <div class="ap-card-body">
                    <div class="ap-card-img">
                      <a (click)="changeProduct(item.id)" routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">
                        <ng-container *ngIf="!item.mediafiles.length>0">
                          <img class="ap-card-img-f" src="assets/img/no-image-aircraft.jpg">
                        </ng-container>
                        <ng-container *ngIf="item.mediafiles.length>0">
                          <img *ngIf="!isVideo(item.mediafiles[0])" class="ap-card-img-f"
                            src="{{variables.serverAssetThumbUrl}}{{item.mediafiles[0]}}">
                          <i class="icon-play" *ngIf="isVideo(item.mediafiles[0])"></i>
                          <video class="ap-card-img-f" *ngIf="isVideo(item.mediafiles[0])">
                            <source src="{{variables.serverAssetUrl}}{{item.mediafiles[0]}}" type="video/mp4">
                          </video>
                        </ng-container>
                      </a>
                      <img *ngIf="item.subscription_type=='premium'" class="premium-isting"
                        src="assets/img/premium_isting.svg">
                    </div>
                    <div class="ap-card-data">
                      <h4 class="cursor-pointer" (click)="changeProduct(item.id)"
                        routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">
                        {{item.year}}
                        {{item.category.name}} {{item.make?.name}} {{item.model?.name}}</h4>
                      <div class="row ap-data-row border-0 pb-0">
                        <div class="col pr-0">
                          <h4 class="text-secondary" *ngIf="item.price">
                            {{item.currency?.symbol}}{{item.price | number}}</h4>
                          <h4 class="text-secondary" *ngIf="!item.price">Call for price</h4>
                        </div>
                      </div>
                      <div class="row ap-data-row border-bottom-0">
                        <div class="col pr-0">
                          <h4 class="mb-1" *ngIf="item.sellername">{{item.sellername |
                            titlecase}}</h4>
                          <h4 *ngIf="!item.sellername">No Description</h4>

                          <ng-container *ngIf="!item.country">No Description</ng-container>
                          <ng-container *ngIf="item.country">{{item.country?.name |
                            uppercase}}</ng-container>

                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
