<section *ngIf="false" class="section section--banner" data-aos="fade-zoom-in" data-aos-once="true"
  data-aos-easing="ease-in-back" data-aos-delay="0" data-aos-offset="0">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h1 class="banner-title" [innerHTML]="cmsData?.home?.home_page_banner_heading"></h1>
        <p class="banner-description" [innerHTML]="cmsData?.home?.home_page_banner_paragraph"></p>
        <div class="banner-btns">
          <a routerLink="/products" class="btn btn-ziofly-default active">
            <span>Browse listings</span>
          </a> &nbsp;
          <!-- <a routerLink="/contact-us" class="btn btn-ziofly-default">
                <span>Find a Dealer</span>
                </a> -->
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex justify-content-end">
        <img *ngIf="cmsData?.home?.home_page_banner_image"
          src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_banner_image}}" id="flightImg"
          class="flight-image" alt="flight-image">
      </div>
    </div>
  </div>
</section>
<section class="section section--banner-new">
  <img class="home-banner-sideplan left" src="assets/img/home-banner-sideplan-left.png">
  <img class="home-banner-sideplan right" src="assets/img/home-banner-sideplan-right.png">
  <div class="container">
    <div class="text-center banner-new-text">
      <h1 class="banner-title" [innerHTML]="cmsData?.home?.home_page_banner_heading"></h1>
      <p class="banner-description" [innerHTML]="cmsData?.home?.home_page_banner_paragraph"></p>
      <div class="homebanner-mob-btn">
        <a routerLink="/products" class="btn btn-ziofly-default active">
          <span>Browse listings</span>
        </a>
        <a routerLink="user/sell-aircraft" (click)="sellAircraft()" class="btn btn-ziofly-default banner-btn-2 active">
          <span>Sell your aircraft</span>
        </a>
      </div>

    </div>
  </div>
  <div class="new-home-plane-div">
    <img class="new-home-plane with-hover" src="assets/img/new-home-plane-hover.gif">
    <img class="new-home-plane without-hover" src="assets/img/new-home-plane.png">
  </div>

</section>
<div class="light-gradient-bg">
  <section class="section section--categories">
    <div class="">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section--title-most-popular">
              <h2 class="section--title">
                <span>Most popular</span> <b>Categories</b>
                <span class="animate-flight-svg">
                  <i class="animate-flight-svg-border"></i>
                  <i class="animate-flight-svg-icon icon-flight-right"></i>
                </span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-lg-3 col-left pt-0 pt-md-3 pt-lg-3">

        </div>
        <div class="col-12 col-sm-12 col-lg-9 col-right" *ngIf="!isMobile">
          <div class="flight-categories-row">
            <div class="flight-categories-col transparent">
            </div>
            <div class="flight-categories-col dark">
              <a [routerLink]="['/products', {category:cmsData?.categories[0].slug}]" class="flight-cat-card">
                <div class="flight-cat-card-content">
                  <div class="flight-cat-card-icon">
                    <img *ngIf="cmsData?.categories[0].image"
                      src="{{variables.serverAssetUrl}}{{cmsData?.categories[0].image}}" alt="image">
                  </div>
                  <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[0].name"></div>
                </div>
                <div class="flight-cat-card-footer">
                  <a [routerLink]="['/products', {category:cmsData?.categories[0].slug}]"
                    class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                        class="icon-arrow-right"></i></span></a>
                </div>
              </a>
            </div>
            <div class="flight-categories-col">
              <a [routerLink]="['/products', {category:cmsData?.categories[1].slug}]" class="flight-cat-card">
                <div class="flight-cat-card-content">
                  <div class="flight-cat-card-icon">
                    <img *ngIf="cmsData?.categories[0].image"
                      src="{{variables.serverAssetUrl}}{{cmsData?.categories[1].image}}" alt="image">
                  </div>
                  <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[1].name"></div>
                </div>
                <div class="flight-cat-card-footer">
                  <a [routerLink]="['/products', {category:cmsData?.categories[1].slug}]"
                    class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                        class="icon-arrow-right"></i></span></a>
                </div>
              </a>
            </div>
            <div class="flight-categories-col dark">
              <a [routerLink]="['/products', {category:cmsData?.categories[2].slug}]" class="flight-cat-card">
                <div class="flight-cat-card-content">
                  <div class="flight-cat-card-icon">
                    <img *ngIf="cmsData?.categories[0].image"
                      src="{{variables.serverAssetUrl}}{{cmsData?.categories[2].image}}" alt="image">
                  </div>
                  <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[2].name"></div>
                </div>
                <div class="flight-cat-card-footer">
                  <a [routerLink]="['/products', {category:cmsData?.categories[2].slug}]"
                    class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                        class="icon-arrow-right"></i></span></a>
                </div>
              </a>
            </div>
            <div class="flight-categories-col dark">
              <a [routerLink]="['/products', {category:cmsData?.categories[3].slug}]" class="flight-cat-card">
                <div class="flight-cat-card-content">
                  <div class="flight-cat-card-icon">
                    <img *ngIf="cmsData?.categories[0].image"
                      src="{{variables.serverAssetUrl}}{{cmsData?.categories[3].image}}" alt="image">
                  </div>
                  <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[3].name"></div>
                </div>
                <div class="flight-cat-card-footer">
                  <a [routerLink]="['/products', {category:cmsData?.categories[3].slug}]"
                    class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                        class="icon-arrow-right"></i></span></a>
                </div>
              </a>
            </div>
            <div class="flight-categories-col">
              <a [routerLink]="['/products', {category:cmsData?.categories[4].slug}]" class="flight-cat-card">
                <div class="flight-cat-card-content">
                  <div class="flight-cat-card-icon">
                    <img *ngIf="cmsData?.categories[0].image"
                      src="{{variables.serverAssetUrl}}{{cmsData?.categories[4].image}}" alt="image">
                  </div>
                  <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[4].name"></div>
                </div>
                <div class="flight-cat-card-footer">
                  <a [routerLink]="['/products', {category:cmsData?.categories[4].slug}]"
                    class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                        class="icon-arrow-right"></i></span></a>
                </div>
              </a>
            </div>
            <div class="flight-categories-col dark">
              <a [routerLink]="['/products', {category:cmsData?.categories[5].slug}]" class="flight-cat-card">
                <div class="flight-cat-card-content">
                  <div class="flight-cat-card-icon">
                    <img *ngIf="cmsData?.categories[0].image"
                      src="{{variables.serverAssetUrl}}{{cmsData?.categories[5].image}}" alt="image">
                  </div>
                  <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[5].name"></div>
                </div>
                <div class="flight-cat-card-footer">
                  <a [routerLink]="['/products', {category:cmsData?.categories[5].slug}]"
                    class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                        class="icon-arrow-right"></i></span></a>
                </div>
              </a>
            </div>
            <div class="flight-categories-col">
              <a [routerLink]="['/products', {category: 'special_use'}]" class="flight-cat-card">
                <div class="flight-cat-card-content">
                  <div class="flight-cat-card-icon">
                    <img src="assets/img/flight_types/fic-007.svg" alt="image">
                  </div>
                  <div class="flight-cat-card-title">Special Use</div>
                </div>
                <div class="flight-cat-card-footer">
                  <a [routerLink]="['/products', {category: 'special_use'}]"
                    class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                        class="icon-arrow-right"></i></span></a>
                </div>
              </a>
            </div>
          </div>
        </div>
        <!-- mobile-category-slider -->
        <div class="col-12 col-sm-12 col-lg-9 col-right" *ngIf="isMobile">
          <ngx-slick-carousel class="carousel category-mob-slider" #slickModal="slick-carousel"
            [config]="categoryslideConfig" (init)="slickInit($event)" (breakpoint)="breakpoint($event)"
            (afterChange)="afterChange($event)" (beforeChange)="beforeChange($event)">
            <div ngxSlickItem class="slide">
              <div class="flight-categories-row">
                <div class="flight-categories-col transparent">
                </div>
                <div class="flight-categories-col dark category-col-m-b category-col-m-r">
                  <a [routerLink]="['/products', {category:cmsData?.categories[0].slug}]" class="flight-cat-card">
                    <div class="flight-cat-card-content">
                      <div class="flight-cat-card-icon">
                        <img *ngIf="cmsData?.categories[0].image"
                          src="{{variables.serverAssetUrl}}{{cmsData?.categories[0].image}}" alt="image">
                      </div>
                      <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[0].name"></div>
                    </div>
                    <div class="flight-cat-card-footer">
                      <a [routerLink]="['/products', {category:cmsData?.categories[0].slug}]"
                        class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                            class="icon-arrow-right"></i></span></a>
                    </div>
                  </a>
                </div>
                <div class="flight-categories-col category-col-m-b">
                  <a [routerLink]="['/products', {category:cmsData?.categories[1].slug}]" class="flight-cat-card">
                    <div class="flight-cat-card-content">
                      <div class="flight-cat-card-icon">
                        <img *ngIf="cmsData?.categories[0].image"
                          src="{{variables.serverAssetUrl}}{{cmsData?.categories[1].image}}" alt="image">
                      </div>
                      <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[1].name"></div>
                    </div>
                    <div class="flight-cat-card-footer">
                      <a [routerLink]="['/products', {category:cmsData?.categories[1].slug}]"
                        class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                            class="icon-arrow-right"></i></span></a>
                    </div>
                  </a>
                </div>
                <div class="flight-categories-col dark category-col-m-r">
                  <a [routerLink]="['/products', {category:cmsData?.categories[2].slug}]" class="flight-cat-card">
                    <div class="flight-cat-card-content">
                      <div class="flight-cat-card-icon">
                        <img *ngIf="cmsData?.categories[0].image"
                          src="{{variables.serverAssetUrl}}{{cmsData?.categories[2].image}}" alt="image">
                      </div>
                      <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[2].name"></div>
                    </div>
                    <div class="flight-cat-card-footer">
                      <a [routerLink]="['/products', {category:cmsData?.categories[2].slug}]"
                        class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                            class="icon-arrow-right"></i></span></a>
                    </div>
                  </a>
                </div>
                <div class="flight-categories-col dark">
                  <a [routerLink]="['/products', {category:cmsData?.categories[3].slug}]" class="flight-cat-card">
                    <div class="flight-cat-card-content">
                      <div class="flight-cat-card-icon">
                        <img *ngIf="cmsData?.categories[0].image"
                          src="{{variables.serverAssetUrl}}{{cmsData?.categories[3].image}}" alt="image">
                      </div>
                      <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[3].name"></div>
                    </div>
                    <div class="flight-cat-card-footer">
                      <a [routerLink]="['/products', {category:cmsData?.categories[3].slug}]"
                        class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                            class="icon-arrow-right"></i></span></a>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div ngxSlickItem class="slide">
              <div class="flight-categories-row">
                <div class="flight-categories-col transparent">
                </div>
                <div class="flight-categories-col dark category-col-m-b category-col-m-r">
                  <a [routerLink]="['/products', {category:cmsData?.categories[4].slug}]" class="flight-cat-card">
                    <div class="flight-cat-card-content">
                      <div class="flight-cat-card-icon">
                        <img *ngIf="cmsData?.categories[4].image"
                          src="{{variables.serverAssetUrl}}{{cmsData?.categories[4].image}}" alt="image">
                      </div>
                      <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[4].name"></div>
                    </div>
                    <div class="flight-cat-card-footer">
                      <a [routerLink]="['/products', {category:cmsData?.categories[4].slug}]"
                        class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                            class="icon-arrow-right"></i></span></a>
                    </div>
                  </a>
                </div>
                <div class="flight-categories-col dark category-col-m-b">
                  <a [routerLink]="['/products', {category:cmsData?.categories[5].slug}]" class="flight-cat-card">
                    <div class="flight-cat-card-content">
                      <div class="flight-cat-card-icon">
                        <img *ngIf="cmsData?.categories[5].image"
                          src="{{variables.serverAssetUrl}}{{cmsData?.categories[5].image}}" alt="image">
                      </div>
                      <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[5].name"></div>
                    </div>
                    <div class="flight-cat-card-footer">
                      <a [routerLink]="['/products', {category:cmsData?.categories[5].slug}]"
                        class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                            class="icon-arrow-right"></i></span></a>
                    </div>
                  </a>
                </div>
                <div class="flight-categories-col dark category-col-m-b category-col-m-r">
                  <a [routerLink]="['/products', {category:cmsData?.categories[6].slug}]" class="flight-cat-card">
                    <div class="flight-cat-card-content">
                      <div class="flight-cat-card-icon">
                        <img *ngIf="cmsData?.categories[6].image"
                          src="{{variables.serverAssetUrl}}{{cmsData?.categories[6].image}}" alt="image">
                      </div>
                      <div class="flight-cat-card-title" [innerHTML]="cmsData?.categories[6].name"></div>
                    </div>
                    <div class="flight-cat-card-footer">
                      <a [routerLink]="['/products', {category:cmsData?.categories[6].slug}]"
                        class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                            class="icon-arrow-right"></i></span></a>
                    </div>
                  </a>
                </div>
                <div class="flight-categories-col category-col-m-b">
                  <a [routerLink]="['/products', {category: 'special_use'}]" class="flight-cat-card">
                    <div class="flight-cat-card-content">
                      <div class="flight-cat-card-icon">
                        <img src="assets/img/flight_types/fic-007.svg" alt="image">
                      </div>
                      <div class="flight-cat-card-title">Special Use</div>
                    </div>
                    <div class="flight-cat-card-footer">
                      <a [routerLink]="['/products', {category: 'special_use'}]"
                        class="btn btn-ziofly-default btn-ziofly-circle sm"><span><i
                            class="icon-arrow-right"></i></span></a>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
        <!-- mobile-category-slider -->
      </div>
    </div>
  </section>
  <section class="section section--recent">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12">
          <div class="text-center">
            <h2 class="section--title mb-0">
              <span [innerHTML]="cmsData?.home?.home_page_second_section_heading"></span>
              <span class="animate-flight-svg">
                <i class="animate-flight-svg-border"></i>
                <i class="animate-flight-svg-icon icon-flight-right"></i>
              </span>
            </h2>
          </div>
          <!-- FOR LEFT RIGHT TYPE heading -->
          <!-- <div class="section--title-with-desc">
                   <div class="section--title-with-desc-left">
                      <h2 class="section--title">
                         <span [innerHTML]="cmsData?.home?.home_page_second_section_heading"></span>
                         <span class="animate-flight-svg">
                         <i class="animate-flight-svg-border"></i>
                         <i class="animate-flight-svg-icon icon-flight-right"></i>
                         </span>
                      </h2>
                   </div>
                   <div class="section--title-with-desc-right">
                      <p [innerHTML]="cmsData?.home?.home_page_second_section_paragraph"></p>
                   </div>
                </div> -->
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="recent-slider pt-0 featured-aircraft-slider" (window:resize)="onResize($event)">
            <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel" [config]="slideConfig"
              (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
              (beforeChange)="beforeChange($event)">
              <div ngxSlickItem *ngFor="let slide of productList; let i = index;let im = index" class="slide">
                <div class="recent-slider--slide">
                  <div class="recent-slider--card">
                    <div class="recent-slider--card-img-section" *ngIf="!isMobile">
                      <div class="recent-slider--card-img-holder">
                        <!-- <a routerLink="/product-detail/{{slide.listing_id}}"> -->
                        <a
                          routerLink="/product-detail/{{slide.category.name}}/{{slide.make?.name}}/{{slide.model?.name}}/{{slide.year}}/{{slide.listing_id}}">
                          <!-- {{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}} -->
                          <img *ngIf="slide.mediafiles[0]" src="{{variables.serverAssetUrl}}{{slide.mediafiles[0]}}"
                            id="recentSlideImg_{{i}}"
                            onError="this.onerror=null;this.src='assets/img/placeholder-aircarft.jpg';">
                          <img *ngIf="!slide.mediafiles[0]" src="assets/img/no-image-aircraft.jpg"
                            id="recentSlideImg_{{i}}">
                        </a>
                      </div>
                      <div class="recent-slider--card-thumb-holder">
                        <ng-container *ngFor="let item of slide.mediafiles | slice:0:4; let z = index">
                          <img *ngIf="!isVideo(item)" class="recent-slider--card-thumb-{{z}}" (click)="setImage($event)"
                            [attr.data-id]="'recentSlideImg_'+i" src="{{variables.serverAssetUrl}}{{item}}">
                        </ng-container>
                      </div>
                    </div>
                    <div class="recent-slider--card-img-section video-img-slider" *ngIf="isMobile">
                      <div class="recent-slider--card-img-holder mobile-large-image"
                        *ngFor="let media of slide.mediafiles | slice:0:1">
                        <a href="{{variables.serverAssetUrl}}{{media}}" class="glightbox" id="glbox-{{im}}">
                          <img *ngIf="!isVideo(media)" src="{{variables.serverAssetUrl}}{{media}}"
                            id="recentSlideImg_{{i}}" alt="image" class="img-fluid">
                          <img class="video-play-icon" src="assets/img/video-play-icon.png" *ngIf="isVideo(media)">
                          <video class="ap-card-img-f" *ngIf="isVideo(media)">
                            <source src="{{variables.serverAssetUrl}}{{media}}" type="video/mp4">
                          </video>
                        </a>
                      </div>
                      <div class="recent-slider--card-thumb-holder">
                        <ng-container *ngFor="let item of slide.mediafiles | slice:1:slide.image_count; let z = index;">
                          <!-- <a href="{{variables.serverAssetUrl}}{{item}}" [ngClass]="{'glightbox': z < 3 && !isVideo(item)}" id="glbox-{{im}}">
                                          <img *ngIf="z < 3 && !isVideo(item)" class="recent-slider--card-thumb-{{z}}" src="{{variables.serverAssetUrl}}{{item}}">
                                          <span *ngIf="slide.total_image_count == 2" class="remaining_image_count">+{{slide.image_count - 4}}</span>
                                       </a> -->
                          <a href="{{variables.serverAssetUrl}}{{item}}" [ngClass]="{'glightbox': z < 3}"
                            id="glbox-{{im}}">
                            <img *ngIf="z < 3 && !isVideo(item)" class="recent-slider--card-thumb-{{z}}"
                              src="{{variables.serverAssetUrl}}{{item}}">
                            <img class="video-play-icon" src="assets/img/video-play-icon.png"
                              *ngIf="z < 3 && isVideo(item)">
                            <video class="ap-card-img-f" *ngIf="z < 3 && isVideo(item)">
                              <source src="{{variables.serverAssetUrl}}{{item}}" type="video/mp4">
                            </video>
                            <span *ngIf="z == 2" class="remaining_image_count">+{{slide.image_count -
                              3}}</span>
                          </a>
                        </ng-container>
                      </div>
                    </div>
                    <div class="recent-slider--card-txt-section">
                      <h4 class="recent-slider--title cursor-pointer"
                        routerLink="/product-detail/{{slide.category.name}}/{{slide.make?.name}}/{{slide.model?.name}}/{{slide.year}}/{{slide.listing_id}}">
                        {{slide.year}}
                        {{slide.make?.name}} {{slide.model?.name}}</h4>
                      <p class="recent-slider--sub-title mob-category-text"><span
                          class="category-label-text">Category:</span> <span
                          class="category-name-txt">{{slide.category.name}}</span></p>
                      <!-- <div class="recent-slider--ratings" data-rating="5"></div> -->
                      <!-- <p class="recent-slider--price">USD $79,900</p> -->
                      <div class="mobile-slider-des-price-row">
                        <p class="recent-slider--price" *ngIf="slide.price">
                          <!-- {{slide.currency?.code}}  -->
                          {{slide.currency?.symbol}}{{slide.price | number}}
                        </p>
                        <p class="recent-slider--price" *ngIf="!slide.price">Call for price</p>
                        <table class="recent-slider--features">
                          <!-- <tr>
                                        <td><span class="rct-td"><span class="rct-text">Sale Status:</span><span class="rct-gap"></span></span></td>
                                        <td>{{slide.sale_status}}</td>
                                     </tr> -->
                          <!-- <tr>
                                        <td><span class="rct-td"><span class="rct-text">Year:</span><span class="rct-gap"></span></span></td>
                                        <td>
                                           <ng-container *ngIf="!slide.year">NA</ng-container>
                                           <ng-container *ngIf="slide.year">{{slide.year}}</ng-container>
                                          </td>
                                     </tr> -->
                          <!-- <tr>
                                        <td><span class="rct-td"><span class="rct-text">Make:</span><span class="rct-gap"></span></span></td>
                                        <td>{{slide.make?.name}}</td>
                                     </tr>
                                     <tr>
                                       <td><span class="rct-td"><span class="rct-text">Model:</span><span class="rct-gap"></span></span></td>
                                       <td>{{slide.model?.name}}</td>
                                    </tr> -->
                          <tr>
                            <td><span class="rct-td"><span class="rct-text">Location:</span><span
                                  class="rct-gap"></span></span></td>
                            <td>
                              <!-- Livermore, CA USA -->
                              <ng-container *ngIf="!slide.country">NA</ng-container>
                              <ng-container *ngIf="slide.country">{{slide.country?.name}}</ng-container>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div class="recent-slider--desc">
                        <p *ngIf="!slide.aircraft_description">NA</p>
                        <p *ngIf="slide.aircraft_description">{{ (slide.aircraft_description.length>100)?
                          (slide.aircraft_description | slice:0:100)+'..':(slide.aircraft_description) }}
                        </p>
                      </div>
                      <div class="recent-slider--btns">
                        <a routerLink="/product-detail/{{slide.category.name}}/{{slide.make?.name}}/{{slide.model?.name}}/{{slide.year}}/{{slide.listing_id}}"
                          class="btn btn-ziofly-default active">
                          <span>View Details</span>
                        </a>
                      </div>
                    </div>
                    <div class="recent-slider--card-navigation product-slider-mobile-nav">
                      <span class="nav-btn-holder prev"><a href="javascript:void(0)" (click)="slickModal.slickPrev()"
                          class="btn btn-ziofly-default btn-ziofly-circle active"><span><i
                              class="icon-arrow-right flip-x"></i></span></a></span>
                      <span class="nav-btn-holder next"><a href="javascript:void(0)" (click)="slickModal.slickNext()"
                          class="btn btn-ziofly-default btn-ziofly-circle active"><span><i
                              class="icon-arrow-right"></i></span></a></span>
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
            <div class="recent-slider--bullets-container">
              <ngx-slick-carousel class="recent-slider--bullets carousel thumbs" #slickModal="slick-carousel"
                [config]="slideConfig_2">
                <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                  <span></span>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<section class="section section--choose">
  <div class="background-gradient"></div>
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-lg-6 why-choose-us-banner-container">
        <div class="section--title-with-desc  sm why-choose-mob">
          <div class="section--title-with-desc-left">
            <h2 class="section--title --light">
              <span [innerHTML]="cmsData?.home?.home_page_third_section_heading"></span>
              <span class="animate-flight-svg">
                <i class="animate-flight-svg-border"></i>
                <i class="animate-flight-svg-icon icon-flight-right"></i>
              </span>
            </h2>
          </div>
          <div class="section--title-with-desc-right">
            <p [innerHTML]="cmsData?.home?.home_page_third_section_paragraph"></p>
          </div>
        </div>
        <div class="why-choose-us-banner">
          <!-- <img src="assets/img/why-choose-us-laptop.png" alt="image" > -->
          <div class="laptop-video-player">
            <img src="assets/img/laptop_frame_2.png" alt="image" class="laptop">
            <video class="video" id="whyChooseUsVideo" (play)="videoPlaying($event)" (pause)="videoPlaying($event)">
              <!-- <source src="assets/videos/movie.mp4" type="video/mp4"> -->
              <ng-container *ngIf="cmsData?.home?.home_page_third_section_file">
                <source src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_third_section_file}}"
                  type="video/mp4">
              </ng-container>
            </video>
            <span class="laptop-video-play" (click)="playVid()">
              <!-- <i class="icon-play"></i> -->
              <img src="assets/img/play-button.svg" alt="Play" class="img-fluid mob-play-btn">
            </span>
            <div class="laptop-video-overlay" (click)="playVid()"></div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-6 pl-sm-5">
        <div class="why-choose-us-features">
          <div class="row">
            <div class="col-12 col-sm-6">
              <div class="why-choose-us-card">
                <span *ngIf="cmsData?.home?.home_page_icon_one" class="feature-icon"><img
                    src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_icon_one}}" alt="image"></span>
                <div class="feature-title" [innerHTML]="cmsData?.home?.home_page_icon_one_heading"></div>
                <div class="feature-desc" [innerHTML]="cmsData?.home?.home_page_icon_one_paragraph"></div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="why-choose-us-card">
                <span *ngIf="cmsData?.home?.home_page_icon_two" class="feature-icon"><img
                    src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_icon_two}}" alt="image"></span>
                <div class="feature-title" [innerHTML]="cmsData?.home?.home_page_icon_two_heading"></div>
                <div class="feature-desc" [innerHTML]="cmsData?.home?.home_page_icon_two_paragraph"></div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="why-choose-us-card  mb-lg-0">
                <span *ngIf="cmsData?.home?.home_page_icon_three" class="feature-icon"><img
                    src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_icon_three}}" alt="image"></span>
                <div class="feature-title" [innerHTML]="cmsData?.home?.home_page_icon_three_heading"></div>
                <div class="feature-desc" [innerHTML]="cmsData?.home?.home_page_icon_three_paragraph"></div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="why-choose-us-card mb-lg-0">
                <span *ngIf="cmsData?.home?.home_page_icon_four" class="feature-icon"><img
                    src="{{variables.serverAssetUrl}}{{cmsData?.home?.home_page_icon_four}}" alt="image"></span>
                <div class="feature-title" [innerHTML]="cmsData?.home?.home_page_icon_four_heading"></div>
                <div class="feature-desc" [innerHTML]="cmsData?.home?.home_page_icon_four_paragraph"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section section--clients mt-5">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">

        <div class="">

          <ngx-slick-carousel class="client-slider carousel" #slickModal="slick-carousel" [config]="slideConfig_3">
            <div ngxSlickItem *ngFor="let slide of clients" class="slide">
              <div class="client-slider-slide">
                <div class="client-card">
                  <img src="{{slide.src}}">
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>

      </div>
    </div>
  </div>
</section>
