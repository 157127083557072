<div class="page-wrapper section" id="page_wrapper">
    <div class="container">
        <nav class=" mt-0 mb-2" aria-label="breadcrumb">
            <ol class="breadcrumb mt-0">
              <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Favourite</li>
            </ol>
          </nav>
          <div class="row">
              <div class="col-12">
                <h3 class="font-weight-bold text-body mt-1">Favorites</h3>
              </div>
          </div>
          <div class="ap-list mt-1">
            <ng-container *ngIf="productLoading">
                <div class="text-center py-5">
                    <span class="zf-btn-loader zf-btn-loader-yellow m-auto"></span>
                </div>
            </ng-container>
            <ng-container *ngIf="!productLoading">
                <div class="row gutter-10">
                    <div class="col-12 text-center my-5" *ngIf="productList.length<1">
                        No record found
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 md-6 mb-4"  *ngFor="let item of productList | paginate: config; let i = index">
                        <div class="ap-card">
                            <div class="ap-card-body">
                                <div class="ap-card-img cursor-pointer">
                                    <!-- <a class="ap-card-img-action left"><img src="assets/img/action-abc.png"></a> -->
                                    <a class="ap-card-img-action" (click)="addToUnfav(item.id)">
                                        <img style="max-width: 45%;" src="assets/img/fav-delete.png">
                                    </a>
                                    <a routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">
                                        <ng-container *ngIf="!item.mediafiles.length>0">
                                            <img class="ap-card-img-f" src="assets/img/no-image-aircraft.jpg">
                                        </ng-container>
                                        <ng-container *ngIf="item.mediafiles.length>0">
                                            <img *ngIf="!isVideo(item.mediafiles[0])" class="ap-card-img-f" src="{{variables.serverAssetThumbUrl}}{{item.mediafiles[0]}}">
                                            <i class="icon-play" *ngIf="isVideo(item.mediafiles[0])"></i>
                                            <video class="ap-card-img-f" *ngIf="isVideo(item.mediafiles[0])">
                                                <source src="{{variables.serverAssetUrl}}{{item.mediafiles[0]}}" type="video/mp4">
                                            </video>
                                        </ng-container>
                                    </a>
                                    <img *ngIf="item.subscription_type=='premium'" class="premium-isting" src="assets/img/premium_isting.svg">
                                </div>
                                <div class="ap-card-data">
                                    <h4 class="cursor-pointer" routerLink="/product-detail/{{item.category.name}}/{{item.make?.name}}/{{item.model?.name}}/{{item.year}}/{{item.listing_id}}">{{item.year}} {{item.category.name}} {{item.make?.name}} {{item.model?.name}}</h4>
                                    <p class="ap-desc">{{item.aircraft_description}}</p>
                                    <div class="row ap-data-row text-black">

                                        <div class="col-12">REG#:
                                            <ng-container *ngIf="!item.show_resgistration_number">NA</ng-container>
                                            <ng-container *ngIf="item.show_resgistration_number">{{item.registration_no}}</ng-container>
                                        </div>
                                        <div class="col-12">SN#:<span class="mr-2"></span>
                                            <ng-container *ngIf="!item.show_serialnumber">NA</ng-container>
                                            <ng-container *ngIf="item.show_serialnumber">{{item.serial_no}}</ng-container>
                                        </div>
                                    </div>
                                    <div class="row ap-data-row">
                                        <div class="col pr-0">
                                            <h4 *ngIf="item.price">
                                                {{item.currency?.symbol}}{{item.price | number}}
                                            </h4>
                                            <h4 *ngIf="!item.price">Call for price</h4>
                                        </div>
                                        <!-- <div class="col-auto"><a><img class="mt-minus-3" src="assets/img/performance-spec.png"> Get Financing</a></div> -->
                                    </div>
                                    <div class="row ap-data-row border-bottom-0">
                                        <div class="col pr-0">
                                            <ng-container *ngIf="item.display_seller_name">
                                                <h4 class="mb-1" *ngIf="item.sellername">{{item.sellername | titlecase}}</h4>
                                                <h4 *ngIf="!item.sellername">NA</h4>
                                            </ng-container>
                                            <ng-container *ngIf="!item.display_seller_name">
                                                <h4>NA</h4>
                                            </ng-container>
                                            <!-- {{item.city_id}}  -->
                                            <!-- {{item.state_id}}  -->
                                            <ng-container *ngIf="!item.country">NA</ng-container>
                                            <ng-container *ngIf="item.country">{{item.country?.name}}</ng-container>

                                        </div>
                                        <!-- <div class="col-auto"><a class="text-center"><img class="d-block mx-auto mb-1" src="assets/img/get-financing.png"> Performance Spec</a></div> -->
                                    </div>
                                    <div class="row">
                                        <div class="col-6 pr-1">
                                            <a class="btn btn-ap btn-block" href="tel:{{item.phone}}"><span><img src="assets/img/telephone-icon.png"> Call</span></a>
                                        </div>
                                        <div class="col-6 pl-1">
                                            <a class="btn btn-ap btn-block" (click)="openModalForm(item)"><span><img src="assets/img/msg-icon.png">Message</span></a>
                                        </div>
                                    </div>
                                    <div class="text-center ap-updated-at">
                                        Last Update:
                                        <ng-container *ngIf="item.updated_at"> {{item.updated_at | date:"MM/dd/yyyy"}}</ng-container>
                                        <ng-container *ngIf="!item.updated_at">NA</ng-container>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="productList.length>0">
                        <pagination-controls
            (pageChange)="pageChange($event)"
            previousLabel=""
                      nextLabel=""
             class="zf-pagination"></pagination-controls>
                    </div>
                </div>

            </ng-container>

        </div>
    </div>
</div>




<!-- Modal -->

<div class="modal-form-modal" [class.open]="isModalFormView">
    <div class="modal-form-box sm p-0">
        <div class="email-seller rounded p-md-4">
            <h4 class="d-flex align-items-center"><img src="assets/img/form-msg.png" class="mr-2"> <span class=" font-weight-bold">Email seller</span></h4>
        <form [formGroup]="enquiryForm" class="mt-3">
            <div class="form-group">
                <input type="text" class="form-control with-icon-left name" placeholder="Name" formControlName="name">
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback d-block">
                    <div *ngIf="f.name.errors.required">Name is required</div>
                    <div *ngIf="f.name.errors.pattern">Name should contain only alphabetical characters</div>
                </div>
            </div>
            <div class="form-group">
                <input type="text" class="form-control with-icon-left email" placeholder="Email" formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email is Invalid</div>
                </div>
            </div>
            <div class="form-group">
                <input type="text" class="form-control  d-none" placeholder="Phone" maxlength="12" formControlName="phone">
                <input type="text" class="form-control with-icon-left phone" placeholder="Phone" formControlName="phone_copy" mask="(000) 000-000000" (change)="changePhone()" (keypress)="changePhone()" >
                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback d-block">
                    <div *ngIf="f.phone.errors.required">{{validations.messages.phone.required}}</div>
                    <div *ngIf="f.phone.errors.pattern">{{validations.messages.phone.invalid}}</div>
                </div>
            </div>
            <div class="form-group">
                <textarea class="form-control" formControlName="message" placeholder="I am interested in product. Is it still up for the sale? Please reach out with my email or phone number" rows="4"></textarea>
                <div *ngIf="submitted && f.message.errors" class="invalid-feedback d-block">
                    <div *ngIf="f.message.errors.required">Message is required</div>
                </div>
            </div>
            <!-- <div class="mt-2">
                <label>
                    <input type="checkbox" name="list_are_posted" formControlName="notify_for_similar_listing"> Notify me if similar listings are posted
                </label>
            </div>
            <div class="mt-2">
                <label>
                    <input type="checkbox" name="news_letter" formControlName="notify_offers_giveaways_newsletters"> Send me exclusive offers, giveaways, and monthly newsletters
                </label>
            </div> -->
            <div class="mt-2">
                <button class="btn btn-ziofly-default active mr-2" (click)="closeModalForm()"><span>Cancel</span></button>
                <button class="btn btn-ziofly-default active" (click)="enquiryFormSubmit()" [disabled]="submitting"><span>
                    <span class="mr-2" *ngIf="submitting">
                        <span class="zf-btn-loader"></span>
                    </span>
                    Send Email</span></button>
            </div>
        </form>
        </div>
    </div>
</div>
