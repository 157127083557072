import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
// import { error } from 'console';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition, } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../user-pages/image-dialog/image-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
//import { debug } from 'console';

declare var stripe: any;
declare var elements: any;
declare var $: any;

@Component({
  selector: 'ziofly-mobile-payment-pay',
  templateUrl: './mobile-payment-pay.component.html',
  styleUrls: ['./mobile-payment-pay.component.scss'],
  animations: [
    trigger('toggleHeight', [
      state('inactive', style({
        height: '0',
        opacity: '0',
        // display: 'none'
      })),
      state('active', style({
        height: '*',
        opacity: '1',
        // display: 'block'
      })),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ])
  ]
})
export class MobilePaymentPayComponent implements OnInit {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  @Output() cardAddedSuccessEvent = new EventEmitter<any>();
  @Input() savedCardDetails: any;
  @Input() product_status;
  //@Input() subscription_message;
  @Output() getSaveCardDataEvent = new EventEmitter<any>();
  canPublishListing: boolean = true;
  isNextLoading: boolean = false;
  hideCardForm: boolean = false;
  hidePromoCode: boolean = false;
  Vlist: any = [];
  subscription_message: any;
  subscription_type: any;
  submitting = false;
  submittedValue = false;
  hideafterEmailVarify = true;
  submitted = false;
  submitted2 = false;
  saveCardForm: FormGroup;
  saveCardForm2: FormGroup;

  dd_options = {
    minimumResultsForSearch: -1,
  };
  monthList: any;
  yearList: any;
  show_card: any;
  apply_promoCodeStatus: boolean;
  tokenValue;
  product_id;
  selectedListngType;

  navigationSubState: { [menu: string]: string } = {
    payment: 'active',
  };
  navigationCode: { [menu: string]: string } = {
    promocode: 'active',
  };
  private _zf_tokenSubject: BehaviorSubject<any>;

  constructor(private cd: ChangeDetectorRef, private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private formbuilder: FormBuilder,
    public dialog: MatDialog,
     private route: ActivatedRoute,
     private auth: AuthService
    ) {
      // force route reload whenever params change;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.route.queryParams
        .subscribe(params => {
          this.tokenValue = params['user_token'];
          this.product_id = params['product_id'];
          if(this.tokenValue) {
            this.auth._zf_tokenSubject.next(this.tokenValue);
            // localStorage.setItem('_zf_token', this.tokenValue);
          }

          });
    var currentYear = new Date().getFullYear();
    var maxYear = currentYear + 51;
    this.yearList = [];
    while (currentYear < maxYear) {
      this.yearList.push({
        id: currentYear,
        text: currentYear
      })
      currentYear++;
    }
    this.monthList = [];
    var num = 1;
    while (num < 13) {
      this.monthList.push({
        id: num,
        text: num
      })
      num++;
    }

    this.saveCardForm = this.formbuilder.group({
      cardnumber: ['', [Validators.required, Validators.minLength(17), Validators.maxLength(23)]],
      month: ['', [Validators.required]],
      year: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
    })

    this.saveCardForm2 = this.formbuilder.group({
      promocode: ['', [Validators.required]],
    })
  }

  onSubmitClick() {
    this.submitted = true;
    if (this.saveCardForm.invalid) {
      return;
    }
    this.submitting = true;

    (<any>window).Stripe.card.createToken({
      number: this.saveCardForm.value.cardnumber,
      exp_month: this.saveCardForm.value.month,
      exp_year: this.saveCardForm.value.year,
      cvc: this.saveCardForm.value.cvv,
    }, (status: number, response: any) => {


      this.submitted = false;
      if (status === 200) {
        // this.message = `Success! Card token ${response.card.id}.`;
        var apidata = {
          stripetoken: response.id
        }

        this.userService.storecard(apidata).subscribe(data => {
          if (data["status"]) { //when cards is stored successfully
            this.getUpdatedSubscription();
            this.toastr.success({ msg: data['message'] })
            this.getSavedCardDetails();
            this.submitting = false;
          } else {
            this.submitting = false;
            this.toastr.error({ msg: data['message'] })
            // show toaster message when if something went wrong
          }
        }, error => {
          this.toastr.error({ msg: 'Something went wrong, please try again' })
          this.submitting = false;
        });
      } else {
        this.toastr.error({ msg: response.error.message });
        this.submitting = false;
      }
    });
  }

  applyPromoCode() {
    this.submitted2 = true;
    if (this.saveCardForm2.invalid) {
      return;
    }
    this.submitting = true;
    const uploadDatas = new FormData();
    uploadDatas.append('product_id', this.product_id);
    uploadDatas.append('promocode', this.saveCardForm2.value.promocode);
    this.userService.applyPromoCode(uploadDatas).subscribe(data => {
      this.f2['promocode'].reset();
      if (data["status"]) {
        this.submitted2 = false;
        this.submitting = false;
        this.apply_promoCodeStatus = data["status"];
        this.toastr.success({ msg: data["message"] })
        var publishList = document.getElementById("publish_Listing");
        publishList.classList.add("list-txt");
      } else {
        this.submitted2 = false;
        this.submitting = false;
        this.toastr.error({ msg: data["message"] })
        // document.getElementById("demo2").className = " newClass";
        // var publishList = document.getElementById("publish_Listing");
        // publishList.classList.add("list-txt");
      }
    }, error => {
      this.submitted2 = false;
      this.submitting = false;
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    });
  }

  ngOnInit() {
    this.loadStripe();
    if (this.savedCardDetails && this.savedCardDetails.cardno) {
      this.navigationSubState['payment'] = 'inactive';
    }
    if (this.savedCardDetails && this.savedCardDetails.cardno) {
      this.navigationCode['promocode'] = 'inactive';
    }
    $(document).ready(function () {
      $("#add_card_2").addClass("show");

      $("#card-btn-2").click(function () {
        $("#add_promocode_2").removeClass("show");
      });
      $("#promo-btn-2").click(function () {
        $("#add_card_2").removeClass("show");
      });
    });
    this.getSavedCardDetails() // if already card is stored check and show saved cards
    this.getUpdatedSubscription() // if already card is stored check and show saved cards
    // console.log("Saved Card Details");
    // console.log(this.savedCardDetails);
  }

  getUpdatedSubscription(){

    //const uploadData3 = new FormData();
    let uploadData3 = {};
    uploadData3['product_id'] = this.product_id;
    try{
      this.userService.getSubscription(uploadData3).subscribe(data=>{

          // console.log("Get Updated Subscription called!");
        if(data['status']) {
          if(data['data']) {
            this.selectedListngType = data["data"][0];
          }
        }
      });
      // console.log("in try");
    }
    catch(e){
      console.log(e);
      // console.log("in catch");

    }

    // console.log(this.selectedListngType);
  }

  getSavedCardDetails(){
    this.userService.getcarddetails().subscribe(data=>{

      this.savedCardDetails = data["data"];

        if(this.savedCardDetails?.length != 0 && !(this.savedCardDetails?.cardno == '' || this.savedCardDetails?.cardno == null)) {
          this.hideCardForm = false;
          this.hidePromoCode = false;
        } else {
          this.hideCardForm = true;
          this.hidePromoCode = false;
        }
    });
  }

  addPromoCode() {
    this.hidePromoCode = true;
    this.hideCardForm = false;
    this.saveCardForm.reset()
    this.saveCardForm2.reset()
  }

  addCardDetails() {
    this.hideCardForm = true
    this.hidePromoCode = false;
    this.saveCardForm.reset()
    this.saveCardForm2.reset()
  }
  cardNumType() {
    if (this.saveCardForm.value.cardnumber) {
      var _num = this.saveCardForm.value.cardnumber;
      _num = _num.replace(/ /g, '');
      if (_num.length > 0) {
        var a = _num.toString().replace(/\d{4}(?=.)/g, '$& ');
        this.saveCardForm.controls.cardnumber.setValue(a);
      }
    }
  }

  get f() { return this.saveCardForm.controls; }

  get f2() { return this.saveCardForm2.controls; }

  ngAfterViewInit() {
    this.canPublish() // final server validation check

    const style = { // styling the card
      hidePostalCode: true,
      base: {
        lineHeight: '24px',
        fontFamily: 'monospace',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        '::placeholder': {
          color: 'purple'
        }
      }
    };
    // this.card = elements.create('card' ,style);
    // this.card.mount(this.cardInfo.nativeElement);
    // this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() { // removing card details on component destroy
    // this.card.removeEventListener('change', this.cardHandler);
    // this.card.destroy();

    var myobj = document.getElementById("stripe-custom-form-script");
    myobj.remove();
  }


  numberOnly(event): boolean {
    this.cardNumType()
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  loadStripe() {
    this.getSubscriptionMessage();
    if (!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        // window['Stripe'].setPublishableKey('pk_test_51GuEqfI5XxL1byFo66kCuqXGnq6GTg9tPwEy5S29BAdmRrIG1Z0PSnTaM1DbGKgtPbQhzJj6m2EW8infZPeTvuFJ00lIz4CrnH');
        window['Stripe'].setPublishableKey('pk_live_51IbRPbDJFz7KSnz9cJwNUJiaCKjj8QTt9TOZdJ2xNAqLqWZKEGtnPGFQM2B2ktquTnKmtDVpjw61gh6uyIInnTUp00YxxMfr4i');
      }
      window.document.body.appendChild(s);
    }
  }

  getSubscriptionMessage() {
    const uploadData3 = new FormData();
    uploadData3.append('product_id', this.product_id);
    this.userService.getSubscription(uploadData3).subscribe(data => {
      if (data['status']) {
        if (data['data']) {
          this.subscription_message = data["data"][0].subscriptionmessage;
          this.subscription_type = data["data"][0].subscriptiontype;
        }
      }
    });
  }

  onChange({ error }) { // to catch the changes when entering card details
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm, type) { // on submit of card
    const { token, error } = await stripe.createToken(this.card);
    if (error) { // incase when stripe has some error while checkig cards details
      // console.log('Something is wrong:', error)
      this.toastr.error({ msg: error.message })
    } else {
      // after success of stripe card details verification
      const payload = { "status": type, "product_id": this.product_id, "token": token.id }
      this.addCard(payload)
    }
  }

  addCard(payload) {
    this.isNextLoading = true
    // debugger;
    this.userService.addCards(payload).subscribe(data => {
      if (data["status"]) { //when cards is stored successfully
        this.toastr.success({ msg: data['message'] })
        this.isNextLoading = false;
        this.hideafterEmailVarify = false;
        var currenturl = this.router.url;
        var updateurl = currenturl+'&publish_product=yes';
        window.location.href = updateurl;
        // this.cardAddedSuccessEvent.emit(true); // alerting the sell-aircraft.component about the payment success
      } else {
        this.isNextLoading = false;
        if (data["email_verification"] == false) {
          const dialogRef = this.dialog.open(ImageDialogComponent, {
            panelClass: 'cstm_dialog_panel',
            data: 'false' + '-' + this.product_id + '-' + 'mobile_ios'
          });
           dialogRef.afterClosed().subscribe(result => {
            // console.log(result, 'The dialog was closed');
            if(result == 'hidepublishButton') {
              this.hideafterEmailVarify = false;
              var currenturl = this.router.url;
              var updateurl = currenturl+'&verify_email=yes';
              window.location.href = updateurl;
            }
          });
        } else {
          // console.log(data['message']);
          // console.log(data);
          this.toastr.error({ msg: data['message'] })
        }


      }
    }, error => {
      this.toastr.error({ msg: 'Something went wrong, please try again' })
      this.isNextLoading = false;
    });

  }

  canPublish() {
    const payload = { 'product_id': this.product_id }
    this.userService.canPublish(payload).subscribe(data => {
      if (!data["status"]) {
        this.Vlist = data['data'];
        this.canPublishListing = false;
        // note => need to show pop message over here about the required details
      }
      else {
        this.Vlist = data['data'];
        this.canPublishListing = true;

      }
    });
  }

  onPublishCard() { // note => Just for test purpose kindly look into code , need to manager as per UI
    const payload = { "status": 'publish', "product_id": this.product_id, "popupType": 'mobile_ios' }
    this.addCard(payload);
  }

  onRemoveCard() {
    this.savedCardDetails = undefined
  }

  // gotoNextDirect() {
  //   this.router.navigate(['/user/my-aircrafts']);
  //   this.cardAddedSuccessEvent.emit(true)
  // }

  toggleNavigationSub(menuName: string, event) {
    event.preventDefault();
    this.navigationSubState[menuName] = (this.navigationSubState[menuName] === 'inactive' ? 'active' : 'inactive');
    this.navigationCode[menuName] = (this.navigationCode[menuName] === 'inactive' ? 'active' : 'inactive');



  }
  resetPaymentform(){
    this.saveCardForm.reset();
    this.hideCardForm = false;
  }
  backToAircraftList(){
    let currenturl = this.router.url;
    let updateurl = currenturl+'&back_to_aircraft_list=yes';
    window.location.href = updateurl;
  }
}
