<!-- <div class="showmsg_desktop" *ngIf="!mobile">
<a href="/login"> Desktop Version not available </a>
</div> -->
<div class="main-wrapper">
    <div class="container main-container">
        <!-- <div class="row flex-nowrap">
            <div class="col-lg-6 col-sm-6">
                <button class="header-btn">
                    <img src="assets/img/menu.svg" alt="">
                </button>
            </div>
            <div class="col-lg-6 text-right col-sm-6">
                <button class="header-btn">
                    <img src="assets/img/notification.svg" alt="">
                </button>
            </div>
        </div> -->
        <div class="steper-wrap">
            <div class="mt-4 text-center mb-3">
                <!-- <img src="assets/img/payment-step-img.svg" alt="" class="img-fluid"> -->
                <h4 class="plan_heading">Aircraft Info</h4>
            </div>
            <h1 class="mb-0">Please select the type of listing
                to get started</h1>
            <div class="row mob-row">
                <div class="col-lg-6 listing-column">
                    <a (click)="mobileselectPlan('free')">
                        <div class="steper-cololumn free-listing"
                            [ngClass]="selectType == 'free' ? 'activeselctbox' : ''">

                            <h3 class="text-center"><span class="list-heading-icon">
                                    <img src="assets/img/header-star.svg" alt="">
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                    <g id="Group_43405" data-name="Group 43405" transform="translate(-81 -267)">
                                        <circle id="Ellipse_7630" data-name="Ellipse 7630" cx="20" cy="20" r="20"
                                            transform="translate(81 267)" fill="#fff" />
                                        <g id="star" transform="translate(90 276)">
                                            <path id="Path_22027" data-name="Path 22027"
                                                d="M22.807,19.419q0-.507-.768-.631l-6.88-1-3.084-6.236q-.26-.562-.672-.562t-.672.562L7.648,17.788l-6.881,1Q0,18.912,0,19.419a1.044,1.044,0,0,0,.343.658l4.989,4.852L4.153,31.782a2.226,2.226,0,0,0-.027.274.808.808,0,0,0,.144.487.5.5,0,0,0,.432.2,1.163,1.163,0,0,0,.548-.164L11.4,29.343l6.154,3.235a1.109,1.109,0,0,0,.548.164.483.483,0,0,0,.418-.2.809.809,0,0,0,.144-.487,2.071,2.071,0,0,0-.014-.274l-1.179-6.853,4.975-4.852A.99.99,0,0,0,22.807,19.419Z"
                                                transform="translate(0 -10.99)" fill="#0bc9a5" />
                                        </g>
                                    </g>
                                </svg> -->
                                </span> Free Listing</h3>
                            <p class="list-price text-center">$0.00</p>
                            <ul class="list-unstyled price-list mt-4 mb-3">
                                <li><img src="assets/img/check-circle.svg" alt="">1 listing per account</li>
                                <li><img src="assets/img/check-circle.svg" alt="">60 day Listing</li>
                                <li><img src="assets/img/check-circle.svg" alt="">Upload upto 10 Photos</li>
                                <li><img src="assets/img/close-circle.svg" alt="">No Video</li>
                                <li><img src="assets/img/close-circle.svg" alt="">Premium placement in search results
                                </li>
                                <li><img src="assets/img/close-circle.svg" alt="">1 mentioned post per month on Ziofly
                                    social media platforms</li>
                                <!-- <li><img src="assets/img/check.svg" alt="">Account Executive to Manage
                                Your Account and Inventory</li> -->
                            </ul>
                            <div class="step-btn-wrap">
                                <button class="btn btn-dark">
                                    <span class="listcheck-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17"
                                            viewBox="0 0 12 9">
                                            <g id="Group_43377" data-name="Group 43377" transform="translate(-2 -4)">
                                                <path id="Path_57528" data-name="Path 57528"
                                                    d="M5.3,12.7a.967.967,0,0,0,1.4,0l7-7a.99.99,0,0,0-1.4-1.4L6,10.6,3.7,8.3a.967.967,0,0,0-1.4,0,.967.967,0,0,0,0,1.4Z" />
                                            </g>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-lg-6 listing-column">
                    <a (click)="mobileselectPlan('premium')">
                        <div class="steper-cololumn premium-listing"
                            [ngClass]="selectType == 'premium' ? 'activeselctbox' : ''">

                            <h3 class="text-center"><span class="list-heading-icon">
                                    <img src="assets/img/premium-list.svg" alt="">
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                    <g id="Group_43405" data-name="Group 43405" transform="translate(-81 -267)">
                                        <g id="Group_37808" data-name="Group 37808">
                                            <circle id="Ellipse_7630" data-name="Ellipse 7630" cx="20" cy="20" r="20"
                                                transform="translate(81 267)" fill="#fff" />
                                            <path id="Union_57" data-name="Union 57"
                                                d="M3.215,18.6a.587.587,0,0,1-.586-.587V16.594a.586.586,0,0,1,.586-.586H4.14l-.622-2.23H20.88l-.622,2.23h.9a.586.586,0,0,1,.586.586v1.423a.587.587,0,0,1-.586.587Zm.153-5.364L1.676,7.186a1.286,1.286,0,0,1-.358.046,1.324,1.324,0,0,1,0-2.648A1.33,1.33,0,0,1,2.648,5.915a1.316,1.316,0,0,1-.372.913L4.418,9v.005l2.093,2.12L8.332,8.1l3.014-5.021a1.639,1.639,0,0,1-.8-1.417,1.655,1.655,0,1,1,2.5,1.417L16.071,8.1v.008l1.813,3.02L19.98,9l2.139-2.174a1.293,1.293,0,0,1-.372-.913,1.325,1.325,0,1,1,1.32,1.317,1.18,1.18,0,0,1-.345-.046l-1.692,6.053Zm6.969-3.946L12.2,11.8l1.864-2.51L12.2,6.287Zm-5.38-.507V8.781L4.668,8.49,5.808,6.16a1.327,1.327,0,0,1,.563-2.524A1.336,1.336,0,0,1,7.7,4.966a1.306,1.306,0,0,1-.617,1.107l.927,1.508-.143.24L6.406,10.257ZM16.61,7.957V7.951l-.221-.367.927-1.506A1.294,1.294,0,0,1,16.7,4.966,1.325,1.325,0,1,1,18.587,6.16l1.142,2.327-1.74,1.767Z"
                                                transform="translate(88.801 277.699)" fill="#094c62" />
                                        </g>
                                    </g>
                                </svg> -->
                                </span> Premium Listing</h3>
                            <p class="list-price text-center">$29.00</p>
                            <ul class="list-unstyled price-list mt-4 mb-3">
                                <li><img src="assets/img/check-circle.svg" alt="">1 listing per account</li>
                                <li><img src="assets/img/check-circle.svg" alt="">Unlimited Photos</li>
                                <li><img src="assets/img/check-circle.svg" alt="">Videos</li>
                                <li><img src="assets/img/check-circle.svg" alt="">Ad Analytics</li>
                                <li><img src="assets/img/check-circle.svg" alt="">Featured on Home page</li>
                                <li><img src="assets/img/check-circle.svg" alt="">Premium placement in search results
                                </li>
                                <li><img src="assets/img/check-circle.svg" alt="">1 mentioned post per month on Ziofly
                                    social media platforms</li>
                            </ul>
                            <div class="step-btn-wrap">
                                <button class="btn btn-green">
                                    <span class="listcheck-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17"
                                            viewBox="0 0 12 9">
                                            <g id="Group_43377" data-name="Group 43377" transform="translate(-2 -4)">
                                                <path id="Path_57528" data-name="Path 57528"
                                                    d="M5.3,12.7a.967.967,0,0,0,1.4,0l7-7a.99.99,0,0,0-1.4-1.4L6,10.6,3.7,8.3a.967.967,0,0,0-1.4,0,.967.967,0,0,0,0,1.4Z" />
                                            </g>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </a>
                </div>
            </div>


        </div>
    </div>
</div>