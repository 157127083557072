import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SwalService } from 'src/app/services/swal.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ziofly-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

  datas: any;
  msg: any;
  id: any;
  show: boolean;
  token: any;
  product_id: any;
  mobileios:any;

  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    private swal: SwalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private UserService: UserService,
    private ToastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router) {

      let d = data.split("-");
      let status = d[0];
      let id = d[1];
      if(d[2]) {
        this.mobileios = d[2];
      }


      if(status == 'false'){
        this.show = false;
        this.product_id = id;
      }else{
        this.show = true;
        this.msg = status;
        this.id = id;
      }

  }

  ngOnInit(): void {
  }

  upgradePremium(){
    this.swal.confirm({title:'Are you sure you want to upgrade your subscription to premium?'}).then((result) => {
      if (result.isConfirmed) {
        const uploadData3 = new FormData();
        uploadData3.append('product_id', this.id);
        this.UserService.changeSubscription(uploadData3).subscribe(data=>{
          this.ToastrService.success({msg:data['message']});
          this.dialogRef.close();
        },
        error=>{
          this.ToastrService.error({msg:error.message});
        })
      }else if (result.isDenied) {
      }
    });
  }

  emailVerify(){

        this.UserService.sendemailverification({product_id: this.product_id})
        .subscribe(data=>{
            if(data['status']) {
              this.ToastrService.success({msg:data['message']});
              this.dialogRef.close();
              if(this.mobileios) {
                this.dialogRef.close('hidepublishButton');
              } else {
                this.router.navigate(['/user/my-aircrafts']);
              }
            }
          },
          error=>{
            this.ToastrService.error({msg:error.message});
          })
      //}else if (result.isDenied) {
      //}
    //});
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
