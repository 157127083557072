import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatch } from 'src/app/helper/must-match';
import { CustomValidators } from 'src/app/helper/strong-password';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { validations } from 'src/environments/validations';
import { variables } from 'src/environments/variables';

@Component({
  selector: 'ziofly-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  variables=variables;
  validations=validations;
  zForm: FormGroup;
  submitted = false;
  submitting;
  token;
  cmsData;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private cms: CmsService
  ) { 
    this.token = this.route.snapshot.paramMap.get("token");
    this.cms.getCmsData().subscribe(x=>{
      this.cmsData = x[0]
    })
  }
  get f() { return this.zForm.controls; }
  ngOnInit(): void {
    this.zForm = this.formBuilder.group({   
      usertoken: [this.token],  
      newpassword: ['', [Validators.required,         
        // CustomValidators.patternValidator(this.validations.def.password.hasNumber_regex, {
        //   hasNumber: true
        // }),        
        // CustomValidators.patternValidator(this.validations.def.password.hasCapitalCase_regex, {
        //   hasCapitalCase: true
        // }),        
        // CustomValidators.patternValidator(this.validations.def.password.hasSmallCase_regex, {
        //   hasSmallCase: true
        // }),        
        /*CustomValidators.patternValidator(this.validations.def.password.hasSpecialCharacters_regex, {
            hasSpecialCharacters: true
        }),*/
        Validators.minLength(this.validations.def.password.min),Validators.maxLength(this.validations.def.password.max)]], 
      password_confirmation: ['', [Validators.required]],
    },{
      validator: MustMatch('newpassword', 'password_confirmation')
    });
  }
  reset() {
    this.submitted=true;
    if(this.zForm.invalid) {
      return
    }
    this.submitting=true;
    this.userService.resetPassword(this.zForm.value).subscribe(data=>{
        if(data['status']) {
          this.submitting=false;
          this.toastr.success({msg:this.validations.messages.reset_password.success})
          this.router.navigate(['/login']);
        } else {
          this.submitting=false;
          this.toastr.error({msg:data['message']})  
          this.router.navigate(['/'])
        }
      }, 
      error=>{
        this.submitting=false;
        this.toastr.error({msg:error.message})      
        this.router.navigate(['/'])
      })
  }
}
