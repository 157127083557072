<div class="page-wrapper event-page-wrapper" id="page_wrapper">

	<div class="event-banner" *ngIf="cmsData?.aviation_events?.aviation_events_banner_image">
		<div class="container">
			<div class="row justify-content-center">
				<!-- <div class="col-12 col-lg-3">
					<div class="event-banner-content">
						<div class="about-us-banner-content-inner">
							<h3 class="title" [innerHTML]="cmsData?.aviation_events?.aviation_events_banner_heading">
							</h3>
						</div>
					</div>
				</div> -->
				<div class="col-12 col-md-12 col-lg-12 align-self-center"> 
					<div class="banner-img-wrap">
						<div class="about-us-banner-person-img-holder text-center">
							<img src="assets/img/aviation-banner-img.png" class="img-fluid">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="event-banner" *ngIf="!cmsData?.aviation_events?.aviation_events_banner_image">
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="event-banner-content">
						<div class="about-us-banner-content-inner">
							<h3 class="title" [innerHTML]="cmsData?.aviation_events?.aviation_events_banner_heading">
							</h3>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6"> </div>
			</div>
		</div>
	</div>

</div>
<!--<section class="section event-upcoming">
    <div class="container">
        <div class="row ">
            <div class="col-11" style="margin: 0px auto;">
                <div _ngcontent-vma-c105="" class="mc-card">
                    <div _ngcontent-vma-c105="" class="mc-card-body">
                      <div class="row" style="width: 100%;">
                          <div class="col-12 col-sm-6 col-lg-3"></div>
                          <div class="col-12 col-sm-6 col-lg-3"></div>
                          <div class="col-12 col-sm-6 col-lg-3"></div>
                          <div class="col-12 col-sm-6 col-lg-3">
                                <div class="filter-button-align"> 
                                    <a  href="" class="btn btn-ziofly-default active">
                                    <span _ngcontent-rry-c53="" _ngcontent-nvg-c50="" _ngcontent-egg-c59="">Apply Filter</span></a>
                                </div>
                          </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
</section>-->
<section class="section event-upcoming">
	<nav class=" mt-0 mb-0 mob-sec-breadcrumb" aria-label="breadcrumb">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<ol class="breadcrumb mt-0 py-0">
						<li class="breadcrumb-item"><a routerLink="/">Home</a></li>
						<li class="breadcrumb-item active" aria-current="page">Aviation Events</li>
					</ol>
				</div>
			</div>
		</div>
	</nav>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="align-center--section--title mob-sec-heading-hide">
					<h2 class="section--title">
						<b>Upcoming Events
						</b>
						<span class="animate-flight-svg">
							<i class="animate-flight-svg-border"></i>
							<i class="animate-flight-svg-icon icon-flight-right"></i>
						</span>
					</h2>
				</div>
			</div>

		</div>
		<div class="row about-us-why-choose-us-card event-row-bottom">

			<div class="col-12 col-sm-6 col-lg-4 detail-col-p"
				*ngFor="let item of newsEvent | paginate: config; let i = index">
				<div class="ap-card ng-tns-c115-0">
					<div class="event-card-body">
						<div class="ap-card-img-inner cursor-pointer ng-tns-c115-0">
							<div class="d-flex" style="justify-content: space-between;">
								<!--<a>
									<div class="free-event">Free</div>
								</a>-->
								<a class="ap-card-img-action ng-tns-c115-0 ng-star-inserted">
									<img src="assets/img/action-heart.png" class="ng-tns-c115-0">
								</a>
							</div>
							<a class="ng-tns-c115-0">
								<img class="ap-card-img-event ng-tns-c115-0 ng-star-inserted"
									src="{{variables.serverAssetUrl}}{{item?.image}}">
							</a>
						</div>
						<div class="inner-event-box d-flex flex-grow-1">
							<div class="date-event">
								<div class="date-event-inner">
									<span class="month-event">{{item?.event_start_date | dateTimeFormatFilter:'month'}}</span>
									<br> <span class="days-event">{{item?.event_start_date | dateTimeFormatFilter:'day'}}</span>
								</div>
							</div>
							<div class="event-card-data ng-tns-c115-0 flex-grow-1">
								<h4 class="cursor-pointer ng-tns-c115-0" tabindex="0">{{item?.title}}</h4>
								<p class="ap-desc ng-tns-c115-0">{{item?.address}}</p>
								<div class="d-flex more-blogbtn" style="justify-content: space-between;">
									<div>
										<a (click)="openModalForm(item)" class="btn btn-ziofly-default active">
											<span>View Details</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section>
<div>
	<div class="" *ngIf="newsEvent?.length>0">
		<pagination-controls (pageChange)="pageChange($event)" previousLabel="" nextLabel="" class="zf-pagination">
		</pagination-controls>
	</div>
</div>
<!-- Modal -->

<div class="modal-form-modal event-dialog" [class.open]="isModalFormView">
	<div class="modal-form-box">
		<button class="dialog-close" (click)="closeModalForm()">
			<img src="assets/img/close.svg" alt="" class="img-fluid"></button>
		<div class="event-modal-img-wrap">
			<img src="{{variables.serverAssetUrl}}{{eventDetail?.image}}" alt="" class="img-fluid">
		</div>
		<div class="event-dialog-content">
			<h3 class="text-black mb-3">{{eventDetail?.title}}</h3>
			<p [innerHtml]="eventDetail?.description">
			</p>
			<div class="row">
				<div class="col-lg-6 mb-4 mb-md-4 mb-lg-0">
					<div class="dialog-content-column">
						<h4>Event Details</h4>
						<ul class="list-unstyled mb-0 dialog-event-list">
							<li>
								<span><img src="assets/img/event-icon.svg" alt=""></span>
								<span>Event Type:</span> {{eventDetail?.event_type}}
							</li>
							<li>
								<span><img src="assets/img/calander-icon.svg" alt=""></span>
								<span>When:</span> {{eventDetail?.event_start_date | dateTimeFormatFilter: 'longDate'}},

								To {{eventDetail?.event_end_date | dateTimeFormatFilter: 'longDate'}},

							</li>
							<li>
								<span><img src="assets/img/clock.svg" alt=""></span>
								{{eventDetail?.event_start_time}}
								|
								{{eventDetail?.event_end_time}}
							</li>
							<li>
								<span><img src="assets/img/location-icon.svg" alt=""></span>
								<span>Where:</span> {{eventDetail?.address}}
							</li>
							<!--<li>
								<span><img src="assets/img/fee-icon.svg" alt=""></span>
								<span>Fee:</span> $50
							</li>-->
						</ul>
					</div>
				</div>
				<div class="col-lg-6 mb-4 mb-md-4 mb-lg-0">
					<div class="dialog-content-column">
						<h4>Contact Details</h4>
						<ul class="list-unstyled mb-0 dialog-event-list">
							<li>
								<span>Contact Name:</span> {{eventDetail?.contact_name}}
							</li>
							<li>
								<span>Phone Number:</span> {{eventDetail?.contact_phone_number}}
							</li>
							<li>
								<span>Email:</span> {{eventDetail?.contact_email}}
							</li>
							<li>
								<span>Website:</span> {{eventDetail?.contact_website}}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>