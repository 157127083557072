<div class="about-us-banner d-flex align-items-center section">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="about-us-banner-content top-mobile-heading">
                    <div class="about-us-banner-content-inner">
                        <h3 class="title">Contact Us</h3>

                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="about-us-banner-person">
                    <div class="about-us-banner-person-img-holder">
                        <img src="assets/img/contact-us-page.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="page-wrapper contact-us-page-wrapper">

    <div class="map-iframe-wrapper">
        <iframe class="map-iframe"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3321.1811934142465!2d-117.73989258479541!3d33.65246668071661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dce7fdfde27809%3A0x88fe0554f3847d73!2s18%20Technology%20Dr%20%23107%2C%20Irvine%2C%20CA%2092618%2C%20USA!5e0!3m2!1sen!2sin!4v1617794958417!5m2!1sen!2sin"
            loading="lazy"></iframe>
        <!-- <iframe class="map-iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7481.368246822882!2d-122.19304407909064!3d47.73224625778494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1614834970855!5m2!1sen!2sin"  loading="lazy"></iframe> -->
    </div>
    <nav class=" mt-0 mb-0 mob-sec-breadcrumb" aria-label="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <ol class="breadcrumb mt-0 pt-0 pb-0">
                        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                    </ol>
                </div>
            </div>
        </div>
    </nav>
    <div class="container contact-us-page-content">
        <div class="row">

            <div class="col-12 p-0">
                <div class="contact-us-banner">
                    <div class="contact-us-banner-row">
                        <div class="contact-us-banner-col contact-us-banner-col-map">
                            <div class="row align-items-center">
                                <div class="col-12 col-lg-4" *ngIf="cmsData?.contactus.contact_us_phone">
                                    <div class="contact-us-option-card">
                                        <div class="contact-us-option-icon">
                                            <i class="icon-call"></i>
                                        </div>
                                        <div class="contact-us-option-text" style="font-size: 15px;">
                                            <a
                                                href="tel:{{cmsData.contactus.contact_us_phone}}">{{cmsData.contactus.contact_us_phone}}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" *ngIf="cmsData?.contactus.contact_us_email">
                                    <div class="contact-us-option-card">
                                        <div class="contact-us-option-icon">
                                            <i class="icon-email"></i>
                                        </div>
                                        <div class="contact-us-option-text" style="font-size: 15px;">
                                            <a
                                                href="mailto:{{cmsData.contactus.contact_us_email}}">{{cmsData.contactus.contact_us_email}}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4" *ngIf="cmsData?.contactus.contact_us_address">
                                    <div class="contact-us-option-card">
                                        <div class="contact-us-option-icon">
                                            <i class="icon-location"></i>
                                        </div>
                                        <div class="contact-us-option-text" style="font-size: 15px;">
                                            <p>{{cmsData.contactus.contact_us_address}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-us-banner-col contact-us-banner-col-form">
                            <p class="subtitle text-left text-md-center text-lg-center">Get In Touch</p>
                            <!-- <h3 class="title text-left text-md-center text-lg-center">Consult Our Experts About Your
                                Plane For Free</h3> -->
                            <form [formGroup]="zForm" class="theme-bg-form" (ngSubmit)="submit()">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="zf-input-group"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div class="zf-input-label">Email</div>
                                            <div class="zf-input-control">
                                                <input type="search" class="form-control" formControlName="email"
                                                    maxlength="{{validations.def.email.max}}">
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">{{validations.messages.email.required}}
                                            </div>
                                            <div *ngIf="f.email.errors.pattern">{{validations.messages.email.invalid}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <div class="zf-input-group"
                                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                            <div class="zf-input-label">Name</div>
                                            <div class="zf-input-control">
                                                <input type="search" class="form-control" formControlName="name"
                                                    maxlength="{{validations.def.name.full.max}}">
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">
                                                {{validations.messages.full_name.required}}</div>
                                            <div *ngIf="f.name.errors.pattern">
                                                {{validations.messages.full_name.invalid}}</div>
                                            <div
                                                *ngIf="!f.name.errors.required && !f.name.errors.pattern && f.name.errors.trimError">
                                                {{validations.messages.full_name.invalid}}</div>
                                        </div>
                                    </div>
                                    <!--<div class="col-12 col-lg-6">
                                        <div class="zf-input-group"  [ngClass]="{ 'is-invalid': submitted && f.website.errors }">
                                            <div class="zf-input-label">Website Link</div>
                                            <div class="zf-input-control">
                                                <input type="search"   class="form-control" formControlName="website" maxlength="{{validations.def.website.max}}" >
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.website.errors" class="invalid-feedback">
                                            <div *ngIf="f.website.errors.required">{{validations.messages.website.required}}</div>
                                            <div *ngIf="f.website.errors.pattern">{{validations.messages.website.invalid}}</div>
                                        </div>
                                    </div>-->
                                    <div class="col-12 col-lg-6">
                                        <div class="zf-input-group"
                                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                            <div class="zf-input-label">Phone Number</div>
                                            <div class="zf-input-control">
                                                <input type="number" pattern="[0-9]*" class="form-control"
                                                    formControlName="phone" maxlength="{{validations.def.phone.max}}">
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">{{validations.messages.phone.required}}
                                            </div>
                                            <div *ngIf="f.phone.errors.pattern">{{validations.messages.phone.invalid}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="zf-input-group zf-textarea-group is-floating-label"
                                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                                            <div class="zf-input-label">Message</div>
                                            <div class="zf-input-control">
                                                <!-- <input type="search"  class="form-control" formControlName="message" maxlength="{{validations.def.message.max}}" >                                                 -->
                                                <textarea type="search" class="form-control" formControlName="message"
                                                    maxlength="{{validations.def.message.max}}" rows="5"></textarea>
                                            </div>
                                        </div>
                                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                            <div *ngIf="f.message.errors.required">
                                                {{validations.messages.message.required}}</div>
                                            <div *ngIf="f.message.errors.minlength || f.message.errors.trimError">
                                                {{validations.messages.message.invalid}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12 text-center text-md-left text-lg-left mt-3 mt-md-0 mt-lg-0">
                                        <span class="has-send-btn-graphics">
                                            <button [disabled]="submitting"
                                                class="btn btn-ziofly-default active min-width-200">
                                                <span *ngIf="!submitting">Send Message</span>
                                                <span *ngIf="submitting">
                                                    <span class="zf-btn-loader"></span>
                                                </span>
                                            </button>
                                            <img src="assets/img/contact-sent-enquiry.svg" alt="image"
                                                class="send-btn-graphics">
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>