<div class="container-fluid">
    <div class="row">
        <div class="col-12 p-0">
            <div class="page-banner">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h3 *ngIf="title" class="page-banner-title">{{title}}</h3>
                        </div>
                    </div>
                </div>
                <img *ngIf="image" class="page-banner-img"  [src]="image" alt="image" >
            </div>
        </div>
    </div>
</div>
