import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private toastr: ToastrService, private router: Router,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // if ([401, 403].indexOf(err.status) !== -1) {
            //     // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            //     if(err.error.error){
            //         this.toastr.error({msg:err.error.error})  
            //     }
            //     else{
            //         this.toastr.error({msg:'Unauthorized'}) 
            //     }
            //     this.auth.logout();
            //     this.router.navigate(['/']);
            //     location.reload();
            // }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }))
    }
}