<div class="main-wrapper pt-5">
  <div class="container main-container pb-5">
    <div class="steper-wrap">
      <div class="row">
        <div class="col-12">
          <div class="tabcontent_wrap">
            <h4 class="h3-add-aircarft mb-3">Please provide payment details to list your
              aircraft</h4>
            <div>
              <div class="card-text-charge mb-4">
                <h6 class="font-weight-bold text-black free-listing-msg ">60 Days Free Listing
                </h6>
                <p class="small text-black mb-0">Note: {{subscription_message}}</p>
              </div>
              <form [formGroup]="saveCardForm2" (submit)="applyPromoCode()">
                <div class="form-group promo-group mb-4">
                  <input type="text" class="form-control" formControlName="promocode" placeholder="Apply Promocode">
                  <button class="btn btn-dark" type="submit">Apply</button>
                </div>
              </form>
              <div class="save_card_wrap">
                <div class="row align-items-center justify-content-between card_heading_wrap">
                  <div class="col-6">
                    <h4 class="mb-0">Saved card</h4>
                  </div>
                  <div class="col-6 text-right">
                    <button class="btn btn-green" (click)="openModalFormAddCard()">Add card</button>
                  </div>
                </div>
                <div  >
                  <div *ngFor="let item of cardsDetail">
                  <div class="custom-control custom-checkbox mb-3 mt-4"  >
                    <input type="radio" class="custom-control-input"  id="{{item.id}}" (change)="changePaymentCard(item)" name="example" checked>
                    <label class="custom-control-label" for="{{item.id}}">
                      <div>
                        <div class="">
                          <h5 class="mb-1 flex-grow-1">bank debit card **** {{item.last4}}
                          </h5>
                          <p>{{userData.first_name}}</p>
                          <p>Expires {{item.exp_month}}/{{item.exp_year}}</p>
                          <div  class="d-flex align-items-center justify-content-between card_txt-1 mt-3" *ngIf="cardIdToCharge==item.id">
                           <form [formGroup]="cvcCheck">
                            <div class="card_txt-2">
                              <span>Enter CVV</span>
                              <input type="number" formControlName="cvc" class="form-control">
                              <!-- <input type="hidden"  formControlName="tokenId" [(ngModel)]="item.id"> -->
                            </div>
                          </form>
                            <button type="button" class="btn btn-primary cvv_btn" (click)="openModalForm()">What is
                              CVV?</button>
                          </div>
                        </div>
                      </div>
                    </label>

                    <button class="remove-card" (click)="deletePaymentCard(item.id)" >
                      <img src="./assets/img/delete-icon.svg" alt="Remove">
                    </button>
                  </div>
                </div>
                </div>
              </div>
              <div class="row mt-4 btn-wrap-row">
                <div class="col-6 pr-2">
                  <button class="btn btn-dark" (click)="gotoNextDirect()">Save Draft</button>
                </div>
                <div class="col-6 pl-2">
                  <button class="btn btn-green" (click)="submitCVC()">Publish Listing</button>
                  <!-- (click)="onPublishCard()" -->
                </div>
              </div>
            </div>
          </div>
          <!-- </ng-container> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Model for add card -->
<div class="modal modal-form-modal cvv_modal" [class.open]="isModalFormViewAddCard">
  <div  class="modal-dialog card-size" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close" (click)="closeModalAddCard()">
          <img src="assets/img/close-icon-dark.svg" alt="Close" class="img-fluid">
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-4">
            <img src="assets/img/cc-master.png" alt="MasterCard" class="img-fluid">
          </div>
          <div class="col-md-4">
            <img src="assets/img/cc-discover.png" alt="" class="img-fluid">
          </div>
          <div class="col-md-4">
            <img src="assets/img/cc-visa.png" alt="" class="img-fluid">
          </div>
        </div>
        <br>

        <p style="text-align: center;">Note:{{subscription_message}}
        </p>
        <br>
        <form [formGroup]="saveCardForm" (ngSubmit)="onSubmitClick()">
          <div >
          <div class="row">

            <div class="col-sm-12">
              <div class="zf-input-group" >
                <div class="zf-input-label">Card Number</div>
                <div class="zf-input-control">
                  <!-- <ng-select2 formControlName="month" [data]="monthList" [options]="dd_options"></ng-select2> -->
                  <input type="text " class="form-control" formControlName="cardnumber" [ngClass]="{ 'is-invalid': submitted && f.cardnumber.errors }" />
                  <div *ngIf="submitted && f.cardnumber.errors" class="invalid-feedback">
                    <div *ngIf="f.cardnumber.errors.required">Card Number is required</div>
                    <div *ngIf="f.cardnumber.errors.minlength || f.cardnumber.errors.maxlength">
                      Card Number should contain minimum 14 digit and maximum 19 digit
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="zf-input-group">
                <div class="zf-input-label">Month</div>
                <div class="zf-input-control">
                  <ng-select2 formControlName="month" [data]="monthList" [options]="dd_options" [ngClass]="{ 'is-invalid': submitted && f.month.errors }"></ng-select2>
                  <!-- <input type="text" formControlName="month" class="form-control"> -->
                  <div *ngIf="submitted && f.month.errors" class="invalid-feedback">
                    <div *ngIf="f.month.errors.required">Month is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="zf-input-group">
                <div class="zf-input-label">Year</div>
                <div class="zf-input-control">
                  <ng-select2 formControlName="year" [data]="yearList" [options]="dd_options" [ngClass]="{ 'is-invalid': submitted && f.year.errors }"></ng-select2>
                  <!-- <input type="text" class="form-control" formControlName="year" > -->
                  <div *ngIf="submitted && f.year.errors" class="invalid-feedback">
                    <div *ngIf="f.year.errors.required">Year is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="zf-input-group">
                <div class="zf-input-label">CVV</div>
                <div class="zf-input-control">
                  <input type="text" pattern="[0-9]*" class="form-control" formControlName="cvv" maxlength="4"  [ngClass]="{ 'is-invalid': submitted && f.cvv.errors }"
                    (keypress)="numberOnly($event)" />
                    <div *ngIf="submitted && f.cvv.errors" class="invalid-feedback">
                      <div *ngIf="f.cvv.errors.required">CVV is required</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <button type="submit" style="background-color: rgb(252, 201, 56); border-radius: 20px; border: none;"
                class="btn btn-ziofly-default default">
                <span>Add card</span>
              </button>
            </div>
            <div class="col">
              <button style="border-radius:25px; border: 0px;" type="button" (click)="closeModalAddCard()"
                class="btn btn-ziofly-default default">
                <span>Cancle</span>
              </button>
            </div>

          </div>
        </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal modal-form-modal cvv_modal" [class.open]="isModalFormView">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">What is the CVV Number</h5>
        <button type="button" class="close" (click)="closeModalForm()">
          <img src="assets/img/close-icon-dark.svg" alt="Close" class="img-fluid">
        </button>
      </div>
      <div class="modal-body">
        <p>
          The CVV number is the last three digits at the back of your card. For American Express Cards, the
          CVV is a 4-digit number on the front of the card.
        </p>
        <div>
          <img src="assets/img/cvv_img.jpg" alt="" class="img-fluid">
        </div>
      </div>
      <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
    </div>
  </div>
</div>
