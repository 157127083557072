import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { variables } from 'src/environments/variables';

@Component({
  selector: 'ziofly-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  userData; 
  
  // profile_img = variables.blank_img;
  profile_img = 'assets/img/dummy-user.png';
  constructor(private userService: UserService) { }
  variables = variables;
  ngOnInit(): void {    
    this.userService.getUserData().subscribe(data=>{
      this.userData = data;
      setTimeout(() => {
        if(this.userData.avatar) {
          this.profile_img = variables.serverAssetUrl+this.userData.avatar
        } else {
          // this.profile_img = variables.no_img_path
        }  
      }, 200);
    })

    this.userService.getUserDetails().subscribe(data=>{
      this.userData = data['data'];
      setTimeout(() => {
        if(this.userData.avatar) {
          this.profile_img = variables.serverAssetUrl+this.userData.avatar
        } else {
          // this.profile_img = variables.no_img_path
        }  
      }, 200);
    })
    
  }

}
