import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { endpoints } from 'src/environments/endpoint';
@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private dataSource = new BehaviorSubject({});
  data = this.dataSource.asObservable();
  constructor(private http: HttpClient) { }
  setCmsData(val: object) {
    this.dataSource.next(val);
  }
  getCmsData() {
    return this.data;
  }
  getCmsDataFromServer(){
    return this.http.get(endpoints.cms)
  }
  getCountries(){
    return this.http.get(endpoints.countries)
  }
  getStates(data){
    return this.http.post(endpoints.states, data)
  }
  getCities(data){
    return this.http.post(endpoints.cities, data)
  }
  getCategories(){
    return this.http.get(endpoints.categories)
  }
  sendEnquiry(data) {
    return this.http.post(endpoints.contactus, data)
  }


  getProducts(data){
    return this.http.post(endpoints.baseUrl+'/optimized/getproducts', data);
  }
  getProductFilter(data){
    return this.http.post(endpoints.baseUrl+'/optimized/productfilters', data);
  }
  similarProductList(data){
    return this.http.post(endpoints.baseUrl+'/optimized/similarproductlist', data);
  }

  productSendenquiry(data){
    return this.http.post(endpoints.baseUrl+'/optimized/sendenquiry', data);
  }

  aircraftviews(data){
    return this.http.post(endpoints.baseUrl+'/optimized/aircraftviews', data);
  }
  aircraftviewed(data){
    return this.http.post(endpoints.baseUrl+'/optimized/aircraftviewed', data);
  }
  public getIPAddress(){
    return this.http.get("http://api.ipify.org/?format=json");
  }

  getTermandCondition(){
    return this.http.get(endpoints.baseUrl+'/termandcondition');
  }
  getPrivacyPolicy(){
    return this.http.get(endpoints.baseUrl+'/privacypolicy');
  }


  /*-------------------- get meta tags and meta titles --------------*/

  getMetaTags(){
    return this.http.get(endpoints.baseUrl+'/seo');
  }

}
