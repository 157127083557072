import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';

@Component({
  selector: 'ziofly-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  pagedata:any;
  pageLoading = false;
  constructor( private cms: CmsService) {
    this.getPageData()
  }


  ngOnInit(): void {
  }
  getPageData(){
    this.pageLoading = true;
    this.cms.getPrivacyPolicy().subscribe(data=>{
      this.pagedata = data['data'];
      // console.log(this.pagedata)
      this.pageLoading = false;
    })
  }
}
