import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'ziofly-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  scrollconfig;
  constructor() { }

  ngOnInit(): void {
    document.getElementsByTagName('html')[0].classList.add('disable-scroll')
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('html')[0].classList.remove('disable-scroll')
  }

}
