<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="auth-card">                
                <div class="auth-card-logo">
                    <a routerLink="/"><img src="{{variables.logo_path}}" alt="Ziofly Logo"></a>
                </div>
                <div class="auth-card-title">
                    Welcome to Ziofly
                </div>
                <div class="row">
                    <div class="col-12">
                        <button [disabled]="verifying" class="btn btn-ziofly-default active block">
                            <span *ngIf="!verifying">Please wait</span>
                            <span *ngIf="verifying">
                                <span class="zf-btn-loader"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>