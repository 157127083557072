// const web = 'http://uat.ziofly.com/admin';
// const web = 'https://ziofly.com/admin';
// const web = 'http://ziofly.unduitwireless.com/admin';
// const web = 'http://dev.ziofly.com/admin';

// const web = 'http://uat.ziofly.com/admin';
const web = 'https://ziofly.com/admin';

export const variables = {
  app : 'Ziofly',
  logo_path: 'assets/img/logo.svg',
  no_img_path: 'assets/img/no_image.jpg',
  blank_img: 'assets/img/transparent_pixel.png',
  apiUrl: web+'/api',
  serverAssetUrl : web+'/storage/app/',
  serverAssetThumbUrl : web+'/storage/app/'+'thumb/'
  //serverAssetThumbUrl : web+'/storage/app/'+'thumb/'
};
