import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/common/loader/loader.service';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { validations } from 'src/environments/validations';
import { variables } from 'src/environments/variables';

declare function initInputs();
declare var stripe: any;
declare var elements : any;
declare var $ : any;
@Component({
  selector: 'ziofly-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  savedCardDetails:any;
  submitting = false;
  submitted = false;
  submitted2 = false;
  saveCardForm : FormGroup;
  saveCardForm2 : FormGroup;

  dd_options = {
    minimumResultsForSearch: -1,
  };
  monthList:any;
  yearList:any;
  sub_msg: any;
  @Input() count: any;
  Product_id: any;
  subscription_type: any;
  apply_promoCodeStatus: any;

  constructor(
    private userService: UserService,
    private formbuilder: FormBuilder,
    private cms: CmsService,
    private loader: LoaderService,
    private toastr: ToastrService,

  ) {
    this.getSavedCardDetails();
    var currentYear = new Date().getFullYear();
    var maxYear = currentYear+51;
    this.yearList = [];
    while (currentYear<maxYear) {
      this.yearList.push({
        id: currentYear,
        text: currentYear
      })
      currentYear++;
    }
    this.monthList = [];
    var num = 1;
    while (num<13) {
      this.monthList.push({
        id: num,
        text: num
      })
      num++;
    }

    this.saveCardForm = this.formbuilder.group({
      cardnumber: ['', [Validators.required, Validators.minLength(17), Validators.maxLength(23)]],
      month: ['', [Validators.required]],
      year: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
    })

    this.saveCardForm2 = this.formbuilder.group({
      promocode: ['', [Validators.required]],
    })
  }

  cardNumType(){
    if(this.saveCardForm.value.cardnumber){
      var _num = this.saveCardForm.value.cardnumber;
      _num = _num.replace(/ /g,'');
      if (_num.length > 0) {
        var a = _num.toString().replace(/\d{4}(?=.)/g, '$& ');
          this.saveCardForm.controls.cardnumber.setValue(a);
      }
    }
  }

  get f() { return this.saveCardForm.controls; }

  get f2() { return this.saveCardForm2.controls; }

  onSubmitClick(){
    this.submitted = true;
    if(this.saveCardForm.invalid){
      return;
    }
    this.submitting = true;

    (<any>window).Stripe.card.createToken({
      number: this.saveCardForm.value.cardnumber,
      exp_month:this.saveCardForm.value.month,
      exp_year:  this.saveCardForm.value.year,
      cvc: this.saveCardForm.value.cvv,
    }, (status: number, response: any) => {
      this.submitted = false;
      if (status === 200) {
        // this.message = `Success! Card token ${response.card.id}.`;
        var  apidata = {
          stripetoken: response.id
        }
        this.userService.storecard(apidata).subscribe(data=>{
          if(data["status"]){ //when cards is stored successfully
            this.toastr.success({msg:data['message']})
            this.getSavedCardDetails()
            this.submitting = false;
            this.saveCardForm.reset();
            $('.add-card-card-form .zf-input-group.focused').removeClass('focused');
            setTimeout(() => {
              initInputs();
            }, 500);
            this.submitted = false;
          }else{
            this.submitting = false
            this.toastr.error({msg:data['message']})
            // show toaster message when if something went wrong
          }
        },error=>{
          this.toastr.error({msg:'Something went wrong, please try again'})
          this.submitting = false;
        });
      } else {
        this.toastr.error({msg:response.error.message});
        this.submitting = false;
      }
    });
  }


  ngOnInit(): void {
    this.loadStripe();
    $("#promo-btn").click(function () {
      $("#add_card").removeClass("show");
    });
    $("#card-btn").click(function () {
      $("#add_promocode").removeClass("show");
    });
    this.Product_id = this.userService.getProductId();
    this.getSubscriptionMessage(this.Product_id);
  }

  applyPromoCode() {
    this.submitted2 = true;
    if (this.saveCardForm2.invalid) {
      return;
    }

    this.submitting = true;
    if(this.Product_id){
      const uploadDatas = new FormData();
      uploadDatas.append('product_id', this.Product_id);
      uploadDatas.append('promocode', this.saveCardForm2.value.promocode);
      this.userService.applyPromoCode(uploadDatas).subscribe(data => {
        this.f2['promocode'].reset();
        if (data["status"]) {
          this.submitting = false;
          this.submitted2 = false;
          this.apply_promoCodeStatus = data["status"];
          this.toastr.success({ msg: data["message"] })
        } else {
          this.submitting = false;
          this.submitted2 = false;
          this.toastr.error({ msg: data["message"] })
        }
      }, error => {
        this.submitting = false;
        this.submitted2 = false;
        this.toastr.error({ msg: 'Something went wrong, please try again' })
      });
    }else{
      this.submitting = false;
    }

  }

  ngOnDestroy(){
    var myobj = document.getElementById("stripe-custom-form-script");
    myobj.remove();
  }

  numberOnly(event): boolean {
    this.cardNumType()
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getSavedCardDetails(){
    this.userService.getcarddetails().subscribe(data=>{
      if(data['status']){
        this.savedCardDetails = data["data"]
        // console.log(this.savedCardDetails);
      }
    });
  }

  loadStripe() {
    if(!window.document.getElementById('stripe-custom-form-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-custom-form-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        // window['Stripe'].setPublishableKey('pk_test_51GuEqfI5XxL1byFo66kCuqXGnq6GTg9tPwEy5S29BAdmRrIG1Z0PSnTaM1DbGKgtPbQhzJj6m2EW8infZPeTvuFJ00lIz4CrnH');
        window['Stripe'].setPublishableKey('pk_live_51IbRPbDJFz7KSnz9cJwNUJiaCKjj8QTt9TOZdJ2xNAqLqWZKEGtnPGFQM2B2ktquTnKmtDVpjw61gh6uyIInnTUp00YxxMfr4i');
      }
      window.document.body.appendChild(s);
    }
  }

  getSubscriptionMessage(Product_id){
    if(Product_id){
      const uploadData3 = new FormData();
      uploadData3.append('product_id', Product_id);
      this.userService.getSubscription(uploadData3).subscribe(data => {
        if (data['status']) {
          if (data['data']) {
            this.subscription_type = data["data"][0].subscriptiontype;
          }
        }
      });
    }
  }

}
