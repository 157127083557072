import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoint';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public _zf_tokenSubject: BehaviorSubject<any>;
  public _zf_token: Observable<any>;

  constructor(private http: HttpClient) {
    this._zf_tokenSubject = new BehaviorSubject<any>(localStorage.getItem('_zf_token'));
    this._zf_token = this._zf_tokenSubject.asObservable();
  }

  public get _zf_tokenValue(): any {
    return this._zf_tokenSubject.value;
  }

  login(data, type) {
    if(type == ''){
      return this.http.post<any>(endpoints.auth.login, data)
        .pipe(map(user => {
          if(user.status) {
            if(user.data) {
              var temp_zf_token = user.data
              temp_zf_token = temp_zf_token.replace(/['"]+/g, '');
              localStorage.setItem('_zf_token', temp_zf_token);
              this._zf_tokenSubject.next(temp_zf_token);
            }
          }
            return user;
        }));
    }else{
      return this.http.post<any>(endpoints.auth.signup, data)
      .pipe(map(user => {
        if(user.status) {
          if(user.data) {
            var temp_zf_token = user.token
            temp_zf_token = temp_zf_token.replace(/['"]+/g, '');
            localStorage.setItem('_zf_token', temp_zf_token);
            this._zf_tokenSubject.next(temp_zf_token);
          }
        }
          return user;
      }));
    }
  }

  logout() {
    localStorage.removeItem('_zf_token');
    this._zf_tokenSubject.next(null);
  }
}
