import { Component, OnInit } from '@angular/core';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { variables } from 'src/environments/variables';
import { validations } from 'src/environments/validations';

@Component({
  selector: 'ziofly-favorite-product',
  templateUrl: './favorite-product.component.html',
  styleUrls: ['./favorite-product.component.scss']
})
export class FavoriteProductComponent implements OnInit {
  productList:any = [];
  productLoading = false;
  variables = variables;
  limitList= ['12','24','36',];
  limitListShowable = [];
  public offset = 0;
  public config;

  validations=validations;
  enquiryForm : FormGroup;
  isModalFormView = false;
  submitted = false;
  submitting = false;
  selectedProduct: any;

  constructor(
    private formBuilder: FormBuilder,
    private cms: CmsService,
    private toastr: ToastrService,
    public UserService : UserService
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: 8,
      totalItems: 0,
      query: ''
    };
    this.getProducts();
  }
  changePhone(){
    setTimeout(() => {
      var _phone = this.enquiryForm.value.phone_copy;
      _phone = _phone.replace(/[^A-Z0-9]/ig, "_");
      this.enquiryForm.controls.phone.setValue(_phone);
    }, 200);
  }
  ngOnInit(): void {
    this.enquiryForm = this.formBuilder.group({
      name : ['', [Validators.required, Validators.pattern(validations.def.name.regex)]],
      email : ['', [Validators.required, Validators.email]],
      phone : ['', [Validators.required, Validators.pattern(this.validations.def.phone.regex)]],
      phone_copy : [''],
      message : ["I am interested in product. Is it still up for the sale? Please reach out with my email or phone number", Validators.required],
      notify_for_similar_listing : [''],
      notify_offers_giveaways_newsletters : [''],
      product_id : [''],
      seller_id : [''],
    })
  }
  getProducts(){
    this.productLoading = true;
    var apidata = {
      offset: this.offset,
      limit: this.config.itemsPerPage,
    }
    this.UserService.getfavourite(apidata).subscribe(data=>{
      // console.log(data['data']);
      this.productList = data['data'];
      this.config.totalItems = data['totalproducts'];
      // if(this.config.totalItems==undefined){
      //   this.config.totalItems = 0;
      // }
      // console.log(this.productList)
      // setTimeout(() => {
      //   this.productLoading = false;
      // }, 1000);
      this.productLoading = false;
    })
  }
  listLimitChange(e){
    this.config.itemsPerPage = e;
    this.getProducts();
  }
  pageChange(newPage: number) {
    // debugger;
    this.config.currentPage = newPage;
    this.offset = (newPage-1)*this.config.itemsPerPage;
    this.getProducts();
  }
  isVideo(path){
    const images = ["jpg", "gif", "png"]
    const videos = ["mp4", "3gp", "ogg"]
    const extension = path.split(".")[1]
    if (videos.includes(extension)) {
      return true;
    }
    else{
      return false;
    }
  }
  addToUnfav(id){
    var _token = localStorage.getItem('_zf_token');
    if(_token){
      var apidata = {
        product_id : id
      }

      this.productList = this.productList.filter(item=>item.id !=id );

      this.UserService.unFavourite(apidata).subscribe(data=>{
        // console.log(data)
        if(data['status']){
          this.toastr.success({msg: data['message']});
          this.getProducts()
        }
        else{
          this.toastr.error({msg: data['message']});
        }
      },error=>{
        this.toastr.error({msg:'Something went wrong'})
      })
    }
    else{
      this.toastr.error({msg: 'Please login to mark the product as favorite.'})
    }
  }

  openModalForm(product){
    this.selectedProduct = product;
    this.isModalFormView = true;
    const body = document.getElementsByTagName('html')[0];
    body.classList.add('overflow-hidden');
  }
  closeModalForm(){
    this.enquiryForm.reset();
    this.submitting = false;
    this.submitted = false;
    this.isModalFormView = false;
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('overflow-hidden');
  }
  get f() { return this.enquiryForm.controls; }
  enquiryFormSubmit(){
    this.submitted = true;
    if(this.enquiryForm.invalid){
      return;
    }

    if(this.enquiryForm.value.notify_for_similar_listing){
      this.enquiryForm.controls.notify_for_similar_listing.setValue(1);
    }
    else{
      this.enquiryForm.controls.notify_for_similar_listing.setValue(0);
    }

    if(this.enquiryForm.value.notify_offers_giveaways_newsletters){
      this.enquiryForm.controls.notify_offers_giveaways_newsletters.setValue(1);
    }
    else{
      this.enquiryForm.controls.notify_offers_giveaways_newsletters.setValue(0);
    }

    this.enquiryForm.controls.product_id.setValue(this.selectedProduct.id);
    this.enquiryForm.controls.seller_id.setValue(this.selectedProduct.sellerid);
    this.submitting = true;
    this.cms.productSendenquiry(this.enquiryForm.value).subscribe(data=>{
      if(data['status']) {
        this.toastr.success({msg: data['message']}) ;
        this.enquiryForm.reset();
        this.closeModalForm();
        this.submitting = false;
        this.submitted = false;

      } else {
        if(data['data']){
          this.toastr.error({msg:data['data'][0]});
          this.submitting = false
        }
        else{
          this.toastr.error({msg:data['message']})
          this.submitting = false
        }
      }
    },error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})
    })

  }

}
