<header id="header">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="zf-navbar" >
                    <div class="zf-navbar-col zf-navbar-col-left">
                        <div class="zf-navbar-logo">
                            <a routerLink="/">
                                <img *ngIf="cmsData?.site.logo" src="{{variables.serverAssetUrl}}{{cmsData?.site.logo}}" alt="image" >
                            </a>
                        </div>
                    </div>
                    <div class="zf-navbar-col zf-navbar-col-center">
                        <div class="zf-nav-search-toggle" (click)="mobile_search=!mobile_search">
                            <a href="javascript:void(0)" class="btn btn-ziofly-default btn-ziofly-circle mr-2"><span><i class="icon-search fs-22"></i></span></a>
                        </div>
                        <div class="zf-nav-search-box" [ngClass]="mobile_search ? 'mobile_search_open' : ''">
                            <div class="zf-nav-search-col zf-nav-search-select">
                                <span class="text-app-primary">Aircraft</span>
                                <!-- <select>
                                    <option>Aircraft</option>
                                    <option>Airplane</option>
                                </select> -->
                            </div>
                            <div class="zf-nav-search-col zf-nav-search-input">
                                <div class="zf-nav-search-input-holder">
                                    <input type="search" (keyup.enter)="searchProductEnter()"  [(ngModel)]="searchProductHeader" id="search_product_header" placeholder="Search aircrafts...">
                                </div>
                            </div>
                            <div class="zf-nav-search-col zf-nav-search-button">
                                <a #btn_search_product_header (click)="searchProduct()" id="btn_search_product_header"><i class="icon-search"></i></a>
                            </div>
                            <div class="zf-nav-search-col zf-nav-close-button" *ngIf="mobile_search">
                                <a href="javascript:void(0)" (click)="mobile_search=!mobile_search"><i class="icon-close"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="zf-navbar-col zf-navbar-col-right">
                        <div class="zf-navbar-links">
                            <a href="javascript:void(0)" (click)="sellAircraft()" routerLink="/user/sell-aircraft" 
                            class="btn btn-ziofly-default active">
                                <span>Sell your aircraft</span>
                            </a>
                            <ng-container *ngIf="isUserLoggedIn">
                                <!-- <a routerLink="/user/notifications" class="btn btn-ziofly-transparent btn-ziofly-circle user-link">
                                    <span class="notification-icon on">
                                        <i class="icon-bell"></i>
                                        <i class="icon-circle"></i>
                                    </span>
                                </a>  -->
                                <span class="header-user-info-dd ml-4 mr-2">
                                    <span class="header-user-info-dd-upper">                                       
                                            <img *ngIf="profile_img" [src]="profile_img" alt="image" >
                                            <!-- <img *ngIf="!profile_img" src="assets/img/dummy-user.png"> -->
                                            <span>{{userData?.first_name | titlecase}}</span>
                                            <i class="icon-dd-arrow"></i>                                       
                                    </span>
                                    <span class="header-user-info-dd-lower">
                                        <div class="inner">
                                            <ul>
                                                <li><a routerLink="/user/profile">My Profile</a></li>
                                                <li><a href="javascript:void(0)" routerLink="/user/my-aircrafts" >My Aircrafts</a></li>
                                                <li><a href="javascript:void(0)" routerLink="/user/favorite-products">Favorite</a></li>
                                                <li><a (click)="paymentMethod()" style="cursor: pointer;">Payment Method</a></li>
                                                <li><a href="javascript:void(0)" (click)="logout()">Log Out</a></li>
                                            </ul>
                                        </div>
                                    </span>
                                </span>
                            </ng-container>
                            <ng-container *ngIf="!isUserLoggedIn">
                                <a routerLink="/login" class="btn btn-ziofly-transparent btn-ziofly-circle user-link"><i class="icon-user"></i></a>   
                            </ng-container>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-ziofly-default btn-ziofly-circle btn-ziofly-color-theme" (click)="navOpen($event)">
                            <span><i class="icon-menu"></i></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<div *ngIf="navbar_open" class="navbar-side-menu">
    <div class="navbar-side-menu-inner"  (clickOutside)="navClose($event)" [ngClass]="navbar_open ? 'on' : ''">
        <div class="navbar-side-menu-header">
            <a href="javascript:void(0)" class="btn btn-ziofly-default btn-ziofly-circle circle-outline" (click)="navClose($event)">
                <span><i class="icon-close"></i></span>
            </a>
        </div>
        <div class="navbar-side-menu-body">
            <perfect-scrollbar style="max-width: 100%; max-height: 100%;" [config]="scrollconfig">
                <ul>
                    <ng-container *ngIf="!isUserLoggedIn">
                        <li class="link-sm-only"><i class="icon-caret-right"></i><a  (click)="navClose($event)" routerLink="/login">Login/Signup</a></li> 
                    </ng-container>
                    <ng-container *ngIf="isUserLoggedIn">
                        <li class="link-sm-only"><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/user/profile">My Profile</a></li>
                        <!-- <li class="link-sm-only"><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/user/notifications">Notifications</a></li> -->
                        <li class="link-sm-only"><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/user/my-aircrafts">My Aircrafts</a></li>
                        <li class="link-sm-only"><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/user/favorite-products">Favorite</a></li>
                        <li class="link-sm-only"><i class="icon-caret-right"></i>
                            <a (click)="navClose($event)" routerLink="/user/payment-method" style="cursor: pointer;"
                            (click)="paymentMethod()">Payment Method</a>
                        </li>
                    </ng-container>
                    <li><i class="icon-caret-right"></i><a (click)="sellAircraft(); navClose($event);"
                        routerLink="/user/sell-aircraft">Sell your aircraft</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/products">Aircraft listings</a></li>
                    <!-- <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/">Engines</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/">Services</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/">Events</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/">Testimonials</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/">Help</a></li> -->
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/contact-us">Contact us</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/blog">Blog</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/news-articles">Aviation News & Articles</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/event">Aviation Event</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/about-us">About us</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/privacy-policy">Privacy policy</a></li>
                    <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/terms-and-conditions">Terms & Conditions</a></li>
                    <!-- <li><i class="icon-caret-right"></i><a (click)="navClose($event)" routerLink="/">Our Brands</a></li> -->
                    <li  *ngIf="isUserLoggedIn" class="link-sm-only"><i class="icon-caret-right"></i><a href="javascript:void(0)" (click)="logout();navClose($event)">Log Out</a></li>
        
                </ul>
              </perfect-scrollbar>
         
        </div>
        <div class="navbar-side-menu-footer"> 
            <p>Follow us</p>
            <ul>
                <li *ngIf="cmsData?.site?.facebook_link"><a target="_blank" href="{{cmsData?.site?.facebook_link}}"><i class="icon-facebook"></i></a></li>
                <li *ngIf="cmsData?.site?.linkedin_link"><a target="_blank" href="{{cmsData?.site?.linkedin_link}}"><i class="icon-linkedin"></i></a></li>
                <li *ngIf="cmsData?.site?.instagram_link"><a target="_blank" href="{{cmsData?.site?.instagram_link}}"><i class="icon-instagram"></i></a></li>
                <li *ngIf="cmsData?.site?.twitter_link"><a target="_blank" href="{{cmsData?.site?.twitter_link}}"><i class="icon-twitter"></i></a></li>
                <li *ngIf="cmsData?.site?.youtube_link"><a target="_blank" href="{{cmsData?.site?.youtube_link}}"><i class="icon-youtube"></i></a></li>
            </ul>
        </div>
    </div>
</div>