
<div class="signup-container forgot-password-container">
    <div class="container px-0">
        <div class="row">
            <div class="col-12">
                <div class="auth-card auth-password-card">                
                    <div class="auth-card-logo">
                        <a routerLink="/"><img *ngIf="cmsData?.site.logo" src="{{variables.serverAssetUrl}}{{cmsData?.site.logo}}" alt="image" ></a>
                    </div>
                    <div class="auth-card-title">
                        Forgot Password
                    </div>
                    <form [formGroup]="zForm" (ngSubmit)="forgot()">
                        <div class="auth-form">
                            <div class="row">
                                <div class="col-12">
                                    <div class="zf-input-group with-icon" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div class="zf-input-icon"><i class="icon-email"></i></div>
                                        <div class="zf-input-label">Enter your email address</div>
                                        <div class="zf-input-control">
                                            <input type="search" class="form-control" formControlName="email" maxlength="{{validations.def.email.max}}">
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">{{validations.messages.email.required}}</div>
                                        <div *ngIf="f.email.errors.pattern">{{validations.messages.email.invalid}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    
                                    <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active block">
                                        <span *ngIf="!submitting">Send Reset Link</span>
                                        <span *ngIf="submitting">
                                            <span class="zf-btn-loader"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-12">
                                    <p class="text-center mb-0  color-black">Back to <a routerLink="/login">Sign In</a></p>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
