import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CmsService } from 'src/app/services/cms.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { variables } from 'src/environments/variables';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, animate, transition,} from '@angular/animations';
import { validations } from 'src/environments/validations';
import GLightbox from 'glightbox';

declare var $;
@Component({
  selector: 'ziofly-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  animations: [
    trigger('toggleHeight', [
        state('inactive', style({
            height: '0',
            opacity: '0'
        })),
        state('active', style({
            height: '*',
            opacity: '1'
        })),
        transition('inactive => active', animate('200ms ease-in')),
        transition('active => inactive', animate('200ms ease-out'))
    ])
]
})
export class ProductDetailComponent implements OnInit {
  lightbox:any;
  ipAddress = '';
  productData:any = [];
  productList:any = [];
  productLoading = false;
  similerListingLoading = false;
  product_id = '';
  listing_id = '';
  imagecount = '';
  variables = variables;
  validations=validations;
  isMobile:boolean;
  navigationSubState: { [menu: string]: string} = {
    detail_1: 'inactive',
    detail_2: 'inactive',
    detail_3: 'inactive',
    detail_4: 'inactive',
    detail_5: 'inactive',
    detail_6: 'inactive',
    detail_7: 'inactive',
  };

  slideConfigone = {
    "autoplay": false,
    "draggable": false,
    "speed": 1000,
    "slidesToShow": 1,
    "slidesToScroll" : 1,
    "centerMode": false,
    "infinite": false,
    "centerPadding": '0px',
    "focusOnSelect": true,
    "asNavFor" :".pd-slider-thumb",
    "arrows": true,
    "responsive": [
      {
        breakpoint: 767,
        settings: {
          "arrows": true,
          "dots": false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          "arrows": true,
          "dots": false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          "arrows": true,
          "dots": false,
        }
      },
    ]
  };
  slideConfigthumb = {
    "autoplay": false,
    "draggable": true,
    "speed": 1000,
    "slidesToShow": 6,
    "slidesToScroll" : 1,
    // "centerMode": true,
    "infinite": false,
    "centerPadding": '10px',
    "focusOnSelect": true,
    "asNavFor" :".pd-slider-one",
    "arrows": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        }
      },
    ]
  };

  slideConfig_3 = {
    "autoplay": true,
    "draggable": false,
    "speed": 200,
    "slidesToShow": 6,
    "slidesToScroll" : 1,
    "infinite": true,
    "arrows": true,
    "appendArrows": '.slide__arrows',
    "prevArrow": '<div class="news__arrow news__arrow_dir_left"><span class="material-icons">west</span></div>',
    "nextArrow": '<div class="news__arrow news__arrow_dir_right ml-3"><span class="material-icons">east</span></div>',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  enquiryForm : FormGroup;
  contactForm : FormGroup;
  submitted = false;
  submitting = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private cms: CmsService,
    private toastr: ToastrService,
    public UserService : UserService
  ) {
    var editProductId = this.route.snapshot.paramMap.get("id");


    // console.log('this is edit product id',editProductId)
    if(editProductId){
    this.cms.getIPAddress().subscribe((res:any)=>{
      this.ipAddress=res.ip;
    });
     this.getProductDetail(editProductId);
    }
  }
  changeProduct(id){
    setTimeout(() => {
      var editProductId = this.route.snapshot.paramMap.get("id");
      // console.log("this is change Product id",editProductId)
      // console.log("this is from paramter id",id)
      if(editProductId){
       this.getProductDetail(editProductId);
      }
    }, 100);
  }
  toggleNavigationSub(menuName: string, event) {
    var curruntState = this.navigationSubState[menuName];
    this.closeAllDetailToggle()
    var el = event.target;
    const hasClass = el.classList.contains('open');
    $('.mdc-collapse-head a.open').removeClass('open');
    if(hasClass) {
      el.classList.remove('open');
    } else {
      el.classList.add('open');
    }
      event.preventDefault();
      // this.navigationSubState[menuName] = (this.navigationSubState[menuName] === 'inactive' ? 'active' : 'inactive');
      if((curruntState === 'inactive')){
        this.navigationSubState[menuName] = 'active';
      }


  }
  closeAllDetailToggle(){
    this.navigationSubState['detail_1'] = 'inactive',
    this.navigationSubState['detail_2'] = 'inactive',
    this.navigationSubState['detail_3'] = 'inactive',
    this.navigationSubState['detail_4'] = 'inactive',
    this.navigationSubState['detail_5'] = 'inactive',
    this.navigationSubState['detail_6'] = 'inactive'
    this.navigationSubState['detail_7'] = 'inactive'
  }
  changePhone(){
    setTimeout(() => {
      var _phone = this.enquiryForm.value.phone_copy;
      _phone = _phone.replace(/[^A-Z0-9]/ig, "_");
      this.enquiryForm.controls.phone.setValue(_phone);
    }, 200);

    setTimeout(() => {
      var _phone = this.contactForm.value.phone_copy;
      _phone = _phone.replace(/[^A-Z0-9]/ig, "_");
      this.contactForm.controls.phone.setValue(_phone);
    }, 200);
  }
  onResize(event){
    const screensize = event.target.innerWidth;
    this.isMobile = screensize < 768;
  }
  ngOnInit(): void {
    this.isMobile = window.innerWidth < 768;
    this.enquiryForm = this.formBuilder.group({
      name : ['', [Validators.required, Validators.pattern(validations.def.name.regex)]],
      email : ['', [Validators.required, Validators.email]],
      phone : ['', [Validators.required,  Validators.pattern(this.validations.def.phone.regex)]],
      phone_copy : [''],
      message : ["I am interested in product. Is it still up for the sale? Please reach out with my email or phone number", Validators.required],
      notify_for_similar_listing : [''],
      notify_offers_giveaways_newsletters : [''],
      product_id : [''],
      seller_id : [''],
    })

    this.contactForm = this.formBuilder.group({
      name : ['', [Validators.required, Validators.pattern(validations.def.name.regex)]],
      email : ['', [Validators.required, Validators.email]],
      phone : ['', [Validators.required,  Validators.pattern(this.validations.def.phone.regex)]],
      phone_copy : [''],
      message: ['', [Validators.required]],
      notify_for_similar_listing : [''],
      notify_offers_giveaways_newsletters : [''],
      product_id : [''],
      seller_id : [''],
    })
  }

  get f() { return this.enquiryForm.controls; }

  get f2() { return this.contactForm.controls; }

  enquiryFormSubmit(){
    this.submitted = true;
    if(this.enquiryForm.invalid){
      return;
    }

    if(this.enquiryForm.value.notify_for_similar_listing){
      this.enquiryForm.controls.notify_for_similar_listing.setValue(1);
    }
    else{
      this.enquiryForm.controls.notify_for_similar_listing.setValue(0);
    }

    if(this.enquiryForm.value.notify_offers_giveaways_newsletters){
      this.enquiryForm.controls.notify_offers_giveaways_newsletters.setValue(1);
    }
    else{
      this.enquiryForm.controls.notify_offers_giveaways_newsletters.setValue(0);
    }

    this.enquiryForm.controls.product_id.setValue(this.product_id);
    this.enquiryForm.controls.seller_id.setValue(this.productData.sellerid);
    this.submitting = true;
    this.cms.productSendenquiry(this.enquiryForm.value).subscribe(data=>{
      if(data['status']) {
        this.toastr.success({msg: data['message']});
        this.enquiryForm.reset();
        this.enquiryForm.get('phone_copy').setValue('');
        this.submitting = false;
        this.submitted = false;
      } else {
        if(data['data']){
          this.toastr.error({msg:data['data'][0]});
          this.submitting = false
        }
        else{
          this.toastr.error({msg:data['message']})
          this.submitting = false
        }
      }
    },error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})
    })
  }


  enquiryFormSubmits(){
    this.submitted = true;
    if(this.contactForm.invalid){
      return;
    }

    if(this.contactForm.value.notify_for_similar_listing){
      this.contactForm.controls.notify_for_similar_listing.setValue(1);
    }
    else{
      this.contactForm.controls.notify_for_similar_listing.setValue(0);
    }

    if(this.contactForm.value.notify_offers_giveaways_newsletters){
      this.contactForm.controls.notify_offers_giveaways_newsletters.setValue(1);
    }
    else{
      this.contactForm.controls.notify_offers_giveaways_newsletters.setValue(0);
    }

    this.contactForm.controls.product_id.setValue(this.product_id);
    this.contactForm.controls.seller_id.setValue(this.productData.sellerid);
    this.submitting = true;

    this.cms.sendEnquiry(this.contactForm.value).subscribe(data=>{
      if(data['status']) {
        this.toastr.success({msg: data['message']}) ;
        this.contactForm.reset();
        this.contactForm.get('phone_copy').setValue('');
        this.submitting = false;
        this.submitted = false;
      } else {
        if(data['data']){
          this.toastr.error({msg:data['data'][0]});
          this.submitting = false
        }
        else{
          this.toastr.error({msg:data['message']})
          this.submitting = false
        }
      }
    },error=>{
      this.submitting=false;
      this.toastr.error({msg:error.message})
    })
  }

  getProductDetail(editProductId){
    this.productLoading = true;
    var apidata = {
      listing_id : editProductId
    }
    this.cms.getProducts(apidata).subscribe(data=>{
      if(data['status']){
        this.productData = data['data'][0];
        this.listing_id = editProductId;
        this.product_id = this.productData.id;
        this.productLoading = false;
        this.productData.imagecount = this.productData.mediafiles.length;
        this.similarproductlist();
        this.aircraftviewsApi();
        setTimeout(() => {
          this.lightbox = GLightbox({
            selector: ".img_glightbox",
            // alwaysShowNavOnTouchDevices: true
          });
        }, 500);
      }
      else{
        this.toastr.error({msg: data['message']});
        this.router.navigate(['/products']);
        this.productLoading = false;
      }
    },error=>{
      this.toastr.error({msg:'Something went wrong, please try again'})
      this.router.navigate(['/products']);
      this.productLoading = false;
    })
  }

  similarproductlist(){

    this.similerListingLoading = true;
    var apidata = {
      product_id : this.product_id
    }
    this.cms.similarProductList(apidata).subscribe(data=>{
      if(data['status']){
        this.productList = [];
        this.productList = data['data'];
        // console.log('ProductList()', this.productList );
        this.similerListingLoading = false;
      }
      else{
        // this.toastr.error({msg: data['message']});
        // this.router.navigate(['/products']);
        this.similerListingLoading = false;
      }
    },error=>{
      // this.toastr.error({msg:'Something went wrong, please try again'})
      // this.router.navigate(['/products']);
      this.similerListingLoading = false;
    })
  }
  isVideo(path){
    const images = ["jpg", "gif", "png"]
    const videos = ["mp4", "3gp", "ogg", "quicktime", "qt"]
    const extension = path.split(".")[1]
    if (videos.includes(extension)) {
      return true;
    }
    else{
      return false;
    }
  }

  aircraftviewsApi(){
    var _token = localStorage.getItem('_zf_token');
    if(!_token){
      var apidata = {
        ip_address: this.ipAddress,
        product_id : this.product_id
      }
      this.cms.aircraftviews(apidata).subscribe(data=>{
        // console.log(data)
      },error=>{

      })
    }
    else{
      // aircraftviewed
      var apidata2 = {
        product_id : this.product_id,
        ip_address: this.ipAddress,
      }
      this.cms.aircraftviewed(apidata2).subscribe(data=>{
        // console.log(data)
      },error=>{

      })
    }

  }


  slickInit(e) {
  }

  breakpoint(e) {
  }

  afterChange(e) {
    // console.log(e.currentSlide);
    $('.pd-slider-thumb [data-slick-index="'+e.currentSlide+'"].pd-slider-thumb-slide').click();
    // this.currentSlide = e.currentSlide
    $("video").each(function() {
      $(this).get(0).pause();
  });
  }

  beforeChange(e) {
    // this.currentSlide = e.currentSlide
  }
  addToFav(id){
    var _token = localStorage.getItem('_zf_token');
    if(_token){
      var apidata = {
        product_id : id
      }
      this.productData.is_favourite = true;
      this.UserService.addtoFavourite(apidata).subscribe(data=>{
        // console.log(data)
        if(data['status']){
          // this.toastr.success({msg: data['message']});

        }
        else{
          // this.toastr.error({msg: data['message']});
        }
      },error=>{
        // this.toastr.error({msg:'Something went wrong, please try again'})
      })
    }
    else{
      this.toastr.error({msg: 'Please login to make product as favorite'})
    }
  }
  addToUnfav(id){
    var _token = localStorage.getItem('_zf_token');
    if(_token){
      var apidata = {
        product_id : id
      }
      this.productData.is_favourite = false;
      this.UserService.unFavourite(apidata).subscribe(data=>{
        // console.log(data)
        if(data['status']){
          // this.toastr.success({msg: data['message']});

        }
        else{
          // this.toastr.error({msg: data['message']});
        }
      },error=>{
        // this.toastr.error({msg:'Something went wrong, please try again'})
      })
    }
    else{
      this.toastr.error({msg: 'Please login to mark the product as favorite.'})
    }
  }
  zoomImage(){
    const body = document.getElementsByTagName('html')[0];
    body.classList.add('zoom-product-slider');
    $('.pd-slider-one').slick('resize');
  }
  zoomOutImage(){
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('zoom-product-slider');
  }
  ngOnDestroy(){
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('zoom-product-slider');
  }
}
