import { Injectable, Pipe, PipeTransform } from "@angular/core";


@Pipe({name: "dateTimeFormatFilter"})
@Injectable()
export class DateTimeFormatPipe implements PipeTransform {
transform(date: any, format: string): any {
    if (date) {
      if (date.toString().indexOf(' ') === -1) {
         date = date + ' 00:00:00';
      }
     const temp = date.toString().replace(' ', 'T');
     const dateobj = new Date(temp);
     if(format == 'month'){
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        return months[dateobj.getMonth()];
     }else if(format == 'day'){
        return dateobj.getDate();
     }else{
        const eventdate = dateobj.getDate();
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const eventmonth = months[dateobj.getMonth()];
        const year = dateobj.getFullYear();
        const finaldate = eventmonth+" "+eventdate+" "+year;
        return finaldate;
     }
    }
  }
}