<div class="signup-container">
    <div class="container px-0">
        <div class="auth-card auth-login-card">
            <div class="auth-card-logo">
                <a routerLink="/"><img *ngIf="cmsData?.site.logo"
                        src="{{variables.serverAssetUrl}}{{cmsData?.site.logo}}" alt="image"></a>
            </div>
            <div class="auth-card-title">
                Login into your account
            </div>
            <div class="auth-form">
                <form [formGroup]="zForm" (ngSubmit)="login()">
                    <div class="row">
                        <div class="col-12">
                            <div class="zf-input-group with-icon"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <div class="zf-input-icon"><i class="icon-email"></i></div>
                                <div class="zf-input-label">Enter your email address</div>
                                <div class="zf-input-control">
                                    <input type="search" class="form-control" formControlName="email"
                                        (keypress)="convertLowerCase($event)" maxlength="{{validations.def.email.max}}">
                                    <!-- <span *ngIf="hasDataInStorage" title="Delete saved logins" class="toggle-icon" (click)="deleteSavedCred()"><i class="icon-delete" ></i></span> -->
                                </div>
                            </div>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">{{validations.messages.email.required}}</div>
                                <div *ngIf="f.email.errors.pattern">{{validations.messages.email.invalid}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="zf-input-group with-icon"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                <div class="zf-input-icon"><i class="icon-password"></i></div>
                                <div class="zf-input-label">Enter your password</div>
                                <div class="zf-input-control">
                                    <input type="password" zioflyAppPassword class="form-control"
                                        formControlName="password" maxlength="{{validations.def.password.max}}">
                                </div>
                            </div>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">{{validations.messages.password.required}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1 mb-4">
                        <div class="col-12 col-sm-7">
                            <div>
                                <label class="zf-toggle-switch">
                                    <input type="checkbox" [attr.checked]="user_want_to_remember==true ? '' : null"
                                        (change)="toggleRemember($event)">
                                    <div class="zf-toggle-switch-box">
                                        <div class="zf-toggle-switch-thumb"></div>
                                    </div>
                                    <span>Remember Me</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-5 right-on-sm">
                            <a routerLink="/forgot-password">Forgot Password?</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active block">
                                <span *ngIf="!submitting">Sign In</span>
                                <span *ngIf="submitting">
                                    <span class="zf-btn-loader"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <!-- <div class="auth-divider">
                or Login with
            </div>
            <div class="auth-social-btns">
                <button class="btn btn-ziofly-social google">
                    <img src="assets/img/auth-icon-google.svg" alt="image" >
                    Google</button>
                <button class="btn btn-ziofly-social facebook">
                    <img src="assets/img/auth-icon-facebook.svg" alt="image" >
                    Facebook</button>
            </div> -->
            <div class="row mt-3">
                <div class="col-12">
                    <p class="text-center mb-0  color-black">Don't have an account? <a routerLink="/signup">Sign Up</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>