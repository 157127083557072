import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoaderService } from '../common/loader/loader.service';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private auth: AuthService,
        private userService: UserService,
        private loader: LoaderService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const _zf_token = this.auth._zf_tokenValue;
        if (_zf_token) { 
            return true;
        }
        this.router.navigate(['/login']);
        return false;
    }
}