<!-- <ziofly-banner image="assets/img/payment-method-banner.png" title="Payment Method"></ziofly-banner> -->
<ng-container *ngIf="false">
  <div *ngIf="false">
    <form  #checkout="ngForm" class="checkout">
      <!-- [hidden]="savedCardDetails" -->
      <div class="form-row">
        <div>
          <div style="text-align: left;">
            <img class="card-img">
          </div>
        </div>
        <div>
          <div id="card-info" #cardInfo></div>
        </div>
      </div>
      <button color="primary" class="btn btn-ziofly-default active mr-1" (click)="onSubmit(checkout,'draft')"
        type="submit">
        <span *ngIf="!isNextLoading">Add and Draft your listing </span>
        <span *ngIf="isNextLoading">
          <span class="zf-btn-loader"></span>
        </span>
      </button>
      <button [disabled]="!canPublishListing" color="primary" class="btn btn-ziofly-default active"
        (click)="onSubmit(checkout,'publish')" type="submit">
        <span> Add and Publish your listing </span>
      </button>
    </form>
  </div>

  <!-- <div *ngIf="savedCardDetails">
    <p>**** **** **** {{savedCardDetails.cardno}} <span (click)="onRemoveCard()" class='removeCard'>Remove Card
        <sup>*</sup></span> </p>
    <button color="primary" class="btn btn-ziofly-default active mr-2" (click)="gotoNextDirect()">
      <span> Draft your listing </span>
    </button>
    <div class="v-msgs-show" [class.isbefore]="Vlist.length>0">
      <ul class="vList" *ngIf="Vlist.length>0">
        <li *ngFor="let item of Vlist; let i = index">
          {{item}}
        </li>
      </ul>
      <button [disabled]="!canPublishListing" color="primary" class="btn btn-ziofly-default active"
        (click)="onPublishCard()" type="submit">
        <span class="mr-2" *ngIf="isNextLoading">
          <span class="zf-btn-loader"></span>
        </span>
        <span> Publish your listing </span>
      </button>
    </div>
  </div> -->
  <p class="free-charge-warn">For free subscription you will be charge $9.99/month on 61st day</p>
</ng-container>

<!-- ============ New Design =============  -->

<div class="mt-3">
  <div class="card-text-charge">
    <h6 class="font-weight-bold text-black free-listing-msg">60 Days Free Listing</h6>
    <h6 class="font-weight-bold text-black premium-listing-msg">Premium Listing</h6>
    <p class="small text-black mb-0">{{subscription_message}}</p>
  </div>
  <div class="">
    <h6 *ngIf="savedCardDetails?.cardno" class="">Saved card</h6>
    <div class="row">
      <div *ngIf="savedCardDetails?.cardno" class="col-auto col-lg-4 mb-3">
        <div class="card--card-design">
          <img class="card--card-img" src="assets/img/card-card-img.png">
          <p class="card--card-num">**** **** **** {{savedCardDetails.cardno}}</p>
          <div class="card--card-valid">
            <span class="small">VALID <br />THRU</span>
            <img src="assets/img/card-card-arrow.png">
            <span>{{savedCardDetails.exp_month}}/{{savedCardDetails.exp_year}}</span>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-4 desk-add-card-btn mb-3">
        <!-- <a class="card-add-btn" id="card-btn" [class.open]="navigationSubState['payment'] === 'active'"
          (click)="toggleNavigationSub('payment', $event)">
          <!--<i class="icon-close"></i>
            Add Card--
        </a> -->
        <a class="card-add-btn" id="card-btn-2" data-toggle="collapse" href="#add_card_2" role="button"
         aria-expanded="false" aria-controls="add_card_2">
          <!--<i class="icon-close"></i>
            Add Card-->
          Add Card
        </a>
      </div>
      <div class="col-6 col-lg-4 desk-add-card-btn promo-add-btn-wrap mb-3"
      [hidden]="subscription_type != 'free'">
        <!-- <a class="card-add-btn" id="promo-btn" [class.open]="navigationCode['promocode'] === 'active'"
          (click)="toggleNavigationSub('promocode', $event)">

            Add Promo Code
        </a> -->
        <a class="card-add-btn" id="promo-btn-2" data-toggle="collapse" href="#add_promocode_2"
        role="button" aria-expanded="false" aria-controls="add_promocode_2"
        >
            Use promo code
        </a>
      </div>
    </div>


    <!-- <p class="mb-0">{{savedCardDetails.brand}}  **** **** **** {{savedCardDetails.cardno}} <span class="px-1"></span>with Exp  {{savedCardDetails.exp_month}}/{{savedCardDetails.exp_year}} </p> -->
  </div>

    <!-- Add your card details. <a *ngIf="savedCardDetails?.cardno" class="payment-card-toggle-btn" [class.open]="navigationSubState['payment'] === 'active'" (click)="toggleNavigationSub('payment', $event)"><i class="icon-close"></i></a> -->
    <!-- <div class="add-card-card card-add mt-1 mb-4" [@toggleHeight]="navigationSubState.payment">
      <!--<p  class="small mb-2 pb-1">{{subscription_message}}dddddd</p--
      <div class="row">
        <div class="col-12 mb-2">
          <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.cardnumber.errors }">
            <div class="zf-input-label">Card Number</div>
            <div class="zf-input-control">
              <input type="text" class="form-control" formControlName="cardnumber" (keypress)="numberOnly($event)"
                (change)="cardNumType()" maxlength="23">
              <!--<input type="number" pattern="[0-9]*" class="form-control" formControlName="cardnumber"
                       maxlength="23">--
            </div>
          </div>
          <div *ngIf="submitted && f.cardnumber.errors" class="invalid-feedback">
            <div *ngIf="f.cardnumber.errors.required">Card Number is required</div>
            <div *ngIf="f.cardnumber.errors.minlength || f.cardnumber.errors.maxlength">
              Card Number should contain minimum 14 digit and maximum 19 digit
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="row cc-card-month-year">
            <div class="col-6 pr-0">
              <div class="zf-input-group cc-card-month" [ngClass]="{ 'is-invalid': submitted && f.month.errors }">
                <div class="zf-input-label">Month</div>
                <div class="zf-input-control">
                  <ng-select2 formControlName="month" [data]="monthList" [options]="dd_options"></ng-select2>
                </div>
              </div>
              <div *ngIf="submitted && f.month.errors" class="invalid-feedback">
                <div *ngIf="f.month.errors.required">Month is required</div>
              </div>
            </div>
            <div class="col-6 pl-0">
              <div class="zf-input-group cc-card-year" [ngClass]="{ 'is-invalid': submitted && f.year.errors }">
                <div class="zf-input-label">Year</div>
                <div class="zf-input-control">
                  <ng-select2 formControlName="year" [data]="yearList" [options]="dd_options"></ng-select2>
                </div>
              </div>
              <div *ngIf="submitted && f.year.errors" class="invalid-feedback">
                <div *ngIf="f.year.errors.required">Year is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.cvv.errors }">
            <div class="zf-input-label">Enter CVV</div>
            <div class="zf-input-control">
              <!--<input type="text" class="form-control" formControlName="cvv"
                      maxlength="3" (keypress)="numberOnly($event)">--
              <input type="text" pattern="[0-9]*" class="form-control" formControlName="cvv" maxlength="4"
                (keypress)="numberOnly($event)">
            </div>
          </div>
          <div *ngIf="submitted && f.cvv.errors" class="invalid-feedback">
            <div *ngIf="f.cvv.errors.required">CVV is required</div>
          </div>
        </div>
      </div>
      <div class="mb-3 responsive-btn-wrap">
        <!-- <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active min-width-125 mr-2" (click)="onSubmitClick()">
              <span *ngIf="!submitting"> Add and Draft your listing</span>
              <span *ngIf="submitting">
                  <span class="zf-btn-loader"></span>
              </span>
          </button>  --
        <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active min-width-125"
          (click)="onSubmitClick()">
          <span *ngIf="!submitting">Save this card</span>
          <span *ngIf="submitting">
            <span class="zf-btn-loader"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="add-card-card add-promo-code" [@toggleHeight]="navigationCode.promocode">
      <!--<p  class="small mb-2 pb-1">{{subscription_message}}dddddd</p--
      <div class="row">
        <div class="col-12 mb-2">
          <div class="zf-input-group">
            <div class="zf-input-label">Card Number</div>
            <div class="zf-input-control">
              <input type="text" class="form-control" formControlName="promocode">
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 responsive-btn-wrap">
        <!-- <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active min-width-125 mr-2" (click)="onSubmitClick()">
              <span *ngIf="!submitting"> Add and Draft your listing</span>
              <span *ngIf="submitting">
                  <span class="zf-btn-loader"></span>
              </span>
          </button>  --
        <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active min-width-125"
          (click)="onSubmitClick()">
          <span *ngIf="!submitting">Apply Promo Code</span>
          <span *ngIf="submitting">
            <span class="zf-btn-loader"></span>
          </span>
        </button>
      </div>
    </div> -->
    <div class="row" id="toggle_row">

      <div class="col-lg-12">
        <form class="form-min-height-170" [formGroup]="saveCardForm">
        <div class="collapse mb-4" id="add_card_2">
          <p *ngIf="savedCardDetails?.cardno" class="small mb-3">If you add a new card, it will be saved as the default payment
            method.</p>
          <div class="add-card-card card-add mt-1 mb-4">
            <div class="row">
              <div class="col-12 mb-2">
                <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.cardnumber.errors }">
                  <div class="zf-input-label">Card Number</div>
                  <div class="zf-input-control">
                    <input type="text" class="form-control" formControlName="cardnumber" (keypress)="numberOnly($event)"
                      (change)="cardNumType()" maxlength="23">
                  </div>
                </div>
                <div *ngIf="submitted && f.cardnumber.errors" class="invalid-feedback">
                  <div *ngIf="f.cardnumber.errors.required">Card Number is required</div>
                  <div *ngIf="f.cardnumber.errors.minlength || f.cardnumber.errors.maxlength">
                    Card Number should contain minimum 14 digit and maximum 19 digit
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row cc-card-month-year">
                  <div class="col-6 pr-0">
                    <div class="zf-input-group cc-card-month" [ngClass]="{ 'is-invalid': submitted && f.month.errors }">
                      <div class="zf-input-label">Month</div>
                      <div class="zf-input-control">
                        <ng-select2 formControlName="month" [data]="monthList" [options]="dd_options"></ng-select2>
                      </div>
                    </div>
                    <div *ngIf="submitted && f.month.errors" class="invalid-feedback">
                      <div *ngIf="f.month.errors.required">Month is required</div>
                    </div>
                  </div>
                  <div class="col-6 pl-0">
                    <div class="zf-input-group cc-card-year" [ngClass]="{ 'is-invalid': submitted && f.year.errors }">
                      <div class="zf-input-label">Year</div>
                      <div class="zf-input-control">
                        <ng-select2 formControlName="year" [data]="yearList" [options]="dd_options"></ng-select2>
                      </div>
                    </div>
                    <div *ngIf="submitted && f.year.errors" class="invalid-feedback">
                      <div *ngIf="f.year.errors.required">Year is required</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted && f.cvv.errors }">
                  <div class="zf-input-label">Enter CVV</div>
                  <div class="zf-input-control">
                    <input type="text" pattern="[0-9]*" class="form-control" formControlName="cvv" maxlength="4"
                      (keypress)="numberOnly($event)">
                  </div>
                </div>
                <div *ngIf="submitted && f.cvv.errors" class="invalid-feedback">
                  <div *ngIf="f.cvv.errors.required">CVV is required</div>
                </div>
              </div>
            </div>
            <div class="mb-3 responsive-btn-wrap">
              <button type="submit" [disabled]="submitting" class="btn btn-ziofly-default active min-width-125"
                (click)="onSubmitClick()">
                <span *ngIf="!submitting">Save</span>
                <span *ngIf="submitting">
                  <span class="zf-btn-loader"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        </form>
      </div>

      <div class="col-lg-12">
        <form class="form-min-height-170" [formGroup]="saveCardForm2">
        <div class="collapse mb-4" id="add_promocode_2">
          <div class="add-card-card add-promo-code">
            <div class="row">
              <div class="col-12 mb-2">
                <div class="zf-input-group" [ngClass]="{ 'is-invalid': submitted2 && f2.promocode.errors }">
                  <div class="zf-input-label">Promo Code</div>
                  <div class="zf-input-control">
                    <input type="text" class="form-control" formControlName="promocode">
                  </div>
                </div>
                <div *ngIf="submitted2 && f2.promocode.errors" class="invalid-feedback">
                  <div *ngIf="f2.promocode.errors.required && submitted2">Promo Code is required</div>
                </div>
              </div>
            </div>
            <div class="mb-3 responsive-btn-wrap">
              <button type="submit" [disabled]="submitting"
              class="btn btn-ziofly-default active min-width-125"
                (click)="applyPromoCode()">
                <span *ngIf="!submitting">Apply Promo Code</span>
                <span *ngIf="submitting">
                  <span class="zf-btn-loader"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
      </div>

    </div>





  <div class="mob-add-card-btn mt-3 mb-5">
    <a class="card-add-btn" [class.open]="navigationSubState['payment'] === 'active'"
      (click)="toggleNavigationSub('payment', $event)">
      <!-- <span class="material-icons">
        add
        </span>

      Add Card -->
    </a>
  </div>

  <div class="buttons-listing back-button-listing" *ngIf="!savedCardDetails?.cardno"
  [hidden]="apply_promoCodeStatus == true">
    <ng-container>
      <a [routerLink]="['/user/my-aircrafts']" color="primary" class="btn btn-ziofly-default active mr-2">
        <span>I will do it later</span>
      </a>
    </ng-container>
  </div>

  <div class="buttons-listing back-button-listing" [class.disable]="submitting"
  *ngIf="savedCardDetails?.cardno || apply_promoCodeStatus == true">
    <ng-container *ngIf="product_status==1 || product_status==0">
      <button color="primary" class="btn btn-ziofly-default active mr-2" (click)="gotoNextDirect()">
        <span>Save and Exit </span>
      </button>
    </ng-container>
    <ng-container *ngIf="!(product_status==1 || product_status==0)">
      <button color="primary" class="btn btn-ziofly-default active mr-2" (click)="gotoNextDirect()">
        <span> Draft your listing </span>
      </button>
      <div class="v-msgs-show" [class.isbefore]="Vlist.length>0">
        <ul class="vList" *ngIf="Vlist.length>0">
          <li *ngFor="let item of Vlist; let i = index">
            {{item}}
          </li>
        </ul>
        <button [disabled]="!canPublishListing" color="primary" class="btn btn-ziofly-default active"
          (click)="onPublishCard()" type="submit">
          <span class="mr-2" *ngIf="isNextLoading">
            <span class="zf-btn-loader"></span>
          </span>
          <span> Publish your listing </span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
