<div class="signup-container">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="auth-card">
                    <div class="auth-card-nav">
                        <a routerLink="/user/profile"
                            class="btn btn-sm btn-ziofly-default btn-ziofly-default-border"><span><i
                                    class="icon-arrow-right rotate-180 mr-2"></i> Back</span></a>
                    </div>
                    <div class="auth-card-logo">
                        <img src="/assets/img/change-password.svg">
                    </div>
                    <div class="auth-card-title">
                        Change password
                    </div>
                    <form [formGroup]="zForm" (ngSubmit)="changePassword()">
                        <div class="auth-form">
                            <div class="row">
                                <div class="col-12">
                                    <div class="zf-input-group"
                                        [ngClass]="{ 'is-invalid': submitted && f.current_password.errors }">
                                        <div class="zf-input-label">Enter Current Password</div>
                                        <div class="zf-input-control">
                                            <input type="password" (keyup)="checkPassSame()" zioflyAppPassword
                                                class="form-control" formControlName="current_password"
                                                maxlength="{{validations.def.password.max}}">
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.current_password.errors" class="invalid-feedback">
                                        <div *ngIf="f.current_password.errors.required">
                                            {{validations.messages.current_password.required}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="zf-input-group"
                                        [ngClass]="{ 'is-invalid': submitted && f.new_password.errors || submitted && isPassSame }">
                                        <div class="zf-input-label">Enter New Password</div>
                                        <div class="zf-input-control">
                                            <input type="password" (keyup)="checkPassSame()" zioflyAppPassword
                                                class="form-control" formControlName="new_password"
                                                maxlength="{{validations.def.password.max}}">
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && isPassSame" class="invalid-feedback">
                                        <div>{{validations.messages.new_password.issame}}</div>
                                    </div>
                                    <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback mb-3">
                                        <div *ngIf="f.new_password.errors.required">
                                            {{validations.messages.new_password.required}}</div>
                                        <!--<div *ngIf="f.new_password.errors.minlength || f.new_password.errors.maxlength 
                                        || f.new_password.errors.hasNumber || f.new_password.errors.hasCapitalCase 
                                        || f.new_password.errors.hasSmallCase || f.new_password.errors.hasSpecialCharacters">
                                        {{validations.messages.new_password.invalid}}</div>-->
                                        <div *ngIf="f.new_password.errors.minlength || f.new_password.errors.maxlength">
                                            {{validations.messages.new_password.invalid}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="zf-input-group"
                                        [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }">
                                        <div class="zf-input-label">Confirm New Password</div>
                                        <div class="zf-input-control">
                                            <input type="password" zioflyAppPassword class="form-control"
                                                formControlName="password_confirmation"
                                                maxlength="{{validations.def.password.max}}">
                                        </div>
                                    </div>
                                    <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                                        <div *ngIf="f.password_confirmation.errors.required">
                                            {{validations.messages.password_confirmation.required}}</div>
                                        <div *ngIf="f.password_confirmation.errors.mustMatch">
                                            {{validations.messages.password_confirmation.same}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <button type="submit" [disabled]="submitting"
                                        class="btn btn-ziofly-default active block">
                                        <span *ngIf="!submitting">Set New Password</span>
                                        <span *ngIf="submitting">
                                            <span class="zf-btn-loader"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>