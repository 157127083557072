<div class="dialog-inner-wrap" *ngIf="show">
    <h3>Oops!</h3>
    <p>{{msg}}</p>
    <div class="my-4">
        <img src="assets/img/image-dialog-img.png" alt="" class="img-fluid">
    </div>
    <div class="btn-wrap mb-3 mt-5">
        <a class="btn btn-ziofly-default active" (click)="upgradePremium()">
            <span>Upgrade to Premium</span>
        </a>
    </div>
    <div class="btn-wrap">
        <a mat-button class="btn btn-ziofly-default" (click)="onClose()">
            <span>I'll do it later</span>
        </a>
    </div>
</div>

<div class="dialog-inner-wrap" *ngIf="!show">
    <h3>Please verify your email</h3>
    <p>In order to publish your product</p>
    <div class="my-4">
        <img src="assets/img/image-dialog-img.png" alt="" class="img-fluid">
    </div>
    <div class="btn-wrap mb-3 mt-5">
        <a class="btn btn-ziofly-default active" (click)="emailVerify()">
            <span>Send Verification link</span>
        </a>
    </div>
    <!--
    <div class="btn-wrap">
        <a mat-button class="btn btn-ziofly-default" (click)="onClose()">
            <span>Close</span>
        </a>
    </div>
    -->
</div>