import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './front-pages/layout/header/header.component';
import { ContentComponent } from './front-pages/layout/content/content.component';
import { FooterComponent } from './front-pages/layout/footer/footer.component';
import { HomeComponent } from './front-pages/home/home.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FaqComponent } from './front-pages/faq/faq.component';
import { LoaderComponent } from './common/loader/loader.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthLayoutComponent } from './front-pages/auth/auth-layout/auth-layout.component';
import { LoginComponent } from './front-pages/auth/login/login.component';
import { SignupComponent } from './front-pages/auth/signup/signup.component';
import { ForgotComponent } from './front-pages/auth/forgot/forgot.component';
import { NgSelect2Module } from 'ng-select2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './front-pages/user-pages/profile/profile.component';
import { JwtInterceptor } from './helper/jwt.interceptor';
import { BannerComponent } from './common/banner/banner.component';
import { ProfileEditComponent } from './front-pages/user-pages/profile-edit/profile-edit.component';
import { EncrDecrService } from './services/encr-decr.service';
import { AppPasswordDirective } from './helper/app-password.directive';
import { VerifyEmailComponent } from './front-pages/auth/verify-email/verify-email.component';
import { ResetPasswordComponent } from './front-pages/auth/reset-password/reset-password.component';
import { ChangePasswordComponent } from './front-pages/user-pages/change-password/change-password.component';
import { DivLoaderComponent } from './common/div-loader/div-loader.component';
import { CommonModule } from '@angular/common';
import { ContactUSComponent } from './front-pages/contact-us/contact-us.component';
import { NotificationsComponent } from './front-pages/user-pages/notifications/notifications.component';
import { PaymentMethodComponent } from './front-pages/user-pages/payment-method/payment-method.component';
import { AboutUsComponent } from './front-pages/about-us/about-us.component';
import { FileAcceptDirective } from './helper/file-accept.directive';
import { SellAircraftComponent } from './front-pages/user-pages/sell-aircraft/sell-aircraft.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MyAircraftsComponent } from './front-pages/user-pages/my-aircrafts/my-aircrafts.component';
import { ProductListComponent } from './front-pages/user-pages/product-list/product-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductDetailComponent } from './front-pages/user-pages/product-detail/product-detail.component';
import { FavoriteProductComponent } from './front-pages/user-pages/favorite-product/favorite-product.component';
import { PaymentComponent } from './front-pages/user-pages/notifications/payment/payment.component';
import { PrivacyPolicyComponent } from './front-pages/privacy-policy/privacy-policy.component';
import { ErrorInterceptor } from './helper/error.interceptor';
import { TermsAndConditionsComponent } from './front-pages/terms-and-conditions/terms-and-conditions.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ImageDialogComponent } from './front-pages/user-pages/image-dialog/image-dialog.component'
import {MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import { BlogComponent } from './front-pages/blog/blog.component';
import { BlogDetailComponent } from './front-pages/blog-detail/blog-detail.component';
import { NewsArticlesComponent } from './front-pages/news-articles/news-articles.component';
import { EventComponent } from './front-pages/event/event.component';
import { NewsDetailComponent } from './front-pages/news-detail/news-detail.component';
import { MathModule } from './math/math.module';
import { MobileSelectPlanComponent } from './front-pages/mobile-app/mobile-select-plan/mobile-select-plan.component';
import { MobilePaymentPayComponent } from './front-pages/mobile-app/mobile-payment-pay/mobile-payment-pay.component';
import { MobileContentComponent } from './front-pages/layout/mobile-content/mobile-content.component';
import { DateTimeFormatPipe } from './pipes/pipe';
import { PaymentNewComponent } from './front-pages/payment-new/payment-new.component';
import { EncrCvvService } from './services/cvv-encrypt';



const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContentComponent,
    MobileContentComponent,
    FooterComponent,
    HomeComponent,
    FaqComponent,
    LoaderComponent,
    AuthLayoutComponent,
    LoginComponent,
    SignupComponent,
    ForgotComponent,
    ProfileComponent,
    BannerComponent,
    ProfileEditComponent,
    AppPasswordDirective,
    VerifyEmailComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    DivLoaderComponent,
    ContactUSComponent,
    NotificationsComponent,
    PaymentMethodComponent,
    AboutUsComponent,
    FileAcceptDirective,
    SellAircraftComponent,
    MyAircraftsComponent,
    ProductListComponent,
    ProductDetailComponent,
    FavoriteProductComponent,
    PaymentComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ImageDialogComponent,
    BlogComponent,
    BlogDetailComponent,
    NewsArticlesComponent,
    EventComponent,
    NewsDetailComponent,
    MobileSelectPlanComponent,
    MobilePaymentPayComponent,
    DateTimeFormatPipe,
    PaymentNewComponent
  ],
  imports: [
    MathModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ClickOutsideModule,
    PerfectScrollbarModule,
    SlickCarouselModule,
    NgSelect2Module,
    ReactiveFormsModule,
    NgxSliderModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    MatDialogModule

  ],
  exports: [
    MathModule,
  ],
  entryComponents: [ImageDialogComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    EncrDecrService,
    EncrCvvService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
