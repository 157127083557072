<div class="page-wrapper blog-page-wrapper">
	<div class="blog-banner" *ngIf="cmsData?.blog?.blog_banner_image">
		<div class="container">
			<div class="row justify-content-center">
				<!-- <div class="col-12 col-lg-3">
					<div class="blog-banner-content">
						<div class="about-us-banner-content-inner">
							<h3 class="title" [innerHTML]="cmsData?.blog.blog_banner_heading"></h3>
						</div>
					</div>
				</div> -->
				<div class="col-11 col-md-8 col-lg-8 align-self-center">
					<div class="banner-img-wrap">
						<div class="about-us-banner-person-img-holder">
							<img src="assets/img/blog-banner-img.png" class="img-fluid">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="blog-banner" *ngIf="!cmsData?.blog?.blog_banner_image">
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="blog-banner-content">
						<div class="about-us-banner-content-inner">
							<h3 class="title" [innerHTML]="cmsData?.blog.blog_banner_heading"></h3>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6"></div>
			</div>
		</div>
	</div>

</div>
<section class="section vision-misson-section">
	<nav class=" mt-0 mb-0 mob-sec-breadcrumb" aria-label="breadcrumb">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<ol class="breadcrumb mt-0 py-0">
						<li class="breadcrumb-item"><a routerLink="/">Home</a></li>
						<li class="breadcrumb-item active" aria-current="page">Blog</li>
					</ol>
				</div>
			</div>
		</div>

	</nav>
	<div class="container">
		<div class="row">
			<div class="col-12 col-lg-6 align-center--section--title-after-md mob-sec-heading-hide">
				<h2 class="section--title">

					<b [innerHTML]="cmsData?.blog.blog_first_section_heading"></b>
					<span class="animate-flight-svg">
						<i class="animate-flight-svg-border"></i>
						<i class="animate-flight-svg-icon icon-flight-right"></i>
					</span>
				</h2>
			</div>


			<div class="col-12 col-lg-6"></div>
			<div class="col-12  blog-slide-data">
				<ngx-slick-carousel class="similar-listing-slider carousel" #slickModal="slick-carousel"
					[config]="slideConfig_3">
					<div ngxSlickItem class="similar-listing-slide blog-slider" *ngFor="let item of featureBlogData">
						<div class="ap-card">
							<div class="ap-card-body shadow-boder">
								<div class="ap-card-img">
									<ng-container>
										<img class="cursor-pointer ap-card-img-f" (click)="blogDetail(item.slug)"
											src="{{variables.serverAssetUrl}}{{item.image}}">
									</ng-container>
								</div>
								<div class="p-3 p-md-0 p-lg-0">
									<div class="d-flex blog-box-inner blog-label-mob">
										<div>
											<a class="btn-color text-white p-2 px-3"
												(click)="blogDetail(item.slug)">
												{{item.blogcategory}}
											</a>
										</div>
									</div>
									<div>
										<h4 class="cursor-pointer ng-tns-c113-0">{{item.title}}</h4>
									</div>
									<div>
										<div _ngcontent-xyb-c50="" class="d-flex">
											<!-- <a _ngcontent-xyb-c50="" _ngcontent-nji-c102="" ng-reflect-router-link="" href=""
												class="mc-data-link link-connected" style="margin-right: 5px;">
												<span _ngcontent-xyb-c50="" _ngcontent-nji-c102="">
													<img _ngcontent-xyb-c50="" _ngcontent-nji-c102="" src="assets/img/share.png"
														class="mc-img">
												</span>Share
											</a>
											<a _ngcontent-xyb-c50="" _ngcontent-nji-c102="" ng-reflect-router-link="" href=""
												class="mc-data-link link-connected" style="margin-right: 5px;">
												<span _ngcontent-xyb-c50="" _ngcontent-nji-c102="">
													<img _ngcontent-xyb-c50="" _ngcontent-nji-c102=""
														src="assets/img/message.png" class="mc-img">
												</span>150
											</a> -->
											<a _ngcontent-xyb-c50="" _ngcontent-nji-c102=""
												class="mc-data-link link-connected mob-link-blog"
												style="margin-right: 5px;">
												<span _ngcontent-xyb-c50="" _ngcontent-nji-c102="">
													<img _ngcontent-xyb-c50="" _ngcontent-nji-c102=""
														src="assets/img/view.png" class="mc-img">
												</span>{{item.blogview}}
											</a>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</ngx-slick-carousel>
			</div>
		</div>

	</div>
</section>
<section class="section vision-misson-section blog-popular">
	<div class="page-wrapper privacy-pages-cms">
		<div class="container detail-col-p">
			<div class="row space mb-3">
				<div class="col-12 col-lg-6 align-center--section--title-after-md">
					<h2 class="section--title ">
						<b [innerHTML]="cmsData?.blog.blog_second_section_heading"></b>
						<span class="animate-flight-svg">
							<i class="animate-flight-svg-border"></i>
							<i class="animate-flight-svg-icon icon-flight-right"></i>
						</span>
					</h2>
				</div>
			</div>
			<div class="row bloglist-bottom-rowspace">
				<div class="col-lg-6" *ngFor="let item of latestBlogData | paginate: config; let i = index">
					<a class="mc-data-link" [routerLink]="['/blog-detail', item.slug]">
						<div class="card-seller ">
							<div class="d-flex box-center-align blog-col-1">
								<div class="box-left-side">
									<img src="{{variables.serverAssetUrl}}{{item.image}}" class="blog-img-small">
								</div>
								<div class="box-right-side">
									<div class="col ng-tns-c106-0">
										<h5 class="ng-tns-c106-3 ng-star-inserted">
											{{item.title}}
										</h5>

										<p [innerHTML]="item.description" class="desc_length"></p>
										<div class="d-flex align-items-center more-blogbtn"
											style="justify-content: space-between;">
											<div>
												<a class="btn btn-ziofly-default active" href="">
													<span _ngcontent-egg-c59="">Read More</span>
												</a>
											</div>

											<div style="justify-content: space-around;">
												<!-- <a _ngcontent-nji-c102="" class="mc-data-link link-connected" ng-reflect-router-link="" href="" style="margin-right: 5px;"> 
                                                    <span _ngcontent-nji-c102="">
                                                        <img _ngcontent-nji-c102="" src="assets/img/share.png" class="mc-img">
                                                    </span>Share
                                                </a>
												<a _ngcontent-nji-c102="" class="mc-data-link link-connected" ng-reflect-router-link="" href="" style="margin-right: 5px;"> 
                                                    <span _ngcontent-nji-c102="">
                                                        <img _ngcontent-nji-c102="" src="assets/img/message.png" class="mc-img">
                                                    </span>150
                                                </a> -->
												<a _ngcontent-nji-c102=""
													class="mc-data-link link-connected mob-link-blog"
													style="margin-right: 5px;">
													<span _ngcontent-nji-c102="">
														<img _ngcontent-nji-c102="" src="assets/img/view.png"
															class="mc-img">
													</span>{{item.blogview}}
												</a>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</section>
<div _ngcontent-iup-c102="">
	<!--<pagination-controls _ngcontent-iup-c102="" previouslabel="" nextlabel="" class="zf-pagination" ng-reflect-previous-label="" ng-reflect-next-label="">
		<pagination-template ng-reflect-max-size="7">
			<ul role="navigation" class="ngx-pagination" aria-label="Pagination">
				<li class="pagination-previous disabled"><span>
                      <span class="show-for-sr">page</span></span>
				</li>
				<li class="small-screen">1 / 2</li>
				<li class="current"> <span class="show-for-sr">You're on page </span><span>1</span>
				</li>
				<li><a tabindex="0"><span class="show-for-sr">page </span><span>2</span></a>
				</li>
				<li class="pagination-next">
					<a tabindex="0" aria-label=" page"> <span class="show-for-sr">page</span>
					</a>
				</li>
			</ul>
		</pagination-template>
	</pagination-controls>-->
	<div class="" *ngIf="latestBlogData?.length>0">
		<pagination-controls (pageChange)="pageChange($event)" previousLabel="" nextLabel="" class="zf-pagination">
		</pagination-controls>
	</div>
</div>