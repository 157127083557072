<div class="about-us-banner privacy-banner d-flex align-items-center z-pagebanner">
    <div class="container">
        <div class="row">
            <div class="col-12 col-lg-8">
                <div class="about-us-banner-content">
                    <div class="about-us-banner-content-inner">
                        <h3 class="title">{{blogdetail?.title}}</h3>

                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">

            </div>
        </div>
    </div>
</div>
<section class="blog-detail-bgcolor py-0 py-md-2 py-lg-2">
    <div class="back-prieves-page">
        <div class="container">
            <div class="row">
                <div class="col-12 text-right text-sm-right text-md-left text-lg-lef">
                    <a _ngcontent-hgo-c119="" class="btn btn-ziofly-default default" [routerLink]="['/blog']">
                        <span _ngcontent-hgo-c119="">Back</span>
                    </a>
                </div>

            </div>
        </div>
    </div>
    <nav class=" mt-0 mb-0 mob-sec-breadcrumb" aria-label="breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <ol class="breadcrumb mt-0 py-0">
                        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/blog">Blog</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Blog Details</li>
                    </ol>
                </div>
            </div>
        </div>
    </nav>
    <div class="blog-detail-img">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12 detail-col-p" *ngIf="blogdetail?.image">
                    <div class="detail-img-wrap">
                        <!--<img  alt="image" src="http://dev.ziofly.com/admin/storage/app/products/sNbKMEtuzjPmVecKmYnz1QmviN9ljFHKID6FInID.jpg" class="img-fluid">-->
                        <img alt="image" src="{{variables.serverAssetUrl}}{{blogdetail?.image}}" class="img-fluid">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-12">
                    <div class="page-wrapper privacy-pages-cms">


                        <div class="mg-btm">
                            <span [innerHTML]="blogdetail?.description"> This privacy policy has been compiled to
                                better serve those who are concerned with
                                how their 'Personally identifiable information' (PII) is being used online. PII,
                                used in US privacy law and information security, is information that can be used o
                                n its own or with other information to identify, contact, or locate a single person,
                                or to identify an individual in context. Please read our privacy policy carefully to
                                get a clear understanding of how we collect, use, protect or otherwise handle your
                                Personally Identifiable Information in accordance with our website.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>