import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'ziofly-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  public show: boolean;
  constructor(private loaderService: LoaderService) { }

  ngOnInit(): void {
    this.loaderService.loaderStatus$.subscribe((response: any) => {
      this.show = response.status
      if(this.show) {
        document.getElementsByTagName('html')[0].classList.add('disable-scroll')
        document.getElementsByTagName('body')[0].classList.add('disable-scroll')
      } else {
        document.getElementsByTagName('html')[0].classList.remove('disable-scroll')
        document.getElementsByTagName('body')[0].classList.remove('disable-scroll')
      }
    });
  }

}
