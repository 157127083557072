import { Component, OnInit } from '@angular/core';
import {Options } from '@angular-slider/ngx-slider'
import { BlogService } from 'src/app/services/blog.service';
import { environment } from 'src/environments/environment';
import { variables } from 'src/environments/variables';
import { CmsService } from 'src/app/services/cms.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ziofly-news-articles',
  templateUrl: './news-articles.component.html',
  styleUrls: ['./news-articles.component.scss']
})
export class NewsArticlesComponent implements OnInit {

  public newsArticle: any;
  public baseUrlServer: string = environment.apiUrl + '/';
  public config;
  public offset = 0;
  limitList= ['6','12','18'];
  limitListShowable = [];
  public hotNews1: any;
  public hotNews2: any;
  public hotNews3: any;
  public hotNews4: any;
  public LatestNews: any = [];
  variables = variables;
  cmsData;

  constructor(private blog:BlogService, private user:BlogService, private dataheading:BlogService,
    private cms: CmsService, private Router: Router){
      this.cms.getCmsData().subscribe(x=>{
        this.cmsData = x[0];
        // console.log(this.cmsData);
      })
  }

  ngOnInit(): void {
    this.config = {
      currentPage: 1,
      itemsPerPage: 6,
      totalItems: 0,
      query: ''
    };
    this.getFirstNewsSection();
    this.getAviationNews();
    this.setLimit();
    this.listLimitChange(6);
  }

  getFirstNewsSection(){
    var apidata = {
      limit: this.config.itemsPerPage,
    };
    this.blog.hotNewsDetail(apidata).subscribe(res=>{
      let data = res;
      this.newsArticle = res['data'];

      this.newsArticle.forEach((val, index) => {
        if(val.priority == 1){
          this.hotNews1 = val;
        }
        if(val.priority == 2){
          this.hotNews2 = val;
        }
        if(val.priority == 3){
          this.hotNews3 = val;
        }
        if(val.priority == 4){
          this.hotNews4 = val;
        }
      });
    })
  }

  setLimit(){
    this.limitListShowable = [];
    var isStop = false;
    this.limitList.map(x=>{
      if(isStop){
        return;
      }
      var y = x;
      if(x>this.config.totalItems){
          y = this.config.totalItems
          isStop = true;
      }
      this.limitListShowable.push({
          id: x,
          text: y + " out of "+this.config.totalItems
      })
    })
  }

  getAviationNews(){
    var apidata = {
      offset: this.offset,
      limit: this.config.itemsPerPage,
    };
    this.blog.aviationNewsDetail(apidata).subscribe(res=>{
      let data = res;
      this.LatestNews = res['data'];
      this.config.totalItems = res['newscount'];
      if(this.config.totalItems==undefined){
        this.config.totalItems = 0;
      }
      this.setLimit();
    })
  }

  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.offset = (newPage-1)*this.config.itemsPerPage;
    this.getAviationNews();
  }

  listLimitChange(e){
    this.config.itemsPerPage = e;
    this.getAviationNews();
  }

  details(slug){
    this.Router.navigate(['/news-detail', slug]);
  }
}
